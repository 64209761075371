import React, { useEffect, useState } from "react";
import "./financialtab.css";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { ExternalPendingPaymentsColoumns, setExternalPendingPaymentsData } from "./Data";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { LoadPanel, Popup } from "devextreme-react";
import { DynamicTabb } from "../../../../../components/DynamicTab/DynamicTab";
import "../financial.css";
import { PersonFind } from "../../../../PersonFind/PersonFind";
import { Toster } from "../../../../../components/Toster/Toster";

export const ExternalPendingPayments = () => {
  const pathname = handleGetLocal("pathURL");
  const [popupOPen, setpopupOPen] = useState(false);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [payment, setPayment] = useState(null);
  const [note, setNote] = useState('');
  const [notePopup, setNotePopup] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  const getExternalPendingPayments = async () => {
    setLoad(true);
    
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Financial/get-external-payments-grid`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setData(setExternalPendingPaymentsData(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  const acceptRejectPayment = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    console.log('here', payment);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Financial/${payment?.mode}-external-payment`,
        {
          "gPendingPaymentID": payment?.gPendingPaymentId,
          "gPersonID": payment["gPersonId"],
          "Notes": note || 'test'  
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setNotePopup(false);
        setNote('');
        setLoad(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: "success",
          message: `payment ${payment?.mode}ed successfully.`
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            type: "",
            message: ``
          });
        }, 1000);
        getExternalPendingPayments();
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  useEffect(() => {
    if(payment !== null && payment.mode === 'reject'){
      setNotePopup(true);
    }
    if(payment?.mode === 'accept'){
      if(payment['gPersonId']){
        acceptRejectPayment();
      } else {
        setpopupOPen(true);
      }
    }
  }, [payment]);

  const updateExternalPaymentRecord = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/update-personid-for-pending-payment`,
        {
          "gPendingPaymentID": payment?.gPendingPaymentId,
          "gPersonID": confirmationData?.gPersonId
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setPayment({...payment, ['gPersonId']: confirmationData?.gPersonId});
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  useEffect(() => {
    updateExternalPaymentRecord();
    setpopupOPen(false);
  }, [confirmationData]);

  useEffect(() => {
    getExternalPendingPayments();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <PersonFind isPopupVisible={popupOPen} setIsPopupVisible={setpopupOPen} fromOP3={true} setConfirmationData={setConfirmationData}/>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={notePopup}
        onHiding={() => {
          setNotePopup(false);
        }}
        showTitle={false}
        width={800}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="edit_confirmation" style={{border:'none'}}>
          <div className="popup_header">
            <div className="popup_header_title">
              <div class="popup_header_title_data">
                <p>Confirmation</p>
              </div>
            </div>
            <svg
              onClick={() => {
                setNotePopup(false);
              }}
              style={{
                cursor: "pointer",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="edit_confirmation_content">
            <p className="sub_heading">
              Reason?
            </p>
          </div>
          <textarea
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  className="dark-mode-textarea"
                  style={{ marginLeft: "40px" }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={acceptRejectPayment}>
              Yes
            </button>
            <button
              className="btn edit_payment_btn"
              onClick={() => {
                setNotePopup(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Popup>
      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <div className="financial_overview_tab_buttons">
            <p className="page-info-number">OP.3</p>
            <div className="page-info-buttons">
              <div className="buttons" style={{ marginTop: "20px" }}>
                <button className="btn" onClick={getExternalPendingPayments}>
                  Refresh
                  <svg
                    className="svg-blue-dark"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                  >
                    <path
                      d="M7.99905 3.99857V1.20857C7.99905 0.758566 7.45905 0.538566 7.14905 0.858566L3.34905 4.64857C3.14905 4.84857 3.14905 5.15857 3.34905 5.35857L7.13905 9.14857C7.45905 9.45857 7.99905 9.23857 7.99905 8.78857V5.99857C11.7291 5.99857 14.6791 9.41857 13.8591 13.2886C13.3891 15.5586 11.5491 17.3886 9.28905 17.8586C5.71905 18.6086 2.53905 16.1586 2.05905 12.8486C1.98905 12.3686 1.56905 11.9986 1.07905 11.9986C0.479051 11.9986 -0.000948563 12.5286 0.0790514 13.1286C0.699051 17.5186 4.87905 20.7686 9.60905 19.8486C12.7291 19.2386 15.2391 16.7286 15.8491 13.6086C16.8391 8.47857 12.9391 3.99857 7.99905 3.99857Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={ExternalPendingPaymentsColoumns}
              load={load}
              coloumWidthText={"Financial_op3"}
              tableRow={data?.map((val, i) => {
                return { ...val, ID: i };
              })}
              ExternalPendingPaymentsContextMenu={true}
              setPayment={(selected) => setPayment(selected)}
              pendingStatusFilter={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
