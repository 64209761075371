import { LoadPanel, Popup, TextBox } from "devextreme-react";
import { ProntactEntitiesTopButton } from "./ProntactEntitiesTopButton";
import { Toster } from "../../../components/Toster/Toster";
import { ApiError } from "../../Error/ApiError";
import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { ProntactEntitiesSubTab } from "./ProntactEntitiesSubTab/ProntactEntitiesSubTab";
import { ProntactEntityFunction } from "./ProntactEntityFunction";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  removeaddProntactEntitiesdynamicTab,
  setProntactEntitiesSubTabinvoicingData,
  setProntactEntitieschange,
} from "../../../reducer/AdminReadReducer";
import { useSelector } from "react-redux";
import { setentityperson } from "../../../reducer/ParticipateReducer";
import { headersidebarfunction } from "../../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../../HeaderSideBar/HelpPopup";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";
import { setDynamicTab } from "../../../reducer/HomePageReducer";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled, IsChangefunction }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
        IsChangefunction={IsChangefunction}
      />
    </>
  );
};

export const ProntactEntities = () => {
  const clas = new ProntactEntityFunction();
  const [EditTogel, setEditTogel] = useState(false);
  const { contectTableAddData2 } = useSelector((store) => store.participate);
  const [erorhandling, seterorhandling] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [loading, setloading] = useState(false);
  const [entitidata, setentitidata] = useState([]);
  const {
    ProntactEntitiesSubTabAddresData,
    ProntactEntitiesSubTabinvoicingData,
    ProntactEntitiesSubTabDockumentWizarddata,
  } = useSelector((store) => store.AdminTable);

  const { Entitiesid } = useParams();
  const [addProntactEntities, setaddProntactEntities] = useState(false);
  const [cloneEntityId, setCloneEntityId] = useState("");
  const [entitydatadropdown, setentitydatadropdown] = useState([]);
  const [entitydatadropdown2, setentitydatadropdown2] = useState([]);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [fields, setFIelds] = useState({
    "Entity Name": "",
    Telephone: "",
    Fax: "",
    Region: { name: "", id: "" },
    "Payment Allocation Rule": { name: "", id: "" },
  });
  const [ContactPositiondropdown, setContactPositiondropdown] = useState([]);
  const [Contactdropdown, setContactdropdown] = useState([]);

  const [entityid, setentityid] = useState("");
  const [fields2, setfields2] = useState({
    "Company Entity": { name: "", id: "" },
    "Reporting Entity": { name: "", id: "" },
  });

  const oncancel = () => {
    setEntityNameValidation("");
    setPaymentAllocationRuleValidation("");
    if (Entitiesid && PaymentAllocationRuledropdown?.length) {
      clas.getentity(
        setloading,
        setFIelds,
        Entitiesid,
        PaymentAllocationRuledropdown,
        navigation,
        setentitidata,
        entitydatadropdown,
        Regiondropdown,
        dispatch,
        setentityid,
        setcheckispersoncreate,
        setfields2,
        entitydatadropdown2
      );
    } else {
      setFIelds({
        "Entity Name": "",
        Telephone: "",
        Fax: "",
        Region: { name: "", id: "" },
        "Payment Allocation Rule": { name: "", id: "" },
      });
    }

    dispatch(setProntactEntitieschange(false));
    setaddProntactEntities(false);
    if (!Entitiesid) {
      dispatch(removeaddProntactEntitiesdynamicTab("/prontact/entity"));
    }
    setEditTogel(false);
  };

  const [Regiondropdown, setRegiondropdown] = useState([]);
  const [PaymentAllocationRuledropdown, setPaymentAllocationRuledropdown] = useState([]);
  const [checkispersoncreate, setcheckispersoncreate] = useState(false);

  const [EntityNameValidation, setEntityNameValidation] = useState("");
  const [PaymentAllocationRuleValidation, setPaymentAllocationRuleValidation] = useState("");

  const onSave = () => {
    if (fields?.["Entity Name"]?.trim() && fields?.["Payment Allocation Rule"]?.id) {
      setEntityNameValidation("");
      setPaymentAllocationRuleValidation("");
      clas.Save(
        fields,
        navigation,
        setloading,
        toastConfig,
        setToastConfig,
        Entitiesid,
        dispatch,
        setEditTogel,
        setFIelds,
        PaymentAllocationRuledropdown,
        setProntactEntitieschange,
        entitidata,
        setentitidata,
        entitydatadropdown,
        Regiondropdown,
        ProntactEntitiesSubTabAddresData,
        ProntactEntitiesSubTabinvoicingData,
        ProntactEntitiesSubTabDockumentWizarddata,
        contectTableAddData2,
        setentityid,
        setcheckispersoncreate,
        fields2,
        setfields2,
        entitydatadropdown2
      );
    } else {
      !fields?.["Entity Name"]?.trim()
        ? setEntityNameValidation("Please enter the Entity Name")
        : setEntityNameValidation("");
      !fields?.["Payment Allocation Rule"]?.id
        ? setPaymentAllocationRuleValidation("Please select the Payment Allocation Rule")
        : setPaymentAllocationRuleValidation("");
    }
  };

  useEffect(() => {
    clas.getDRopDown(
      navigation,
      setRegiondropdown,
      setPaymentAllocationRuledropdown,
      setentitydatadropdown,
      setContactPositiondropdown,
      setContactdropdown,
      setentitydatadropdown2
    );
  }, []);

  const getDRopDown = () => {
    clas.getDRopDown(
      navigation,
      setRegiondropdown,
      setPaymentAllocationRuledropdown,
      setentitydatadropdown,
      setContactPositiondropdown,
      setContactdropdown,
      setentitydatadropdown2
    );
  };

  useEffect(() => {
    if (Entitiesid && PaymentAllocationRuledropdown?.length) {
      clas.getentity(
        setloading,
        setFIelds,
        Entitiesid,
        PaymentAllocationRuledropdown,
        navigation,
        setentitidata,
        entitydatadropdown,
        Regiondropdown,
        dispatch,
        setentityid,
        setcheckispersoncreate,
        setfields2,
        entitydatadropdown2
      );
      setEntityNameValidation("");
      setPaymentAllocationRuleValidation("");
    } else if (!cloneEntityId) {
      setFIelds({
        "Entity Name": "",
        Telephone: "",
        Fax: "",
        Region: { name: "", id: "" },
        "Payment Allocation Rule": { name: "", id: "" },
      });
    }
    if (!cloneEntityId) {
      dispatch(
        setProntactEntitiesSubTabinvoicingData({
          EMailAddressList: "",
          BCCAddressList: "",
          InvoiceTemplet1: { name: "", id: "" },
          InvoiceTemplet2: { name: "", id: "" },
          InvoiceTemplet3: { name: "", id: "" },
        })
      );
      setEditTogel(false);
      setaddProntactEntities(false);
      setentitidata([]);
    }
  }, [Entitiesid, PaymentAllocationRuledropdown?.length]);

  const getentity = () => {
    clas.getentity(
      setloading,
      setFIelds,
      Entitiesid,
      PaymentAllocationRuledropdown,
      navigation,
      setentitidata,
      entitydatadropdown,
      Regiondropdown,
      dispatch,
      setentityid,
      setcheckispersoncreate,
      setfields2,
      entitydatadropdown2
    );
  };

  const clas2 = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);

  useEffect(() => {
    clas2.getHelp(
      setloading,
      navigation,
      setmarkup,
      setmarkupid,
      "MainProntact",
      setmarkupissScreenNamu,
      setmarkupisCaption
    );
  }, []);

  return (
    <>
      {markupid && (
        <Popup
          visible={markupid && helpPopUp}
          onHiding={() => {
            setHelpPopUp(false);
          }}
          showTitle={false}
          width={1200}
          height={670}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <HelpPopup
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            setHelpPopUp={setHelpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
          />
        </Popup>
      )}
      <div className="particcipentPage">
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <DynamicTabb />
        <div style={{ display: "flex" }}>
          <p class="popup_header_title_para2">EN.1</p>
          {markupid && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28px"
              height="28px"
              viewBox="0 0 24 24"
              fill="none"
              style={{ cursor: "pointer" }}
              className="svg-blue-black"
              onClick={() => {
                setHelpPopUp(true);
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                fill="white"
              />
            </svg>
          )}
        </div>
        {erorhandling ? (
          <ApiError />
        ) : (
          <div>
            <LoadPanel shadingColor="true" visible={false} delay={10} />
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <div className="go-to-header-section">
              <div style={{ height: "400px" }} className="go-to-header-container">
                <div className="page-info-buttons">
                  <ProntactEntitiesTopButton
                    setToastConfig={setToastConfig}
                    toastConfig={toastConfig}
                    setaddProntactEntities={setaddProntactEntities}
                    setEditTogel={setEditTogel}
                    getentity={getentity}
                    setentityid={setentityid}
                    entitidata={entitidata}
                    setCloneEntityId={setCloneEntityId}
                  />
                  {EditTogel ? (
                    <>
                      <div className="buttons">
                        <button
                          onClick={() => {
                            oncancel();
                          }}
                          className="secondary_btn"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            onSave();
                          }}
                          className="primary_btn"
                        >
                          Save
                          <svg
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    (addProntactEntities || Entitiesid) && (
                      <button
                        className="primary_btn"
                        onClick={() => {
                          setEditTogel(true);
                        }}
                      >
                        Edit
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006V5.04006Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    )
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "65%",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="goto-header-content">
                    <div className="go-to-inputs-field">
                      <p style={{ fontWeight: "bold" }}>
                        Entity Name <span className="marks_mandatary">*</span>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <TextBox
                          disabled={!EditTogel}
                          style={{ width: "100%" }}
                          value={fields["Entity Name"]}
                          onValueChange={(e) => {
                            setEntityNameValidation("");
                            dispatch(setProntactEntitieschange(true));
                            setFIelds({
                              ...fields,
                              "Entity Name": e,
                            });
                          }}
                          isValid={true}
                          placeholder={"Entity Name"}
                        />
                        <div
                          style={{
                            width: "200px",
                            marginLeft: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <p style={{ fontWeight: "bold", fontSize: "13px" }}>Entity Id :</p>
                          <p style={{ fontWeight: "bold", fontSize: "13px" }}>
                            {entityid && Entitiesid && `E${entityid}`}
                          </p>
                        </div>
                      </div>

                      <p style={{ color: "red" }}>{EntityNameValidation}</p>
                    </div>
                    <div className="go-to-inputs-field">
                      <p style={{ fontWeight: "bold" }}>Telephone</p>
                      <div>
                        <TextBox
                          disabled={!EditTogel}
                          style={{ width: "140%" }}
                          validationError={false}
                          showMaskMode="always"
                          maskRules={{ H: /^[0-9*]$/ }}
                          mask="(HHH) HHH-HHHH"
                          isValid={true}
                          value={String(fields.Telephone).replace(/\s/g, "")}
                          onValueChange={(e) => {
                            dispatch(setProntactEntitieschange(true));
                            setFIelds({
                              ...fields,
                              Telephone: e.replace(/\s/g, ""),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="go-to-inputs-field">
                      <p style={{ fontWeight: "bold" }}>Fax</p>
                      <div>
                        <TextBox
                          disabled={!EditTogel}
                          style={{ width: "140%" }}
                          value={fields.Fax}
                          onValueChange={(e) => {
                            dispatch(setProntactEntitieschange(true));
                            setFIelds({
                              ...fields,
                              Fax: e,
                            });
                          }}
                          isValid={true}
                          placeholder={"Fax"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="goto-header-content">
                    {Entitiesid &&
                      (entityid && checkispersoncreate ? (
                        <button
                          onClick={() => {
                            if (!entityid) {
                              return;
                            }
                            dispatch(
                              setDynamicTab({
                                Offender: `E${entityid}`,
                                link: `/participant/${`E${entityid}`}`,
                                title: fields["Entity Name"],
                                entity: true,
                              })
                            );
                            dispatch(setentityperson(true));
                            navigation(`/participant/${`E${entityid}`}`);
                          }}
                          style={{
                            width: "170px",
                            marginTop: "15%",
                            background: "var(--button-lightblack-lightblue)",
                            color: "var(--TextColor)",
                          }}
                        >
                          Jump To Entity Person
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            clas.create_entity_person(
                              setloading,
                              navigation,
                              Entitiesid,
                              setentityid,
                              setcheckispersoncreate
                            );
                          }}
                          style={{
                            width: "170px",
                            marginTop: "15%",
                            background: "var(--button-lightblack-lightblue)",
                            color: "var(--TextColor)",
                          }}
                        >
                          Create Entity Person
                        </button>
                      ))}
                  </div>
                  <div className="goto-header-content">
                    <div className="goto-docket-svg-content">
                      <div className="go-to-inputs-field edit_content_space">
                        <p style={{ fontWeight: "bold" }}>Region</p>
                        <DropDown
                          disabled={!EditTogel}
                          dropDownData={Regiondropdown}
                          fieldNmae={"Region"}
                          fields={fields}
                          setfields={setFIelds}
                          IsChangefunction={setProntactEntitieschange}
                        />
                      </div>
                    </div>
                    <div className="go-to-inputs-field edit_content_space">
                      <p style={{ fontWeight: "bold" }}>
                        Payment Allocation Rule <span className="marks_mandatary">*</span>
                      </p>
                      <DropDown
                        disabled={!EditTogel}
                        dropDownData={PaymentAllocationRuledropdown}
                        fieldNmae={"Payment Allocation Rule"}
                        fields={fields}
                        setfields={setFIelds}
                        reset={setPaymentAllocationRuleValidation}
                        IsChangefunction={setProntactEntitieschange}
                      />
                      <p style={{ color: "red" }}>{PaymentAllocationRuleValidation}</p>
                    </div>
                  </div>
                </div>
              </div>
              <ProntactEntitiesSubTab
                EditTogel={EditTogel}
                addProntactEntities={addProntactEntities}
                setentitidata={setentitidata}
                entitidata={entitidata}
                entitydatadropdown={entitydatadropdown}
                ContactPositiondropdown={ContactPositiondropdown}
                Contactdropdown={Contactdropdown}
                getDRopDown={getDRopDown}
                entitydatadropdown2={entitydatadropdown2}
                fields2={fields2}
                setfields2={setfields2}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
