import { DataGrid, DateBox, DropDownBox, LoadPanel, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { useSelector } from "react-redux";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
      />
    </>
  );
};

export const AddTolling = ({
  setAddpopupTogall,
  text,
  reset,
  setReset,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  userinfo,
  Tollingreset,
  setTollingreset,
  goToTollingDockerDasible,
  dockertDetail,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState({
    Docket: { name: "", id: "" },
    Type: { name: "", id: "" },
    "Start Date": new Date().toISOString().split("T")[0] + "T00:00:00",
    "End Date": "",
    Active: true,
    DocketStatus: false,
  });
  const [docketTypes, setdocketTypes] = useState([]);

  const pathname = handleGetLocal("pathURL");

  const [Docketvalidation, setDocketvalidation] = useState("");
  const [Typevalidation, setTypevalidation] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const OnSave = async (body) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/tolling/add-edit`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully updated!",
            type: "success",
          });
        }, 1400);
        Reset();
        setTollingreset(!Tollingreset);
        setAddpopupTogall(false);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const { user_data } = useSelector((store) => store.Home);

  const Save = () => {
    if (selectedValue?.gDocketId && fields?.Type.id) {
      setDocketvalidation("");
      setTypevalidation("");
      OnSave({
        GTollingId: "00000000-0000-0000-0000-000000000000",
        GEntryUserId: user_data.gUserId,
        GDocketId: selectedValue?.gDocketId,
        DStart: fields?.["Start Date"],
        BActive: fields.Active ? 1 : 0,
        DEnd: fields?.["End Date"],
        GTollingTypeId: fields?.Type.id,
        IDays: 0,
      });
    } else {
      !fields?.Type.id && setTypevalidation("Please Select Type");
      !selectedValue?.gDocketId && setDocketvalidation("Please Select Docket");
    }
  };

  const Reset = () => {
    const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
    setSelectedValue(selectedDocket);
    setDocketvalidation("");
    setFields({
      Docket: { name: "", id: "" },
      Type: { name: "", id: "" },
      "Start Date": new Date().toISOString().split("T")[0] + "T00:00:00",
      "End Date": "",
      Active: true,
      DocketStatus: false,
    });
  };

  useEffect(() => {
    Reset();
  }, [reset]);

  useEffect(() => {
    if (dropdownData?.tollingType?.length) {
      setdocketTypes(
        dropdownData?.tollingType?.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
    }
  }, [dropdownData]);

  useEffect(() => {
    if (text === "Add Tolling") {
      const fetchedDataSource = docketDropdownData?.map((val) => {
        const convertDateFormat = (dateString) => {
          const dateObj = new Date(dateString);
          return dateObj.toLocaleDateString("en-US");
        };
        return {
          Court: val?.sCourtName,
          Docket: val?.sDocketNumberCourtCounts,
          "Sentence Date": convertDateFormat(val?.dSentence),
          "Supervision Status": val?.sSupervisionStatusName,
          gDocketId: val?.gDocketId,
          Offense: val?.sOffenseDescription,
        };
      });
      setDataSource(fetchedDataSource);
    }
  }, [text, docketDropdownData]);
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{
          width: "100%",
          height: "438px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        <ScrollView>
          <div className="RecivePaymentModel_header">
            <div style={{ width: "160px" }} className="RecivePaymentModel_header_name">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_632_343122)">
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_632_343122">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p style={{ marginLeft: "-10px" }}>{"Add Tolling"} </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  DK.9
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setAddpopupTogall(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ width: "96%", marginLeft: "2%" }}>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2inputt">
                <p>Docket</p>
                <div>
                  <DropDownBox
                    placeholder="Select..."
                    disabled={goToTollingDockerDasible ? true : false}
                    style={{ marginTop: "3.5%" }}
                    width={280}
                    dataSource={dataSource}
                    value={selectedValue?.Docket}
                    valueExpr={"Docket"}
                    displayExpr={"Docket"}
                    opened={isGridBoxOpened}
                    onOptionChanged={onGridBoxOpened}
                    contentRender={(value, element) => (
                      <DataGrid
                        dataSource={dataSource}
                        hoverStateEnabled={true}
                        height={"auto"}
                        onRowClick={(e) => {
                          setSelectedValue(e?.data);
                          setDocketvalidation("");
                          setIsGridBoxOpened(false);
                        }}
                      >
                        <Selection mode="single" />
                        <FilterRow visible={true} />
                        <Column dataField="Court" caption="Court" width={"auto"} />
                        <Column dataField="Docket" caption="Docket" width={"auto"} />
                        <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                        <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                        <Column dataField="Offense" caption="Offense" width={"auto"} />
                      </DataGrid>
                    )}
                  />
                </div>
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {Docketvalidation}
                </p>
              </div>
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Type <span>*</span>
                </p>
                <div>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"Type"}
                    dropDownData={docketTypes}
                    reset={setTypevalidation}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {Typevalidation}
                  </p>
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2inputt">
                <p>Start Date</p>
                <div>
                  <DateBox
                    style={{ marginTop: "3.5%" }}
                    value={fields?.["Start Date"]}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        "Start Date": e ? new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA") : null,
                      });
                    }}
                    useMaskBehavior={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2inputt">
                <p>End Date</p>
                <div>
                  <DateBox
                    style={{ marginTop: "3.5%" }}
                    disabled={fields.Active}
                    value={fields?.["End Date"]}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        "End Date": e ? new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA") : null,
                      });
                    }}
                    useMaskBehavior={true}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="collectionpopupBLOCK2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-20px",
                  }}
                  className="collectionpopupBLOCK2inputt"
                >
                  <input
                    checked={fields?.Active}
                    onChange={() => {
                      setFields({ ...fields, Active: !fields.Active });
                    }}
                    type="checkbox"
                  />
                  <p>Active</p>
                </div>
                <div className="collectionpopupBLOCK2inputt">
                  <div style={{ marginBottom: "5%" }} className="quickAddObligationn">
                    <div>
                      <p>Days</p>
                      <p
                        style={{
                          marginLeft: "15px",
                        }}
                      >
                        {"0"}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" />
                    <p>Change Docket Status to Tolled-Warrant</p>
                  </div>
                </div>
              </div>
            </div>
            <p style={{ marginTop: "2%" }}>
              Active Tolls will automatically set their end date to the current date every day. Only one Toll may be
              active on a given docket.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  width: "15%",
                  cursor: "pointer",
                  marginRight: "1%",
                }}
                onClick={() => {
                  Save();
                }}
              >
                Save
              </button>
              <button
                style={{
                  background: "var(--button-lightblack-lightblue)",
                  color: "var(--icons2)",
                  width: "15%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Reset();
                  setAddpopupTogall(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
