import { useState } from "react";
import { Table1 } from "../../../../../Administration/Table1/Table1";
import { recipientsTableColumn } from "../Data";
import { Popup } from "devextreme-react";
import { AddRecipients } from "./AddRecipients";
import ObligationVoidPopup from "./ObligationVoidPopup";

export const RecipientsTable = ({
  toastConfig,
  setToastConfig,
  recipientsTableData,
  refreshGetRecipientsTableData,
  recipientsDropdownData,
  userinfo,
  recipientsPaymentDropdownData,
  recipientsEntityDropdownData,
  gObligationID,
}) => {
  const [loading, setLoading] = useState(false);
  const [addRecipientsPopup, setAddRecipientsPopup] = useState(false);
  const [recipientsType, setRecipientsType] = useState("Add");
  const [recipientsRowData, setRecipientsRowData] = useState([]);
  const [recipientsClickRowData, setRecipientsClickRowData] = useState([]);
  const [voidPopup, setVoidPopup] = useState(false);

  const address = `${
    recipientsClickRowData?.sRecipientDisbursement_Address1
      ? recipientsClickRowData?.sRecipientDisbursement_Address1
      : ""
  },
  ${recipientsClickRowData?.sRecipientDisbursement_State ? recipientsClickRowData?.sRecipientDisbursement_State : ""}
  ${recipientsClickRowData?.sRecipientDisbursement_Zip ? recipientsClickRowData?.sRecipientDisbursement_Zip : ""}-
  ${recipientsClickRowData?.sRecipientDisbursement_Zip4 ? recipientsClickRowData?.sRecipientDisbursement_Zip4 : ""}`;

  return (
    <>
      <Popup
        visible={addRecipientsPopup}
        onHiding={() => {
          setAddRecipientsPopup(false);
        }}
        showTitle={false}
        width={680}
        height={600}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddRecipients
          addRecipientsPopup={addRecipientsPopup}
          setAddRecipientsPopup={setAddRecipientsPopup}
          recipientsType={recipientsType}
          recipientsRowData={recipientsRowData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          recipientsDropdownData={recipientsDropdownData}
          refreshGetRecipientsTableData={refreshGetRecipientsTableData}
          sRecipientCategoryName={userinfo?.sRecipientCategoryName}
          recipientsPaymentDropdownData={recipientsPaymentDropdownData}
          recipientsEntityDropdownData={recipientsEntityDropdownData}
          gObligationID={gObligationID}
        />
      </Popup>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={680}
        height={460}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ObligationVoidPopup
          voidPopup={voidPopup}
          setVoidPopup={setVoidPopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          voidHeading={"Void Payment"}
          voidSubHeading={"Void Recipient"}
          bottomHeading={"Void Recipient"}
          setLoading={setLoading}
          refreshApi={refreshGetRecipientsTableData}
          apiURl={"obligations/void-recipient"}
          fieldID={recipientsRowData?.gDisburseId}
          fieldName={"GDisburseId"}
        />
      </Popup>
      <div className="recipients-table-section">
        <button
          className="financial_overview_tab_button"
          onClick={() => {
            setAddRecipientsPopup(!addRecipientsPopup);
            setRecipientsType("Add");
          }}
          style={{ width: "168px" }}
        >
          Add Recipients
          <svg
            className="svg-blue-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_6714_35042)">
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_6714_35042">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div className="financial_tab_table">
          <Table1
            editTable={false}
            height={"auto"}
            Tablecoloum={recipientsTableColumn}
            tableRow={recipientsTableData?.map((val, i) => {
              return { ...val, ID: i };
            })}
            load={loading}
            coloumWidthText={"Recipients"}
            mandatoryColoum={"DOE"}
            recipientsMenu={true}
            setAddRecipientsPopup={setAddRecipientsPopup}
            setRecipientsType={setRecipientsType}
            setRecipientsRowData={setRecipientsRowData}
            setVoidPopup={setVoidPopup}
            setVoideFalse={true}
            setRecipientsClickRowData={setRecipientsClickRowData}
          />
        </div>
        <div className="recipients-details">
          <div className="card-content-info" style={{ gap: "10px" }}>
            <span className="fee-payment-content-info-para">Recipients Name</span>
            <span className="fee-payment-content-info-span">{recipientsClickRowData?.sRecipientDisbursementName}</span>
          </div>
          <div className="card-content-info" style={{ gap: "10px" }}>
            <span className="fee-payment-content-info-para">Recipients Phone</span>
            <span className="fee-payment-content-info-span">
              [{recipientsClickRowData?.Phone ? recipientsClickRowData?.Phone : "Phone"}]
            </span>
          </div>
          <div className="card-content-info" style={{ gap: "10px" }}>
            <span className="fee-payment-content-info-para">Address</span>
            <span className="fee-payment-content-info-span">{address ? address : ""}</span>
          </div>
        </div>
      </div>
    </>
  );
};
