import { ContextMenu, DataGrid, LoadPanel } from "devextreme-react";
import { Column, HeaderFilter, Pager } from "devextreme-react/data-grid";
import { ApiError } from "../../Error/ApiError";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ThreeDotMenu from "../../../components/Common/Icons/ThreeDotMenu";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setDynamicTab } from "../../../reducer/HomePageReducer";
import { DynamicTabb } from '../../../components/DynamicTab/DynamicTab';
import { handleGetLocal } from "../../../services/auth-service";

export const ContactsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [erorhandling, seterorhandling] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ContactsList, setContactsList] = useState([]);

  const [selectedContacts, setSelectedContacts] = useState({});

  const saveCookieCrum = async (perdonId) => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => { })
      .catch(() => { });
  };

  const getContactsList = async () => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Contacts/all-contacts-get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        if (res?.data?.data.length) {
          setContactsList(res.data.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getContactsList();
  }, []);

  return (
    <>
      <div className="particcipentPage">
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <DynamicTabb />
        <div style={{ display: "flex" }}>
          <p class="popup_header_title_para2">CO.4</p>
        </div>
        {erorhandling ? (
          <ApiError />
        ) : (
          <div>
            <LoadPanel shadingColor="true" visible={false} delay={10} />
            <div className="go-to-header-section">
              <div className="Contacts-list">
                <DataGrid
                  dataSource={ContactsList}
                  columnAutoWidth
                  showBorders={true}
                  loadPanel={false}
                  allowColumnResizing={true}
                  showColumnLines={true}
                  showRowLines={true}
                  allowColumnReordering={true}
                  onContextMenuPreparing={(e) => {
                    setSelectedContacts(e.row?.data);
                  }}
                >
                  <HeaderFilter visible={true} allowSearch={true} />
                  <Column
                    width={50}
                    type="buttons"
                    cellRender={(rowData) => {
                      return (
                        <div
                          className="add_navBar_hamburger"
                          id="threedots"
                          onClick={() => {
                            setSelectedContacts(rowData.data);
                          }}
                        >
                          <ThreeDotMenu />
                          <ContextMenu
                            showEvent="dxclick"
                            target="#threedots"
                            dataSource={[
                              {
                                text: "Jump to Contact",
                                icon: "search",
                              },
                            ]}
                            onItemClick={(e) => {
                              if (e.itemData.text === "Jump to Contact") {
                                console.log('selectedContacts', selectedContacts);
                                saveCookieCrum(selectedContacts?.gContactId);
                                dispatch(
                                  setDynamicTab({
                                    title: "Contacts",
                                    link: `/prontact/Contacts/${selectedContacts?.gContactId}`,
                                    Offender: "/prontact/Contacts",
                                  })
                                );
                                navigate(`/prontact/Contacts/${selectedContacts?.gContactId}`);
                              }
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  <Column dataField="sContactSequence" caption="Contact Id" />
                  <Column dataField="sLastName" caption="Last Name" />
                  <Column dataField="sFirstName" caption="First Name" />
                  <Column dataField="sSalutation" caption="Salutation" />
                  <Column dataField="bApproved" caption="Approved" />
                  <Column dataField="sPrimaryEntityName" caption="Primary Entity" />
                  <Column dataField="sPrimaryEntityContactPosition" caption="Position" />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 30, 50]}
                    showNavigationButtons={true}
                    showInfo={true}
                  />
                </DataGrid>
                <ContextMenu
                  dataSource={[
                    {
                      text: "Jump to Contact",
                      icon: "search",
                    },
                  ]}
                  target=".dx-data-row"
                  onItemClick={(e) => {
                    if (e.itemData.text === "Jump to Contact") {
                      saveCookieCrum(selectedContacts?.gContactId);
                      dispatch(
                        setDynamicTab({
                          title: "Contacts",
                          link: `/prontact/Contacts/${selectedContacts?.gContactId}`,
                          Offender: "/prontact/Contacts",
                        })
                      );
                      navigate(`/prontact/Contacts/${selectedContacts?.gContactId}`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
