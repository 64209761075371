import React, { useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { warrentsTablecoloumn } from "../Data";
import { Popup } from "devextreme-react";
import { AddWarrentsPopup } from "./AddWarrentsPopup";
import { VoidPopup } from "../VoidPopup";
import { ApiError } from "../../../../Error/ApiError";

export const Warrents = ({
  warrantData,
  loading,
  getWarrantsDataApi,
  setLoading,
  setToastConfig,
  toastConfig,
  dropdownData,
  docketDropdownData,
  userinfo,
  warrentErrorHandling,
  gotoWarrents,
  dockertDetail,
  dockerHeaderData,
}) => {
  const [addWarrentsPopup, setAddWarrentsPopup] = useState(false);
  const [voidPopup, setVoidPopup] = useState(false);
  const [rowWarrentData, setRowWarrentData] = useState([]);
  const [warrantType, setWarrantType] = useState("");

  return (
    <>
      <Popup
        visible={addWarrentsPopup}
        onHiding={() => {
          setAddWarrentsPopup(false);
        }}
        showTitle={false}
        width={768}
        height={680}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddWarrentsPopup
          addWarrentsPopup={addWarrentsPopup}
          setAddWarrentsPopup={setAddWarrentsPopup}
          setLoading={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          rowWarrentData={rowWarrentData}
          warrantType={warrantType}
          getWarrantsDataApi={getWarrantsDataApi}
          userinfo={userinfo}
          gotoWarrents={gotoWarrents}
          dockertDetail={dockertDetail}
          dockerHeaderData={dockerHeaderData}
        />
      </Popup>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPopup
          setVoidPopup={setVoidPopup}
          voidHeading="Void Warrant"
          voidSubHeading="Void Warrant"
          setLoad={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          filedId={rowWarrentData?.gWarrantId}
          fieldName="GWarrantId"
          apiURl="Warrants/voidWarrant"
          refreshApi={getWarrantsDataApi}
          userinfo={userinfo}
          comment="Comments"
        />
      </Popup>
      <div className="warrents">
        <div className="buttons">
          <button
            className="btn"
            onClick={() => {
              setAddWarrentsPopup(true);
              setWarrantType("ADD");
            }}
            style={{ width: "auto" }}
          >
            Add Warrant <AddIcon className="svg-blue-white" />
          </button>
        </div>
        {warrentErrorHandling ? (
          <ApiError />
        ) : (
          <div className="warrents_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={warrentsTablecoloumn}
              tableRow={warrantData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"Warrant"}
              mandatoryColoum={"Docket"}
              exportInDifferentFormate={true}
              exportwidth={27}
              exporttitle={"Warrant"}
              warrantMenu={true}
              setAddWarrentsPopup={setAddWarrentsPopup}
              setVoidPopup={setVoidPopup}
              setRowWarrentData={setRowWarrentData}
              setWarrantType={setWarrantType}
              setVoideFalse={true}
            />
          </div>
        )}
      </div>
    </>
  );
};
