import { LoadPanel, ScrollView } from "devextreme-react";
import { ApiError } from "../../../../Error/ApiError";
import { useEffect, useState } from "react";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { Table1 } from "../../../../Administration/Table1/Table1";

const RandomizerSummaryTable = (data) => {
  const arr = [];
  data?.map((val, i) => {
    arr.push({
      "Offender Number": val?.sOffenderNumber,
      Name: val?.sLastFirst,
      "Birth Date": val?.dDob,
      Frequency: val?.sFrequencyName,
      Supervisor: val?.sPrimarySupervisorName,
      "Next Text": val?.sNextTestDateTime,
      "Last Login": val?.dLastLogin,
      Location: val?.sLocation,
      sLastName: val?.sLastName,
      ID: i,
    });
  });
  return arr;
};

const Tablecoloum = [
  {
    title: "Offender Number",
  },
  { title: "Name" },
  { title: "Birth Date", date: true },
  {
    title: "Frequency",
  },
  {
    title: "Supervisor",
  },
  {
    title: "Next Text",
  },
  {
    title: "Last Login",
  },
  {
    title: "Location",
  },
];

export const RandomizerSummary = ({ RandomizerSummaryPopUpshow, setRandomizerSummaryPopUpshow }) => {
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [errorhandler, seterrorhandler] = useState(false);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [randomizerSummaryRowData, setRandomizerSummaryRowData] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);

  const RandomizerSummaryapi = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/get-randomizer-summary`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setdata(RandomizerSummaryTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  };

  useEffect(() => {
    RandomizerSummaryapi();
  }, []);

  return (
    <>
      <div>
        <LoadPanel shadingColor="true" visible={loading} delay={10} />

        <ScrollView style={{ width: "100%", height: "519px" }}>
          <div style={{ height: "519px" }} className="notes_edit_popup">
            <div className="RecivePaymentModel_header">
              <div style={{ width: "230px" }} className="RecivePaymentModel_header_name">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M21.04 13.13C21.18 13.13 21.31 13.19 21.42 13.3L22.7 14.58C22.92 14.79 22.92 15.14 22.7 15.35L21.7 16.35L19.65 14.3L20.65 13.3C20.76 13.19 20.9 13.13 21.04 13.13ZM19.07 14.88L21.12 16.93L15.06 23H13V20.94L19.07 14.88ZM3 7V5H5V4C5 2.89 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20.05 2 21 2.95 21 4V10L11 20V22H7C5.95 22 5 21.05 5 20V19H3V17H5V13H3V11H5V7H3ZM5 7H7V5H5V7ZM5 11V13H7V11H5ZM5 17V19H7V17H5Z"
                    fill="#fff"
                  />
                </svg>
                <p style={{ marginLeft: "-10px" }}>Randomizer Summary</p>
                <span
                  style={{
                    padding: "2px 9px",
                    gap: "8px",
                    width: "45px",
                    height: "24px",
                    background: "#FEF2F2",
                    borderRadius: "4px",
                  }}
                >
                  <p
                    style={{
                      color: "#DC2626",
                      fontFamily: "Readex Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "10px",
                      lineHeight: "20px",
                      width: "21px",
                      height: "20px",
                    }}
                  >
                    DT.14
                  </p>
                </span>
              </div>
              <svg
                onClick={() => {
                  setRandomizerSummaryPopUpshow(false);
                }}
                style={{
                  cursor: "pointer",
                  marginTop: "17px",
                  marginRight: "2%",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="buttons" style={{ marginLeft: "20px", marginTop: "3px" }}>
              <button className="btn" onClick={RandomizerSummaryapi}>
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_7103_77174)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7103_77174">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_7103_86918)">
                    <path
                      d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7103_86918">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            {errorhandler ? (
              <ApiError />
            ) : (
              <div className="editTable_notes">
                <Table1
                  Tablecoloum={Tablecoloum}
                  tableRow={data}
                  editTable={false}
                  load={false}
                  coloumWidthText={"NotesTable"}
                  height={"auto"}
                  exportInDifferentFormate={true}
                  exportPopupOpen={exportPopupOpen}
                  setexportPopupOpen={setexportPopupOpen}
                  exportwidth={27}
                  exporttitle={"Randomizer-Summary"}
                  mandatoryColoum={"Offender Number"}
                  showTotalBlock={false}
                  randmoizerSummaryMenu={true}
                  randomizerSummaryRowData={randomizerSummaryRowData}
                  setRandomizerSummaryRowData={setRandomizerSummaryRowData}
                  setRandomizerSummaryPopUpshow={setRandomizerSummaryPopUpshow}
                />
              </div>
            )}
          </div>
        </ScrollView>
      </div>
    </>
  );
};
