import React, { useEffect, useState } from "react";
import "./goToDocketPopup.css";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setdockerValue } from "../../../../../reducer/participantNoteReducer";
import { useSelector } from "react-redux";
import { setDynamicTab } from "../../../../../reducer/HomePageReducer";

export const CreateConcurrentDocket = ({
  createConcurrentDocketPopup,
  setCreateConcurrentDocketPopup,
  typeDocketAdd,
  data,
  toastConfig,
  setToastConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({ docketNumber: "", docketCount: "" });
  const [error, setError] = useState({ docketNumberError: "" });
  const navigate = useNavigate();

  useEffect(() => {
    setError({
      docketNumberError: "",
    });
    if (typeDocketAdd === "CreateConcurrentDocket") {
      setFields({
        docketNumber: data?.sDocketNumber,
        docketCount: data?.iCourtCounts + 1,
      });
    } else {
      setFields({
        docketNumber: "",
        docketCount: 1,
      });
    }
  }, [createConcurrentDocketPopup]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (error.docketNumberError) {
      setError({
        ...error,
        docketNumberError: "",
      });
    }
  };

  const docketData = {
    parentDocketId: data?.gDocketId,
    count: fields?.docketCount,
    docketNumber: fields?.docketNumber,
    isClone: typeDocketAdd === "CreateConcurrentDocket" ? false : true,
  };

  const dispatch = useDispatch();
  const { dockerValue } = useSelector((store) => store.participateNotes);

  const handelSave = async () => {
    let isValid = true;
    if (!fields.docketNumber) {
      setError({
        ...error,
        docketNumberError: "Please Enter Docket Number",
      });
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/docket/create-duplicate-docket`, docketData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          if (val?.data?.isSuccess) {
            setLoading(false);
            setCreateConcurrentDocketPopup(false);
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: typeDocketAdd === "CreateConcurrentDocket" ? "Successfully Added!" : "Successfully Clone!",
              type: "success",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: typeDocketAdd === "CreateConcurrentDocket" ? "Successfully Added!" : "Successfully Clone!",
                type: "success",
              });
            }, 1900);

            dispatch(
              setDynamicTab({
                Offender: `/docket/${val?.data?.data?.sDocketNumberCourtCounts.replace(/\//g, "-")}`,
                link: `/docket/${val?.data?.data?.sDocketNumberCourtCounts.replace(/\//g, "-")}`,
                title: `Docket ${val?.data?.data?.sDocketNumberCourtCounts}`,
                sDocketNumberCourtCounts: val?.data?.data?.sDocketNumberCourtCounts,
                userinfo: dockerValue?.userinfo,
                getTopDynamicDocketid: val?.data?.data?.gDocketId,
              })
            );
            dispatch(
              setdockerValue({
                getTopDynamicDocketid: val?.data?.data?.gDocketId,
                userinfo: dockerValue?.userinfo,
              })
            );
            navigate(`/docket/${val?.data?.data?.sDocketNumberCourtCounts.replace(/\//g, "-")}`);
          }
        })
        .catch((error) => {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "DocketNumber and Count already exists!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "DocketNumber and Count already exists!",
              type: "error",
            });
          }, 1900);
          setLoading(false);
          if (!error?.response) {
            navigate(`/error/500`);
          } else if (error?.response?.status === 401) {
            logOut();
          }
        });
    }
  };

  return (
    <>
      <div className="create-concurrent-docket-container">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_974_363073)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_974_363073">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>{typeDocketAdd === "CreateConcurrentDocket" ? "Create Concurrent Docket" : "Clone Docket"}</p>
            </div>
            <p class="popup_header_title_para">DK.9</p>
          </div>
          <svg
            onClick={() => {
              setCreateConcurrentDocketPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <section className="go-to-docket-popup-content">
          <div className="go-to-docket-popup-fields">
            <div className="go-to-inputs-field">
              <label htmlFor="docketNumber" className="go-to-label-filed">
                Docket Number <span className="go-to-mark-input">*</span>
              </label>
              <input
                type="text"
                className="go-to-input-filed"
                placeholder="Enter Docket Number"
                name="docketNumber"
                id="docketNumber"
                value={fields.docketNumber}
                onChange={handleInputChange}
              />
              <p className="go-to-error-field">{error.docketNumberError}</p>
            </div>
            <div className="go-to-inputs-field">
              <input
                type="number"
                className="go-to-input-filed-custom input-margin"
                name="docketCount"
                id="docketCount"
                value={fields.docketCount}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handelSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setCreateConcurrentDocketPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </section>
      </div>
    </>
  );
};
