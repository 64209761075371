const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};
export const equimentTableColumn = [
  { moreoption: true },
  { title: "Status" },
  { title: "Location" },
  { title: "Vendor" },
  { title: "Date Return To Vendor", date: true },
  { title: "Date Received From Vendor", date: true },
  { title: "Note" },
  { title: "Equipment Type" },
  { title: "Serial Number" },
  { title: "Pronto ID" },
  { title: "Participant" },
  { title: "Vendor Status" },
];
export const equimentLogTableColumn = [
  { title: "Vendor" },
  { title: "Equipment Type" },
  { title: "Serial Number" },
  { title: "Start Date", date: true },
  { title: "Removal Date", date: true },
  { title: "Return Condition" },
  { title: "Participant" },
  { title: "Pronto ID" },
  { title: "Note" },
  { title: "Court Location" },
  { title: "Docket" },
];
export const rateEditorTableColumn = [
  { moreoption: true },
  { title: "Entity/Agency" },
  { title: "Entity ID" },
  { title: "Equipment Type" },
  { title: "Daily Rate", currency: true },
  { title: "Install Rate", currency: true },
  { title: "Daily Housing Rate", currency: true },
  { title: "GSA SIN" },
  { title: "GSA Mfr Part" },
  { title: "GSA Product" },
];
export const vendorInventoryTableColumn = [
  { moreoption: true },
  { title: "Alert", bool: true },
  { title: "Vendor" },
  { title: "Vendor Status 1" },
  { title: "Location" },
  { title: "Vendor Update Date", date: true },
  { title: "Equipment Type" },
  { title: "Serial Number" },
  { title: "Vendor Status 2" },
  { title: "Vendor Assigned" },
  { title: "Pronto Name" },
  { title: "Pronto ID" },
  { title: "Pronto Last Name" },
  { title: "Vendor Last Name" },
];
export const referralTableColumn = [
  { title: "Company" },
  { title: "Form" },
  { title: "Last Name" },
  { title: "First Name" },
  { title: "Middle Name" },
  { title: "DOB", date: true },
  { title: "Entry Date", date: true },
  { title: "Status" },
  { title: "Offender No" },
  { title: "Last First" },
  { title: "Location" },
  { title: "Supervisor" },
  { title: "Form Id" },
  { title: "Entry No" },
  { title: "Attachment" },
];
export const agencyInvoicesTableColumn = [
  { moreoption: true },
  { title: "Select", bool: true, selectAllCustomCheckBox: true },
  { title: "Invoice #" },
  { title: "Date", date: true },
  { title: "Agency" },
  { title: "Company" },
  { title: "Amount", currency: true },
  { title: "Amount Paid", currency: true },
  { title: "Balance", currency: true },
  { title: "Court" },
  { title: "Void", bool: true },
  { title: "Credit Amount", currency: true },
  { title: "Credit Reason" },
  { title: "Email Date" },
  { title: "Note" },
];
export const receiveAgencyPaymentTableColumn = [
  { title: "Select", bool: true, selectAllCustomCheckBox: true, editable: false },
  { title: "Start", date: true, editable: false },
  { title: "End", date: true, editable: false },
  { title: "Quantity", editable: false },
  { title: "Rate", currency: true, editable: false },
  { title: "Amount", currency: true, editable: false },
  { title: "Type", editable: false },
  { title: "Offender #", editable: false },
  { title: "Offender Name", editable: false },
  { title: "AMT To Receive", currency: true, editable: true },
  { title: "Remarks", editable: false },
];

export const setEquimentTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Status: val?.sEquipmentStatusName,
      Location: val?.sLocationName,
      Vendor: val?.sVendorName,
      "Date Return To Vendor": convertDateFormat(val?.dReturned),
      "Date Received From Vendor": convertDateFormat(val?.dReceived),
      Note: val?.sNote,
      "Equipment Type": val?.sEquipmentTypeName,
      "Serial Number": val?.sSerialNumber,
      "Pronto ID": val?.sOffenderNumber,
      Participant: val?.sLastFirst,
      "Vendor Status": val?.sEquipmentStatusName,
      gEmEquipmentId: val?.gEmEquipmentId,
      gEmEquipmentTypeId: val?.gEmEquipmentTypeId,
      gEmVendorId: val?.gEmVendorId,
      gEquipmentStatusId: val?.gEquipmentStatusId,
      gLocationId: val?.gLocationId,
      sVendorNote: val?.sVendorNote,
      ID: i,
    });
  });
  return arr;
};

export const setEquimentLogTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Vendor: val?.sVendorName,
      "Equipment Type": val?.sEquipmentTypeName,
      "Serial Number": val?.sSerialNumber,
      "Start Date": convertDateFormat(val?.dDoe),
      "Removal Date": convertDateFormat(val?.dRemoval),
      "Return Condition": val?.sEquipmentReturnCondition,
      Participant: val?.sLastFirst,
      "Pronto ID": val?.sOffenderNumber,
      Note: val?.sNote,
      "Court Location": val?.sCourtName,
      Docket: val?.sDocketNumber,
      gEmEquipmentId: val?.gEmEquipmentId,
      gEmEquipmentLogId: val?.gEmEquipmentLogId,
      gEmEquipmentReturnedConditionId: val?.gEmEquipmentReturnedConditionId,
      gEmPeriodId: val?.gEmPeriodId,
      gEntryUserId: val?.gEntryUserId,
      gPersonId: val?.gPersonId,
      ID: i,
    });
  });
  return arr;
};

export const setVendorInventoryTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Alert: val?.bAlert,
      Vendor: val?.vendorId,
      "Vendor Status 1": val?.status,
      Location: val?.vendorLocation,
      "Vendor Update Date": convertDateFormat(val?.dDoe),
      "Equipment Type": val?.deviceType,
      "Serial Number": val?.serialNo,
      "Vendor Status 2": val?.sVendorNote,
      "Vendor Assigned": val?.assignedToName,
      "Pronto Name": val?.s,
      "Pronto ID": val?.s,
      "Pronto Last Name": val?.s,
      "Vendor Last Name": val?.sAssignedToLastName,
      gInventoryId: val?.gInventoryId,
      gPersonId: val?.gPersonId,
      sOffenderNumber: val?.sOffenderNumber,
      sLastFirst: val?.sLastFirst,
      ID: i,
    });
  });
  return arr;
};
export const setReferralTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Company: val?.sCompany,
      Form: val?.sFormName,
      "Last Name": val?.sLastName,
      "First Name": val?.sFirstName,
      "Middle Name": val?.sMiddleName,
      DOB: convertDateFormat(val?.dDob),
      "Entry Date": convertDateFormat(val?.dDoe),
      Status: val?.sStatus,
      "Offender No": val?.sOffenderNumber,
      "Last First": val?.sLastFirst,
      Location: val?.sGPM_EntityName,
      Supervisor: val?.sPrimarySupervisorName,
      "Form Id": val?.iFormId,
      "Entry No": val?.iEntryNo,
      Attachment: val?.bAttachment,
      gReferralId: val?.gReferralId,
      gPersonId: val?.gPersonId,
      sEntryData: val?.sEntryData,
      sAttachmentUrl: val?.sAttachmentUrl,
      sAttachmentName: val?.sAttachmentName,
      sLastFirst: val?.sLastFirst,
      SAddress1: val?.sAddress1,
      sAddress2: val?.sAddress2,
      ID: i,
      sCity: val?.sCity,
      sState: val?.sState,
      sZip: val?.sZip,
      sHomePhone: val?.sHomePhone,
      sCellPhone: val?.sCellPhone,
      dDoe: val?.dDoe,
      sStatus: val?.sStatus,
      sRecordType: val?.sRecordType,
      sAgent: val?.sAgent,
      sAgentEmail: val?.sAgentEmail,
      sAgentPhone: val?.sAgentPhone,
      sOffenderNumber: val?.sOffenderNumber,
      sGpmEntityName: val?.sGpmEntityName,
      bAttachment: val?.bAttachment,
    });
  });
  return arr;
};
export const setRateEditorTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Entity/Agency": val?.sEntityName,
      "Entity ID": val?.sEntitySequence,
      "Equipment Type": val?.sEquipmentTypeName,
      "Daily Rate": val?.fRate,
      "Install Rate": val?.fInstallRate,
      "Daily Housing Rate": val?.fHousingRate,
      "GSA SIN": val?.sGsaSin,
      "GSA Mfr Part": val?.sGsaMfrPart,
      "GSA Product": val?.sGsaProduct,
      gEntityId: val?.gEntityId,
      gEntryUserId: val?.gEntryUserId,
      gRateId: val?.gRateId,
      gEmEquipmentTypeId: val?.gEmEquipmentTypeId,
      ID: i,
    });
  });
  return arr;
};

export const getAgencyInvoicesTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Invoice #": val?.sInvoiceNumber,
      Date: convertDateFormat(val?.dInvoice),
      Agency: val?.sName,
      Company: val?.sCompanyName,
      Amount: val?.fAmount,
      Court: val?.sCourtName,
      Void: val?.bVoid,
      "Credit Amount": val?.fCreditAmount,
      "Credit Reason": val?.sCreditReason,
      "Amount Paid": val?.fAmountPaid,
      Balance: val?.fBalance,
      "Email Date": val?.dInvoiceEmailed,
      Note: val?.sNote,
      gBillingId: val?.gBillingId,
      gCompanyEntityId: val?.gCompanyEntityId,
      gEntityId: val?.gEntityId,
      gEntryUserId: val?.gEntryUserId,
      gVoidedById: val?.gVoidedById,
      ID: i,
    });
  });
  return arr;
};
export const getReceiveAgencyPaymentInvoiceDropdownData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Invoice #": val?.sInvoiceNumber,
      Entity: val?.sName,
      Amount: val?.fAmount,
      Due: val?.fAmountDue,
      Items: val?.fItemsDue,
      Date: convertDateFormat(val?.dInvoice),
      gBillingID: val?.gBillingID,
      gPersonID: val?.gPersonID,
      sDescription: val?.sDescription,
      ID: i,
    });
  });
  return arr;
};
export const getAgencyPaymentGridDatas = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Start: convertDateFormat(val?.dPeriodStart),
      End: convertDateFormat(val?.dPeriodEnd),
      Quantity: val?.iDays,
      Rate: val?.fRate,
      Amount: val?.fAmount,
      Type: val?.sEmType,
      "Offender #": val?.sOffenderNumber,
      "Offender Name": val?.sLastFirst,
      "AMT To Receive": val?.fBalance,
      Remarks: val?.sFeeRemarks,
      gBillingDetailId: val?.gBillingDetailId,
      gBillingId: val?.gBillingId,
      gEmPeriodId: val?.gEmPeriodId,
      gPaymentAllocationId: val?.gPaymentAllocationId,
      gPersonId: val?.gPersonId,
      ID: i,
      dInvoice: val?.dInvoice,
      gObligationId: val?.gObligationId
    });
  });
  return arr;
};

export const periodSummaryColumns = [
  { moreoption: true, customwidth:"50px"},
  { title: "Offender #", field: "sOffenderNumber" }, 
  { title: "Offender Name", field: "sLastFirst" }, 
  { title: "Payment Type", field: "sPaymentTypeName" }, 
  { title: "Start", date: true, field: "dRateStart" },
  { title: "End", date: true, field: "dRangeEnd" },
  { title: "Rate", currency: true, field: "fRate" }, 
  { title: "Billing Agency", field: "sBillingAgency", customwidth:"400px"}, 
  { title: "Fee Name", field: "sActivity" }, 
  { title: "Court", field: "sCourtName" },
  { title: "EM Type", field: "sEM_TYPE" },
  { title: "Days", field: "iDays" }, 
  { title: "Due", currency: true, field: "fDue" },
  { title: "Collected", currency: true, field: "fCollected" }, 
  { title: "Arrears", currency: true, field: "fArrears" }, 
  { title: "Fee Code", field: "sDDSLegalCode" },
  { title: "Location", field: "sRegionName" },
  { title: "Rate Frequency", field: "sRateFrequency" }, 
  { title: "Supervisor", field: "sPrimarySupervisorName" } 
];

