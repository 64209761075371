import axios from "axios";
import { logOut } from "../../../../components/Logout";
import { handleGetLocal } from "../../../../services/auth-service";
import {
  setGoToobligationIsChange,
} from "../../../../reducer/ParticipateReducer";
import { setgotooblogationValue } from "../../../../reducer/participantNoteReducer";
import {
  setEmEquipmentLogObligationTableData,
  setEmPeriodObligationTableData,
  setObligationOverviewTableData,
  setTableDisbursementTableData,
  setTableGeneratePaymentPlanTableData,
  setTableRecipientsTableData,
} from "./GoTOObligationSubTabs/Data";
import { removeDynamicTab, setDynamicTab } from "../../../../reducer/HomePageReducer";

export class GoTOObligationFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  getattachmentTableData = async (
    setattachmentTabledata,
    setLoading,
    navigate,
    gotooblogationValue,
    setattachmentCount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/attachments/get-attachments?recordId=${gotooblogationValue?.gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setattachmentCount(val?.data?.data?.length);
        setattachmentTabledata(this.setTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  getObligation = async (
    setField,
    setLoading,
    navigate,
    gotooblogationValue,
    setdata,
    setOffenderid,
    seterorhandling,
    dispatch,
    setsDocketNumberCourtCounts,
    sDocketNumberCourtCounts,
    flag = false,
    data = null,
    reset = null,
    save = null,
    obligationid2,
    field,
    paymentAgencyDropdown
  ) => {
    if (gotooblogationValue?.gObligationId) {
      setLoading(true);
      // console.log('gotooblogationValue?.gObligationId', gotooblogationValue?.gObligationId);
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/obligations/getObligation?obligationId=${gotooblogationValue?.gObligationId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${this.pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading(false);
          if (val?.data?.isSuccess) {
            dispatch(setGoToobligationIsChange(false));
            const obligationData = { ...val?.data?.data?.obligation, ...val?.data?.data?.accrual };
            setdata(obligationData);
            let billingEntity = paymentAgencyDropdown?.find(
              (paymentAgency) => paymentAgency?.id === obligationData?.gBillingEntityId
            );
            dispatch(
              setgotooblogationValue({
                gObligationId: obligationData?.gObligationId,
                amount: obligationData?.fAmount
              })
            );
            setOffenderid(val?.data?.caption);
            seterorhandling(false);
            setField({
              "Obligation Ref #": obligationData?.sReference,
              Offender: obligationData?.sPersonNameLastFirst,
              "Fee Code": {
                name: obligationData?.sFeeName,
                id: obligationData?.gFeeScheduleFeeId,
              },
              "Payment Agency": {
                name: billingEntity?.name,
                id: obligationData?.gBillingEntityId,
              },
              "Docket Reference": gotooblogationValue?.userinfo?.sReferenceNumberV2
                ? gotooblogationValue?.userinfo?.sReferenceNumberV2
                : obligationData?.sDocketReferenceNumber,
              "Docket Number": obligationData?.sDocketNumberCourtCounts,
              "Recipient Category": {
                name: obligationData?.sRecipientCategoryName,
                id: obligationData?.gFeeScheduleCategoryId,
              },
              Offense: { name: "", id: "" },
              "Financial Status": {
                name: obligationData?.sFinancialStatusName,
                id: obligationData?.gFinancialStatusId,
              },
              "Amount paid": "",
              "Amount Disbursed": "",
              "Amount On Hand": "",
              Arrears: "",
              Balance: "",
              "Payment Alert": obligationData?.sPaymentAlert,
              "Current Amount": obligationData?.fAmount,
              "Date Active": obligationData?.dActive,
              "Change Financial Status": obligationData?.dFinancialStatus,
              "Notes Remarks": obligationData?.sFeeRemarks
            });
            setsDocketNumberCourtCounts(obligationData?.sDocketNumberCourtCounts);
            if (localStorage.getItem("RedirecttoObligation")) {
              localStorage.removeItem("RedirecttoObligation");
              return;
            }
            if (save) {
              return;
            }
            if (reset) {
              dispatch(
                removeDynamicTab("Obligation : [ New ]")
              );
              return;
            }

            if (flag) {
              dispatch(
                removeDynamicTab("Obligation : [ New ]")
              );
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligationData?.sReference
                    }`,
                  link: `/obligation/${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligationData?.sReference
                    }`,
                  title: `Obligation : ${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")} / ${obligationData?.sReference
                    }`,
                  sDocketNumberCourtCounts: obligationData?.sDocketNumberCourtCounts,
                  ObligationRef: obligationData?.sReference,
                  gObligationId: obligationData?.gObligationId,
                })
              );
              navigate(
                `/obligation/${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligationData?.sReference
                }`
              );
              return;
            }
            if (obligationid2 == "tempdata") {
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligationData?.sReference
                    }`,
                  link: `/obligation/${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligationData?.sReference
                    }`,
                  title: `Obligation : ${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")} / ${obligationData?.sReference
                    }`,
                  sDocketNumberCourtCounts: obligationData?.sDocketNumberCourtCounts,
                  ObligationRef: obligationData?.sReference,
                  gObligationId: obligationData?.gObligationId,
                })
              );
            }
            navigate(
              `/obligation/${obligationData?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligationData?.sReference
              }`
            );
          } else {
            seterorhandling(true);
          }
        })
        .catch((val) => {
          setLoading(false);
          if (!val?.response) {
            navigate(`/error/500`);
          } else if (val?.response?.status == 401) {
            logOut();
          } else {
            seterorhandling(true);
          }
        });
    }
    setLoading(false);
  };

  getDRopDown = async (
    navigate,
    setObligationType,
    setRecipientCategorye,
    setOffense,
    setFinancialStatus,
    setdropdowndata,
    setField2
  ) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setdropdowndata(val?.data?.data);
        val?.data?.data?.obligationTypes?.length &&
          setObligationType(
            val?.data?.data?.obligationTypes.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.recipientCategory?.length &&
          setRecipientCategorye(
            val?.data?.data?.recipientCategory.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.offenses?.length &&
          setOffense(
            val?.data?.data?.offenses.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.financialStatuses?.length &&
          setFinancialStatus(
            val?.data?.data?.financialStatuses.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );

        val?.data?.data?.defaultFinancialStatus &&
          setField2((prev) => {
            return {
              ...prev,
              "Financial Status": {
                name: val?.data?.data?.defaultFinancialStatus?.value,
                id: val?.data?.data?.defaultFinancialStatus?.key,
              },
            };
          });
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Filename: val?.filename,
        Category: val?.attachmentCateogoryName,
        Description: val?.description,
        ID: val?.recordId,
        "Entered By": val?.enteredByUserName,
        DOE: val?.doe,
        attachment: val?.attachment,
        attachmentCategoryId: val?.attachmentCategoryId,
        binaryDataId: val?.binaryDataId,
        image: val?.image,
      });
    });

    return arr;
  };

  Saveheader = async (
    body,
    navigate,
    setLoading2,
    setToastConfig,
    toastConfig,
    gotooblogationValue,
    setdata,
    setOffenderid,
    seterorhandling,
    setField,
    dispatch,
    setsDocketNumberCourtCounts,
    sDocketNumberCourtCounts,
    obligationid2,
    field,
    PaymentAgency
  ) => {
    setLoading2(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/addEditObligation`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);
          this.getObligation(
            setField,
            setLoading2,
            navigate,
            gotooblogationValue,
            setdata,
            setOffenderid,
            seterorhandling,
            dispatch,
            setsDocketNumberCourtCounts,
            sDocketNumberCourtCounts,
            false,
            false,
            false,
            true,
            obligationid2,
            field,
            PaymentAgency
          );
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }
        setLoading2(false);
      })
      .catch((val) => {
        setLoading2(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
          this.getObligation(
            setField,
            setLoading2,
            navigate,
            gotooblogationValue,
            setdata,
            setOffenderid,
            seterorhandling,
            dispatch,
            setsDocketNumberCourtCounts,
            sDocketNumberCourtCounts,
            false,
            false,
            false,
            true,
            obligationid2,
            field
          );
        }
      });
  };

  Save(
    field,
    navigate,
    setLoading2,
    setToastConfig,
    toastConfig,
    id,
    gotooblogationValue,
    data,
    setdata,
    setOffenderid,
    seterorhandling,
    setField,
    GoTOObligationReference,
    dispatch,
    setsDocketNumberCourtCounts,
    sDocketNumberCourtCounts,
    feePaymentPlanData,
    obligationid2,
    obligationRefMessage,
    PaymentAgency
  ) {
    this.Saveheader(
      {
        GObligationId: id,
        GEntryUserId: localStorage.getItem("gUserId"),
        GDocketId: GoTOObligationReference?.gDocketID ? GoTOObligationReference?.gDocketID : data?.gDocketId,
        GFeeScheduleCategoryId: "2f9bd615-d3f8-e011-98aa-00508d98a9ee",
        // SReference: field?.["Obligation Ref #"],
        SReference: obligationRefMessage,
        GFinancialStatusId: field?.["Financial Status"]?.id,
        GOffenseId: "42109C74-C17E-E711-80C6-00155D0C070B",
        FAmount: feePaymentPlanData?.amount,
        sPaymentAlert: field?.["Payment Alert"] ? field?.["Payment Alert"] : "",


        BillingEntityID: field?.["Payment Agency"]?.id
          ? field?.["Payment Agency"]?.id
          : "00000000-0000-0000-0000-000000000000",
        gFeeScheduleFeeID: field?.["Fee Code"]?.id ? field?.["Fee Code"]?.id : "00000000-0000-0000-0000-000000000000",
        // DFinancialStatus: field?.["Change Financial Status"] ? field?.["Change Financial Status"] : "",
        dActive: field?.["Date Active"] ? field?.["Date Active"] : "",
        DStart: feePaymentPlanData?.dStart,
        DEnd: feePaymentPlanData?.dEnd,
        FRate: feePaymentPlanData?.fRate,
        GFrequencyID: feePaymentPlanData?.frequency,
        IPaymentDay: feePaymentPlanData?.paymentDay,
        sFeeRemarks: field?.["Notes Remarks"] ? field?.["Notes Remarks"] : ""
      },
      navigate,
      setLoading2,
      setToastConfig,
      toastConfig,
      gotooblogationValue,
      setdata,
      setOffenderid,
      seterorhandling,
      setField,
      dispatch,
      setsDocketNumberCourtCounts,
      sDocketNumberCourtCounts,
      obligationid2,
      field,
      PaymentAgency
    );
  }

  Cloneobligation = async (
    setLoading,
    toastConfig,
    setToastConfig,
    navigate,
    gotooblogationValue,
    body,
    setcloneObligation,
    dispatch
  ) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/clone-obligation`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);

          dispatch(
            setDynamicTab({
              Offender: `/obligation/${val?.data?.data?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${val?.data?.data?.sReference
                }`,
              link: `/obligation/${val?.data?.data?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${val?.data?.data?.sReference
                }`,
              title: `Obligation : ${val?.data?.data?.sDocketNumberCourtCounts} / ${val?.data?.data?.sReference}`,
              sDocketNumberCourtCounts: val?.data?.data?.sDocketNumberCourtCounts,
              ObligationRef: val?.data?.data?.sReference,
              gObligationId: val?.data?.data?.gObligationId,
              userinfo: val?.data?.data?.userinfo,
            })
          );
          dispatch(
            setgotooblogationValue({
              gObligationId: val?.data?.data?.gObligationId,
              userinfo: val?.data?.data?.userinfo,
            })
          );
          navigate(
            `/obligation/${val?.data?.data?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${val?.data?.data?.sReference
            }`
          );

          setcloneObligation(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Obligation already exists!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Obligation already exists!",
              type: "error",
            });
          }, 1400);
        }
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Obligation already exists!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Obligation already exists!",
              type: "error",
            });
          }, 1400);
        }
      });
  };

  async getFIndTable(setLoading, search, setdata, navigate) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/find-obligation?search=${search ? search : null}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(this.setobligationFindTable(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  setobligationFindTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Obligation Ref #": val?.sReference,
        Offender: val?.sPersonNameLastFirst,
        "Case Folder": "",
        "Case Ref #": "",
        sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
        ID: i,
        gObligationID: val?.gObligationID,
        userinfo: val,
      });
    });
    return arr;
  };

  async getFIndDocketTable(setLoading, search, setdata, navigate) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/find?search=${search ? search : null}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(this.setDocketFindTable(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  setDocketFindTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Reference: val?.sReferenceNumber,
        Location: val?.sGPMLocation_EntityName,
        "Docket #": val?.sDocketNumberCourtCounts,
        Offender: val?.sPersonNameLastFirst,
        "Offender #": val?.sOffenderNumber,
        SSN: val?.sSSNx4,
        "Alt ID": val?.s,
        "Court / Agency": val?.s,
        "Sent Date": val?.s,
        ID: i,
        gDocketID: val?.gDocketID,
        gPersonId: val?.gPersonID,
      });
    });
    return arr;
  };

  addObligation = async (
    setLoading2,
    setToastConfig,
    toastConfig,
    navigate,
    body,
    data,
    dispatch,
    setField,
    gotooblogationValue,
    setdata,
    setOffenderid,
    seterorhandling,
    setsDocketNumberCourtCounts,
    sDocketNumberCourtCounts,
    setaddobligation,
    obligationid2,
    field
  ) => {
    setLoading2(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/addEditObligation`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setaddobligation(false);
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);

          this.getObligation(
            setField,
            setLoading2,
            navigate,
            { gObligationId: val?.data?.data?.gObligationId },
            setdata,
            setOffenderid,
            seterorhandling,
            dispatch,
            setsDocketNumberCourtCounts,
            sDocketNumberCourtCounts,
            true,
            data,
            false,
            null,
            obligationid2,
            field
          );
        } else {
          setLoading2(false);
          dispatch(setGoToobligationIsChange(false));
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "error!",
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading2(false);
        setaddobligation(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }
      });
  };

  async changesupervisionstatus(
    setField,
    setLoading2,
    body,
    setToastConfig,
    toastConfig,
    navigate,
    setChangeFinatialSTatusPopup,
    getObligation
  ) {
    setLoading2(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/change-financial-status`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);
          getObligation();
          setChangeFinatialSTatusPopup(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "error!",
              type: "error",
            });
          }, 1400);
        }
        setLoading2(false);
      })
      .catch((val) => {
        setLoading2(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }
      });
  }

  getRecipientsTableData = async (
    setLoading,
    navigate,
    gotooblogationValue,
    setRecipientsTableData,
    setRecipientsCount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Obligations/get-recipient-pannel?gObligationId=${gotooblogationValue?.gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setRecipientsCount(val?.data?.data?.length);
        setRecipientsTableData(setTableRecipientsTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getDisbursementTableData = async (
    setLoading,
    navigate,
    gotooblogationValue,
    setDisbursementsTableData,
    setDisbursementsCount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Obligations/get-disbursement-pannel?gObligationId=${gotooblogationValue?.gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setDisbursementsCount(val?.data?.data?.length);
        setDisbursementsTableData(setTableDisbursementTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getPaymentCreditsTableData = async (
    setLoading,
    navigate,
    gotooblogationValue,
    setPaymentCreditsTableData,
    setPaymentCreditsCount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/obligation-payments-credits?gObligationID=${gotooblogationValue?.gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setPaymentCreditsCount(val?.data.data?.length);
        setPaymentCreditsTableData(setObligationOverviewTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getRecipientsDropdownData = async (
    setLoading,
    navigate,
    setRecipientsDropdownData,
    setRecipientsPaymentDropdownData,
    setRecipientsEntityDropdownData
  ) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setRecipientsDropdownData(val?.data?.data);
        setRecipientsPaymentDropdownData(
          val?.data?.data?.bakAccount?.map((item) => {
            return {
              "Account Name": item?.sName,
              "Account Number": item?.sAccountNumber,
              gBankAccountId: item?.gBankAccountId,
              gBankId: item?.gBankId,
              gEntryUserId: item?.gEntryUserId,
            };
          })
        );
        setRecipientsEntityDropdownData(
          val?.data?.data?.entity?.map((item) => {
            return {
              Entity: item?.sName,
              Type: item?.sEntityTypeName,
              "Address?": item?.bIsBilling,
              gEntityId: item?.gEntityId,
              gReportingEntityId: item?.gReportingEntityId,
            };
          })
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  getpaymentCreditsDropdownData = async (setLoading, navigate, setPaymentCreditsDropdownData) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Payment/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setPaymentCreditsDropdownData(val?.data?.data);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getRecipientsInputsAddressData = async (setLoading, navigate, gRestitutionRecipientId, setFields) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/get-recipient?gRestitutionRecipientId=${gRestitutionRecipientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setFields((prev) => ({
          ...prev,
          firstName: val?.data?.data?.sFirstName,
          lastCompany: val?.data?.data?.sLastName,
          addressLine1: val?.data?.data?.sAddress1,
          addressLine2: val?.data?.data?.sAddress2,
          city: val?.data?.data?.sCityName,
          state: val?.data?.data?.sState,
          zip: val?.data?.data?.sZip,
          zipCode: val?.data?.data?.sZip4,
          country: val?.data?.data?.sCounty,
          commentsPhone: val?.data?.data?.sAddressComment,
        }));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getFeeViolationDropdown = async (navigate, setFeeViolationDropdown) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Fee Schedule Fee`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {

        setFeeViolationDropdown(
          val?.data?.data.map((item) => {
            return {
              name: item?.value,
              id: item?.id,
            };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getViolationFeeFineDropdown = async (gFeeScheduleID, navigate, setPaymentAgencyEntity) => {
    if (!gFeeScheduleID) {
      return;
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/get-violation-fines?feeViolationId=${gFeeScheduleID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        let nameData = val?.data?.data.map((item) => {
          return {
            sFineAmountName: item?.sFineAmountName,
          };
        });
        setPaymentAgencyEntity(nameData[0]?.sFineAmountName);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getGeneratePaymentPlanTableData = async (
    setLoading,
    navigate,
    gotooblogationValue,
    setGeneratePaymentPlanTableData
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/get-paymentplan?gObligationId=${gotooblogationValue?.gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setGeneratePaymentPlanTableData(setTableGeneratePaymentPlanTableData(val?.data?.data));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  getFeeCodeChangeData = async (gFeeScheduleId, setLoading, navigate, setFields) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/amount-fee-schedule?gFeeScheduleFeeID=${gFeeScheduleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setFields((prev) => ({
          ...prev,
          adjustmentAmount: val?.data?.data?.fFee,
          feeDescription: `${val?.data?.data?.sLocalViolationDescription} `,
          paymentAgencyEntity: val?.data?.data?.sFineAmountName,
        }));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getPaymentPlanDropdownData = async (gotooblogationValue, setMonthStartDropdown, setLoading, navigate) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-payment-plan?obligationId=${gotooblogationValue?.gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setMonthStartDropdown(
          val?.data?.data?.monthStartOffsets.map((val) => {
            return {
              name: val?.value,
              id: val?.key,
            };
          })
        );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  FeeCodeGEtDropDownApi = async (setfeecodedropdown, navigate) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Fee Schedule Fee`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setfeecodedropdown(
            val?.data?.data?.map((vall) => {
              return {
                name: vall?.value,
                id: vall?.id,
              };
            })
          );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  getPaymentAgencyDropdown = async (setPaymentAgency, navigate) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Billing Agency`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setPaymentAgency(
            val?.data?.data?.map((vall) => {
              return {
                name: vall.value,
                id: vall.id ? vall.id : "00000000-0000-0000-0000-000000000000",
              };
            })
          );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getEmPeriodObligationTableData = async (setLoading, navigate, gObligationID, setEmPeriodTableData) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/getPeriodObligation?obligationId=${gObligationID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setEmPeriodTableData(setEmPeriodObligationTableData(val?.data?.data));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getEmEquipmentLogObligationTableData = async (setLoading, navigate, gObligationID, setEmEquipmentLogTableData) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/getEquipmentLog?obligationId=${gObligationID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.data) setEmEquipmentLogTableData(setEmEquipmentLogObligationTableData(val?.data?.data));
        else setEmEquipmentLogTableData([]);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getPaymentPlanFlag = async (setLoading, navigate, setPaymentPlanFlag, setObligationRefMessage) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/playment-plan-flag`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setPaymentPlanFlag(val?.data?.data);
        setObligationRefMessage(val?.data?.responseMessage);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getEmPeriodDropdownData = async (
    navigate,
    setFrequencyDropdownData,
    setEmTypeDropdownData,
    setPaymentTypeDropdownData,
    setEquipmentTypeDropdownData
  ) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setEmTypeDropdownData(
          val?.data?.data?.emType.map((item) => {
            return {
              name: item?.value,
              id: item?.key,
            };
          })
        );
        setFrequencyDropdownData(
          val?.data?.data?.frequency.map((item) => {
            return {
              name: item?.value,
              id: item?.key,
            };
          })
        );
        setPaymentTypeDropdownData(
          val?.data?.data?.paymentType.map((item) => {
            return {
              name: item?.value,
              id: item?.key,
            };
          })
        );
        setEquipmentTypeDropdownData(
          val?.data?.data?.equipmentType.map((item) => {
            return {
              name: item?.value,
              id: item?.key,
            };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getObligationDropdownData = async (navigate, gPersonId, setObligationDropdownData) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/dropdown?gPersonId=${gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setObligationDropdownData(
          val?.data?.data?.obligation.map((item) => {
            return {
              name: item?.sDdsviolationDescription,
              id: item?.gObligationId,
              type: item?.sFeeScheduleType,
            };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  async addEditPeriodObligation(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    apiPayload,
    typeTab,
    setAddEditEMPeriodPopup,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/addEditPeriodObligation`, apiPayload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddEditEMPeriodPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: typeTab === "Add" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: typeTab === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async addEditEquipmentLog(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    data,
    typeTab,
    setAddEqupmentLogPopup,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/addEditEquipmentLog`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddEqupmentLogPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: typeTab === "Add" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: typeTab === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async changeFinancialStatus(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    apiPayload,
    setChangeFinancialStatusPopup,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/change-financial-status`, apiPayload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setChangeFinancialStatusPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }

  async changeDocketFinancialStatus(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    apiPayload,
    setChangeFinancialStatusPopup,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/change-financial-status`, apiPayload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setChangeFinancialStatusPopup(false);
          if (refreshApi) refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }

  async getFeeScheduleType(navigate, setLoading, setToastConfig, toastConfig, gFeeScheduleTypeId, setIsAccrual, setIsAccrued) {
    setLoading(true);
    try {
      const val = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/feeschedule/get-feeschedulebyid?gFeeScheduleFeeId=${gFeeScheduleTypeId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      );
      setLoading(false);
      setIsAccrual(val?.data?.data?.sFeeScheduleType === "Accrued");   //if Accrued then Accrual Screen
      setIsAccrued(val?.data?.data?.sFeeScheduleType === "Accrual");  // if Accrual then Monitoring Screen else fee and payment plan
      return val?.data?.data?.fFeeAmount ?? 0;
    } catch (error) {
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      }
    }
  }

  getObligationAccrualFrequencyData(navigate, setFrequencyDropdown) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/get-obligation-accrual-frequency-data`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setFrequencyDropdown(
          val?.data?.data?.map((item) => ({
            id: item.gFrequencyID,
            name: item.sName,
          }))
        );
      })
      .catch((error) => {
        if (!error?.response) {
          navigate("/error/500");
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }
}
