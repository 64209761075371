import React from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { completedServiceTablecoloumn } from "../Data";
import { ApiError } from "../../../../Error/ApiError";

export const CompletedService = ({
  setAddCommunityServiceLogPopup,
  setVoidPopup,
  communityCompletedServiceData,
  load,
  setCompletedRowData,
  setAddCommunityServiceLogType,
  communityCompletedErorrHandling,
}) => {
  return (
    <>
      <div className="completed_service">
        {communityCompletedErorrHandling ? (
          <ApiError />
        ) : (
          <div className="completed_service_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={completedServiceTablecoloumn}
              tableRow={communityCompletedServiceData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={load}
              coloumWidthText={"PlannedservicesTable"}
              mandatoryColoum={"Reason"}
              exportInDifferentFormate={true}
              exportwidth={27}
              exporttitle={"Plannedservices"}
              setAddCommunityServiceLogPopup={setAddCommunityServiceLogPopup}
              completedMenu={true}
              setVoidPopup={setVoidPopup}
              setCompletedRowData={setCompletedRowData}
              setAddCommunityServiceLogType={setAddCommunityServiceLogType}
              setVoideFalse={true}
            />
          </div>
        )}
      </div>
    </>
  );
};
