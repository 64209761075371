import { useEffect, useState } from "react";
import { DateBox, SelectBox } from "devextreme-react";
import { FinancialFunction } from "../../../../../Financial/FinancialFunction";
import { useNavigate } from "react-router-dom";

const AddEditObligationDetail = ({
  obligationDetail,
  modalType,
  setShowPopup,
  reloadObligationDetails,
  setToastConfig,
  getObligation,
  key
}) => {
  const [transactionTypes, setTransactionTypes] = useState([]);
  const ApiClass = new FinancialFunction();
  const navigate = useNavigate();

  const [fields, setFields] = useState({
    participant: "",
    obligation: "",
    date: new Date().toISOString(),
    rate: 0,
    transactionType: "",
    note: "",
  });

  useEffect(() => {
    const getTransactionTypes = () => {
      ApiClass.populateObligationDetailTypes(navigate, setTransactionTypes);
    };
    getTransactionTypes();
  }, []);

  useEffect(() => {
    setFields({
      participant: `${obligationDetail?.sOffenderNumber} - ${obligationDetail?.sLastFirst}`,
      obligation: obligationDetail?.sFeeName,
      rate: obligationDetail?.fRate,
      note: obligationDetail?.sNote,
      date: obligationDetail?.dDoe ? new Date(obligationDetail?.dDoe) : new Date(),
      transactionType: obligationDetail?.gTransactionTypeId,
    });
  }, [obligationDetail, key]);

  const onChange = (e) => {
    const { name, value } = e.target;
    const numericFields = ["rate"];
    const parsedValue = numericFields.includes(name) ? parseFloat(value) || 0 : value;
    setFields((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
  };

  const handleSave = () => {
    if (fields?.transactionType && fields?.rate) {
      if (modalType === "edit") {
        const body = {
          gObligationDetailId: obligationDetail?.gObligationDetailId,
          fRate: fields.rate,
          fAmount: fields.rate,
          iQuantity: 1,
          sNote: fields.note,
          gTransactionTypeId: fields.transactionType,
          dDoe: fields.date,
        };
        ApiClass.addEditObligationDetail(navigate, setToastConfig, body, reloadObligationDetails, setShowPopup, setFields, getObligation);
      } else {
        const body = {
          fRate: fields.rate,
          fAmount: fields.rate,
          iQuantity: 1,
          sNote: fields.note,
          gTransactionTypeId: fields.transactionType,
          dDoe: fields.date,
          gObligationId: obligationDetail?.gObligationId?.gObligationId || obligationDetail?.gObligationId,
        };
        ApiClass.addEditObligationDetail(navigate, setToastConfig, body, reloadObligationDetails, setShowPopup, setFields, getObligation);
      }
    } else {
      setToastConfig({
        isVisible: true,
        type: "error",
        message: "Check the form again!",
      });
      setTimeout(
        () =>
          setToastConfig({
            isVisible: false,
            type: "error",
            message: "Check the form again!",
          }),
        1000
      );
    }
  };

  return (
    <>
      <div className="obligation_detail_popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>{modalType === "edit" ? "Edit" : "Add"} Obligation Detail</p>
            </div>
            <p class="popup_header_title_para">OB.28</p>
          </div>
          <svg
            onClick={() => {
              setShowPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div>
          <div className="">
            <div className="" style={{ margin: "10px" }}>
              <p className="edit_container_label">Participant : {fields?.participant}</p>
            </div>
            <div className="" style={{ margin: "10px" }}>
              <p className="edit_container_label">Obligation : {fields?.obligation}</p>
            </div>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <p>Date</p>
                </td>
                <td>
                  <DateBox
                    value={fields?.date}
                    onValueChanged={(e) => {
                      setFields((prev) => ({
                        ...prev,
                        date: new Date(e.value).toISOString(),
                      }));
                    }}
                    width="250px"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Amount</p>
                </td>
                <td>
                  <input
                    type="number"
                    name="rate"
                    style={{ width: "250px" }}
                    placeholder="Enter Amount"
                    className="edit_container_input"
                    value={fields?.rate}
                    onChange={onChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Transaction Type</p>
                </td>
                <td>
                  <SelectBox
                    dataSource={transactionTypes}
                    valueExpr={"gTransactionTypeID"}
                    displayExpr={"sName"}
                    value={fields?.transactionType}
                    onValueChange={(value) => {
                      setFields((prev) => ({
                        ...prev,
                        transactionType: value,
                      }));
                    }}
                    width="250px"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Note</p>
                </td>
                <td>
                  <input
                    type="text"
                    name="note"
                    placeholder="Enter Note"
                    className="edit_container_input"
                    value={fields?.note}
                    onChange={onChange}
                    style={{ width: "100%" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="popup_buttons" style={{ marginTop: "10px", marginRight: "10px" }}>
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setFields({
                  participant: "",
                  obligation: "",
                  date: new Date().toISOString(),
                  rate: 0,
                  transactionType: "",
                  note: "",
                });
                setShowPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditObligationDetail;
