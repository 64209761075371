import { useState } from "react";
import { Table2 } from "../../../../../../components/Table2/Table2";
import { useEffect } from "react";
import { GoToDocketPaymentPlanFunction } from "./GoToDocketPaymentPlanFunction";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../../../../assets/images/icons/download.svg";

const PaymentPlanTablecoloumn = [
  { title: "Pay Date" },
  { title: "Payment Due", currency: true },
  { title: "Paid", currency: true },
  { title: "Total Paid", currency: true },
  { title: "Total Due", currency: true },
  { title: "Balance", currency: true },
  { title: "Status", pause: true, bool: true },
];

export const PaymentPlan = ({ docketValue }) => {
  const cls = new GoToDocketPaymentPlanFunction();
  const [exportButton, setexportPopupOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    cls.goToDocketPaymentPlanApiCall(
      setLoad,
      seterrorhandling,
      setData,
      navigate,
      docketValue
    );
  }, []);

  return (
    <>
      <div>
        <button
          className="financial_overview_tab_button"
          onClick={() => {
            setexportPopupOpen(true);
          }}
        >
          Export <DownloadIcon className="svg-blue-white" />
        </button>

        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <Table2
            Tablecoloum={PaymentPlanTablecoloumn}
            tableRow={data}
            editTable={false}
            load={false}
            coloumWidthText={"GoToDocketPaymentPlan"}
            height={"auto"}
            mandatoryColoum={"Pay Date"}
            showTotalBlock={false}
            setexportPopupOpen={setexportPopupOpen}
            exporttitle={"Payment Plan"}
            exportPopupOpen={exportButton}
            exportInDifferentFormate={true}
          />
        </div>
      </div>
    </>
  );
};
