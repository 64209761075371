import { useState } from "react";
import { Table1 } from "../../../Administration/Table1/Table1";
import { DocumentWizardDocketsColoumn } from "../Data";

const DocumentWizardDockets = ({
  documentWizardDocketsTableData,
  setDocketsRowTableData,
  editToggle,
  sharequeryshow,
  fields,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="document-wizard-tabs-content">
        <p className="page-info-text-detail">Dockets (Active)</p>
        <Table1
          editTable={false}
          height={"auto"}
          Tablecoloum={DocumentWizardDocketsColoumn}
          tableRow={documentWizardDocketsTableData?.map((val, i) => {
            return { ...val, ID: i };
          })}
          load={loading}
          coloumWidthText={"Document Wizard Dockets"}
          mandatoryColoum={"Docket/Count"}
          documentWizardDocketsSelection={true}
          setDocumentWizardDocketsRowTableData={setDocketsRowTableData}
          setDocumentWizardDiable={editToggle || fields?.reports?.id === ""}
          disableTable={true}
          SharequeryTableselectall={true}
          sharequeryshow={sharequeryshow}
        />
      </div>
    </>
  );
};

export default DocumentWizardDockets;
