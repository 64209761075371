import { DateBox, ScrollView } from "devextreme-react";
import "./feePaymentPlan.css";
import { Table1 } from "../../../../../Administration/Table1/Table1";
import { useState } from "react";
import { violationCodeTableColumn, violationFineTableColumn } from "../Data";

export const FeeScheduleSelect = ({
  feeScheduleSelectPopUp,
  setFeeScheduleSelectPopUp,
}) => {
  const [loading, setLoading] = useState(false);
  const [violationCodeData, setViolationCodeData] = useState([]);

  return (
    <>
      <div className="fee-schedule-select">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Fee Schedule Select</p>
            </div>
            <p class="popup_header_title_para">FN.7</p>
          </div>
          <svg
            onClick={() => {
              setFeeScheduleSelectPopUp(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ width: "100%", height: "540px" }}>
          <div className="fee-schedule-box">
            <div className="fee-shedule-container">
              <div className="card-container" style={{ margin: "0px" }}>
                <p className="card-container-header">Violation Code Section</p>
                <div className="card-container-header-content">
                  <div className="card-flex">
                    <div className="inputs-field-content dropdown-inputs-field-content">
                      <label className="fee-payment-content-info-para">
                        Obligation Start
                      </label>
                      <DateBox
                        useMaskBehavior={true}
                        //   value={fields.obligationStartDate}
                        //   onValueChange={(e) => {
                        //     setFields((prev) => ({
                        //       ...prev,
                        //       obligationStartDate: e,
                        //     }));
                        //   }}
                      />
                    </div>
                    <div className="inputs-field-content dropdown-inputs-field-content">
                      <label
                        htmlFor="quickFilter"
                        className="fee-payment-content-info-para"
                      >
                        Quick Filter
                      </label>
                      <input
                        type="text"
                        name="quickFilter"
                        id="quickFilter"
                        className="fee-payment-content-info-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="fee-schedule-table-left">
                  <Table1
                    editTable={false}
                    height={"auto"}
                    Tablecoloum={violationCodeTableColumn}
                    tableRow={violationCodeData?.map((val, i) => {
                      return { ...val, ID: i };
                    })}
                    load={loading}
                    coloumWidthText={"FeePaymentPlan"}
                    mandatoryColoum={"Pay Date"}
                    exporttitle={"Fee-Payment-Plan"}
                  />
                </div>
              </div>
              <div className="card-container" style={{ margin: "0px" }}>
                <p className="card-container-header">Violation Fine</p>
                <div className="card-container-header-content">
                  <div className="card-flex-column">
                    <label className="fee-payment-content-info-para">
                      Obligation Start
                    </label>
                    <label
                      htmlFor="quickFilter"
                      className="fee-payment-content-info-para"
                    >
                      Quick Filter
                    </label>
                  </div>
                  <div className="fee-schedule-table-left">
                    <Table1
                      editTable={false}
                      height={"auto"}
                      Tablecoloum={violationFineTableColumn}
                      tableRow={violationCodeData?.map((val, i) => {
                        return { ...val, ID: i };
                      })}
                      load={loading}
                      coloumWidthText={"FeePaymentPlan"}
                      mandatoryColoum={"Pay Date"}
                      exporttitle={"Fee-Payment-Plan"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="popup_buttons">
              <button className="btn primary_btn">Save</button>
              <button
                className="btn"
                onClick={() => {
                  setFeeScheduleSelectPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
