import { LoadPanel, Popup } from "devextreme-react";
import { useEffect, useState } from "react";
import { Toster } from "../../components/Toster/Toster";
import { ApiError } from "../Error/ApiError";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add.svg";
import { Tablecoloum, setTableData } from "./Data";
import { Table2 } from "../../components/Table2/Table2";
import { logOut } from "../../components/Logout";
import { useNavigate } from "react-router-dom";
import { QuickAddTaskPopup } from "../Participants/HeaderPopupComponent/AddQuickTask/QuickAddTaskPopup";
import { PendingPOpup } from "../Participants/ParticipantSubTabs/Task/Pending";
import { ReassignTasksPopup } from "./ReassignTasksPopup";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const Pending = ({
  PendingPOpuptogal,
  setPendingPOpuptogal,
  setToastConfig,
  toastConfig,
  reset,
  setreset,
  gettaskEditDAta,
}) => {
  return (
    <>
      <Popup
        visible={gettaskEditDAta?.taskId && PendingPOpuptogal}
        onHiding={() => {
          setPendingPOpuptogal(false);
        }}
        showTitle={false}
        width={720}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <PendingPOpup
          setPendingPOpuptogal={setPendingPOpuptogal}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          reset={reset}
          setreset={setreset}
          gettaskEditDAta={gettaskEditDAta}
        />
      </Popup>
    </>
  );
};

const TasksPopup = ({
  reassignTasksPopup,
  setReassignTasksPopup,
  assignToOptions,
  selectedRowCheckbox,
  toastConfig,
  setToastConfig,
  refreshApi,
}) => {
  return (
    <>
      <Popup
        visible={reassignTasksPopup}
        onHiding={() => {
          setReassignTasksPopup(false);
        }}
        showTitle={false}
        width={576}
        height={420}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ReassignTasksPopup
          setReassignTasksPopup={setReassignTasksPopup}
          assignToOptions={assignToOptions}
          selectedRowCheckbox={selectedRowCheckbox}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          refreshApi={refreshApi}
        />
      </Popup>
    </>
  );
};

export const Tasks = () => {
  const [togal, setTogal] = useState(true);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [errorhandling, setErrorhandling] = useState(false);
  const [data, setdata] = useState([]);
  const [addTaskPopupType, setAddTaskPopupType] = useState("");
  const [Dropdowndata, setDropdowndata] = useState({});
  const [reset, setreset] = useState(false);
  const [gettaskEditDAta, setgettaskEditDAta] = useState(null);
  const [addQuickPopup, setAddQuickPopup] = useState(false);
  const [PendingPOpuptogal, setPendingPOpuptogal] = useState(false);
  const [reassignTasksPopup, setReassignTasksPopup] = useState(false);
  const [assignToOptions, setAssignToOptions] = useState([]);
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);
  const [approveRefundFailed, setApproveRefundFailed] = useState(false);
  const [refundFailedMessage, setRefundFailedMessage] = useState("");
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");

  const refreshTable = () => {
    if (togal) {
      getAllTasksList(localStorage.getItem("gUserId"));
    } else {
      getAllTasksList("00000000-0000-0000-0000-000000000000");
    }
  };

  const setReassignTasksPopupValidate = (value) => {
    if (selectedRowCheckbox.length) {
      setReassignTasksPopup(value);
    } else {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "No Task Selected!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "No Task Selected!",
          type: "error",
        });
      }, 1900);
    }
  };

  const getAssignToOptions = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/get-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAssignToOptions(
            val?.data?.data?.assignedTo?.map((vall) => {
              return {
                name: vall?.name,
                id: vall?.assignedToId,
              };
            })
          );
          setErrorhandling(false);
        } else {
          setErrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  };

  const getAllTasksList = async (gUserId) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/get-all-tasks?gUserId=${gUserId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setdata(setTableData(val.data.data));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getDropdown = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/get-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setDropdowndata(val?.data?.data);
          setErrorhandling(false);
        } else {
          setErrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  };

  const getCompletTask = async (taskid) => {
    gettaskEditDAta?.taskId && setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/task/complete-task?taskId=${taskid ? taskid : gettaskEditDAta?.taskId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Task completed successfully",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Task completed successfully",
            type: "success",
          });
        }, 1900);
        getAllTasksList("00000000-0000-0000-0000-000000000000");
      })
      .catch((val) => {
        getAllTasksList("00000000-0000-0000-0000-000000000000");
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
  
  const approveTaskProcessCloseout = async (taskId, userId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/task/task-approval`,
        {
          gTaskID: taskId, // Make sure taskId is passed correctly
          gUserID: userId, // Ensure userId is passed correctly
          taskOption: "Approve/Process Close-out",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );

      if (response.data.isSuccess) {
        console.log("Task approved successfully", response);
        setToastConfig({
          isVisible: true,
          type: "success",
          message: "Task approved successfully",
        });
      } 
    } catch (error) {
      if (!error.response) {
        navigate(`/error/500`);
      } else if (error.response.status === 401) {
        logOut();
      } 
    }
  };

  const AllocationAdjustment
    = async (taskId, userId) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/task/task-approval`,
          {
            gTaskID: taskId, // Make sure taskId is passed correctly
            gUserID: userId, // Ensure userId is passed correctly
            taskOption: "Allocation Adjustment",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        );

        if (response.data.isSuccess) {
          console.log("Task approved successfully", response);
          setToastConfig({
            isVisible: true,
            type: "success",
            message: "Task approved successfully",
          });
        } 
      } catch (error) {
        if (!error.response) {
          navigate(`/error/500`);
        } else if (error.response.status === 401) {
          logOut();
        } 
      }
    };
    const ApprovProcessRefund
    = async (taskId, userId) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/task/task-approval`,
          {
            gTaskID: taskId, // Make sure taskId is passed correctly
            gUserID: userId, // Ensure userId is passed correctly
            taskOption: "Approve/Process Refund (formerly ROP)",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        );

        if (response.data.isSuccess) {
          console.log("Task approved successfully", response);
          setToastConfig({
            isVisible: true,
            type: "success",
            message: "Task approved successfully",
          });
        } 
      } catch (error) {
        if (!error.response) {
          navigate(`/error/500`);
        } else if (error.response.status === 401) {
          logOut();
        } 
      }}
  useEffect(() => {
    getDropdown();
    getAssignToOptions();
  }, [gettaskEditDAta]);
  
  useEffect(() => {
    if (togal) {
      getAllTasksList(localStorage.getItem("gUserId"));
    } else {
      getAllTasksList("00000000-0000-0000-0000-000000000000");
    }
  }, [togal]);

  const buttonAssigntoMeStyle = {
    backgroundColor: togal ? "#424167" : "#eeeeff",
  };

  const approveRefund = async () => {
    gettaskEditDAta?.taskId && setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/task/approve-refund`,
        gettaskEditDAta?.body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if(val?.data?.success) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Refund Processed",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "",
              type: "",
            });
          }, 1900);
          getAllTasksList("00000000-0000-0000-0000-000000000000");
        } else {
          setApproveRefundFailed(true);
          setRefundFailedMessage(val?.data?.message);
        }
      })
      .catch((val) => {
        getAllTasksList("00000000-0000-0000-0000-000000000000");
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={addQuickPopup}
        onHiding={() => {
          setAddQuickPopup(false);
        }}
        showTitle={false}
        width={1100}
        height={830}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <QuickAddTaskPopup
          setAddQuickPopup={setAddQuickPopup}
          addQuickPopup={addQuickPopup}
          errorhandling={errorhandling}
          loading={loading}
          Dropdowndata={Dropdowndata}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          //userinfo={userinfo}
          reset={reset}
          setreset={setreset}
          //titalNmae={titalNmae}
          gettaskEditDAta={gettaskEditDAta}
          addTaskPopupType={addTaskPopupType}
        />
      </Popup>
      <Popup
        visible={approveRefundFailed}
        onHiding={() => {
          setApproveRefundFailed(false);
        }}
        showTitle={false}
        width={400}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="popup_header">
              <div className="popup_header_title">
                <div class="popup_header_title_data">
                  <p>Approve Refund Failed</p>
                </div>
              </div>
              <svg
                onClick={() => {
                  setApproveRefundFailed(false);
                }}
                style={{
                  cursor: "pointer",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="white"
                />
              </svg>
        </div>
        <div style={{ gap: 10 }} className="quick_add_task_container">
          <div style={{height:'80px'}}>
            {refundFailedMessage}
          </div>
        </div>
        <div
          className="popup_buttons"
          style={{
            marginRight: "2%",
          }}
        >
          <button
            onClick={() => {
              setApproveRefundFailed(false);
              setRefundFailedMessage('');
              getAllTasksList("00000000-0000-0000-0000-000000000000");
            }}
            className="btn primary_btn"
          >
            Ok
          </button>
        </div>
      </Popup>
      <Pending
        PendingPOpuptogal={PendingPOpuptogal}
        setPendingPOpuptogal={setPendingPOpuptogal}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        reset={reset}
        gettaskEditDAta={gettaskEditDAta}
        setreset={setreset}
      />
      <TasksPopup
        reassignTasksPopup={reassignTasksPopup}
        setReassignTasksPopup={setReassignTasksPopup}
        assignToOptions={assignToOptions}
        selectedRowCheckbox={selectedRowCheckbox}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        refreshApi={refreshTable}
      />
      <div className="page-section">
        <DynamicTabb />
        <div className="page-content">
          <p className="page-info-number">TM.1</p>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setAddQuickPopup(true);
                  setAddTaskPopupType("Add");
                }}
              >
                Add Task <AddIcon className="svg-blue-white" />
              </button>
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <button className="btn" onClick={refreshTable}>
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                style={buttonAssigntoMeStyle}
                className="btn"
                onClick={() => {
                  setTogal(!togal);
                }}
              >
                Assigned to Me
              </button>
            </div>
          </div>

          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <div className="tasktable">
                <Table2
                  Tablecoloum={Tablecoloum}
                  tableRow={data}
                  editTable={false}
                  load={loading}
                  coloumWidthText={"AssignedTo"}
                  height={"auto"}
                  mandatoryColoum={"Date Entered"}
                  exportInDifferentFormate={true}
                  exportPopupOpen={exportPopupOpen}
                  setexportPopupOpen={setexportPopupOpen}
                  exportwidth={16.3}
                  exporttitle={"tasks"}
                  showTotalBlock={false}
                  addTaskMenu={true}
                  setAddTaskPopup={setAddQuickPopup}
                  setAddTaskPopupType={setAddTaskPopupType}
                  taskcontextmenu2={true}
                  setAddQuickPopup={setAddQuickPopup}
                  setresettask={setreset}
                  resettask={reset}
                  setgettaskEditDAta={setgettaskEditDAta}
                  gettaskEditDAta={gettaskEditDAta}
                  setPendingPOpuptogal={setPendingPOpuptogal}
                  getCompletTask={getCompletTask}
                  approveTaskProcessCloseout={approveTaskProcessCloseout}
                  AllocationAdjustment={AllocationAdjustment}
                  ApprovProcessRefund={ApprovProcessRefund}
                  setReassignTasksPopup={setReassignTasksPopupValidate}
                  setSelectedRowCheckbox={setSelectedRowCheckbox}
                  selectedRowCheckbox={selectedRowCheckbox}
                  approveRefund={approveRefund}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
