export const Tablecoloum = [
  { moreoption: true, personListControl: true, customwidth: "60px"},
  // { title: "", moreoption: true, customwidth: "50px" },
  { title: "Select", bool: true, personListControl: true, customwidth: 50 },
  { title: "Last Name", underLine: true, id: 1, operation: false, show: true },
  { title: "First Name", id: 2, operation: false, show: true },
  { title: "Agency/Court", id: 3, operation: false, show: true },
  { title: "Primary Location", id: 5, operation: false, show: true },
  { title: "Supervisor", id: 6, operation: false, show: true },
  { title: "Offender Number", id: 7, operation: false, show: false },
  { title: "DOB", date: true, id: 8, operation: true, show: true },
  { title: "SSN", id: 9, operation: true, show: true, number: true },
  { title: "Person Status", id: 10, operation: false, show: true },
  { title: "Credit Balance", id: 11, operation: false, show: false },
  { title: "F/S", id: 12, operation: false, show: true },
  { title: "S/S", id: 13, operation: false, show: true },
  { title: "Compliance", id: 14, operation: false, show: true },
  { title: "Full Name", id: 15, operation: false, show: true },
  { title: "Payment Frequency", id: 16, operation: false, show: true },
  {
    title: "Payment Amount",
    id: 17,
    operation: false,
    show: true,
    currency: true,
  },
  { title: "Entered", id: 18, operation: false, show: true },
  { title: "Balance", id: 19, operation: false, show: true, currency: true},
  { title: "Alt ID", id: 20, operation: false, show: true }
];

export const Tablecoloumemtrue = [
  { moreoption: true, personListControl: true, customwidth: "60px"},
  { title: "Select", bool: true, personListControl: true },
  { title: "Last Name", underLine: true, id: 1, operation: false, show: true },
  { title: "First Name", id: 2, operation: false, show: true },
  { title: "Agency/Court", id: 3, operation: false, show: true },
  { title: "EM Periods", id: 4, operation: false, show: false, bool: true },
  { title: "Primary Location", id: 5, operation: false, show: true },
  { title: "Supervisor", id: 6, operation: false, show: true },
  { title: "Offender Number", id: 7, operation: false, show: false },
  { title: "DOB", date: true, id: 8, operation: true, show: true },
  { title: "SSN", id: 9, operation: true, show: true, number: true },
  { title: "Person Status", id: 10, operation: false, show: true },
  { title: "Credit Balance", id: 11, operation: false, show: false },
  { title: "F/S", id: 12, operation: false, show: true },
  { title: "S/S", id: 13, operation: false, show: true },
  { title: "Compliance", id: 14, operation: false, show: true },
  { title: "Full Name", id: 15, operation: false, show: true },
  { title: "Payment Frequency", id: 16, operation: false, show: true },
  {
    title: "Payment Amount",
    id: 17,
    operation: false,
    show: true,
    currency: true,
  },
  { title: "Entered", id: 18, operation: false, show: true },
  { title: "Balance", id: 19, operation: false, show: true, currency: true},
  { title: "Alt ID", id: 20, operation: false, show: true }
];
