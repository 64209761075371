import React, { useState, useEffect } from "react";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import DropDownBox from "devextreme-react/drop-down-box";
import { useDispatch } from "react-redux";
import {
  setGPersonEmailContactInfoId,
  setGPersonTextContactInfoId,
} from "../../../../reducer/ParticipateReducer";

export const MultiColumnDropdown = ({
  email,
  text,
  editable,
  value,
  setsend,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchedDataSource = email?.map((val) => {
      return {
        [text]: val?.sInformation,
        Comment: val?.sComment,
        ID: val?.gPersonContactInfoID,
      };
    });

    setDataSource(fetchedDataSource);
  }, [email]);

  useEffect(() => {
    dataSource.map((val) => {
      if (val?.ID == value) {
        setSelectedValue(val?.[text]);
        setsend({ [text]: val?.[text], data: val });
      }
    });
  }, [value, dataSource]);
  const [open, setOpen] = useState(false);

  const handleRowClick = (e) => {
    if (text === "Email") {
      dispatch(setGPersonEmailContactInfoId(e?.data?.ID));
    } else {
      dispatch(setGPersonTextContactInfoId(e?.data?.ID));
    }
    setsend({ [text]: e?.data[text], data: e.data });
    setSelectedValue(e?.data[text]);
    setOpen(false);
  };

  return (
    <DropDownBox
      dataSource={dataSource}
      value={selectedValue}
      valueExpr={text}
      displayExpr={text}
      disabled={!editable}
      opened={open}
      onOptionChanged={(e) => {
        if (e.name === "opened") {
          setOpen(e.value);
        }
      }}
      contentRender={(value, element) => (
        <DataGrid
          dataSource={dataSource}
          hoverStateEnabled={true}
          height={"auto"}
          onRowClick={handleRowClick}
        >
          <Selection mode="single" />
          <Column dataField={text} caption={text} />
          <Column dataField="Comment" caption="Comment" />
        </DataGrid>
      )}
    />
  );
};
