import React, { useState, useEffect, useRef } from "react";
import { DataGrid, LoadPanel, ScrollView } from "devextreme-react";
import httpService from "../../services/http-service";
import { logOut } from "../../components/Logout";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../services/auth-service";
import {
  Column,
  Editing,
  HeaderFilter,
  Lookup,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import { ApiError } from "../Error/ApiError";

export const AddReport = ({ setPopUp }) => {
  const componentRef = useRef(null);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorhandling, seterrorhandling] = useState(false);

  const navigate = useNavigate();

  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();

  const getReports = async () => {
    try {
      const response = await httpService.get(
        `api/ReportDashboard/populate-report`,
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      );
      if (response.data.isSuccess) {
        setReports(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(false);
      }
    }
  };

  const updateReport = async (body) => {
    try {
      setLoading(true);
      const response = await httpService.post(
        `api/ReportDashboard/update-report`,
        body,
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      );
      if (response.data.isSuccess) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(false);
      }
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="admin_Add_Table">
        <div ref={componentRef}>
          <div className="admin_Add_Table_head">
            <div className="admin_Add_Table_head_title">
              <div className="admin_Add_Table_head_title_data">
                <p style={{ width: "120px" }}>Manage Reports</p>
              </div>
              <p className="admin_Add_Table_head_para">CR.12</p>
            </div>
            <svg
              onClick={() => {
                setPopUp(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <ScrollView style={{ height: "555px", padding: "20px" }}>
            {errorhandling ? (
              <ApiError />
            ) : (
              <DataGrid
                dataSource={reports}
                onSaved={async (e) => {
                  const changes = e.changes.map((change) => {
                    if (change.type === "remove")
                      return {
                        sReportPath: "removeReportParameter",
                        gReportId: change.key,
                      };
                    return { ...change.data };
                  });
                  updateReport(changes);
                }}
                keyExpr={"gReportId"}
                height={"auto"}
                columnAutoWidth={true}
              >
                <HeaderFilter visible={true} allowSearch={true} />
                <Paging enabled={false} />
                <Editing
                  mode="batch"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                />
                <Column
                  dataField="sCategoryName"
                  caption="Category Name"
                  defaultSortOrder={"asc"}
                >
                  <RequiredRule />
                  <Lookup
                    dataSource={[
                      "Administration",
                      "Disbursements",
                      "Financial",
                      "Monitoring",
                      "Supervision",
                    ]}
                  />
                </Column>
                <Column dataField="bActive" caption="Active" />
                <Column
                  dataField="bIncludeCriteriaOption"
                  caption="IncludeCriteria"
                />
                <Column
                  dataField="sReportName"
                  caption="Report Name"
                  defaultSortOrder={"asc"}
                >
                  <RequiredRule />
                </Column>
                <Column dataField="sReportPath" caption="Report Path">
                  <RequiredRule />
                </Column>
                <Column dataField="iSort" caption="iSort" />
                <Column
                  dataField="sPreProcessor"
                  caption="PreProcessor"
                ></Column>
              </DataGrid>
            )}
          </ScrollView>
        </div>
      </div>
    </>
  );
};
