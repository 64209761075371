import { DateBox, LoadPanel, Popup } from "devextreme-react";
import "./contacts.css";
import { Toster } from "../../../components/Toster/Toster";
import { useEffect, useRef, useState } from "react";
import { handleGetLocal } from "../../../services/auth-service";
import { useNavigate, useParams } from "react-router-dom";
import ContactsSubTabs from "./ContactsSubTabs";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { useDispatch } from "react-redux";
import {
  removeProntactContactsdynamicTab,
  setAddContactsInfoSaveData,
  setAdminSavePopup,
  setContectEntityListTableAddData2,
  setProntactContactsdynamicTab,
  setResetBackProntactContactsdynamicTab,
  setResetProntactContactsdynamicTab,
} from "../../../reducer/AdminReadReducer";
import FindContacts from "./contactsSubTabs/popups/FindContacts";
import { ApiError } from "../../Error/ApiError";
import { ContactsFunction } from "./ContactsFunction";
import { useSelector } from "react-redux";
import HelpPopup from "../../HeaderSideBar/HelpPopup";
import { headersidebarfunction } from "../../HeaderSideBar/headersidebarfunction";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";

const Contacts = () => {
  const [loading, setLoading] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const params = useParams();
  const navigate = useNavigate();
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [isEditableButton, setIsEditableButton] = useState(false);
  const [typeFeeSchedule, setTypeFeeSchedule] = useState("Add");
  const [fields, setFields] = useState({
    firstRowName: "",
    secondRowName: "",
    thirdRowName: "",
    fourthRowName: "",
    fifthRowName: "",
    Rowsalutation: "",
    contactID: "",
    nextContact: "",
    approved: false,
    phoneNo: "",
    email: "",
    physicalAddressLine1: "",
    physicalAddressLine2: "",
    physicalAddressCity: "",
    physicalAddressState: "",
    physicalAddressZip: "",
    physicalAddressZipCode: "",
    physicalAddressCountry: "",
    mailingAddressLine1: "",
    mailingAddressLine2: "",
    mailingAddressCity: "",
    mailingAddressState: "",
    mailingAddressZip: "",
    mailingAddressZipCode: "",
    mailingAddressCountry: "",
    homeAddressLine1: "",
    homeAddressLine2: "",
    homeAddressCity: "",
    homeAddressState: "",
    homeAddressZip: "",
    homeAddressZipCode: "",
    homeAddressCountry: "",
    firstName: "",
    middleName: "",
    lastName: "",
    prefix: "",
    suffix: "",
    title: { name: "", id: "" },
    salutation: "",
  });
  const [contactData, setContactData] = useState([]);
  const [contactHandling, setContactHandling] = useState(false);
  const dispatch = useDispatch();
  const [findContactPopup, setFindContactPopup] = useState(false);
  const [lastNamevalidation, setLastNamevalidation] = useState("");
  const [firstNamevalidation, setFirstNamevalidation] = useState("");
  const [titleDropdown, setTitleDropdown] = useState([]);
  const [contactInfoType, setcontactInfoType] = useState([]);
  const [data, setdata] = useState([]);
  const [contactInfoTypeCount, setContactInfoTypeCount] = useState(0);
  const apiClass = new ContactsFunction();
  const [contactsEntityListDropdown, SetContactsEntityListDropdown] = useState([]);
  const [entityListRightData, setEntityListRightData] = useState([]);
  const [entityListLeftData, setEntityListLeftData] = useState([]);
  const [contactEntityListCount, setContactEntityListCount] = useState(0);
  const { addContactsInfoSaveData, ContectEntityListTableAddData2 } = useSelector((store) => store.AdminTable);
  const [reportCatalogData, setReportCatalogData] = useState([]);
  const [allData, setAllData] = useState([]);

  const emptyFields = () => {
    setFields({
      firstRowName: "",
      secondRowName: "",
      thirdRowName: "",
      fourthRowName: "",
      fifthRowName: "",
      Rowsalutation: "",
      contactID: "",
      nextContact: "",
      approved: false,
      phoneNo: "",
      email: "",
      physicalAddressLine1: "",
      physicalAddressLine2: "",
      physicalAddressCity: "",
      physicalAddressState: "",
      physicalAddressZip: "",
      physicalAddressZipCode: "",
      physicalAddressCountry: "",
      mailingAddressLine1: "",
      mailingAddressLine2: "",
      mailingAddressCity: "",
      mailingAddressState: "",
      mailingAddressZip: "",
      mailingAddressZipCode: "",
      mailingAddressCountry: "",
      homeAddressLine1: "",
      homeAddressLine2: "",
      homeAddressCity: "",
      homeAddressState: "",
      homeAddressZip: "",
      homeAddressZipCode: "",
      homeAddressCountry: "",
      firstName: "",
      middleName: "",
      lastName: "",
      prefix: "",
      suffix: "",
      title: { name: "", id: "" },
      salutation: "",
    });
    setLastNamevalidation("");
    setFirstNamevalidation("");
  };

  const OnAdd = () => {
    setTypeFeeSchedule("Add");
    setContactInfoTypeCount(0);
    setContactEntityListCount(0);
    dispatch(setResetProntactContactsdynamicTab(`/prontact/contacts/${params.id}`));
    setIsEditableButton(true);
    emptyFields();
    setdata([]);
    setContactData([]);
    setEntityListLeftData([]);
    setEntityListRightData([]);
    dispatch(setContectEntityListTableAddData2([]));
    dispatch(setAddContactsInfoSaveData([]));
    setAllData([]);
  };

  const oncancel = () => {
    dispatch(setAdminSavePopup({ adminSavePopup: false }));
    setIsEditableButton(!isEditableButton);
    dispatch(
      setResetBackProntactContactsdynamicTab({
        title: contactData?.sLastFirst,
        link: `/prontact/contacts/${params.id}`,
      })
    );
    if (params?.id) {
      setLastNamevalidation("");
      setFirstNamevalidation("");
      getContactHeaderInfoDetails(params?.id);
      setTypeFeeSchedule("Edit");
      apiClass.getContactInfoDetails(params?.id, setLoading, setdata, setContactInfoTypeCount);
      apiClass.getContactEntityListData(
        params,
        setLoading,
        setEntityListLeftData,
        setEntityListRightData,
        setContactEntityListCount,
        dispatch
      );
    } else {
      setTypeFeeSchedule("Add");
      emptyFields();
      setEntityListLeftData([]);
      setEntityListRightData([]);
      dispatch(setAddContactsInfoSaveData([]));
      dispatch(setContectEntityListTableAddData2([]));
      setAllData([]);
      setdata([]);
    }
  };

  const bodyData = {
    GContactId: typeFeeSchedule === "Add" ? "00000000-0000-0000-0000-000000000000" : contactData?.gContactId,
    GEntryUserId: localStorage.getItem("gUserId"),
    SFirstName: fields?.firstName,
    SLastName: fields?.lastName,
    STitle: fields?.title?.name ? fields?.title?.name : null,
    SSalutation: fields?.salutation,
    SPhysical_Address1: fields?.physicalAddressLine1,
    SPhysical_Address2: fields?.physicalAddressLine2,
    GPhysicalCityId: null,
    SPhysicalState: fields?.physicalAddressState,
    SPhysicalZip: fields?.physicalAddressZip,
    SMailing_Address1: fields?.mailingAddressLine1,
    SMailing_Address2: fields?.mailingAddressLine2,
    GMailingCityId: null,
    SMailingState: fields?.mailingAddressState,
    SMailingZip: fields?.mailingAddressZip,
    SPrefixName: fields?.prefix,
    SSuffixName: fields?.prefix,
    SHome_Address1: fields?.homeAddressLine1,
    SHome_Address2: fields?.homeAddressLine2,
    GHomeCityId: null,
    SHomeState: fields?.homeAddressState,
    SHomeZip: fields?.homeAddressZip,
    sMailingCity: fields?.mailingAddressCity,
    sHomeCity: fields?.homeAddressCity,
    sPhysicalCity: fields?.physicalAddressCity,
    sPhysicalZip4: fields?.physicalAddressZipCode,
    sMailingZip4: fields?.mailingAddressZipCode,
    sHomeZip4: fields?.homeAddressZipCode,
    sHomeCounty: fields?.homeAddressCountry,
    sPhysicalCounty: fields?.physicalAddressCountry,
    sMailingCounty: fields?.mailingAddressCountry,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!fields?.firstName || fields?.firstName.charAt(0) === " ") {
      setFirstNamevalidation("Enter First Name");
      isValid = false;
    }
    if (!fields?.lastName || fields?.lastName.charAt(0) === " ") {
      setLastNamevalidation("Enter First Name");
      isValid = false;
    }
    if (isValid) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/Contacts/contacts-add-edit`, bodyData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          if (val?.data?.isSuccess) {
            if (typeFeeSchedule === "Add") {
              const updatedContactsInfoSaveData = addContactsInfoSaveData?.map((item) => ({
                ...item,
                GContactId: val?.data?.data?.gContactId,
              }));
              apiClass.saveContactsContactsList(updatedContactsInfoSaveData, setLoading, navigate);
            }
            const updateContactsEntityList = ContectEntityListTableAddData2?.map((item) => {
              return {
                GEntityContactId:
                  typeFeeSchedule === "Add" ? "00000000-0000-0000-0000-000000000000" : item?.gEntityContactId,
                GEntryUserId: localStorage.getItem("gUserId"),
                GEntityId: item?.Entity,
                GContactId:
                  typeFeeSchedule === "Add"
                    ? val?.data?.data?.gContactId
                    : item?.gContactId || typeFeeSchedule === "Edit"
                    ? val?.data?.data?.gContactId
                    : item?.gContactId,
                BActive: item?.Active ? item?.Active : false,
                DStart: item?.Start,
                DEnd: item?.End,
                GContactPositionId: item?.Position,
                BPrimary: item?.Primary ? item?.Primary : false,
              };
            });
            apiClass.saveContactsEntityList(
              updateContactsEntityList,
              setLoading,
              navigate,
              entityListRightData,
              typeFeeSchedule,
              params,
              setEntityListLeftData,
              setEntityListRightData,
              setContactEntityListCount,
              dispatch
            );
            dispatch(setAdminSavePopup({ adminSavePopup: false }));
            dispatch(removeProntactContactsdynamicTab("Contacts"));
            navigate(`/prontact/contacts/${val?.data?.data?.gContactId}`);
            dispatch(
              setProntactContactsdynamicTab({
                title: `Contacts: ${val?.data?.data?.sLastName},${val?.data?.data?.sFirstName}`,
                link: `/prontact/contacts/${val?.data?.data?.gContactId}`,
              })
            );
            setTypeFeeSchedule("Edit");
            setIsEditableButton(!isEditableButton);
            getContactHeaderInfoDetails(val?.data?.data?.gContactId);
            setIsEditableButton(false);
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: typeFeeSchedule === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: typeFeeSchedule === "Add" ? "Successfully Added!" : "Successfully Updated!",
                type: "success",
              });
            }, 1900);
          } else {
            console.error(val?.data?.errors);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error?.message);
        });
    }
  };

  useEffect(() => {
    getDropdownValues();
    apiClass.ContectTypeDropDown(setcontactInfoType);
    apiClass.getContactsEntityListDropdown(SetContactsEntityListDropdown, setReportCatalogData);
    if (params?.id) {
      setTypeFeeSchedule("Edit");
      setIsEditableButton(false);
      getContactHeaderInfoDetails(params?.id);
      apiClass.getContactInfoDetails(params?.id, setLoading, setdata, setContactInfoTypeCount);
      apiClass.getContactEntityListData(
        params,
        setLoading,
        setEntityListLeftData,
        setEntityListRightData,
        setContactEntityListCount,
        dispatch
      );
    } else {
      setTypeFeeSchedule("Add");
      emptyFields();
      setContactInfoTypeCount(0);
    }
  }, [params?.id]);

  const getContactHeaderInfoDetails = async (GContactId) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Contacts/contacts-get?GContactId=${GContactId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setContactHandling(false);
          setContactData(val?.data?.data);
          setFieldsCOntactData(val?.data?.data);
          setLoading(false);
        } else {
          setContactHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setContactHandling(true);
        }
      });
  };

  const setFieldsCOntactData = (setInfoData) => {
    console.log('setInfoData', setInfoData);
    setFields((prev) => ({
      ...prev,
      firstRowName: setInfoData?.sLastFirst,
      secondRowName: setInfoData?.sTitle,
      thirdRowName: setInfoData?.sPhysicalAddress1,
      fourthRowName: setInfoData?.sPhysicalAddress2,
      fifthRowName: `${setInfoData?.sPhysicalCity? `${setInfoData?.sPhysicalCity},` : ""} ${setInfoData?.sPhysicalState || ""} ${
        setInfoData?.sPhysicalZip ? setInfoData?.sPhysicalZip : ""
      } ${setInfoData?.sPhysicalZip4 ? -setInfoData?.sPhysicalZip4 : ""}`,
      Rowsalutation: setInfoData?.sSalutation,
      contactID: setInfoData?.sContactSequence,
      physicalAddressLine1: setInfoData?.sPhysicalAddress1,
      physicalAddressLine2: setInfoData?.sPhysicalAddress2,
      physicalAddressCity: setInfoData?.sPhysicalCity ? setInfoData?.sPhysicalCity : "",
      physicalAddressState: setInfoData?.sPhysicalState ? setInfoData?.sPhysicalState : "",
      physicalAddressZip: setInfoData?.sPhysicalZip ? setInfoData?.sPhysicalZip : "",
      physicalAddressZipCode: setInfoData?.sPhysicalZip4 ? setInfoData?.sPhysicalZip4 : "",
      physicalAddressCountry: setInfoData?.sPhysicalCounty,
      mailingAddressLine1: setInfoData?.sMailingAddress1,
      mailingAddressLine2: setInfoData?.sMailingAddress2,
      mailingAddressCity: setInfoData?.sMailingCity ? setInfoData?.sMailingCity : "",
      mailingAddressState: setInfoData?.sMailingState ? setInfoData?.sMailingState : "",
      mailingAddressZip: setInfoData?.sMailingZip ? setInfoData?.sMailingZip : "",
      mailingAddressZipCode: setInfoData?.sMailingZip4 ? setInfoData?.sMailingZip4 : "",
      mailingAddressCountry: setInfoData?.sMailingCounty,
      homeAddressLine1: setInfoData?.sHomeAddress1,
      homeAddressLine2: setInfoData?.sHomeAddress2,
      homeAddressCity: setInfoData?.sHomeCity ? setInfoData?.sHomeCity : "",
      homeAddressState: setInfoData?.sHomeState ? setInfoData?.sHomeState : "",
      homeAddressZip: setInfoData?.sHomeZip ? setInfoData?.sHomeZip : "",
      homeAddressZipCode: setInfoData?.sHomeZip4 ? setInfoData?.sHomeZip4 : "",
      homeAddressCountry: setInfoData?.sHomeCounty,
      firstName: setInfoData?.sFirstName,
      middleName: setInfoData?.sMiddleName,
      lastName: setInfoData?.sLastName,
      prefix: setInfoData?.sPrefixName,
      suffix: setInfoData?.sSuffixName,
      title: { name: setInfoData?.sTitle, id: "" },
      salutation: setInfoData?.sSalutation,
    }));
  };

  const getDropdownValues = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setTitleDropdown(
            val?.data?.data?.title?.map((item, index) => {
              return { key: index, value: item.value };
            })
          );
        } else {
          console.error(val?.data?.errors);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const clas2 = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);

  useEffect(() => {
    clas2.getHelp(
      setLoading,
      navigate,
      setmarkup,
      setmarkupid,
      "MainProntact",
      setmarkupissScreenNamu,
      setmarkupisCaption
    );
  }, []);

  return (
    <>
      {markupid && (
        <Popup
          visible={markupid && helpPopUp}
          onHiding={() => {
            setHelpPopUp(false);
          }}
          showTitle={false}
          width={1200}
          height={670}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <HelpPopup
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            setHelpPopUp={setHelpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
          />
        </Popup>
      )}
      <Popup
        visible={findContactPopup}
        onHiding={() => {
          setFindContactPopup(false);
        }}
        showTitle={false}
        width={700}
        height={520}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <FindContacts findContactPopup={findContactPopup} setFindContactPopup={setFindContactPopup} />
      </Popup>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="page-section">
        <DynamicTabb />
        <div className="page-content">
          <div style={{ display: "flex" }}>
            <p className="page-info-number">CO.1</p>
            {markupid && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="28px"
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  cursor: "pointer",
                  marginTop: "-4px",
                  marginLeft: "4px",
                }}
                className="svg-blue-black"
                onClick={() => {
                  setHelpPopUp(true);
                }}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  OnAdd();
                }}
              >
                Add
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15083)">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15083">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  if (params?.id) {
                    setTypeFeeSchedule("Edit");
                    apiClass.ContectTypeDropDown(setcontactInfoType);
                    apiClass.getContactsEntityListDropdown(SetContactsEntityListDropdown, setReportCatalogData);
                    getContactHeaderInfoDetails(params?.id);
                    apiClass.getContactInfoDetails(params?.id, setLoading, setdata, setContactInfoTypeCount);
                    apiClass.getContactEntityListData(
                      params,
                      setLoading,
                      setEntityListLeftData,
                      setEntityListRightData,
                      setContactEntityListCount,
                      dispatch
                    );
                  }
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  setFindContactPopup(true);
                }}
              >
                Find
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15088)">
                    <path
                      d="M15.5006 14.0006H14.7106L14.4306 13.7306C15.6306 12.3306 16.2506 10.4206 15.9106 8.39063C15.4406 5.61063 13.1206 3.39063 10.3206 3.05063C6.09063 2.53063 2.53063 6.09063 3.05063 10.3206C3.39063 13.1206 5.61063 15.4406 8.39063 15.9106C10.4206 16.2506 12.3306 15.6306 13.7306 14.4306L14.0006 14.7106V15.5006L18.2506 19.7506C18.6606 20.1606 19.3306 20.1606 19.7406 19.7506C20.1506 19.3406 20.1506 18.6706 19.7406 18.2606L15.5006 14.0006ZM9.50063 14.0006C7.01063 14.0006 5.00063 11.9906 5.00063 9.50063C5.00063 7.01063 7.01063 5.00063 9.50063 5.00063C11.9906 5.00063 14.0006 7.01063 14.0006 9.50063C14.0006 11.9906 11.9906 14.0006 9.50063 14.0006Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15088">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            {typeFeeSchedule === "Edit" && !isEditableButton && (
              <div className="buttons">
                <button
                  className="primary_btn"
                  onClick={() => {
                    setIsEditableButton(true);
                    setTypeFeeSchedule("Edit");
                  }}
                >
                  Edit
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_6809_74139)">
                      <path
                        d="M3 17.4586V20.4986C3 20.7786 3.22 20.9986 3.5 20.9986H6.54C6.67 20.9986 6.8 20.9486 6.89 20.8486L17.81 9.93859L14.06 6.18859L3.15 17.0986C3.05 17.1986 3 17.3186 3 17.4586ZM20.71 7.03859C21.1 6.64859 21.1 6.01859 20.71 5.62859L18.37 3.28859C17.98 2.89859 17.35 2.89859 16.96 3.28859L15.13 5.11859L18.88 8.86859L20.71 7.03859Z"
                        fill="#fff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6809_74139">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            )}
            {isEditableButton && (
              <div className="buttons">
                <button
                  className="secondary_btn"
                  onClick={() => {
                    oncancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="primary_btn"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                      fill="white"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
          </div>

          {contactHandling ? (
            <ApiError />
          ) : (
            <>
              <div className="page-section-fileds">
                <div className="first-input-section">
                  <p className="fee-payment-content-info-para">{fields?.firstRowName}</p>
                  <p className="fee-payment-content-info-para">{fields?.secondRowName}</p>
                  <div className="input-flex-div">
                    <p className="edit_container_label">Salutation</p>
                    <p className="fee-payment-content-info-para">{fields?.Rowsalutation}</p>
                  </div>
                  <p className="fee-payment-content-info-para">{fields?.thirdRowName}</p>
                  <p className="fee-payment-content-info-para">{fields?.fourthRowName}</p>
                  <p className="fee-payment-content-info-para">{fields?.fifthRowName}</p>
                </div>
                <div className="first-input-section">
                  <div className="input-flex-div">
                    <p className="edit_container_label">Contact ID</p>
                    <p className="fee-payment-content-info-para">{fields?.contactID}</p>
                  </div>
                </div>
              </div>
              <ContactsSubTabs
                isEditableButton={isEditableButton}
                toastConfig={toastConfig}
                setToastConfig={setToastConfig}
                contactData={contactData}
                fields={fields}
                setFields={setFields}
                lastNamevalidation={lastNamevalidation}
                setLastNamevalidation={setLastNamevalidation}
                firstNamevalidation={firstNamevalidation}
                setFirstNamevalidation={setFirstNamevalidation}
                titleDropdown={titleDropdown}
                contactInfoType={contactInfoType}
                data={data}
                setdata={setdata}
                contactInfoTypeCount={contactInfoTypeCount}
                setContactInfoTypeCount={setContactInfoTypeCount}
                contactsEntityListDropdown={contactsEntityListDropdown}
                SetContactsEntityListDropdown={SetContactsEntityListDropdown}
                entityListRightData={entityListRightData}
                setEntityListRightData={setEntityListRightData}
                entityListLeftData={entityListLeftData}
                setEntityListLeftData={setEntityListLeftData}
                contactEntityListCount={contactEntityListCount}
                typeFeeSchedule={typeFeeSchedule}
                reportCatalogData={reportCatalogData}
                allData={allData}
                setAllData={setAllData}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Contacts;
