import { useState } from "react";
import { Table1 } from "../../../../../Administration/Table1/Table1";
import { disbursementsTableColumn } from "../Data";

export const DisbursementsTable = ({
  toastConfig,
  setToastConfig,
  disbursementsTableData,
  refreshGetDisbursementTableData,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Table1
        editTable={false}
        height={"auto"}
        Tablecoloum={disbursementsTableColumn}
        tableRow={disbursementsTableData?.map((val, i) => {
          return { ...val, ID: i };
        })}
        load={loading}
        coloumWidthText={"Disbursements"}
        mandatoryColoum={"DOE"}
      />
    </>
  );
};
