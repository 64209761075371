import { LoadPanel, Popup, TextBox, Tooltip } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { GoToObligationSubTab } from "./GoToObligationSubTab";
import { GoToObligationTopButton } from "./GoToObligationTopButton";
import "./goToObligation.css";
import { useEffect, useState } from "react";
import { DateBox } from "devextreme-react";
import { useSelector } from "react-redux";
import { GoTOObligationFunction } from "./GoTOObligationFunction";
import { useNavigate, useParams } from "react-router-dom";
import { removenewObligationDynamicTab, setGoToobligationIsChange } from "../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { ApiError } from "../../../Error/ApiError";
import { Toster } from "../../../../components/Toster/Toster";
import { GOTOObligationFindDocker } from "./GOTOObligationFindDocker";
import { GoToDocketChangeFinatialSTatus } from "./GoToDocketChangeFinatialSTatus";
import HelpPopup from "../../../HeaderSideBar/HelpPopup";
import { headersidebarfunction } from "../../../HeaderSideBar/headersidebarfunction";
import GoToObligationChangeFinancialStatus from "./GoToObligationChangeFinancialStatus";
import { getAccessData } from "../../../../utils/getAccessPermission";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { setDynamicTab } from "../../../../reducer/HomePageReducer";
import { ChangeFeePopup } from "./GoTOObligationSubTabs/FeePaymentPlan/ChangeFeePopup";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
  setGoToobligationIsChange,
  selectedFeeCode,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name || selectedFeeCode}
        reset={reset}
        disabled={disabled}
        onChange={true}
        IsChangefunction={setGoToobligationIsChange}
      />
    </>
  );
};

const ChangeFinatialSTatus = ({
  ChangeFinatialSTatusPopup,
  setChangeFinatialSTatusPopup,
  data,
  FinancialStatus,
  dropdowndata,
  gObligationId,
  getObligation,
}) => {
  return (
    <>
      <Popup
        visible={ChangeFinatialSTatusPopup}
        onHiding={() => {
          setChangeFinatialSTatusPopup(false);
        }}
        showTitle={false}
        width={800}
        height={608}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <GoToDocketChangeFinatialSTatus
          ChangeFinatialSTatusPopup={ChangeFinatialSTatusPopup}
          setChangeFinatialSTatusPopup={setChangeFinatialSTatusPopup}
          data={data}
          FinancialStatus={FinancialStatus}
          dropdowndata={dropdowndata}
          gObligationId={gObligationId}
          getObligation={getObligation}
        />
      </Popup>
    </>
  );
};

const FIndDocker = ({ setfindDocker, findDocker, setGoTOObligationReference }) => {
  return (
    <>
      <Popup
        visible={findDocker}
        onHiding={() => {
          setfindDocker(false);
        }}
        showTitle={false}
        width={800}
        height={608}
        showCloseButton={true}
        hideOnOutsideClick={true}
        onShown={() => {
          document.querySelector("#TESTE").focus();
        }}
      >
        <GOTOObligationFindDocker
          setfindDocker={setfindDocker}
          findDocker={findDocker}
          setGoTOObligationReference={setGoTOObligationReference}
        />
      </Popup>
    </>
  );
};

export const GoTOObligationHeader = ({
  feeViolationDropdown,
  setFeeViolationDropdown,
}) => {
  const clas2 = new headersidebarfunction();
  const pathname = handleGetLocal("pathURL");
  const [loadingg2, setLoading2] = useState(false);
  const [changeFeePopUp, setChangeFeePopUp] = useState(false);
  const { gotooblogationValue } = useSelector((store) => store.participateNotes);
  const clas = new GoTOObligationFunction();
  const [EditTogel, setEditTogel] = useState(false);
  const navigate = useNavigate();
  const [data, setdata] = useState({});
  const dispatch = useDispatch();
  const [Offenderid, setOffenderid] = useState("");
  const [ObligationType, setObligationType] = useState([]);
  const [RecipientCategory, setRecipientCategorye] = useState([]);
  const [Offense, setOffense] = useState([]);
  const [FinancialStatus, setFinancialStatus] = useState([]);
  const [erorhandling, seterorhandling] = useState(false);
  const [GoTOObligationReference, setGoTOObligationReference] = useState({});
  const [dropdowndata, setdropdowndata] = useState();
  const [field, setField] = useState({
    "Obligation Ref #": gotooblogationValue?.userinfo?.sReference,
    Offender: "",
    "Fee Code": { name: "", id: "" },
    "Payment Agency": { name: "", id: "" },
    "Docket Reference": gotooblogationValue?.userinfo?.sReferenceNumberV2
      ? gotooblogationValue?.userinfo?.sReferenceNumberV2
      : "",
    "Docket Number": "",
    "Recipient Category": { name: "", id: "" },
    Offense: { name: "", id: "" },
    "Financial Status": { name: "", id: "" },
    "Amount paid": "",
    "Amount Disbursed": "",
    "Amount On Hand": "",
    Arrears: "",
    Balance: "",
    "Payment Alert": "",
    "Notes Remarks": "",
    "Change Financial Status": "",
    "Date Active": "",
    "Current Amount": "",
  });
  const [field2, setField2] = useState({
    "Obligation Ref #": gotooblogationValue?.userinfo?.sReference,
    Offender: "",
    "Fee Code": { name: "", id: "" },
    "Payment Agency": { name: "", id: "" },
    "Docket Reference": gotooblogationValue?.userinfo?.sReferenceNumberV2
      ? gotooblogationValue?.userinfo?.sReferenceNumberV2
      : "",
    "Docket Number": "",
    "Recipient Category": { name: "", id: "" },
    Offense: { name: "", id: "" },
    "Financial Status": { name: "", id: "" },
    "Amount paid": "",
    "Amount Disbursed": "",
    "Amount On Hand": "",
    Arrears: "",
    Balance: "",
    "Payment Alert": "",
    "Notes Remarks": "",
    "Current Amount": "",
    "Date Active": "",
    "Change Financial Status": "",
  });
  const [isAccrual, setIsAccrual] = useState(false);
  const [isAccrued, setIsAccrued] = useState(false);
  const [sDocketNumberCourtCounts, setsDocketNumberCourtCounts] = useState("");
  const [addobligation, setaddobligation] = useState(false);
  const [refreshApiButton, setRefreshApiButton] = useState(false);
  const { obligationid2 } = useParams();
  const [paymentPlanFlag, setPaymentPlanFlag] = useState([]);
  const [obligationRefMessage, setObligationRefMessage] = useState();
  const [feecodedropdown, setfeecodedropdown] = useState([]);
  const [PaymentAgency, setPaymentAgency] = useState([]);
  const { AddObligation } = useSelector((store) => store.participateNotes);
  const [changeFinancialStatusPopup, setChangeFinancialStatusPopup] = useState(false);
  const { userAccessData } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [findDocker, setfindDocker] = useState(false);
  const [ObligationRefvalidation, setObligationRefvalidation] = useState("");
  const [ObligationTypevalidation, setObligationTypevalidation] = useState("");
  const [FeeCodevalidation, setFeeCodevalidation] = useState("");
  const [DocketReferencevalidation, setDocketReferencevalidation] = useState("");
  const [RecipientCategoryvalidation, setRecipientCategoryvalidation] = useState("");
  const [FinancialStatusvalidation, setFinancialStatusvalidation] = useState("");
  const [ChangeFinatialSTatusPopup, setChangeFinatialSTatusPopup] = useState(false);
  const [PaymentAgencyvalidation, setPaymentAgencyvalidation] = useState("");
  const [feePaymentPlanData, setFeePaymentPlanData] = useState({
    startDate: "",
    amount: 0,
    dStart: "",
    dEnd: "",
    fRate: 0,
    frequency: "",
    paymentDay: 0,
  });

  const saveCookieCrum = async (id) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => { })
      .catch(() => {
        console.log("catch");
      });
  };

  const oncancel = () => {
    setaddobligation(false);
    dispatch(setGoToobligationIsChange(false));
    data?.gObligationId &&
      clas.getObligation(
        setField,
        setLoading2,
        navigate,
        { gObligationId: data?.gObligationId },
        setdata,
        setOffenderid,
        seterorhandling,
        dispatch,
        setsDocketNumberCourtCounts,
        sDocketNumberCourtCounts,
        false,
        data,
        true,
        null,
        obligationid2,
        field,
        PaymentAgency
      );
    setRefreshApiButton(!refreshApiButton);
    setEditTogel(false);
    setObligationRefvalidation("");
    setObligationTypevalidation("");
    setDocketReferencevalidation("");
    setRecipientCategoryvalidation("");
    setFinancialStatusvalidation("");
  };

  const convertDateFormat = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  };

  const onSave = () => {
    if (!addobligation && ((isAccrual && feePaymentPlanData.dStart !== "") || feePaymentPlanData.startDate !== "")) {
      setEditTogel(false);
      setDocketReferencevalidation("");
      setRecipientCategoryvalidation("");
      setFinancialStatusvalidation("");
      setFeeCodevalidation("");
      clas.Save(
        field,
        navigate,
        setLoading2,
        setToastConfig,
        toastConfig,
        data?.gObligationId,
        { gObligationId: data?.gObligationId },
        data,
        setdata,
        setOffenderid,
        seterorhandling,
        setField,
        GoTOObligationReference,
        dispatch,
        setsDocketNumberCourtCounts,
        sDocketNumberCourtCounts,
        feePaymentPlanData,
        obligationid2,
        obligationRefMessage,
        PaymentAgency
      );
    } else {
      if (GoTOObligationReference?.gDocketID && field2?.["Financial Status"].id && field2?.["Fee Code"].id !== "") {
        if (feePaymentPlanData?.frequency === "" && isAccrual) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Frequency Required!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Frequency Required!",
              type: "error",
            });
          }, 1400);
          return;
        }
        setEditTogel(false);
        setDocketReferencevalidation("");
        setRecipientCategoryvalidation("");
        setFinancialStatusvalidation("");
        setFeeCodevalidation("");
        const initialDate = new Date();
        const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
        const utcFormatted = new Date(utcTime).toISOString();
        clas.addObligation(
          setLoading2,
          setToastConfig,
          toastConfig,
          navigate,
          {
            GObligationId: "00000000-0000-0000-0000-000000000000",
            GEntryUserId: localStorage.getItem("gUserId"),
            GDocketId: GoTOObligationReference?.gDocketID,
            GFeeScheduleCategoryId: "2f9bd615-d3f8-e011-98aa-00508d98a9ee",
            SReference: "",
            GFinancialStatusId: field2?.["Financial Status"]?.id,
            GOffenseId: "42109C74-C17E-E711-80C6-00155D0C070B",
            FAmount: field2["Current Amount"] || 0,
            sPaymentAlert: field2?.["Payment Alert"],
            BillingEntityID: field2?.["Payment Agency"]?.id
              ? field2?.["Payment Agency"]?.id
              : "00000000-0000-0000-0000-000000000000",
            gFeeScheduleFeeID: field2?.["Fee Code"]?.id
              ? field2?.["Fee Code"]?.id
              : "00000000-0000-0000-0000-000000000000",
            DFinancialStatus: utcFormatted,
            dActive: field2["Date Active"],
            DStart: feePaymentPlanData?.dStart,
            DEnd: feePaymentPlanData?.dEnd,
            FRate: feePaymentPlanData?.fRate,
            sFeeRemarks: field2["Notes Remarks"],
            GFrequencyID: feePaymentPlanData?.frequency,
            IPaymentDay: feePaymentPlanData?.paymentDay,
          },
          data,
          dispatch,
          setField,
          gotooblogationValue,
          setdata,
          setOffenderid,
          seterorhandling,
          setsDocketNumberCourtCounts,
          sDocketNumberCourtCounts,
          setaddobligation,
          obligationid2,
          field
        );
      } else {
        !GoTOObligationReference?.gDocketID
          ? setDocketReferencevalidation("Please enter Docket Reference")
          : setDocketReferencevalidation("");
        !field2?.["Recipient Category"].id
          ? setRecipientCategoryvalidation("Please select Recipient Category")
          : setRecipientCategoryvalidation("");
        !field2?.["Financial Status"].id
          ? setFinancialStatusvalidation("Please select Financial Status")
          : setFinancialStatusvalidation("");
        !field2?.["Fee Code"].id ? setFeeCodevalidation("Please enter Fee Code") : setFeeCodevalidation("");
      }
      saveCookieCrum(data?.gObligationId);
    }
  };

  const getFeeCode = async () => {
    const rate = await clas.getFeeScheduleType(
      navigate,
      setLoading2,
      setToastConfig,
      toastConfig,
      field2?.["Fee Code"].id,
      setIsAccrual,
      setIsAccrued
    );
    setFeePaymentPlanData((prev) => ({ ...prev, fRate: rate }));
  };

  const getObligation = () => {
    clas.getObligation(
      setField,
      setLoading2,
      navigate,
      { gObligationId: data?.gObligationId },
      setdata,
      setOffenderid,
      seterorhandling,
      dispatch,
      setsDocketNumberCourtCounts,
      sDocketNumberCourtCounts,
      false,
      null,
      null,
      null,
      obligationid2,
      field,
      PaymentAgency
    );
  };

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "OB1");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    clas.FeeCodeGEtDropDownApi(setfeecodedropdown, navigate);
    clas.getPaymentAgencyDropdown(setPaymentAgency, navigate);
    saveCookieCrum(data?.gObligationId);
  }, [data?.gDocketId]);

  useEffect(() => {
    if (field2?.["Financial Status"].id) {
      setFinancialStatusvalidation("");
    }
  }, [field2?.["Financial Status"].id]);

  useEffect(() => {
    setDocketReferencevalidation("");
    if (GoTOObligationReference?.Reference) {
      dispatch(setGoToobligationIsChange(true));
    }
    if (addobligation) {
      setField2({
        ...field2,
        "Docket Reference": GoTOObligationReference?.Reference,
      });
    } else {
      setField({
        ...field,
        "Docket Reference": GoTOObligationReference?.Reference,
      });
    }
  }, [GoTOObligationReference?.gDocketID]);

  useEffect(() => {
    clas.getDRopDown(
      navigate,
      setObligationType,
      setRecipientCategorye,
      setOffense,
      setFinancialStatus,
      setdropdowndata,
      setField2
    );
    if (gotooblogationValue?.gObligationId && obligationid2 !== "addObligation") {
      clas.getObligation(
        setField,
        setLoading2,
        navigate,
        gotooblogationValue,
        setdata,
        setOffenderid,
        seterorhandling,
        dispatch,
        setsDocketNumberCourtCounts,
        sDocketNumberCourtCounts,
        false,
        null,
        null,
        null,
        obligationid2,
        field,
        PaymentAgency
      );
    }
    saveCookieCrum(gotooblogationValue?.gObligationId);
    localStorage.setItem("offenderId", Offenderid);
  }, [gotooblogationValue?.gObligationId]);

  useEffect(() => {
    setGoTOObligationReference({
      gDocketID: gotooblogationValue?.userinfo?.getTopDynamicDocketid,
      Reference: gotooblogationValue?.userinfo?.sReferenceNumberV2,
    });
    saveCookieCrum(data?.gObligationId);
  }, [gotooblogationValue?.userinfo?.getTopDynamicDocketid]);

  useEffect(() => {
    const defaultPaymentAgencyData = PaymentAgency?.find((item) => item.name === "_Offender");
    setField((prev) => ({
      ...prev,
      "Change Financial Status": "",
    }));
    setField2({
      "Obligation Ref #": gotooblogationValue?.userinfo?.sReference,
      Offender: "",
      "Fee Code": { name: "", id: "" },
      "Payment Agency": {
        name: defaultPaymentAgencyData?.name,
        id: defaultPaymentAgencyData?.id,
      },
      "Docket Reference": gotooblogationValue?.userinfo?.sReferenceNumberV2
        ? gotooblogationValue?.userinfo?.sReferenceNumberV2
        : "",
      "Docket Number": "",
      "Recipient Category": { name: "", id: "" },
      Offense: { name: "", id: "" },
      "Financial Status": { name: "", id: "" },
      "Amount paid": "",
      "Amount Disbursed": "",
      "Amount On Hand": "",
      Arrears: "",
      Balance: "",
      "Payment Alert": "",
      "Notes Remarks": "",
      "Current Amount": "",
      "Date Active": "",
      "Change Financial Status": "",
    });
  }, [addobligation]);

  useEffect(() => {
    if (addobligation) {
      const defaultPaymentAgencyData = PaymentAgency?.find((item) => item.name === "_Offender");
      setField2((prev) => ({
        ...prev,
        "Payment Agency": {
          name: defaultPaymentAgencyData?.name,
          id: defaultPaymentAgencyData?.id,
        },
      }));
    }
  }, [PaymentAgency]);

  useEffect(() => {
    let a = { name: "", id: field["Payment Agency"]?.id };
    let b = { name: "", id: field?.["Fee Code"]?.id };
    if (PaymentAgency.length) {
      PaymentAgency?.map((vall) => {
        if (field?.["Payment Agency"]?.id?.toLowerCase() == vall?.id?.toLowerCase()) {
          a = {
            name: vall.name,
            id: vall.id,
          };
        }
      });
    }
    if (feecodedropdown.length) {
      feecodedropdown?.map((vall) => {
        if (vall?.id?.toLowerCase() == field?.["Fee Code"]?.id?.toLowerCase()) {
          b = {
            name: vall?.name,
            id: vall?.id,
          };
        }
      });
    }
    if (a.name || b.name) {
      setField({
        ...field,
        "Fee Code": b,
        "Payment Agency": a,
      });
    }
  }, [feecodedropdown, PaymentAgency]);

  useEffect(() => {
    if (data?.gOffenseId) {
      let dataa = {};
      Offense.map((val) => {
        if (data?.gOffenseId?.toLowerCase() == val?.id?.toLowerCase()) {
          dataa = val;
        }
      });
      if (addobligation) {
        setField2({
          ...field2,
          Offense: dataa,
        });
      } else {
        setField({
          ...field,
          Offense: dataa,
        });
      }
    }
  }, [Offense, data]);

  useEffect(() => {
    dispatch(
      removenewObligationDynamicTab({
        sDocketNumberCourtCounts: data?.sDocketNumberCourtCounts,
        sReference: data?.sReference,
      })
    );

    setIsAccrual(data.sFeeScheduleType === "Accrued");
    setIsAccrued(data.sFeeScheduleType === "Accrual");
  }, [data]);

  useEffect(() => {
    if (field?.["Fee Code"].id) {
      clas.getFeeScheduleType(
        navigate,
        setLoading2,
        setToastConfig,
        toastConfig,
        field?.["Fee Code"].id,
        setIsAccrual,
        setIsAccrued
      );
    }
    if (addobligation && field2?.["Fee Code"].id) {
      getFeeCode();
      setField2((prev) => ({
        ...prev,
        "Financial Status": FinancialStatus.find((status) => status?.name === "Active"),
      }));
    }
    if (isAccrual || isAccrued) {
      setFeePaymentPlanData((prev) => ({ ...prev, fRate: 0 }));
    }
  }, [field2?.["Fee Code"], isAccrual, isAccrued]);

  useEffect(() => {
    if (addobligation) {
      setField2((prev) => ({
        ...prev,
        "Obligation Ref #": obligationRefMessage,
      }));
    } else {
      setField((prev) => ({
        ...prev,
        "Obligation Ref #": obligationRefMessage,
      }));
    }
  }, [obligationRefMessage]);

  useEffect(() => {
    if (AddObligation) {
      setaddobligation(true);
      setEditTogel(true);
    }
    saveCookieCrum(data?.gObligationId);
    clas2.getHelp(setLoading2, navigate, setmarkup, setmarkupid, "OB1", setmarkupissScreenNamu, setmarkupisCaption);
    getUserAccessPermissions();
  }, []);

  return (
    <>
      {viewAccess ? (
        erorhandling ? (
          <ApiError />
        ) : (
          <div>
            <LoadPanel shadingColor="true" visible={loadingg2} delay={10} />
            {markupid && (
              <Popup
                visible={markupid && helpPopUp}
                onHiding={() => {
                  setHelpPopUp(false);
                }}
                showTitle={false}
                width={1200}
                height={670}
                showCloseButton={true}
                hideOnOutsideClick={true}
              >
                <HelpPopup
                  markup={markup}
                  markupid={markupid}
                  helpPopUp={helpPopUp}
                  setHelpPopUp={setHelpPopUp}
                  markupsCaption={markupsCaption}
                  markupsScreenName={markupsScreenName}
                />
              </Popup>
            )}

            <Popup
              visible={changeFeePopUp}
              onHiding={() => {
                setChangeFeePopUp(false);
              }}
              showTitle={false}
              width={550}
              height={700}
              showCloseButton={true}
              hideOnOutsideClick={true}
            >
              <ChangeFeePopup
                changeFeePopUp={changeFeePopUp}
                setChangeFeePopUp={setChangeFeePopUp}
                data={data}
                feeViolationDropdown={feeViolationDropdown}
                setFeeViolationDropdown={setFeeViolationDropdown}
                toastConfig={toastConfig}
                setToastConfig={setToastConfig}
                gObligationID={data?.gObligationId}
                getObligation={getObligation}
              />
            </Popup>
            <Popup
              visible={changeFinancialStatusPopup}
              onHiding={() => {
                setChangeFinancialStatusPopup(!changeFinancialStatusPopup);
              }}
              showTitle={false}
              width={750}
              height={590}
              showCloseButton={true}
              hideOnOutsideClick={true}
            >
              <GoToObligationChangeFinancialStatus
                changeFinancialStatusPopup={changeFinancialStatusPopup}
                setChangeFinancialStatusPopup={setChangeFinancialStatusPopup}
                financialStatusDropdownData={FinancialStatus}
                changeReasondropdowndata={dropdowndata?.financialStatusChangeReason?.map((val) => {
                  return { name: val?.value, id: val?.key };
                })}
                obligationData={addobligation ? field2 : field}
                obligationHeaderData={data}
                addobligation={addobligation}
                setToastConfig={setToastConfig}
                toastConfig={toastConfig}
                refreshApi={getObligation}
                setLoading={setLoading2}
                setField2={setField2}
              />
            </Popup>
            <FIndDocker
              setGoTOObligationReference={setGoTOObligationReference}
              setfindDocker={setfindDocker}
              findDocker={findDocker}
            />
            <ChangeFinatialSTatus
              ChangeFinatialSTatusPopup={ChangeFinatialSTatusPopup}
              setChangeFinatialSTatusPopup={setChangeFinatialSTatusPopup}
              data={data}
              FinancialStatus={FinancialStatus}
              dropdowndata={dropdowndata}
              gObligationId={gotooblogationValue?.gObligationId}
              getObligation={getObligation}
            />
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <div style={{ display: "flex" }}>
              <div className="screen_num" style={{ margin: ".5%", display: "flex" }}>
                <p>OB.1</p>
              </div>
              {markupid && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28px"
                  height="28px"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  className="svg-blue-black"
                  onClick={() => {
                    setHelpPopUp(true);
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
            <div key={obligationid2} className="go-to-header-section">
              <div className="go-to-header-container">
                <div className="gotodockerheaderedit">
                  <GoToObligationTopButton
                    setToastConfig={setToastConfig}
                    toastConfig={toastConfig}
                    RecipientCategory={RecipientCategory}
                    gotooblogationValue={gotooblogationValue}
                    getObligation={getObligation}
                    setaddobligation={setaddobligation}
                    data={data}
                    setEditTogel={setEditTogel}
                    refreshApiButton={refreshApiButton}
                    setRefreshApiButton={setRefreshApiButton}
                    addAccess={addAccess}
                  />
                  {EditTogel ? (
                    <>
                      <div className="buttons">
                        <button
                          onClick={() => {
                            oncancel();
                          }}
                          className="btn"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            onSave();
                          }}
                          className="primary_btn"
                        >
                          Save
                          <svg
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <button
                      className="primary_btn"
                      disabled={!editAccess}
                      onClick={() => {
                        if (editAccess) setEditTogel(true);
                        else {
                          setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            message: "Access Denied!",
                            type: "error",
                          });
                          setTimeout(() => {
                            setToastConfig({
                              ...toastConfig,
                              isVisible: false,
                              message: "Access Denied!",
                              type: "error",
                            });
                          }, 1400);
                        }
                      }}
                    >
                      Edit
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006V5.04006Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="header-content">
                  <div className="goto-header-content" style={{ height: "20px" }}>
                    <div className="go-to-inputs-field go-to-inputs-field-row">
                      <p
                        style={{ cursor: "pointer" }}
                        className="go-to-label-data text-underline"
                        onClick={() => {
                          if (!Offenderid || addobligation) {
                            return;
                          }
                          dispatch(
                            setDynamicTab({
                              Offender: Offenderid,
                              title: data?.sPersonNameLastFirst?.split(",")?.[0],
                              link: `/participant/${Offenderid}`
                            })
                          );
                          navigate(`/participant/${Offenderid}`);
                        }}
                      >
                        {!addobligation && data?.sPersonNameLastFirst}
                      </p>
                      <p style={{ cursor: "pointer" }} className="go-to-label-data">
                        {!addobligation && Offenderid}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="header-content">
                  <div className="goto-header-content" style={{ height: "0px" }}>
                  <div className="go-to-inputs-field input_field">
                      <label className="go-to-label-filed">
                        Obligation Reference
                      </label>
                      <TextBox
                        style={{width: '300px', marginBottom: '20px'}}
                        value={addobligation ? field2?.['Obligation Ref #'] : field?.['Obligation Ref #']}
                        disabled
                        isValid={true}
                      />
                    </div>
                    <div className="goto-docket-svg-content">
                      <div className="go-to-inputs-field input_field edit_content_space">
                        <label className="go-to-label-filed">
                          Fee Code
                          <span className="marks_mandatary">*</span>
                        </label>
                        <DropDown
                          disabled={addobligation ? false : true}
                          dropDownData={feecodedropdown}
                          fieldNmae={"Fee Code"}
                          fields={addobligation ? field2 : field}
                          setfields={addobligation ? setField2 : setField}
                          setGoToobligationIsChange={setGoToobligationIsChange}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            marginTop: "-15px",
                          }}
                        >
                          {FeeCodevalidation}
                        </p>
                      </div>
                      <span
                        className="fee-payment-content-info-svg"
                        id="ChangeFee"
                        onClick={() => {
                          !EditTogel && setChangeFeePopUp(!changeFeePopUp);
                        }}
                      >
                        <svg
                          className="svg-blue-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_6624_62037)">
                            <path
                              d="M19.4328 12.98C19.4728 12.66 19.5028 12.34 19.5028 12C19.5028 11.66 19.4728 11.34 19.4328 11.02L21.5428 9.37C21.7328 9.22 21.7828 8.95 21.6628 8.73L19.6628 5.27C19.5428 5.05 19.2728 4.97 19.0528 5.05L16.5628 6.05C16.0428 5.65 15.4828 5.32 14.8728 5.07L14.4928 2.42C14.4628 2.18 14.2528 2 14.0028 2H10.0028C9.75277 2 9.54277 2.18 9.51277 2.42L9.13277 5.07C8.52277 5.32 7.96277 5.66 7.44277 6.05L4.95277 5.05C4.72277 4.96 4.46277 5.05 4.34277 5.27L2.34277 8.73C2.21277 8.95 2.27277 9.22 2.46277 9.37L4.57277 11.02C4.53277 11.34 4.50277 11.67 4.50277 12C4.50277 12.33 4.53277 12.66 4.57277 12.98L2.46277 14.63C2.27277 14.78 2.22277 15.05 2.34277 15.27L4.34277 18.73C4.46277 18.95 4.73277 19.03 4.95277 18.95L7.44277 17.95C7.96277 18.35 8.52277 18.68 9.13277 18.93L9.51277 21.58C9.54277 21.82 9.75277 22 10.0028 22H14.0028C14.2528 22 14.4628 21.82 14.4928 21.58L14.8728 18.93C15.4828 18.68 16.0428 18.34 16.5628 17.95L19.0528 18.95C19.2828 19.04 19.5428 18.95 19.6628 18.73L21.6628 15.27C21.7828 15.05 21.7328 14.78 21.5428 14.63L19.4328 12.98ZM12.0028 15.5C10.0728 15.5 8.50277 13.93 8.50277 12C8.50277 10.07 10.0728 8.5 12.0028 8.5C13.9328 8.5 15.5028 10.07 15.5028 12C15.5028 13.93 13.9328 15.5 12.0028 15.5Z"
                              fill="#424167"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6624_62037">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <Tooltip
                          target="#ChangeFee"
                          showEvent="mouseenter"
                          hideEvent="mouseleave"
                          hideOnOutsideClick={false}
                        >
                          <div>Change Fee</div>
                        </Tooltip>
                      </span>
                    </div>
                    <div className="goto-docket-svg-content">
                      <div className="go-to-inputs-field input_field content_space">
                        <label htmlFor="" className="go-to-label-filed">
                          Date Active
                        </label>
                        <DateBox
                          disabled={addobligation ? false : true}
                          useMaskBehavior={true}
                          value={
                            addobligation
                              ? field2?.["Date Active"] || new Date()
                              : convertDateFormat(field?.["Date Active"])
                          }
                          onValueChange={(e) => {
                            dispatch(setGoToobligationIsChange(true));
                            if (addobligation) {
                              setField2({
                                ...field2,
                                "Date Active": e,
                              });
                            } else {
                              setField({
                                ...field,
                                "Date Active": e,
                              });
                            }
                          }}
                          isValid={true}
                        />
                      </div>
                    </div>
                    <div className="go-to-inputs-field input_field content_space" style={{marginBottom: '20px'}}>
                      {isAccrual || isAccrued ? null : (
                        <>
                          <label htmlFor="" className="go-to-label-filed">
                            Amount
                          </label>
                          <TextBox
                            disabled={!addobligation}
                            style={{ width: "150%" }}
                            value={addobligation ? feePaymentPlanData.fRate : field?.["Current Amount"]}
                            onValueChange={(e) => {
                              dispatch(setGoToobligationIsChange(true));
                              addobligation
                                ? setField2({
                                  ...field2,
                                  "Current Amount": e,
                                })
                                : setField({
                                  ...field,
                                  "Current Amount": e,
                                });
                            }}
                            isValid={true}
                          />
                        </>
                      )}
                    </div>
                    <div className="goto-docket-svg-content">
                      <div className="go-to-inputs-field edit_content_space spaces">
                        <label htmlFor="" className="go-to-label-filed">
                          Financial Status
                          <span className="marks_mandatary">*</span>
                        </label>
                        <DropDown
                          disabled={addobligation ? false : true}
                          dropDownData={FinancialStatus}
                          fieldNmae={"Financial Status"}
                          fields={addobligation ? field2 : field}
                          setfields={addobligation ? setField2 : setField}
                          setGoToobligationIsChange={setGoToobligationIsChange}
                          reset={setFinancialStatusvalidation}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            marginTop: "-15px",
                          }}
                        >
                          {FinancialStatusvalidation}
                        </p>
                      </div>
                      <button
                        id="changeFinancialStatusBtn"
                        className="btn goto-svg-right"
                        onClick={() => {
                          if (EditTogel) {
                            return;
                          }
                          setChangeFinancialStatusPopup(true);
                        }}
                      >
                        <svg
                          className="svg-blue-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_6306_6425)">
                            <path
                              d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                              fill="#424167"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6306_6425">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <Tooltip
                        target="#changeFinancialStatusBtn"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                      >
                        <div>Change Financial Status</div>
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <p className="go-to-label-filed">
                          {addobligation
                            ? convertDateFormat(field2?.["Change Financial Status"])
                            : convertDateFormat(field?.["Change Financial Status"])}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="goto-header-content">
                    <div className="goto-docket-svg-content">
                      <div className="go-to-inputs-field input_field edit_content_space">
                        <label htmlFor="" className="go-to-label-filed">
                          Docket Reference
                        </label>
                        <TextBox
                          disabled={true}
                          style={{ width: "88%" }}
                          value={addobligation ? field2?.["Docket Reference"] : field?.["Docket Reference"]}
                          onValueChange={(e) => { }}
                          isValid={true}
                          placeholder={"Person"}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            marginTop: "-15px",
                          }}
                        >
                          {DocketReferencevalidation}
                        </p>
                      </div>
                      <span
                        onClick={() => {
                          if (!EditTogel) {
                            return;
                          }
                          setfindDocker(true);
                        }}
                        className="goto-svg-right"
                      >
                        <svg
                          className="svg-blue-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_6582_34261)">
                            <path
                              d="M15.5006 14.0006H14.7106L14.4306 13.7306C15.6306 12.3306 16.2506 10.4206 15.9106 8.39063C15.4406 5.61063 13.1206 3.39063 10.3206 3.05063C6.09063 2.53063 2.53063 6.09063 3.05063 10.3206C3.39063 13.1206 5.61063 15.4406 8.39063 15.9106C10.4206 16.2506 12.3306 15.6306 13.7306 14.4306L14.0006 14.7106V15.5006L18.2506 19.7506C18.6606 20.1606 19.3306 20.1606 19.7406 19.7506C20.1506 19.3406 20.1506 18.6706 19.7406 18.2606L15.5006 14.0006ZM9.50063 14.0006C7.01063 14.0006 5.00063 11.9906 5.00063 9.50063C5.00063 7.01063 7.01063 5.00063 9.50063 5.00063C11.9906 5.00063 14.0006 7.01063 14.0006 9.50063C14.0006 11.9906 11.9906 14.0006 9.50063 14.0006Z"
                              fill="#424167"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6582_34261">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                    <div className="goto-docket-svg-content">
                      <div className="go-to-inputs-field  input_field edit_content_space space">
                        <label htmlFor="" className="go-to-label-filed">
                          Payment Agency (Entity)
                          <span className="marks_mandatary">*</span>
                        </label>
                        <DropDown
                          disabled={!EditTogel}
                          dropDownData={PaymentAgency}
                          fieldNmae={"Payment Agency"}
                          fields={addobligation ? field2 : field}
                          setfields={addobligation ? setField2 : setField}
                          setGoToobligationIsChange={setGoToobligationIsChange}
                          reset={setPaymentAgencyvalidation}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            marginTop: "-15px",
                          }}
                        >
                          {ObligationTypevalidation}
                        </p>
                      </div>
                    </div>
                    <div className="go-to-inputs-field input_field space">
                      <label htmlFor="" className="go-to-label-filed">
                        Payment Alert
                      </label>
                      <TextBox
                        style={{ width: "150%" }}
                        value={addobligation ? field2?.["Payment Alert"] : field?.["Payment Alert"]}
                        onValueChange={(e) => {
                          dispatch(setGoToobligationIsChange(true));
                          addobligation
                            ? setField2({
                              ...field2,
                              "Payment Alert": e,
                            })
                            : setField({
                              ...field,
                              "Payment Alert": e,
                            });
                        }}
                        isValid={true}
                        placeholder={"Payment Alert"}
                      />
                    </div>
                    <div className="go-to-inputs-field input_field space" style={{ marginTop: "15px" }}>
                      <label htmlFor="" className="go-to-label-filed">
                        Notes/Remarks
                      </label>
                      <TextBox
                        style={{ marginTop: "0%", width: "150%" }}
                        value={addobligation ? field2?.["Notes Remarks"] : field?.["Notes Remarks"]}
                        onValueChange={(e) => {
                          dispatch(setGoToobligationIsChange(true));
                          addobligation
                            ? setField2({
                              ...field2,
                              "Notes Remarks": e,
                            })
                            : setField({
                              ...field,
                              "Notes Remarks": e,
                            });
                        }}
                        isValid={true}
                        placeholder={"Notes/Remarks"}
                      />
                    </div>
                  </div>
                  <div className="gotodockerheadeblock3">
                    <div>
                      <p style={{ color: "var(--greyColor)" }}>Amount</p>
                      <p style={{ marginLeft: "10px" }}>${gotooblogationValue?.amount}</p>
                    </div>
                    <div>
                      <p style={{ color: "var(--greyColor)" }}>Paid</p>
                      <p style={{ marginLeft: "10px" }}>
                        {!addobligation && data?.fPaid !== undefined && `$${data?.fPaid}`}
                      </p>
                    </div>
                    <div>
                      <p style={{ color: "var(--greyColor)" }}>Balance</p>
                      <p style={{ marginLeft: "10px" }}>
                        {!addobligation && data?.fBalance !== undefined && `$${data?.fBalance}`}
                      </p>
                    </div>
                    <div>
                      <p style={{ color: "var(--greyColor)" }}>Arrears</p>
                      <p style={{ marginLeft: "10px" }}>
                        {!addobligation && data?.fArrears !== undefined && `$${data?.fArrears}`}
                      </p>
                    </div>
                    <div>
                      <p style={{ color: "var(--greyColor)" }}>Amount Disbursed</p>
                      <p style={{ marginLeft: "10px" }}>
                        {!addobligation && data?.fAmountDisbursed !== undefined && `$${data?.fAmountDisbursed}`}
                      </p>
                    </div>
                    <div>
                      <p style={{ color: "var(--greyColor)" }}>Amount on Hand</p>
                      <p style={{ marginLeft: "10px" }}>
                        {!addobligation && data?.fBalanceEligible !== undefined && `$${data?.fBalanceEligible}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <GoToObligationSubTab
                data={data}
                dropdowndata={dropdowndata}
                addobligation={addobligation}
                EditTogel={EditTogel}
                refreshApiButton={refreshApiButton}
                getObligation={getObligation}
                feePaymentPlanData={feePaymentPlanData}
                setFeePaymentPlanData={setFeePaymentPlanData}
                paymentPlanFlag={paymentPlanFlag}
                isAccrual={isAccrual}
                isAccrued={isAccrued}
                feecodedropdown={feecodedropdown}
                selectedFeeCode={field2?.["Fee Code"].name || ""}
              />
            </div>
          </div>
        )
      ) : (
        "No View Access"
      )}
    </>
  );
};
