import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { Table2 } from "../../../../../components/Table2/Table2";
import { overVIewFunction } from "./overVIewFunction";
import { ApiError } from "../../../../Error/ApiError";
import { useNavigate } from "react-router-dom";
import { LoadPanel, Popup } from "devextreme-react";
import { ShowACtivityCHecklist } from "./ShowACtivityCHecklist";
import { EditCheckListPOPUP } from "./EditCheckListPOPUP";
import { Toster } from "../../../../../components/Toster/Toster";
import { PendingPOpup } from "../../Task/Pending";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";

const ShowACtivity = ({ setShowACtivitypopup, ShowACtivitypopup, activityData, errorhandling3, data, loading2 }) => {
  return (
    <Popup
      visible={ShowACtivitypopup}
      onHiding={() => {
        setShowACtivitypopup(false);
      }}
      showTitle={false}
      width={700}
      height={500}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <ShowACtivityCHecklist
        setShowACtivitypopup={setShowACtivitypopup}
        activityData={activityData}
        errorhandling3={errorhandling3}
        loading2={loading2}
      />
    </Popup>
  );
};

const EditCheckList = ({
  seteditCHeckoutPOpup,
  editCHeckoutPOpup,
  dataEditCheclist,
  seteditChecklist,
  setToastConfig,
  toastConfig,
  getDRopDown,
  dropdown,
  getTableChecklist2,
  fieldsdropdown,
  AdCHeckLIst,
}) => {
  return (
    <Popup
      visible={editCHeckoutPOpup}
      onHiding={() => {
        seteditChecklist(false);
        seteditCHeckoutPOpup(false);
      }}
      showTitle={false}
      width={800}
      height={600}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <EditCheckListPOPUP
        seteditCHeckoutPOpup={seteditCHeckoutPOpup}
        dataEditCheclist={dataEditCheclist}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        getDRopDown={getDRopDown}
        dropdown={dropdown}
        getTableChecklist2={getTableChecklist2}
        fieldsdropdown={fieldsdropdown}
        editCHeckoutPOpup={editCHeckoutPOpup}
        AdCHeckLIst={AdCHeckLIst}
      />
    </Popup>
  );
};

const EditNote = ({
  PendingPOpuptogal,
  setPendingPOpuptogal,
  setToastConfig,
  toastConfig,
  reset,
  setreset,
  gettaskEditDAta,
  checklist,
  getTopDynamicDocketid,
  getTableChecklist,
  ChecklistNOteTExt,
}) => {
  return (
    <>
      <Popup
        visible={gettaskEditDAta?.gCheckListItemId && PendingPOpuptogal}
        onHiding={() => {
          setPendingPOpuptogal(false);
        }}
        showTitle={false}
        width={720}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <PendingPOpup
          setPendingPOpuptogal={setPendingPOpuptogal}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          reset={reset}
          setreset={setreset}
          gettaskEditDAta={gettaskEditDAta}
          checklist={checklist}
          getTopDynamicDocketid={getTopDynamicDocketid}
          getTableChecklist={getTableChecklist}
          ChecklistNOteTExt={ChecklistNOteTExt}
        />
      </Popup>
    </>
  );
};

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        // reset={reset}
      />
    </>
  );
};

export const ChecklistTab = ({ getTopDynamicDocketid, person, AdCHeckLIst }) => {
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const clas = new overVIewFunction();
  const [fields, setfields] = useState({
    dropdown: { name: "", id: "" },
  });
  const [errorhandling, seterrorhandling] = useState(false);
  const [errorhandling2, seterrorhandlin2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [data, setdata] = useState();
  const [dataEditCheclist, setdataEditCheclist] = useState([]);
  const navigate = useNavigate();
  const [dropdown, setdropdown] = useState([]);
  const [ShowACtivitypopup, setShowACtivitypopup] = useState(false);
  const [editCHeckoutPOpup, seteditCHeckoutPOpup] = useState(false);
  const [getCheckLIstActivity, setgetCheckLIstActivity] = useState();
  const [activityData, setActivityData] = useState([]);
  const [errorhandling3, seterrorhandling3] = useState(false);
  const [PendingPOpuptogal, setPendingPOpuptogal] = useState(false);
  const [reset, setreset] = useState(false);
  const [CheckListTableRightClick, setCheckListTableRightClick] = useState(false);
  const [editChecklist, seteditChecklist] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const getDRopDown = () => {
    clas.getCheckLIstDropDown(seterrorhandling, navigate, setdropdown, person);
  };

  const [prewviewtogal, setprewviewtogal] = useState(undefined);
  const [checklisteditdata, setchecklisteditdata] = useState([]);

  useEffect(() => {
    getDRopDown();
  }, []);

  const pathname = handleGetLocal("pathURL");

  const getCompletTask = async () => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/checklist/update-record`, checklisteditdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        getTableChecklist();
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getTableChecklist = () => {
    clas.getTableData(
      setLoading,
      seterrorhandlin2,
      setdata,
      navigate,
      getTopDynamicDocketid,
      fields?.dropdown?.id,
      setdataEditCheclist
    );
  };

  const getTableChecklist2 = (id) => {
    clas.getTableData2(setLoading, seterrorhandlin2, setdata, navigate, getTopDynamicDocketid, id, setdataEditCheclist);
  };

  useEffect(() => {
    fields?.dropdown?.id && getTableChecklist();
  }, [fields?.dropdown?.id, getTopDynamicDocketid]);

  useEffect(() => {
    fields?.dropdown?.id &&
      clas.getactivityTableData(
        setLoading2,
        navigate,
        getTopDynamicDocketid,
        setActivityData,
        seterrorhandling3,
        fields?.dropdown?.id
      );
  }, [ShowACtivitypopup]);

  useEffect(() => {
    setCheckListTableRightClick(!CheckListTableRightClick);
  }, [editChecklist]);

  useEffect(() => {
    !fields?.dropdown?.id && setdataEditCheclist([]);
  }, [editCHeckoutPOpup]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <ShowACtivity
        ShowACtivitypopup={ShowACtivitypopup}
        setShowACtivitypopup={setShowACtivitypopup}
        activityData={activityData}
        errorhandling3={errorhandling3}
        data={getCheckLIstActivity?.gRecordId}
        loading2={loading2}
      />
      <EditCheckList
        editCHeckoutPOpup={editCHeckoutPOpup}
        seteditCHeckoutPOpup={seteditCHeckoutPOpup}
        dataEditCheclist={dataEditCheclist}
        seteditChecklist={seteditChecklist}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        getDRopDown={getDRopDown}
        dropdown={dropdown}
        getTableChecklist2={getTableChecklist2}
        fieldsdropdown={fields?.dropdown}
        AdCHeckLIst={AdCHeckLIst}
      />
      <EditNote
        PendingPOpuptogal={PendingPOpuptogal}
        setPendingPOpuptogal={setPendingPOpuptogal}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        reset={reset}
        gettaskEditDAta={getCheckLIstActivity}
        setreset={setreset}
        checklist={true}
        getTopDynamicDocketid={getTopDynamicDocketid}
        getTableChecklist={getTableChecklist}
        ChecklistNOteTExt={true}
      />
      {errorhandling || errorhandling2 ? (
        <ApiError />
      ) : (
        <div>
          <div style={{ display: "flex", alignItems: "center", marginTop: ".3%" }}>
            <div style={{ width: "25%" }}>
              <DropDown setfields={setfields} fields={fields} fieldNmae={"dropdown"} dropDownData={dropdown} />
            </div>
            <div className="ChecklistTabbuttons">
              <button
                className="btn"
                onClick={() => {
                  setprewviewtogal(!prewviewtogal);
                }}
              >
                Print
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_8191)">
                    <path
                      d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_8191">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => {
                  setexportPopupOpen(true);
                }}
                className="btn"
              >
                Export
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_5470)">
                    <path
                      d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_5470">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  fields?.dropdown?.id && getTableChecklist();
                  getDRopDown();
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_5486)">
                    <path
                      d="M17.6493 6.35097C16.0193 4.72097 13.7093 3.78097 11.1693 4.04097C7.49929 4.41097 4.47929 7.39097 4.06929 11.061C3.51929 15.911 7.26929 20.001 11.9993 20.001C15.1893 20.001 17.9293 18.131 19.2093 15.441C19.5293 14.771 19.0493 14.001 18.3093 14.001C17.9393 14.001 17.5893 14.201 17.4293 14.531C16.2993 16.961 13.5893 18.501 10.6293 17.841C8.40929 17.351 6.61929 15.541 6.14929 13.321C5.30929 9.44097 8.25929 6.00097 11.9993 6.00097C13.6593 6.00097 15.1393 6.69097 16.2193 7.78097L14.7093 9.29097C14.0793 9.92097 14.5193 11.001 15.4093 11.001H18.9993C19.5493 11.001 19.9993 10.551 19.9993 10.001V6.41097C19.9993 5.52097 18.9193 5.07097 18.2893 5.70097L17.6493 6.35097Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_5486">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => {
                  setShowACtivitypopup(true);
                }}
                className="btn"
              >
                Show Activity
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_35370)">
                    <path
                      d="M20 7V19C20 19.55 19.55 20 19 20H7C6.45 20 6 20.45 6 21C6 21.55 6.45 22 7 22H20C21.1 22 22 21.1 22 20V7C22 6.45 21.55 6 21 6C20.45 6 20 6.45 20 7Z"
                      fill="#424167"
                    />
                    <path
                      d="M16 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H16C17.1 18 18 17.1 18 16V4C18 2.9 17.1 2 16 2ZM9 16H4V11H9V16ZM16 16H11V11H16V16ZM16 9H4V4H16V9Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_35370">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => {
                  seteditChecklist(true);
                  seteditCHeckoutPOpup(true);
                }}
                className="btn"
              >
                Edit Checklist
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_5656)">
                    <path
                      d="M3 17.4605V20.5005C3 20.7805 3.22 21.0005 3.5 21.0005H6.54C6.67 21.0005 6.8 20.9505 6.89 20.8505L17.81 9.94055L14.06 6.19055L3.15 17.1005C3.05 17.2005 3 17.3205 3 17.4605ZM20.71 7.04055C21.1 6.65055 21.1 6.02055 20.71 5.63055L18.37 3.29055C17.98 2.90055 17.35 2.90055 16.96 3.29055L15.13 5.12055L18.88 8.87055L20.71 7.04055Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_5656">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>

              <button
                onClick={() => {
                  getCompletTask();
                }}
              >
                save
              </button>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            <Table2
              Tablecoloum={clas.Tablecoloum}
              tableRow={data}
              editTable={false}
              load={false}
              coloumWidthText={"ChecklistTable"}
              height={"auto"}
              mandatoryColoum={"Completed ?"}
              showTotalBlock={false}
              exporttitle={"Checklist"}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportInDifferentFormate={true}
              CheckListTableRightClick={CheckListTableRightClick}
              seteditCHeckoutPOpup={seteditCHeckoutPOpup}
              seteditCHeckoutNotePOpup={setPendingPOpuptogal}
              setShowACtivitypopup={setShowACtivitypopup}
              setgetCheckLIstActivity={setgetCheckLIstActivity}
              checklistgetTopDynamicDocketid={getTopDynamicDocketid}
              getTableChecklist={getTableChecklist}
              setresetCHecklist={setreset}
              CHecklistreset={reset}
              preview={true}
              prewviewtogal={prewviewtogal}
              Checklist={true}
              setchecklisteditdata={setchecklisteditdata}
            />
          </div>
        </div>
      )}
    </>
  );
};
