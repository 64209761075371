import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { removeDynamicTab, resetDynamicTabs } from "../../reducer/HomePageReducer";
import { useEffect, useState } from "react";
import "./dynamictabmode.css";
import { setgotooblogationValue } from "../../reducer/participantNoteReducer";

export const DynamicTabb = () => {
  const { DynamicTab } = useSelector((store) => store.Home);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState();

  const closeAllTabs = () => {
    dispatch(resetDynamicTabs());
    navigate("/home");
  }

  useEffect(() => {
    if (DynamicTab.length === 0) {
      navigate("/home");
    } else {
      if (data !== undefined && data > DynamicTab) {
        navigate(DynamicTab[DynamicTab.length - 1]?.link);
      }
    }
  }, [DynamicTab]);

  const uniqueTabs = DynamicTab?.filter(
    (val, index, self) =>
      index ===
      self.findIndex(
        (t) => t?.link?.trim().toLowerCase() === val?.link?.trim().toLowerCase()
      )
  );

  const handleTabSwitch = (val) => {
    if(val?.link?.includes('obligation')) {
      if(!(location?.pathname?.includes('obligation'))) {
        setTimeout(() => {
          navigate(val.link)
        }, 500);
      } else {
        dispatch(
          setgotooblogationValue({
            gObligationId: val?.gObligationId,
            userinfo: {
              gPersonId: val?.userinfo?.gPersonId
            }
          })
        );
      }
    } 
  }

  return (
    <>
      <div className="dynamictabmode">
        <ul>
          {uniqueTabs?.map((val, index) => {
            const isActive = location.pathname === val.link;
            return (
              <li
                key={`${val.link}-${index}`}
                style={{
                  background: isActive && "var(--button-lightblue-lightblack)"
                }}
              >
                <span className="personCls">
                  <Link
                    to={!(val.link.includes('obligation')) && val.link}
                    data-rel="tab-2"
                    className={isActive ? "yesactiv" : "activee"}
                    style={{ padding: "0 1px", margin: "13px" }}
                    onClick={() => handleTabSwitch(val)} 
                  >
                    {val.title}
                  </Link>
                  <span className="cls">
                    <svg
                      className="svg-blue-white"
                      style={{ marginTop: "5px", marginRight: "8px" }}
                      width="12"
                      height="12"
                      viewBox="0 0 10 10"
                      onClick={() => {
                        dispatch(removeDynamicTab(val.link));
                        setData(DynamicTab);
                      }}
                    >
                      <path
                        d="M9.19656 0.806647C8.93656 0.546647 8.51656 0.546647 8.25656 0.806647L4.99656 4.05998L1.73656 0.79998C1.47656 0.53998 1.05656 0.53998 0.796562 0.79998C0.536563 1.05998 0.536563 1.47998 0.796562 1.73998L4.05656 4.99998L0.796562 8.25998C0.536563 8.51998 0.536563 8.93998 0.796562 9.19998C1.05656 9.45998 1.47656 9.45998 1.73656 9.19998L4.99656 5.93998L8.25656 9.19998C8.51656 9.45998 8.93656 9.45998 9.19656 9.19998C9.45656 8.93998 9.45656 8.51998 9.19656 8.25998L5.93656 4.99998L9.19656 1.73998C9.4499 1.48665 9.4499 1.05998 9.19656 0.806647Z"
                        fill={isActive ? "rgb(66, 65, 103)" : "rgba(0, 0, 0, 0.5)"}
                      />
                    </svg>
                  </span>
                </span>
              </li>
            );
          })}
        </ul>

        <button className="closebtn" onClick={closeAllTabs}>X</button>
      </div>
    </>
  );
};
