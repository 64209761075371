import React, { useEffect, useState } from "react";
import "./personlist.css";
import { Table2 } from "../../components/Table2/Table2";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { Popup } from "devextreme-react/popup";
import { Tablecoloum, Tablecoloumemtrue } from "./Data";
import { AddFilterPopup } from "../../components/EditfilterPopup.js/AddFilterPopup";
import { setTableData, PersonListBody } from "./Functions";
import { logOut } from "../../components/Logout";
import { useNavigate } from "react-router-dom";
import { SendEmail } from "./SendEmail";
import { Toster } from "../../components/Toster/Toster";
import { ColumnDropdown } from "../../components/Dropdownn";
const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
      />
    </>
  );
};

export const PersonList = () => {
  const pathname = handleGetLocal("pathURL");
  const [selectedPerson, setSelectedPerson] = useState("");
  const [last, setLast] = useState("");
  const [data, setdata] = useState([]);
  const [fields, setfields] = useState({
    selectedPerson: {
      name: "",
      id: "",
      lastName: ""
    },
  });
  const [finalConfirmationPopupVisible, setFinalConfirmationPopupVisible] = useState(false);
  const [personDropdown, setPersonDropdown] = useState([]);
  const [loading, setloading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [getCustomFilterValue, setgetCustomFilterValue] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [selectedPersonIds, setSelectedPersonIds] = useState([]);
  const [isMergePopupVisible, setIsMergePopupVisible] = useState(false);
  const [sourcePersonId, setSourcePersonId] = useState("");
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState(false);
  const [secondConfirmationPopupVisible, setSecondConfirmationPopupVisible] = useState(false);
  const [person, setPerson] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const navigate = useNavigate();

  const getPersonList = async () => {
    setloading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/personListByAllFilters`,
        PersonListBody(getCustomFilterValue),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setdata(setTableData(val.data.data));
        setloading(false);
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getPersonLists = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Person/person-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      });
      setPersonDropdown(
        response?.data?.data?.map((person) => {
          return { name: `${person?.value} ${person?.sLastFirst}, ${person?.sFirstName}`, id: person?.key }; // Adjust if your API response structure is different
        })
      );
    } catch (error) {
      // handleError(error);
    } finally {
      // setLoading(false);
    }
  };

  const showSendEmailPopup = () => {
    if (selectedPersonIds?.length) {
      setSendEmailPopup(true);
    } else {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "No Person Selected!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "No Person Selected!",
          type: "error",
        });
      }, 1900);
    }
  };

  const confirmSecondMerge = async () => {
    setSecondConfirmationPopupVisible(false);
  };

  const handleMergePerson = async () => {
    setIsMergePopupVisible(false);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Person/merge-person`,
        {
          sourcePerson: person,
          sourceOffenderNumber: sourcePersonId,
          targetPerson: fields.selectedPerson.id,
          addToActivity: false,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      if (response.data.success) {
        setConfirmationPopupVisible(true);
      } else {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: response.data.message || "Failed to merge person!",
          type: "error",
        });
      }
      setSelectedPerson('');
      setfields({
        selectedPerson: {
          name: "",
          id: "",
          lastName: ""
        },
      });
    } catch (error) {
      console.error(error);
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "An error occurred while merging!",
        type: "error",
      });
      setSelectedPerson('');
      setfields({
        selectedPerson: {
          name: "",
          id: "",
          lastName: ""
        },
      });
    }
  };

  const confirmMerge = async () => {
    try {
      setConfirmationPopupVisible(false);
      // setSecondConfirmationPopupVisible(true);
      await handleMergePerson();

      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Person merged successfully!",
        type: "success",
      });

      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "",
          type: "",
        });
      }, 2000);
    } catch (error) {
      console.error(error);
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Failed to merge persons!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    getPersonList();
  }, [getCustomFilterValue]);

  useEffect(() => {
    getPersonLists();
  }, []);

  return (
    <div>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />

      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        showTitle={false}
        width={700}
        height={400}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddFilterPopup
          setIsPopupVisible={setIsPopupVisible}
          Tablecoloum={localStorage.getItem("emperiod") == "true" ? Tablecoloumemtrue : Tablecoloum}
          setgetCustomFilterValue={setgetCustomFilterValue}
        />
      </Popup>
      <Popup
        visible={isMergePopupVisible}
        onHiding={() => setIsMergePopupVisible(false)}
        showTitle={false}
        width={400}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="popup_merge">
          <div className="merge_popup">
            <div>
              <p>Enter Information</p>
            </div>
            <div>
              <svg
                onClick={() => {
                  setSelectedPerson('');
                  setfields({
                    selectedPerson: {
                      name: "",
                      id: "",
                      lastName: ""
                    },
                  });
                  setIsMergePopupVisible(false);
                }}
                style={{
                  cursor: "pointer",
                  marginTop: "0px",
                  marginRight: "2%",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div className="target_merge_person">
            <p>Enter Target Offender Number</p>
            <DropDown
              setfields={setfields}
              fields={fields}
              fieldNmae={"selectedPerson"}
              dropDownData={personDropdown}
              value={selectedPerson}
            />
            <p
              style={{
                color: "red",
                position: "absolute",
                fontWeight: "400",
              }}
            >
              {selectedPerson}
            </p>
          </div>

          <div className="merge_btn">
            <button
              onClick={() => {
                setConfirmationMessage(`${last} ${sourcePersonId} will be moved to ${fields.selectedPerson.name}`); // Update confirmation message
                setConfirmationPopupVisible(true);
              }}
            >
              Ok
            </button>
            <button onClick={() => {
              setSelectedPerson('');
              setfields({
                selectedPerson: {
                  name: "",
                  id: "",
                  lastName: ""
                },
              });
              setIsMergePopupVisible(false);
            }
            }>Cancel</button>
          </div>
        </div>
      </Popup>

      <Popup
        visible={confirmationPopupVisible}
        onHiding={() => setConfirmationPopupVisible(false)}
        showTitle={false}
        width={400}
        height={180}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="confirm_popup">
          <h3>Confirm</h3>
          <p style={{ marginTop: "20px" }}>{confirmationMessage}</p>
          <div className="confirm_btn">
            <button onClick={confirmMerge}>Yes</button>
            <button onClick={() => setConfirmationPopupVisible(false)}>No</button>
          </div>
        </div>
      </Popup>

      <Popup
        visible={secondConfirmationPopupVisible}
        onHiding={() => setSecondConfirmationPopupVisible(false)}
        showTitle={false}
        width={400}
        height={180}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="confirm_popup">
          <h3>Confirm</h3>
          <p style={{ marginTop: "20px" }}>
            All activity from {sourcePersonId} has been moved to {fields.selectedPerson.name}
          </p>
          <div className="confirm_btn">
            <button onClick={confirmSecondMerge}>Ok</button>
            <button onClick={() => setSecondConfirmationPopupVisible(false)}>Cancel</button>
          </div>
        </div>
      </Popup>
      <Popup
        visible={finalConfirmationPopupVisible}
        onHiding={() => setFinalConfirmationPopupVisible(false)}
        showTitle={false}
        width={400}
        height={180}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="confirm">
          <div className="final_popup">
            <p >Records have been merged from {fields.selectedPerson.name}.</p>
          </div>
        </div>
      </Popup>

      <Popup
        visible={sendEmailPopup}
        onHiding={() => setSendEmailPopup(false)}
        showTitle={false}
        width={1000}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SendEmail setSendEmailPopup={setSendEmailPopup} personIds={selectedPersonIds} />
      </Popup>

      <div className="personMainTabWrapper">
        <div className="tabwapper">
          <DynamicTabb />
          <div className="tab-main-box">
            <div className="tab-box" id="tab-1">
              <p>Participants tab content/design will be here</p>
            </div>
            <div className="tab-box" id="tab-2" style={{ display: "block" }}>
              <div className="tabcontent">
                <div className="screen_num">
                  <p>PS.3</p>
                </div>
                <div className="interaction flex">
                  <div className="leftAction leftAlign">
                    <button
                      onClick={() => {
                        setexportPopupOpen(true);
                      }}
                      id="myBtn"
                      className="btn btnlightblue"
                    >
                      Export
                      <svg
                        className="svg-blue-white"
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                          fill="#424167"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        getPersonList();
                      }}
                      id="myBtn"
                      className="btn btnlightblue"
                    >
                      Refresh
                      <svg
                        className="svg-blue-white"
                        width="16"
                        height="21"
                        viewBox="0 0 16 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99905 4.00003V1.21003C7.99905 0.760031 7.45905 0.540031 7.14905 0.860031L3.34905 4.65003C3.14905 4.85003 3.14905 5.16003 3.34905 5.36003L7.13905 9.15003C7.45905 9.46003 7.99905 9.24003 7.99905 8.79003V6.00003C11.7291 6.00003 14.6791 9.42003 13.8591 13.29C13.3891 15.56 11.5491 17.39 9.28905 17.86C5.71905 18.61 2.53905 16.16 2.05905 12.85C1.98905 12.37 1.56905 12 1.07905 12C0.479051 12 -0.000948563 12.53 0.0790514 13.13C0.699051 17.52 4.87905 20.77 9.60905 19.85C12.7291 19.24 15.2391 16.73 15.8491 13.61C16.8391 8.48003 12.9391 4.00003 7.99905 4.00003Z"
                          fill="#424167"
                        />
                      </svg>
                    </button>
                    <button
                      id="myBtn"
                      onClick={() => {
                        setIsPopupVisible(!isPopupVisible);
                      }}
                      style={{
                        color: getCustomFilterValue.length >= 1 && "white",
                      }}
                      className={getCustomFilterValue.length >= 1 ? "btn blue" : "btn btnlightblue"}
                    >
                      {getCustomFilterValue.length >= 1 ? "Edit Filter" : "Add Filter"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="Detail_Contact_Tab">
              <div style={{ marginTop: "1.5%" }}>
                <Table2
                  Tablecoloum={localStorage.getItem("emperiod") == "true" ? Tablecoloumemtrue : Tablecoloum}
                  tableRow={data}
                  load={loading}
                  coloumWidthText={"PersonListTableWidth"}
                  height={"auto"}
                  editTable={false}
                  mandatoryColoum={"Offender Number"}
                  showTotalBlock={false}
                  exportInDifferentFormate={true}
                  exportPopupOpen={exportPopupOpen}
                  setexportPopupOpen={setexportPopupOpen}
                  exportwidth={17.4}
                  exporttitle={"person list"}
                  selectedPersonIds={selectedPersonIds}
                  setSelectedPersonIds={setSelectedPersonIds}
                  showSendEmailPopup={showSendEmailPopup}
                  personListControl={true}
                  setSourcePersonId={setSourcePersonId}
                  setIsMergePopupVisible={setIsMergePopupVisible}
                  setPerson={setPerson}
                  setLast={setLast}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
