import { DateBox, Popup, SelectBox } from "devextreme-react";
import { useCallback, useEffect, useState } from "react";
import { GoTOObligationFunction } from "../../GoTOObligationFunction";
import axios from "axios";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import AddEditObligationDetail from "../../../Financial/components/ObligationDetail/AddEditObligationDetail";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { logOut } from "../../../../../../components/Logout";

const FeePaymentPlanB = ({
  data,
  dropdowndata,
  EditTogel,
  addobligation,
  feePaymentPlanData,
  setFeePaymentPlanData,
  isAccrual,
  gObligationID,
  getObligation
}) => {
  const pathname = handleGetLocal("pathURL");
  const { participantdata } = useSelector((store) => store.participate);
  const { gotooblogationValue } = useSelector((store) => store.participateNotes);
  const [fields, setFields] = useState({
    obligationStartDate: new Date().toDateString(),
    obligationEndDate: "",
    amountRate: feePaymentPlanData?.fAmount ?? 0,
    frequency: "",
    rate: feePaymentPlanData?.fRate,
    paymentDay: 0,
    accrualStartDate: new Date().toDateString(),
    accrualEndDate: "",
  });

  const [dayMonths, setDayMonths] = useState(0);
  const [projectedAmount, setProjectedAmount] = useState(0);
  const [duration, setDuration] = useState(0);
  const [frequency, setFrequency] = useState("");

  const [obligationDetail, setObligationDetail] = useState({});

  const [addObligationDetailPopup, setAddObligationDetailPopup] = useState(false);

  const [change, setChange] = useState(true);

  const [frequencyDropdown, setFrequencyDropdown] = useState([]);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const apiClass = new GoTOObligationFunction();
  const navigate = useNavigate();

  useEffect(() => {
    // getData();
    apiClass.getObligationAccrualFrequencyData(navigate, setFrequencyDropdown);
  }, []);

  useEffect(() => {
    setChange(false);
    setFields((prev) => ({
      ...prev,
      obligationStartDate: data.dActive,
      obligationEndDate: data.dProbationEnd,
      amountRate: data?.fAmount,
      frequency: data?.gFrequencyID,
      accrualStartDate: data?.dStart,
      accrualEndDate: data?.dEnd,
      rate: data?.fRate,
      paymentDay: data?.iPaymentDay,
    }));
  }, [data]);

  useEffect(() => {
    if (change) {
      setFeePaymentPlanData((prev) => {
        return {
          ...prev,
          amount: parseInt(fields?.amountRate),
          startDate: fields?.obligationStartDate,
          dStart: fields?.accrualStartDate,
          dEnd: fields?.accrualEndDate,
          fRate: parseInt(fields?.rate),
          frequency: fields?.frequency,
          paymentDay: fields?.paymentDay,
        };
      });
    } else {
      setChange(true);
    }
  }, [fields]);

  useEffect(() => {
    const dEnd = fields.accrualEndDate ? new Date(fields.accrualEndDate) : new Date();
    const dStart = new Date(fields.accrualStartDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const today = new Date();

    if (dEnd.getTime() < dStart.getTime()) return;

    const difference = Math.floor((dEnd.getTime() - dStart.getTime()) / oneDay) + 1;
    setDuration(difference);
    setProjectedAmount(difference * fields.rate);

    if (addobligation)
      if (
        dStart.getFullYear() === today.getFullYear() &&
        dStart.getMonth() === today.getMonth() &&
        dStart.getDate() === today.getDate()
      ) {
        fields.amountRate = fields.rate;
      } else {
        fields.amountRate = 0;
      }
  }, [fields.accrualStartDate, fields.accrualEndDate, fields.rate]);

  useEffect(() => {
    if (addobligation) {
      setFields({
        obligationStartDate: new Date().toDateString(),
        calulateDayMonths: 0,
        obligationEndDate: "",
        amountRate: "",
        frequency: "",
        rate: feePaymentPlanData?.fRate ?? 0,
        paymentDay: 0,
        obligationStartDate: new Date().toDateString(),
        accrualEndDate: "",
      });
    }
  }, [addobligation]);

  useEffect(() => {
    if (addobligation) {
      setChange(false);
      setFields((prev) => ({ ...prev, rate: feePaymentPlanData?.fRate, accrualStartDate: new Date().toDateString() }));
    }
  }, [feePaymentPlanData]);

  useEffect(() => {
    const frequencyAvailable = frequencyDropdown?.find((item) => item.id === fields.frequency);
    if (frequencyAvailable) setFrequency(frequencyAvailable.name);
  }, [fields.frequency, frequencyDropdown]);

  const handleAddDays = useCallback(() => {
    const startDate = new Date(fields.accrualStartDate);
    const daysToAdd = parseInt(dayMonths, 10);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + daysToAdd - 1);

    setFields((prev) => ({
      ...prev,
      accrualEndDate: endDate,
    }));
  }, [fields.accrualStartDate, dayMonths]);

  const handleAddMonth = useCallback(() => {
    const startDate = new Date(fields.accrualStartDate);
    const monthsToAdd = parseInt(dayMonths, 10);

    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + monthsToAdd);

    setFields((prev) => ({
      ...prev,
      accrualEndDate: endDate,
    }));
  }, [fields.accrualStartDate, dayMonths]);

  const openAddObligationDetailModal = () => {
    setObligationDetail({
      sOffenderNumber: participantdata?.sOffenderNumber,
      sFeeName: gotooblogationValue?.userinfo?.sObligationTypeName,
      sLastFirst: participantdata?.sLastFirst,
      gObligationId: gObligationID,
    });
    setAddObligationDetailPopup(true);
  }

  return (
    <div className="fee-paymentplan-section fee-paymentplan-sectionB" style={{ flexDirection: "column" }}>
      {!isAccrual && (
        <>
          <div className="inputfield-flex">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Obligation Start Date
              </label>
              <DateBox
                useMaskBehavior={true}
                value={fields.obligationStartDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    obligationStartDate: e,
                  }));
                }}
                disabled={!EditTogel && !addobligation}
              />
              <p style={{ color: "red" }}>{feePaymentPlanData.startDateValidation}</p>
            </div>
          </div>
          <div className="inputfield-flex">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Amount / Rate
              </label>
              <div className="flex" style={{ position: "relative" }}>
                <input
                  type="number"
                  name="amountRate"
                  id="amountRate"
                  placeholder="Amount / Rate"
                  className="fee-payment-content-info-input"
                  style={{ width: "300px" }}
                  value={fields.amountRate}
                  onChange={(e) => {
                    setFields((prev) => ({
                      ...prev,
                      amountRate: e.target.value,
                    }));
                  }}
                  disabled={!EditTogel && !addobligation}
                />
              </div>
              <p style={{ color: "red" }}>{feePaymentPlanData.amountValidation}</p>
            </div>
          </div>
        </>
      )}

      {isAccrual && (
        <>
          <div className="inputfield-flex">
            <div style={{ fontWeight: "bold", textDecoration: "underline" }}>ACCRUAL PLAN</div>
          </div>
          <div className="inputfield-flex">
            <div className="go-to-inputs-field edit-content-space">
              <label htmlFor="" className="go-to-label-filed">
                Frequency
              </label>
              <SelectBox
                dataSource={frequencyDropdown}
                displayExpr={"name"}
                valueExpr={"id"}
                value={fields.frequency}
                disabled={!EditTogel && !addobligation}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    frequency: e,
                  }));
                }}
              />
            </div>
          </div>
          <div className="inputfield-flex">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Start Date
              </label>
              <DateBox
                useMaskBehavior={true}
                value={fields.accrualStartDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    accrualStartDate: e,
                    paymentDay: new Date(e).getDate(),
                  }));
                }}
                disabled={!EditTogel && !addobligation}
              />
              <p style={{ color: "red" }}>{feePaymentPlanData.startDateValidation}</p>
            </div>
            <div className="go-to-inputs-field">
              <div className="flex" style={{ position: "relative" }}>
                <input
                  type="number"
                  name="calulateDayMonths"
                  id="calulateDayMonths"
                  className="fee-payment-content-info-input"
                  style={{ width: "85px" }}
                  value={dayMonths}
                  onChange={(e) => {
                    setDayMonths(e.target.value);
                  }}
                  disabled={!EditTogel && !addobligation}
                />
              </div>
            </div>
            <button className="btn" onClick={handleAddDays}>
              Add Days
            </button>
            <button className="btn" onClick={handleAddMonth}>
              Add Months
            </button>
          </div>
          <div className="inputfield-flex">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                End Date
              </label>
              <DateBox
                useMaskBehavior={true}
                value={fields.accrualEndDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    accrualEndDate: e,
                  }));
                }}
                disabled={!EditTogel && !addobligation}
              />
            </div>
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Accrual Day
              </label>
              <div className="flex" style={{ position: "relative" }}>
                <input
                  type="number"
                  className="fee-payment-content-info-input"
                  style={{ width: "85px" }}
                  value={fields.paymentDay}
                  onChange={(e) => {
                    setFields((prev) => ({
                      ...prev,
                      paymentDay: e.target.value,
                    }));
                  }}
                  disabled={!EditTogel && !addobligation}
                />
              </div>
            </div>
          </div>
          <div className="inputfield-flex">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Rate
              </label>
              <div className="flex" style={{ position: "relative" }}>
                <input
                  type="number"
                  name="rate"
                  id="rate"
                  placeholder="Rate"
                  className="fee-payment-content-info-input"
                  style={{ width: "150px" }}
                  value={fields.rate}
                  onChange={(e) => {
                    setFields((prev) => ({
                      ...prev,
                      rate: e.target.value,
                    }));
                  }}
                  disabled={!EditTogel && !addobligation}
                />
              </div>
              <p style={{ color: "red" }}>{feePaymentPlanData.amountValidation}</p>
            </div>
            {/* <button style={{marginBottom: '8px'}} onClick={openAddObligationDetailModal}>Add Fee</button> */}
          </div>
        </>
      )}
      { <Popup
        visible={addObligationDetailPopup}
        onHiding={() => {
          setAddObligationDetailPopup(!addObligationDetailPopup);
        }}
        showTitle={false}
        width={600}
        height={420}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEditObligationDetail
          obligationDetail={obligationDetail}
          setShowPopup={setAddObligationDetailPopup}
          modalType={"add"}
          setToastConfig={setToastConfig}
          data={data}
          getObligation={getObligation}
        />
      </Popup>}
    </div>
  );
};

export default FeePaymentPlanB;
