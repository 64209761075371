import { useEffect, useState } from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { ApiError } from "../../../../Error/ApiError";
import { createsTestDatesTableTitle } from "../Data";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import "../drugTestRandomizer.css";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { useSelector } from "react-redux";
import {
  setaddcreatetestdate,
  setresetcreatetestdate,
} from "../../../../../reducer/AdminReadReducer";
import { useDispatch } from "react-redux";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const allMonths = [
  { key: "1", value: "January" },
  { key: "2", value: "February" },
  { key: "3", value: "March" },
  { key: "4", value: "April" },
  { key: "5", value: "May" },
  { key: "6", value: "June" },
  { key: "7", value: "July" },
  { key: "8", value: "August" },
  { key: "9", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];

export const genderOptions = [
  { key: "Any", value: "Any" },
  { key: "Male", value: "Male" },
  { key: "Female", value: "Female" },
];

function getISOWeek(date) {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1;
  const weekNumber = Math.ceil(days / 7);
  return weekNumber;
}

function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

export const CreatesTestDates = ({
  userinfo,
  locationDropdownValues,
  setToastConfig,
  toastConfig,
  activeTab,
}) => {
  const { addcreatetestdate, resetcreatetestdate } = useSelector(
    (store) => store.AdminTable
  );
  const [loading, setLoading] = useState(false);
  const [testDateData, setTestDateData] = useState([]);
  const [slotsCount, setSlotsCount] = useState(0);
  const [participantCount, setParticipantCount] = useState(0);
  const [selectedMonthData, setSelectedMonthData] = useState([]);
  const currentYear = new Date().getFullYear();
  const currentMonthID = new Date().getMonth() + 2;
  const currentMonthDate = new Date(currentYear, currentMonthID - 1, 1);
  const currentMonth = currentMonthDate.toLocaleString("default", {
    month: "long",
  });
  const [fields, setFields] = useState({
    year: { name: currentYear.toString(), id: currentYear.toString() },
    months: { name: currentMonth, id: currentMonthID },
    gender: { name: genderOptions[0].value, id: genderOptions[0].key },
    location: { name: "", id: "" },
    iSlotLimit: "",
  });
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const yearOptions = Array.from({ length: 10 }, (_, index) => ({
    key: (currentYear + index).toString(),
    value: (currentYear + index).toString(),
  }));

  const updateSelectedMonthData = () => {
    const year = parseInt(fields.year.id, 10);
    const monthIndex = parseInt(fields.months.id, 10) - 1;
    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0);

    const data = [];

    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const dayName = currentDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const weekNumber = getISOWeek(currentDate);
      if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
        data.push({
          Date: formatDate(currentDate),
          Day: dayName,
          "Slot 1": false,
          "Slot 2": false,
          "Slot 3": false,
          "Slot 4": false,
          Week: weekNumber,
        });
      }
    }
    setSelectedMonthData(data);
  };

  useEffect(() => {
    updateSelectedMonthData();
  }, [fields.year.id, fields.months.id]);

  useEffect(() => {
    setFields({
      year: { name: currentYear.toString(), id: currentYear.toString() },
      months: { name: currentMonth, id: currentMonthID },
      gender: { name: genderOptions[0].value, id: genderOptions[0].key },
      location: { name: "", id: "" },
      iSlotLimit: "",
    });
  }, [activeTab]);

  useEffect(() => {
    dispatch(setaddcreatetestdate({}));
  }, []);

  const initializeFromLocalStorage = () => {
    const dataWithoutRowId = addcreatetestdate?.rowDataCheck?.map((item) => {
      const { rowId, ...rest } = item;
      return rest;
    });
    setTestDateData(dataWithoutRowId);
    setSlotsCount(addcreatetestdate?.totalCount);
  };

  useEffect(() => {
    initializeFromLocalStorage();
  }, [addcreatetestdate]);

  const divideCount = Number(participantCount) / Number(slotsCount);
  const data = testDateData ? testDateData : [];

  const handleSave = async () => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/add-drug-test-date`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setLoading(false);
          setFields({
            year: { name: currentYear.toString(), id: currentYear.toString() },
            months: { name: currentMonth, id: currentMonthID },
            gender: { name: genderOptions[0].value, id: genderOptions[0].key },
            location: { name: "", id: "" },
            iSlotLimit: "",
          });
          dispatch(setaddcreatetestdate({}));
          dispatch(setresetcreatetestdate(!resetcreatetestdate));
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1900);
        } else {
          console.error(val.data.responseMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <div className="page-content" style={{ padding: "0px" }}>
        <p className="page-info-number">DT.7</p>
        <div className="buttons">
          <button className="btn" onClick={handleSave}>
            Generate
          </button>
          <div className="page-details-search-content">
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">Location</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"location"}
                dropDownData={locationDropdownValues?.location?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">Year</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"year"}
                dropDownData={yearOptions?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                disabled={true}
              />
            </div>
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">Month</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"months"}
                dropDownData={allMonths?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">Gender</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"gender"}
                dropDownData={genderOptions?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
            <div className="display-flex-div">
              <label className="edit_container_label">
                Slot Limit
              </label>
              <input
                type="number"
                name="iSlotLimit"
                id="iSlotLimit"
                placeholder="Slot Limit"
                value={fields.iSlotLimit}
                onChange={handleInputChange}
                className="fee-payment-content-info-input"
                style={{ width: "105px" }}
              />
            </div>
          </div>
        </div>
        <p className="go-to-label-filed">
          {slotsCount > 0
            ? `Participant: ${participantCount}, Slots: ${slotsCount}, Participant/Slot : ${divideCount}`
            : ""}
        </p>
        <div className="flex-frame-content">
          <div className="drug-test-schedule">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={createsTestDatesTableTitle}
              tableRow={selectedMonthData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"Creates-Test-Dates"}
              mandatoryColoum={"Date"}
              createTestFieldData={fields}
              setSlotsCount={setSlotsCount}
            />
          </div>
          {/* <div className="border-frame">
            <div className="frame-content">
              <div className="frame-content-header">
                <p className="frame-content-header-para">Participants</p>
                <p className="frame-content-header-para">Info</p>
              </div>
              <div className="frame-content-data">
                <div className="frame-content-side-data">
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                </div>
                <div className="frame-content-side-data">
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                  <p className="frame-content-side-data-para">Slot 1</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
