import { Table2 } from "../../../../../components/Table2/Table2";

const Tablecoloum = [
  {
    title: "Edit",
  },
  {
    title: "Fee Name",
  },
  {
    title: "Base Fee",
  },
  {
    title: "Start Range",
  },
  {
    title: "End Range",
  },
  {
    title: "Fine Amount",
  },
  {
    title: "Voided",
  },
  {
    title: "In Use",
  },
  {
    title: "Override Surcharges",
  },
  {
    title: "Max Fine",
  },
  {
    title: "Quick Add",
  },
];

export const Feetab = () => {
  return (
    <>
      <Table2
        Tablecoloum={Tablecoloum}
        tableRow={[]}
        editTable={false}
        load={false}
        coloumWidthText={"feesheduleTable"}
        height={"auto"}
        mandatoryColoum={"DOE"}
        showTotalBlock={false}
      />
    </>
  );
};
