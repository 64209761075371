import React, { useState, useEffect } from "react";
import { ReactComponent as BarsIcon } from "../../../../../assets/images/icons/bars.svg";
import { ReactComponent as DownloadIcon } from "../../../../../assets/images/icons/download.svg";
import { OverViewTablecoloumn } from "./Data";
import "./financialtab.css";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { LoadPanel, Popup } from "devextreme-react";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { VoidPaymentPopup } from "./overviewPopUp/VoidPaymentPopup";
import { ChangeCashDrawerPopup } from "./overviewPopUp/ChangeCashDrawerPopup";
import { EditPopup } from "./overviewPopUp/EditPopup";
import { RefundCCPayment } from "./overviewPopUp/RefundCCPayment";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../../Error/ApiError";
import { ReportFunction } from "../../../../Report/REportFunction";
import NewReallocatePaymentPopup from "./overviewPopUp/NewReallocatePaymnetPopup";
import { ConfirmPopup } from "./overviewPopUp/confirmPopup";
import { PersonFind } from "../../../../PersonFind/PersonFind";
import { ConfirmMovePopup } from "./overviewPopUp/confirmMovePopup";

const VoidPayment = ({
  voidPaymentPopup,
  setVoidPaymentPopup,
  userinfo,
  reportDataId,
  rowInfoData,
  amountData,
  gPaymentId,
  setCallApi,
  callApi,
  toastConfig,
  setToastConfig,
}) => {
  return (
    <>
      <Popup
        visible={voidPaymentPopup}
        onHiding={() => {
          setVoidPaymentPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPaymentPopup
          setVoidPaymentPopup={setVoidPaymentPopup}
          userinfo={userinfo}
          reportDataId={reportDataId}
          amountData={amountData}
          rowInfoData={rowInfoData}
          gPaymentId={gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

const ChangeCashDrawer = ({
  cashDrawerPopup,
  setCashDrawerPopup,
  userinfo,
  reportDataId,
  gPaymentId,
  setCallApi,
  callApi,
  listData,
  setListData,
  rowInfoData,
  toastConfig,
  setToastConfig,
}) => {
  return (
    <>
      <Popup
        visible={cashDrawerPopup}
        onHiding={() => {
          setCashDrawerPopup(false);
        }}
        showTitle={false}
        width={576}
        height={420}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ChangeCashDrawerPopup
          setCashDrawerPopup={setCashDrawerPopup}
          userinfo={userinfo}
          reportDataId={reportDataId}
          gPaymentId={gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          listData={listData}
          setListData={setListData}
          rowInfoData={rowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

const EditPopUp = ({
  editPopup,
  setEditPopup,
  userinfo,
  gPaymentId,
  setCallApi,
  callApi,
  listData,
  setListData,
  paymnetMethodData,
  setPaymnetMethodData,
  rowInfoData,
  setRowInfoData,
  toastConfig,
  setToastConfig,
}) => {
  return (
    <>
      <Popup
        visible={editPopup}
        onHiding={() => {
          setEditPopup(false);
        }}
        showTitle={false}
        width={768}
        height={670}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EditPopup
          setEditPopup={setEditPopup}
          userinfo={userinfo}
          gPaymentId={gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          setPaymnetMethodData={setPaymnetMethodData}
          paymnetMethodData={paymnetMethodData}
          listData={listData}
          setListData={setListData}
          rowInfoData={rowInfoData}
          setRowInfoData={setRowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

const VoidCCPopup = ({
  setrefundCCPaymentPopup,
  refundCCPaymentPopup,
  userinfo,
  gPaymentId,
  setCallApi,
  callApi,
  listData,
  setListData,
  paymnetMethodData,
  setPaymnetMethodData,
  rowInfoData,
  setRowInfoData,
  toastConfig,
  setToastConfig,
}) => {
  return (
    <>
      <Popup
        visible={refundCCPaymentPopup}
        onHiding={() => {
          setrefundCCPaymentPopup(false);
        }}
        showTitle={false}
        width={768}
        height={750}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RefundCCPayment
          setrefundCCPaymentPopup={setrefundCCPaymentPopup}
          userinfo={userinfo}
          gPaymentId={gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          setPaymnetMethodData={setPaymnetMethodData}
          paymnetMethodData={paymnetMethodData}
          listData={listData}
          setListData={setListData}
          rowInfoData={rowInfoData}
          setRowInfoData={setRowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
    </>
  );
};

export const Overview = ({
  overviewData,
  setCallApi,
  callApi,
  userinfo,
  listData,
  setListData,
  setPaymnetMethodData,
  paymnetMethodData,
  toastConfig,
  setToastConfig,
  reloadUserInfo,
  OverviewList,
}) => {
  const [load, setLoad] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [voidPaymentPopup, setVoidPaymentPopup] = useState(false);
  const [cashDrawerPopup, setCashDrawerPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [refundCCPaymentPopup, setrefundCCPaymentPopup] = useState(false);
  const [amountData, setAmountData] = useState("");
  const [gPaymentId, setGPaymentId] = useState("");
  const [rowInfoData, setRowInfoData] = useState([]);
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [reallocatePaymentPopup, setReallocatePaymentPopup] = useState(false);
  const [movePaymentPopup, setMovePaymentPopup] = useState(false);
  const [fromPersonOverview, setFromPersonOverview] = useState(false);
  const [unAllocatePopup, setUnAllocatePopup] = useState(false);
  const [reportFolder, setReportFolder] = useState("");
  const [paymentIdForUnallocate, setPaymentIdForUnallocate] = useState('');
  const [paymentIdForMovePayment, setPaymentIdForMovePayment] = useState('');
  const [confirmationData, setConfirmationData] = useState(null);
  const pathname = handleGetLocal("pathURL");

  const reportApis = new ReportFunction();

  useEffect(() => {
    getDetailList();
  }, []);

  const getDetailList = async () => {
    setLoad(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Payment/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setListData(val?.data.data.cashDrawer);
          setPaymnetMethodData(val?.data.data.paymentType);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const getReportFolder = () => {
    setLoad(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Receipt File Name`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoad(false);
        setReportFolder(val.data.data);
      })
      .catch((val) => {
        setLoad(false);
      });
  };
  const handleReceiptNumber = (receiptNumber) => {
    setLoad(true);
    axios
      .get(
        `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/payment/get-report-by-rn?receiptNumber=${receiptNumber}&reportFolder=${reportFolder}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.size > 20) {
          const pdfBlob = new Blob([val.data], {
            type: "application/pdf",
          });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const downloadLink = document.createElement("a");
          downloadLink.href = pdfUrl;
          downloadLink.download = "PY1_Receipt" + ".pdf";
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      })
      .catch((val) => {
        setLoad(false);
      });
  };

  const handleOffenderStatement = () => {
    let printPayload = [];
    printPayload.push({
      sParameterName: "UserId",
      sParameterValue: localStorage.getItem("username"),
    });
    printPayload.push({
      sParameterName: "StatementDate",
      sParameterValue: new Date().toISOString(),
    });
    printPayload.push({
      sParameterName: "OffenderNumber",
      sParameterValue: userinfo?.sOffenderNumber,
    });
    reportApis.print(
      navigate,
      setLoad,
      {
        sReportName: "OFR49_OffenderStatement.rpt",
        sUserId: localStorage.getItem("username"),
        param: [...printPayload],
      },
      setToastConfig,
      toastConfig
    );
  };

  const unAllocateAPICall = async () => {
    setLoad(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Financial/unallocate-payment`,
        {
          "paymentId": paymentIdForUnallocate
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
      .then((val) => {
        setLoad(false);
        setUnAllocatePopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Payment has been Un-allocated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  const movepaymentAPI = async () => {
    setLoad(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Financial/move-payment`,
        {
          "paymentId": paymentIdForMovePayment,
          "sourceId": userinfo?.gPersonId,
          "destinationId": confirmationData?.gPersonId,
          "destinationOffenderNumber": null
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
      .then((val) => {
        setLoad(false);
        setFromPersonOverview(false);
        setMovePaymentPopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Payment has been moved to " + confirmationData['Offender #'],
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  useEffect(() => {
    getReportFolder();
  }, []);

  useEffect(() => {
    OverviewList();
  }, [reallocatePaymentPopup]);

  useEffect(() => {
    if (confirmationData) setFromPersonOverview(true);
  }, [confirmationData]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <PersonFind
        isPopupVisible={movePaymentPopup}
        setIsPopupVisible={setMovePaymentPopup}
        fromPersonOverview={true}
        setConfirmationData={setConfirmationData}
      />
      <VoidPayment
        setVoidPaymentPopup={setVoidPaymentPopup}
        voidPaymentPopup={voidPaymentPopup}
        amountData={amountData}
        rowInfoData={rowInfoData}
        gPaymentId={gPaymentId}
        setCallApi={setCallApi}
        callApi={callApi}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      <ChangeCashDrawer
        cashDrawerPopup={cashDrawerPopup}
        setCashDrawerPopup={setCashDrawerPopup}
        setCallApi={setCallApi}
        callApi={callApi}
        gPaymentId={gPaymentId}
        listData={listData}
        setListData={setListData}
        rowInfoData={rowInfoData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />

      <ConfirmPopup
        unAllocatePopup={unAllocatePopup}
        setUnAllocatePopup={setUnAllocatePopup}
        saveApi={unAllocateAPICall}
      />

      <ConfirmMovePopup
        fromPersonOverview={fromPersonOverview}
        setFromPersonOverview={setFromPersonOverview}
        saveApi={movepaymentAPI}
      />

      <EditPopUp
        editPopup={editPopup}
        setEditPopup={setEditPopup}
        setCallApi={setCallApi}
        callApi={callApi}
        gPaymentId={gPaymentId}
        setPaymnetMethodData={setPaymnetMethodData}
        paymnetMethodData={paymnetMethodData}
        listData={listData}
        setListData={setListData}
        rowInfoData={rowInfoData}
        setRowInfoData={setRowInfoData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      <VoidCCPopup
        refundCCPaymentPopup={refundCCPaymentPopup}
        setrefundCCPaymentPopup={setrefundCCPaymentPopup}
        setCallApi={setCallApi}
        callApi={callApi}
        gPaymentId={gPaymentId}
        setPaymnetMethodData={setPaymnetMethodData}
        paymnetMethodData={paymnetMethodData}
        listData={listData}
        setListData={setListData}
        rowInfoData={rowInfoData}
        setRowInfoData={setRowInfoData}
        userinfo={userinfo}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
      />
      <Popup
        visible={reallocatePaymentPopup}
        onHiding={() => {
          setReallocatePaymentPopup(false);
        }}
        showTitle={false}
        width={982}
        height={722}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <NewReallocatePaymentPopup
          reallocatePaymentPopup={reallocatePaymentPopup}
          setReallocatePaymentPopup={setReallocatePaymentPopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          fieldID={rowInfoData?.gPaymentId}
          setCallApi={setCallApi}
          callApi={callApi}
          refreshApi={reloadUserInfo}
          typePopup={"Overview"}
          rowInfoData={rowInfoData}
        />
      </Popup>

      <div className="financial_overview_tab">
        <div className="financial_overview_tab_buttons">
          <button className="financial_overview_tab_button" onClick={handleOffenderStatement}>
            Offender Statement <BarsIcon className="svg-blue-white" />
          </button>
          <button
            className="financial_overview_tab_button"
            onClick={() => {
              setexportPopupOpen(true);
            }}
          >
            Export <DownloadIcon className="svg-blue-white" />
          </button>
        </div>
        {errorhandling ? (
          <ApiError />
        ) : (
          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={OverViewTablecoloumn}
              tableRow={overviewData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={load}
              coloumWidthText={"Financial"}
              mandatoryColoum={"DOE"}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={27}
              exporttitle={"Financial"}
              amountPayment={true}
              contextMenu={true}
              setVoidPaymentPopup={setVoidPaymentPopup}
              setCashDrawerPopup={setCashDrawerPopup}
              setEditPopup={setEditPopup}
              setAmountData={setAmountData}
              setGPaymentId={setGPaymentId}
              setRowInfoData={setRowInfoData}
              setrefundCCPaymentPopup={setrefundCCPaymentPopup}
              setVoideFalse={true}
              setReallocatePaymentPopup={setReallocatePaymentPopup}
              handleReceiptNumber={handleReceiptNumber}
              setMovePaymentPopup={setMovePaymentPopup}
              setUnAllocatePopup={setUnAllocatePopup}
              setPaymentIdForUnallocate={setPaymentIdForUnallocate}
              setPaymentIdForMovePayment={setPaymentIdForMovePayment}
            />
          </div>
        )}
      </div>
    </>
  );
};
