import React from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { plannedservicesTablecoloumn } from "../Data";
import { ApiError } from "../../../../Error/ApiError";

export const Plannedservices = ({
  setAddCommunityServicePopup,
  setVoidPopup,
  communityPlannedServicesData,
  load,
  setPlannedRowData,
  communityPlannedErorrHandling,
  setGotoAddCommunity,
}) => {
  return (
    <>
      <div className="planned_services">
        {communityPlannedErorrHandling ? (
          <ApiError />
        ) : (
          <div className="planned_services_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={plannedservicesTablecoloumn}
              tableRow={communityPlannedServicesData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={load}
              coloumWidthText={"PlannedservicesTable"}
              mandatoryColoum={"Docket"}
              exportInDifferentFormate={true}
              exportwidth={27}
              exporttitle={"Plannedservices"}
              setAddCommunityServicePopup={setAddCommunityServicePopup}
              plannedMenu={true}
              setVoidPopup={setVoidPopup}
              setPlannedRowData={setPlannedRowData}
              setVoideFalse={true}
              setGotoAddCommunity={setGotoAddCommunity}
            />
          </div>
        )}
      </div>
    </>
  );
};
