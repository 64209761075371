import React, { useState } from "react";
import { LoadPanel, Popup } from "devextreme-react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { ProgramEditPopup } from "./ProgramEditPopup";
import { ProgramTablecoloumn } from "../Data";
import { VoidPopup } from "../VoidPopup";
import { ApiError } from "../../../../Error/ApiError";
import { ReactComponent as DownloadIcon } from "../../../../../assets/images/icons/download.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";

export const Program = ({
  userinfo,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  loading,
  setLoading,
  programTableData,
  getProgramListApi,
  programErorrHandling,
  dockertDetail,
  gotoProgram,
  dockerHeaderData,
}) => {
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [programEditPopup, setProgramEditPopup] = useState(false);
  const [voidPopup, setVoidPopup] = useState(false);
  const [editProgramData, setEditProgramData] = useState([]);
  const [typeProgram, setTtypeProgram] = useState("");

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPopup
          setVoidPopup={setVoidPopup}
          voidHeading="Void Program"
          voidSubHeading="Void Payment"
          setLoad={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          filedId={editProgramData?.gProgramId}
          fieldName="GProgramId"
          apiURl="Programs/voidProgram"
          refreshApi={getProgramListApi}
          comment="Comments"
        />
      </Popup>
      <Popup
        visible={programEditPopup}
        onHiding={() => {
          setProgramEditPopup(false);
        }}
        showTitle={false}
        width={768}
        height={860}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ProgramEditPopup
          programEditPopup={programEditPopup}
          setProgramEditPopup={setProgramEditPopup}
          setLoad={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          userinfo={userinfo}
          setEditProgramData={setEditProgramData}
          editProgramData={editProgramData}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          getProgramListApi={getProgramListApi}
          dockertDetail={dockertDetail}
          gotoProgram={gotoProgram}
          typeProgram={typeProgram}
          dockerHeaderData={dockerHeaderData}
        />
      </Popup>
      <div className="payment_plan_tab">
        <div className="buttons">
          {gotoProgram === true && (
            <button
              className="btn"
              onClick={() => {
                setProgramEditPopup(true);
                setTtypeProgram("Add");
              }}
            >
              Add Program <AddIcon className="svg-blue-white" />
            </button>
          )}
          <button
            className="btn"
            onClick={() => {
              setexportPopupOpen(true);
            }}
          >
            Export <DownloadIcon className="svg-blue-white" />
          </button>
        </div>
        {programErorrHandling ? (
          <ApiError />
        ) : (
          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={ProgramTablecoloumn}
              tableRow={programTableData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"ProgramTable"}
              mandatoryColoum={"Court"}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={27}
              exporttitle={"Program"}
              programMenu={true}
              setProgramEditPopup={setProgramEditPopup}
              setVoidPopup={setVoidPopup}
              setEditProgramData={setEditProgramData}
              setVoideFalse={true}
              setTtypeProgram={setTtypeProgram}
            />
          </div>
        )}
      </div>
    </>
  );
};
