import { useState } from "react";
import { Table1 } from "../../../Administration/Table1/Table1";
import { emTableColumn } from "./Data";
import { LoadPanel, Popup } from "devextreme-react";
import { EMPriod } from "./EmPopup/EMPriod";
import "./em.css";
import { Toster } from "../../../../components/Toster/Toster";
import { useNavigate } from "react-router-dom";
import ConfirmPopup from "../../../Monitoring/Popups/ConfirmPopup";

const EMPriodpopup = ({
  EMPriodpopshow,
  setEMPriodpopshow,
  rowleveldata,
  getEmDataApi,
  setToastConfig,
  toastConfig,
  emPopupType,
  parentRowData,
  setEMFirstSubTabMenu,
}) => {
  return (
    <>
      <Popup
        visible={EMPriodpopshow}
        onHiding={() => {
          setEMPriodpopshow(false);
          setEMFirstSubTabMenu(true);
        }}
        showTitle={false}
        width={730}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EMPriod
          EMPriodpopshow={EMPriodpopshow}
          setEMPriodpopshow={setEMPriodpopshow}
          rowleveldata={rowleveldata}
          getEmDataApi={getEmDataApi}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          emPopupType={emPopupType}
          parentRowData={parentRowData}
        />
      </Popup>
    </>
  );
};

const Em = ({ userinfo, emTableData, getEmDataApi }) => {
  const [loading, setLoading] = useState(false);
  const [EMPriodpopshow, setEMPriodpopshow] = useState(false);
  const [eMFirstSubTabMenu, setEMFirstSubTabMenu] = useState(true);
  const [rowleveldata, setrowleveldata] = useState({});
  const [rowlevelchilddata, setrowlevelchilddata] = useState({});
  const [parentRowData, setParentRowData] = useState([]);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [emPopupType, setEmPopupType] = useState("");
  const navigate = useNavigate();
  const [confirmPopup, setConfirmPopup] = useState(false);

  

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <Popup
        visible={confirmPopup}
        onHiding={() => {
          setConfirmPopup(false);
        }}
        showTitle={false}
        width={450}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ConfirmPopup
          confirmPopup={confirmPopup}
          setConfirmPopup={setConfirmPopup}
          setLoading={setLoading}
          navigate={navigate}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={getEmDataApi}
          apiURl={`api/em/void-emperiod?GEmPeriodId=${rowlevelchilddata?.gEmPeriodId}`}
          typePara={"Void"}
        />
      </Popup>
      <div className="em-subtab">
        <EMPriodpopup
          EMPriodpopshow={EMPriodpopshow}
          setEMPriodpopshow={setEMPriodpopshow}
          rowleveldata={rowleveldata ? rowleveldata : rowlevelchilddata}
          getEmDataApi={getEmDataApi}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          emPopupType={emPopupType}
          parentRowData={parentRowData}
          setEMFirstSubTabMenu={setEMFirstSubTabMenu}
        />
        <div className="table-section">
          <Table1
            editTable={false}
            height={"auto"}
            Tablecoloum={emTableColumn}
            tableRow={emTableData}
            load={loading}
            coloumWidthText={"EM"}
            mandatoryColoum={"Court"}
            emSubTabMenu={true}
            eMFirstSubTabMenu={eMFirstSubTabMenu}
            setEMFirstSubTabMenu={setEMFirstSubTabMenu}
            setEMPriodpopshow={setEMPriodpopshow}
            setrowleveldata={setrowleveldata}
            setrowlevelchilddata={setrowlevelchilddata}
            rowlevelchilddata={rowlevelchilddata}
            rowleveldata={rowleveldata}
            setEmPopupType={setEmPopupType}
            setParentRowData={setParentRowData}
            setConfirmPopup={setConfirmPopup}
          />
        </div>
      </div>
    </>
  );
};

export default Em;
