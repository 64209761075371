import { useEffect, useState } from "react";
import { Table1 } from "../../../Administration/Table1/Table1";
import { conditionsViolationsColumn } from "../Data";
import { DocumentWizardFunction } from "../DocumentWizardFunction";
import { useNavigate } from "react-router-dom";

const ConditionsViolations = ({
  conditionsViolationsTableData,
  setConditionsViolationsRowTableData,
  setConditionsViolationsTableData,
  editToggle,
  sharequeryshow,
  fields,
  docketsRowTableData,
  personId,
}) => {
  const ApiClass = new DocumentWizardFunction();
  const [loading, setLoading] = useState(false);
  const [findNameValue, setFindNameValue] = useState("");
  const [findNameValueValidation, setFindNameValueValidation] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setFindNameValueValidation("");
    setFindNameValue("");
  }, []);
  const docketsFindData = {
    personID: personId,
    selectedDockets: docketsRowTableData?.map((item) => {
      return item?.gSentenceCourtEntityId;
    }),
    filter: findNameValue,
  };

  const findConditionViolation = () => {
    let isValid = true;
    if (!findNameValue || findNameValue.charAt(0) === " ") {
      setFindNameValueValidation("Enter Filter");
      isValid = false;
    }
    if (isValid) {
      ApiClass.findConditionsAndViolationsData(docketsFindData, setLoading, navigate, setConditionsViolationsTableData);
    }
  };

  return (
    <>
      <div className="document-wizard-tabs-content">
        <p className="page-info-text-detail">Conditions and Violations</p>
        <div className="display-flex-div">
          <div className="display-flex-div">
            <label htmlFor="filter" className="go-to-label-filed">
              Filter
            </label>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                findConditionViolation();
              }}
            >
              <input
                type="text"
                name="filter"
                id="filter"
                value={findNameValue}
                className="fee-payment-content-info-input"
                onChange={(e) => {
                  setFindNameValue(e.target.value);
                  setFindNameValueValidation("");
                }}
              />
            </form>
            <button
              className="primary-button"
              onClick={() => {
                findConditionViolation();
              }}
            >
              Find
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1245_382647)">
                  <path
                    d="M15.5006 14.0006H14.7106L14.4306 13.7306C15.6306 12.3306 16.2506 10.4206 15.9106 8.39063C15.4406 5.61063 13.1206 3.39063 10.3206 3.05063C6.09063 2.53063 2.53063 6.09063 3.05063 10.3206C3.39063 13.1206 5.61063 15.4406 8.39063 15.9106C10.4206 16.2506 12.3306 15.6306 13.7306 14.4306L14.0006 14.7106V15.5006L18.2506 19.7506C18.6606 20.1606 19.3306 20.1606 19.7406 19.7506C20.1506 19.3406 20.1506 18.6706 19.7406 18.2606L15.5006 14.0006ZM9.50063 14.0006C7.01063 14.0006 5.00063 11.9906 5.00063 9.50063C5.00063 7.01063 7.01063 5.00063 9.50063 5.00063C11.9906 5.00063 14.0006 7.01063 14.0006 9.50063C14.0006 11.9906 11.9906 14.0006 9.50063 14.0006Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1245_382647">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          {/* <div className="display-flex-div">
            <input type="checkbox" name="acdCode" id="showPerson" />
            <label
              htmlFor="showPerson"
              className="fee-payment-content-info-para"
            >
              Show First and Third Perosn Violations
            </label>
          </div> */}
        </div>
        {findNameValueValidation && <p className="error_validation">{findNameValueValidation}</p>}
        <Table1
          editTable={false}
          height={"auto"}
          Tablecoloum={conditionsViolationsColumn}
          tableRow={conditionsViolationsTableData?.map((val, i) => {
            return { ...val, ID: i };
          })}
          load={loading}
          coloumWidthText={"Conditions and Violations"}
          mandatoryColoum={"Sort"}
          setConditionsViolationsRowTableData={setConditionsViolationsRowTableData}
          documentWizardConditionsViolationsSelection={true}
          SharequeryTableselectall={true}
          sharequeryshow={sharequeryshow}
          setDocumentWizardDiable={editToggle || fields?.reports?.id === ""}
        />
      </div>
    </>
  );
};

export default ConditionsViolations;
