import React, { useEffect, useState } from 'react'
import { DynamicTabb } from '../../components/DynamicTab/DynamicTab';
import { Table2 } from '../../components/Table2/Table2';
import { Tablecoloum } from "../revenue-report/Data";
import { useNavigate } from 'react-router-dom';
import { setTableData } from "../revenue-report/Function";
import { logOut } from "../../components/Logout";
import { handleGetLocal } from '../../services/auth-service';
import axios from 'axios';
import { DateBox, SelectBox } from 'devextreme-react';
import { ColumnDropdown } from '../../components/Dropdownn';
import { useSelector } from 'react-redux';

const DropDown = ({
    dropDownData,
    setfields,
    fields,
    fieldNmae,
    reset,
    disabled,
    value
}) => {
    return (
        <>
            <ColumnDropdown
                data={dropDownData}
                setfields={setfields}
                fields={fields}
                fieldNmae={fieldNmae}
                value={fields[fieldNmae]?.name || value}
                reset={reset}
                disabled={disabled}
            />
        </>
    );
};

function RevenueSummary() {
    const { user_data } = useSelector((store) => store.Home);
    const pathname = handleGetLocal("pathURL");
    const [exportPopupOpen, setexportPopupOpen] = useState(false);
    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(false);
    const [revenueRowData, setRevenueRowData] = useState([]);
    const [date, setdate] = useState({
        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
    });
    const navigate = useNavigate();
    const [quickDatevalue, setquickDatevalue] = useState("Month to Date");
    const today = new Date();
    const dateRanges = [
        { name: "Last 3 Days" },
        { name: "Last 7 Days" },
        { name: "Month to Date" },
        { name: "Previous Month" },
        { name: "Next Month from Date" },
        { name: "Next 3 Month from Date" },
        { name: "last 3 Month to Date" },
        { name: "last 6 Month to Date" },
        { name: "Year to Date" },
        { name: "Previous Year" },
        { name: "Today" },
    ];
    const [locationDropdownData, setlocationDropdownData] = useState([]);
    const [fields, setFields] = useState({
        location: { name: "", id: "" },
    });

    const getDropDown = async () => {
        setloading(true);
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/PersonReportDates/masterDropdownValues`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Domain: `${pathname}`,
              },
            }
          )
          .then((val) => {
            setlocationDropdownData(
              val.data.data.locationEntity.map((vall) => {
                return { name: vall.value, id: vall.key };
              })
            );
            setloading(false);
          })
          .catch((val) => {
            setloading(false);
            if (!val?.response) {
              navigate(`/error/500`);
            } else if (val?.response?.status == 401) {
              logOut();
            }
          });
      };

    const getRevenueReportList = async () => {
        setloading(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/Revenue/get-revenue-report?startDate=${date.startDate}&endDate=${date.endDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                    },
                }
            )
            .then((val) => {
                // Filter data based on location
                const filteredData = val.data?.data?.filter(item =>
                    !fields?.location?.name || item.sLocation === fields?.location?.name
                );

                // setdata(setTableData(val.data.data));
                setdata(setTableData(filteredData));
                setloading(false);
            })
            .catch((val) => {
                setloading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status == 401) {
                    logOut();
                }
            });
    };

    useEffect(() => {
        getRevenueReportList();
    }, [date?.startDate, date?.endDate, fields?.location?.name]);

    useEffect(() => {
        getDropDown();
    }, []);

    return (
        <>
            <div className="arrearageminderMainTabWrapper">
                <div className="tabwapper">
                    <DynamicTabb />
                    <div className="tab-main-box">
                        <div className="tab-box" id="tab-2" style={{ display: "block", marginBottom: "20px" }}>
                            <div className="tabcontent">
                                <div className="screen_num">
                                    <p>EM.30</p>
                                </div>
                            </div>
                        </div>
                        <div className="page-details-search-content">
                            <div className="display-flex-div edit_content_space">
                                <label className="edit_container_label">Location</label>
                                <DropDown
                                    setfields={setFields}
                                    fields={fields}
                                    fieldNmae={"location"}
                                    dropDownData={locationDropdownData}
                                    value={user_data?.sLocationName}
                                />
                            </div>
                            <div className="display-flex-div">
                                <label className="edit_container_label">Start Date</label>
                                <DateBox
                                    useMaskBehavior={true}
                                    value={date.startDate}
                                    onValueChange={(e) => {
                                        if (e) {
                                            const initialDateString = e;
                                            const initialDate = new Date(initialDateString);
                                            const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                                            const utcFormatted = new Date(utcTime).toISOString();
                                            setdate({
                                                ...date,
                                                startDate: e ? utcFormatted : null,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="display-flex-div">
                                <label className="edit_container_label">End Date</label>
                                <DateBox
                                    useMaskBehavior={true}
                                    value={date.endDate}
                                    onValueChange={(e) => {
                                        if (e) {
                                            const initialDateString = e;
                                            const initialDate = new Date(initialDateString);
                                            const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                                            const utcFormatted = new Date(utcTime).toISOString();
                                            setdate({
                                                ...date,
                                                endDate: e ? utcFormatted : null,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="display-flex-div">
                                <label className="edit_container_label">Quick Date</label>
                                <SelectBox
                                    dataSource={dateRanges}
                                    valueExpr="name"
                                    displayExpr="name"
                                    searchEnabled={true}
                                    searchExpr={"name"}
                                    value={quickDatevalue}
                                    dropDownOptions={{ height: "180px" }}
                                    onItemClick={(e) => {
                                        setquickDatevalue(e?.itemData?.name);
                                        let startDate = null;
                                        let endDate = null;
                                        switch (e?.itemData?.name) {
                                            case "Last 3 Days":
                                                startDate = new Date();
                                                startDate.setDate(startDate.getDate() - 2);
                                                endDate = new Date();
                                                break;
                                            case "Last 7 Days":
                                                startDate = new Date();
                                                startDate.setDate(startDate.getDate() - 6);
                                                endDate = new Date();
                                                break;
                                            case "Month to Date":
                                                startDate = new Date();
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "Previous Month":
                                                startDate = new Date();
                                                startDate.setMonth(startDate.getMonth() - 1);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                endDate.setDate(0);
                                                break;
                                            case "Next Month from Date":
                                                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                                                endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
                                                break;
                                            case "Next 3 Month from Date":
                                                startDate = new Date();
                                                endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0);
                                                break;
                                            case "last 3 Month to Date":
                                                startDate = new Date();
                                                startDate.setMonth(startDate.getMonth() - 2);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "last 6 Month to Date":
                                                startDate = new Date();
                                                startDate.setMonth(startDate.getMonth() - 5);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "Year to Date":
                                                startDate = new Date();
                                                startDate.setMonth(0);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                break;
                                            case "Previous Year":
                                                startDate = new Date();
                                                startDate.setFullYear(startDate.getFullYear() - 1);
                                                startDate.setMonth(0);
                                                startDate.setDate(1);
                                                endDate = new Date();
                                                endDate.setFullYear(endDate.getFullYear() - 1);
                                                endDate.setMonth(11);
                                                endDate.setDate(31);
                                                break;
                                            case "Today":
                                            default:
                                                startDate = new Date();
                                                endDate = new Date();
                                                break;
                                        }
                                        setdate({
                                            startDate: startDate.toISOString().slice(0, 10),
                                            endDate: endDate.toISOString().slice(0, 10),
                                        });
                                    }}
                                />
                            </div>
                            <div className="leftAction leftAlign">
                                <button
                                    onClick={() => {
                                        setexportPopupOpen(true);
                                    }}
                                    id="myBtn"
                                    className="btn btnlightblue"
                                >
                                    Export
                                    <svg
                                        className="svg-blue-white"
                                        width="14"
                                        height="17"
                                        viewBox="0 0 14 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                                            fill="#424167"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="Detail_Contact_Tab">
                            <div style={{ marginTop: "1.5%" }}>
                                <Table2
                                    Tablecoloum={Tablecoloum}
                                    tableRow={data}
                                    load={loading}
                                    coloumWidthText={"RevenueReport"}
                                    height={"auto"}
                                    editTable={false}
                                    mandatoryColoum={"Category"}
                                    showTotalBlock={true}
                                    exportInDifferentFormate={true}
                                    exportPopupOpen={exportPopupOpen}
                                    setexportPopupOpen={setexportPopupOpen}
                                    exportwidth={16.3}
                                    exporttitle={"revenue report"}
                                    docketlisthiperline={true}
                                    revenueReportMenu={true}
                                    revenueReportTotal={true}
                                    revenueRowData={revenueRowData}
                                    setRevenueRowData={setRevenueRowData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RevenueSummary;