import { useCallback, useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { DataGrid, DateBox, DropDownBox, Popup } from "devextreme-react";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { EmFunction } from "../EmFunction";
import { useEffect } from "react";
import { QuickaddObligationPopup } from "../../Dockect/overview/QuickaddObligation";
import { setresetReportDates } from "../../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const QuickaddObligation = ({
  setgetQuickaddObligationPopup,
  getQuickaddObligationPopup,
  dropdownData,
  getTopDynamicDocketid,
  toastConfig,
  setToastConfig,
  header,
  callObligationApi,
  setCallObligationApi,
}) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  return (
    <Popup
      visible={getQuickaddObligationPopup}
      onHiding={() => {
        dispatch(setresetReportDates(!resetReportDates));
        setgetQuickaddObligationPopup(false);
      }}
      showTitle={false}
      width={608}
      height={640}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <QuickaddObligationPopup
        getTopDynamicDocketid={getTopDynamicDocketid}
        setgetQuickaddObligationPopup={setgetQuickaddObligationPopup}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        header={header}
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
      />
    </Popup>
  );
};

export const AddObligation = ({
  addObligationPopup,
  setAddObligationPopup,
  setToastConfig,
  toastConfig,
  setLoading,
  navigate,
  typeTab,
  paymentTypeDropdownData,
  paymentAgencyDropdownData,
  frequencyDropdownData,
  obligationDataSource,
  refreshApi,
  emRowLevelData,
  rowLevelData,
  parentRowData,
}) => {
  const [fields, setFields] = useState({
    rateStartDate: "",
    rateEndDate: "",
    paymentType: { name: "", id: "" },
    paymentAgencyType: { name: "", id: "" },
    frequency: { name: "", id: "" },
    rate: "",
  });
  const [selectedValue, setSelectedValue] = useState({
    Fee: "",
    Court: "",
    Reference: "",
    "Fin Status": "",
    GObligationId: "",
  });
  const ApiClass = new EmFunction();
  const [startDateValidation, setStartDateValidation] = useState("");
  const [endDateValidation, setEndDateValidation] = useState("");
  const [obligationValidation, setObligationValidation] = useState("");
  const [rateValidation, setRateValidation] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "rate") {
      setRateValidation("");
    }
  };

  useEffect(() => {
    setStartDateValidation("");
    setObligationValidation("");
    setEndDateValidation("");
    setRateValidation("");
    if (typeTab === "Add") {
      const dailyFrequency = frequencyDropdownData?.find(
        (frequency) => frequency.name === "Daily"
      );
      setFields({
        rateStartDate: "",
        rateEndDate: "",
        paymentType: { name: "", id: "" },
        paymentAgencyType: { name: "", id: "" },
        frequency: { name: dailyFrequency?.name, id: dailyFrequency?.id },
        rate: "",
      });
      setSelectedValue({
        Fee: "",
        Court: "",
        Reference: "",
        "Fin Status": "",
        GObligationId: "",
      });
    } else {
      setSelectedValue({
        Fee: rowLevelData?.[`Fee Name`],
        Court: "",
        Reference: "",
        "Fin Status": "",
        GObligationId: rowLevelData?.gObligationId
          ? rowLevelData?.gObligationId
          : "",
      });
      setFields({
        rateStartDate: rowLevelData?.[`Rate Start`],
        rateEndDate: rowLevelData?.[`Rate End`],
        paymentType: {
          name: rowLevelData?.Type,
          id: rowLevelData?.gEmPaymentTypeId
            ? rowLevelData?.gEmPaymentTypeId
            : "",
        },
        paymentAgencyType: {
          name: rowLevelData?.[`Billing Agency`],
          id: rowLevelData?.gBillingEntityId
            ? rowLevelData?.gBillingEntityId
            : "",
        },
        frequency: {
          name: rowLevelData?.[`Frequency`],
          id: rowLevelData?.gRateFrequencyId
            ? rowLevelData?.gRateFrequencyId
            : "",
        },
        rate: rowLevelData?.Rate ? rowLevelData?.Rate : 0,
      });
    }
  }, [addObligationPopup]);

  const data = {
    GPeriodObligationId:
      typeTab === "Add"
        ? "00000000-0000-0000-0000-000000000000"
        : rowLevelData?.gPeriodObligationId,
    GEmPeriodId:
      typeTab === "Add"
        ? emRowLevelData?.gEmPeriodId
        : rowLevelData?.gEmPeriodId,
    GObligationId: selectedValue?.GObligationId,
    FRate: fields?.rate,
    GRateFrequencyId: fields?.frequency?.id,
    GEmPaymentTypeId: fields?.paymentType?.id,
    GBillingEntityId: fields?.paymentAgencyType?.id,
    GEntryUserId: localStorage.getItem("gUserId"),
    DRateStart: fields?.rateStartDate,
    DRateEnd: fields?.rateEndDate,
  };

  const handleSave = () => {
    let isValid = true;
    if (fields?.rateStartDate === "") {
      isValid = false;
      setStartDateValidation("Please Select Rate Start Date.");
    }
    if (
      fields.rateEndDate &&
      new Date(fields.rateStartDate) >= new Date(fields.rateEndDate)
    ) {
      isValid = false;
      setEndDateValidation("End Date Must Be Later Than Start Date.");
    }
    if (selectedValue?.GObligationId === "") {
      isValid = false;
      setObligationValidation("Please Select Obligation.");
    }
    if (!fields?.rate) {
      isValid = false;
      setRateValidation("Please Enter Valid Rate.");
    }
    if (isValid) {
      ApiClass.addEditEmPeriodObligation(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        data,
        typeTab,
        setAddObligationPopup,
        refreshApi
      );
    }
  };

  const [dropdownData, setDropdownData] = useState([]);
  const pathname = handleGetLocal("pathURL");

  const getDropdownDatas = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getDropdownDatas();
  }, []);

  const [getQuickaddObligationPopup, setgetQuickaddObligationPopup] =
    useState(false);

  // console.log(emRowLevelData, rowLevelData, parentRowData);

  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <QuickaddObligation
        setgetQuickaddObligationPopup={setgetQuickaddObligationPopup}
        getQuickaddObligationPopup={getQuickaddObligationPopup}
        dropdownData={dropdownData}
        getTopDynamicDocketid={parentRowData?.userinfo?.gDocketId}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        header={parentRowData?.userinfo}
      />
      <div className="add-new-rate">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Period Obligation</p>
            </div>
            <p class="popup_header_title_para">EM.5</p>
          </div>
          <svg
            onClick={() => {
              setAddObligationPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="popup-container-data">
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Obligation <span className="marks_mandatary">*</span>
              </p>
              <DropDownBox
                placeholder="Select..."
                dataSource={obligationDataSource}
                value={selectedValue?.Fee}
                valueExpr={"Fee"}
                displayExpr={"Fee"}
                opened={isGridBoxOpened}
                onOptionChanged={onGridBoxOpened}
                contentRender={(value, element) => (
                  <DataGrid
                    dataSource={obligationDataSource}
                    hoverStateEnabled={true}
                    height={"auto"}
                    onRowClick={(e) => {
                      setSelectedValue(e?.data);
                      setObligationValidation("");
                      setIsGridBoxOpened(false);
                    }}
                  >
                    <Selection mode="single" />
                    <FilterRow visible={true} />
                    <Column dataField="Fee" caption="Fee" width={"auto"} />
                    <Column dataField="Court" caption="Court" width={"auto"} />
                    <Column
                      dataField="Reference"
                      caption="Reference"
                      width={"auto"}
                    />
                    <Column
                      dataField="Fin Status"
                      caption="Fin Status"
                      width={"auto"}
                    />
                  </DataGrid>
                )}
              />
              {obligationValidation && (
                <p
                  className="error_validation"
                  style={{ position: "absolute", top: "144px" }}
                >
                  {obligationValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Rate Start Date <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.rateStartDate}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime =
                      initialDate.getTime() -
                      initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setFields((prev) => ({
                      ...prev,
                      rateStartDate: e ? utcFormatted : null,
                    }));
                    setStartDateValidation("");
                  }
                }}
              />
              {startDateValidation && (
                <p
                  className="error_validation"
                  style={{ position: "absolute", top: "144px" }}
                >
                  {startDateValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first import-margin-top">
            <div className="edit_content_flex">
              <p className="edit_container_label">Rate End Date</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.rateEndDate}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime =
                      initialDate.getTime() -
                      initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setFields((prev) => ({
                      ...prev,
                      rateEndDate: e ? utcFormatted : null,
                    }));
                    setEndDateValidation("");
                  }
                }}
              />
              {endDateValidation && (
                <p
                  className="error_validation"
                  style={{ position: "absolute", top: "251px" }}
                >
                  {endDateValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Payment Type</p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"paymentType"}
                dropDownData={paymentTypeDropdownData}
              />
            </div>
          </div>
          <div className="edit_container_first import-margin-top">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Payment Agency (Entity)</p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"paymentAgencyType"}
                dropDownData={paymentAgencyDropdownData}
                // disabled={true}
              />
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Frequency</p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"frequency"}
                dropDownData={frequencyDropdownData}
              />
            </div>
          </div>
          <div className="edit_container_first import-margin-top">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Rate <span className="marks_mandatary">*</span>
              </p>
              <input
                id="rate"
                name="rate"
                type="number"
                placeholder="Enter Rate"
                className="edit_container_input"
                value={fields?.rate}
                onChange={handleInputChange}
              />
              {rateValidation && (
                <p
                  className="error_validation"
                  style={{ position: "absolute", top: "469px" }}
                >
                  {rateValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first import-margin-top">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Only Accrual type obligation can be added, You can use the Quick
                Add obligation to add New obligation.
              </p>
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              onClick={() => {
                setgetQuickaddObligationPopup(true);
              }}
              className="btn primary_btn"
            >
              Quick Add Obligation <AddIcon className="svg-white" />
            </button>
            <button
              className="btn"
              onClick={() => {
                setAddObligationPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
