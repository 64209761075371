import { useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { emEquipmentLogTableColumn } from "../Data";
import { Popup } from "devextreme-react";
import AddEqupmentLog from "./AddEqupmentLog";
import { ApiError } from "../../../../Error/ApiError";
import { useEffect } from "react";
import { EmFunction } from "../EmFunction";

export const EquipmentLogTab = ({
  loading,
  setLoading,
  setToastConfig,
  toastConfig,
  navigate,
  setEMPriodpopshow,
  equipmentStatusDropdown,
  returnStatusDropdown,
  rowleveldata,
}) => {
  const [emEquipmentLogTableData, setEmEquipmentLogTableData] = useState([]);
  const [addEqupmentLogPopup, setAddEqupmentLogPopup] = useState(false);
  const [errorhandling, setErrorhandling] = useState(false);
  const [typeTab, SetTypeTab] = useState("");
  const [rowLevelData, setRowLevelData] = useState([]);
  const [equipmentDropdown, setEquipmentDropdown] = useState([]);
  const ApiClass = new EmFunction();

  const refreshApi = () => {
    rowleveldata?.gPersonId &&
      ApiClass.getEquimentLogData(
        setLoading,
        navigate,
        setEmEquipmentLogTableData,
        setErrorhandling,
        rowleveldata?.gPersonId,
        rowleveldata?.gEmPeriodId
      );
  };

  useEffect(() => {
    refreshApi();
    ApiClass.getEquimentLogDropdownData(
      setLoading,
      navigate,
      setErrorhandling,
      setEquipmentDropdown
    );
  }, []);

  return (
    <>
      <Popup
        visible={addEqupmentLogPopup}
        onHiding={() => {
          setAddEqupmentLogPopup(false);
        }}
        showTitle={false}
        width={768}
        height={620}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEqupmentLog
          addEqupmentLogPopup={addEqupmentLogPopup}
          setAddEqupmentLogPopup={setAddEqupmentLogPopup}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          setLoading={setLoading}
          navigate={navigate}
          typeTab={typeTab}
          equipmentStatusDropdown={equipmentStatusDropdown}
          returnStatusDropdown={returnStatusDropdown}
          rowLevelData={rowLevelData}
          refreshApi={refreshApi}
          emRowLevelData={rowleveldata}
          equipmentDropdown={equipmentDropdown}
        />
      </Popup>
      <div className="buttons">
        <button
          className="btn"
          onClick={() => {
            SetTypeTab("Add");
            setAddEqupmentLogPopup(true);
          }}
        >
          Add Equipment <AddIcon className="svg-blue-white" />
        </button>
      </div>
      {errorhandling ? (
        <ApiError />
      ) : (
        <div className="table-input-width">
          <Table1
            editTable={false}
            height={"auto"}
            Tablecoloum={emEquipmentLogTableColumn}
            tableRow={emEquipmentLogTableData}
            load={loading}
            coloumWidthText={"Em-Equipment"}
            mandatoryColoum={"Type"}
            SetTypeTab={SetTypeTab}
            EMEquipmentLogMenu={true}
            setEMAddEqupmentLogPopup={setAddEqupmentLogPopup}
            setRowLevelData={setRowLevelData}
          />
        </div>
      )}
      <div className="popup_buttons">
        <button
          className="btn"
          onClick={() => {
            setEMPriodpopshow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
