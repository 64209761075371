import { ContextMenu, DataGrid, LoadPanel } from "devextreme-react";
import { useEffect, useState } from "react";
import { Toster } from "../../components/Toster/Toster";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import { ApiError } from "../Error/ApiError";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { Column, HeaderFilter, Pager } from "devextreme-react/cjs/data-grid";
import ThreeDotMenu from "../../components/Common/Icons/ThreeDotMenu";
import { useDispatch } from "react-redux";
import { setAddObligation, setgotooblogationValue, setOpenMonitoringTab } from "../../reducer/participantNoteReducer";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { setDynamicTab } from "../../reducer/HomePageReducer";

const MissingEMRates = () => {
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [rates, setRates] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setData = (rates, obligations) => {
    setRates(
      rates.map((rate, index) => {
        return { ...rate, obligation: obligations[index] };
      })
    );
  };

  const jumpToObligation = (obligation) => {
    dispatch(setAddObligation(false));
    dispatch(
      setDynamicTab({
        Offender: `/obligation/${obligation?.sDocketNumberCourtCounts?.replace("/", "-")}/${obligation?.sReference}`,
        link: `/obligation/${obligation?.sDocketNumberCourtCounts?.replace("/", "-")}/${obligation?.sReference}`,
        title: `Obligation : ${obligation?.sDocketNumberCourtCounts} / ${obligation?.sReference}`,
        sDocketNumberCourtCounts: obligation?.sDocketNumberCourtCounts,
        ObligationRef: obligation?.sReference,
        gObligationId: obligation?.gObligationId,
        userinfo: obligation,
      })
    );
    dispatch(
      setgotooblogationValue({
        gObligationId: obligation?.gObligationId,
        userinfo: obligation,
      })
    );
    navigate(`/obligation/${obligation?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligation?.sReference}`);
  };

  const jumpToMonitoring = (obligation) => {
    jumpToObligation(obligation);
    dispatch(setOpenMonitoringTab(true));
  };

  useEffect(() => {
    ApiClass.getMissingEMRates(setData, navigate, setLoading);
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <MonitoringCrumbs title={"Missing EM Rates"} />
      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">EM.27</p>
          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <>
                <DataGrid
                  dataSource={rates}
                  columnAutoWidth
                  onContextMenuPreparing={(e) => {
                    setSelectedRow(e.row?.data);
                  }}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  onCellPrepared={(e) => {
                    if (e.isAltRow) {
                      e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
                      e.cellElement.style.color = "var(--white-black)";
                    }
                  }}
                >
                  <HeaderFilter visible={true} allowSearch />
                  <Column
                    cellRender={(rowData) => {
                      return (
                        <div
                          id="threedots"
                          className="add_navBar_hamburger"
                          onClick={() => {
                            setSelectedRow(rowData?.data);
                          }}
                          style={{ margin: "-7px" }}
                        >
                          <ThreeDotMenu />
                          <ContextMenu
                            showEvent="dxclick"
                            target="#threedots"
                            dataSource={[
                              {
                                text: "Jump to Person",
                              },
                              {
                                text: "Jump to Obligation",
                              },
                              {
                                text: "Jump to Monitoring",
                              },
                            ]}
                            onItemClick={(e) => {
                              if (e.itemData.text === "Jump to Person") {
                                dispatch(
                                  setDynamicTab({
                                    Offender: selectedRow?.sOffenderNumber,
                                    title: selectedRow?.sLastFirst?.split(",")[0],
                                    link: `/participant/${selectedRow?.sOffenderNumber}`
                                  })
                                );
                                navigate(`/participant/${selectedRow?.sOffenderNumber}`);
                              }
                              if (e.itemData.text === "Jump to Obligation") {
                                jumpToObligation(selectedRow?.obligation);
                              }
                              if (e.itemData.text === "Jump to Monitoring") {
                                jumpToMonitoring(selectedRow?.obligation);
                              }
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  <Column dataField="sLocationName" caption="Location" />
                  <Column dataField="sRegionName" caption="Region" />
                  <Column dataField="sAgencyName" caption="Agency" />
                  <Column dataField="sOffenderNumber" caption="Client ID" />
                  <Column dataField="sLastFirst" caption="Name" />
                  <Column dataField="sDDSViolationDescription" caption="Type" />
                  <Column dataField="dRateStart" caption="Start" dataType="date" />
                  <Column dataField="dRateEnd" caption="End" dataType="date" />
                  <Column dataField="iDaysActive" caption="Days Active" />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 30, 50]}
                    showNavigationButtons={true}
                    showInfo={true}
                  />
                </DataGrid>
              </>
            )}
            <ContextMenu
              target=".dx-data-row"
              dataSource={[
                {
                  text: "Jump to Person",
                },
                {
                  text: "Jump to Obligation",
                },
                {
                  text: "Jump to Monitoring",
                },
              ]}
              onItemClick={(e) => {
                if (e.itemData.text === "Jump to Person") {
                  dispatch(
                    setDynamicTab({
                      Offender: selectedRow?.sOffenderNumber,
                      title: selectedRow?.sLastFirst?.split(",")[0],
                      link: `/participant/${selectedRow?.sOffenderNumber}`
                    })
                  );
                  navigate(`/participant/${selectedRow?.sOffenderNumber}`);
                }
                if (e.itemData.text === "Jump to Obligation") {
                  jumpToObligation(selectedRow?.obligation);
                }
                if (e.itemData.text === "Jump to Monitoring") {
                  jumpToMonitoring(selectedRow?.obligation);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissingEMRates;
