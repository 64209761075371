import { DateBox, Popup, ScrollView } from "devextreme-react";
import { useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from "../../../../../../assets/images/icons/success-check.svg";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { logOut } from "../../../../../../components/Logout";
import ChangeFeePopupConfirm from "./ChangeFeePopupConfirm";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const ChangeFeePopup = ({
  changeFeePopUp,
  setChangeFeePopUp,
  data,
  feeViolationDropdown,
  toastConfig,
  setToastConfig,
  gObligationID,
  getObligation,
}) => {
  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    obligationStartDate: "",
    comments: "",
    fineAmount: 0,
    feeViolation: { name: "", id: "" },
  });
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [commentValidation, setCommentValidation] = useState("");
  const [radioValdation, setRadioValidation] = useState(false);
  const [changeFeeConfirm, setChangeFeeConfirm] = useState(false);
  const [displayAmount, setDisplayAmount] = useState("");

  const handleServiceTypeChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "keepPreviosFine") {
      setFields((prev) => ({
        ...prev,
        fineAmount: data?.fAmount ? data?.fAmount : 0,
      }));
    }
    if (event.target.value === "useDisplayed") {
      setFields((prev) => ({
        ...prev,
        fineAmount: displayAmount,
      }));
    }
    if (event.target.value === "useFineAmount") {
      setFields((prev) => ({
        ...prev,
        fineAmount: 0,
      }));
    }
    setRadioValidation("");
  };

  useEffect(() => {
    const selectedAgency = feeViolationDropdown.find(
      (item) => item?.id?.toLowerCase() === data?.gFeeScheduleFeeId?.toLowerCase()
    );
    setFields({
      obligationStartDate: data?.dActive,
      comments: "",
      fineAmount: 0,
      feeViolation: { name: selectedAgency?.name, id: selectedAgency?.id },
    });
    setCommentValidation("");
    setRadioValidation("");
    getAmountApi(data?.gFeeScheduleFeeId);
    setRadioValue("");
  }, [changeFeePopUp]);

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "comments") {
      if (fields?.comments?.charAt(0) === " ") {
        setCommentValidation("Please enter valid Comment");
      } else {
        setCommentValidation("");
      }
    }
  };

  const getAmountApi = async (gFeeScheduleId) => {
    if (!gFeeScheduleId || changeFeePopUp === false) {
      return;
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/amount-fee-schedule?gFeeScheduleFeeID=${gFeeScheduleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setDisplayAmount(val?.data?.data?.fFee);
        setFields((prev) => ({
          ...prev,
          fineAmount: val?.data?.data?.fFee,
        }));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const changeFeedata = {
    obligationId: gObligationID,
    startDate: fields?.obligationStartDate,
    feeScheduleFeeId: fields?.feeViolation?.id,
    note: fields?.comments,
    newAmount: fields?.fineAmount ? fields?.fineAmount : 0,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!fields?.comments || fields?.comments.charAt(0) === " ") {
      setCommentValidation("Please Enter Comments.");
      isValid = false;
    }
    if (!radioValue) {
      setRadioValidation("Please Select.");
      isValid = false;
    }
    if (isValid) {
      setChangeFeeConfirm(true);
    }
  };

  const saveApi = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/save-change-fee`, changeFeedata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setChangeFeePopUp(false);
        setChangeFeeConfirm(false);
        getObligation();
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Updated!",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };

  useEffect(() => {
    if (fields?.feeViolation?.id) {
      getAmountApi(fields?.feeViolation?.id);
    }
  }, [fields?.feeViolation?.id]);

  return (
    <>
      <Popup
        visible={changeFeeConfirm}
        onHiding={() => {
          setChangeFeeConfirm(false);
        }}
        showTitle={false}
        width={600}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ChangeFeePopupConfirm
          changeFeeConfirm={changeFeeConfirm}
          setChangeFeeConfirm={setChangeFeeConfirm}
          fields={fields}
          saveApi={saveApi}
        />
      </Popup>
      <div className="change-fee-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1306_381769)">
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1306_381769">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Change Fee</p>
            </div>
            <p class="popup_header_title_para">OB.4</p>
          </div>
          <svg
            onClick={() => {
              setChangeFeePopUp(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {/* <ScrollView style={{ width: "100%", height: "655px" }}> */}
          <div className="inputs-field-wrapper">
            <div className="inputs-fields-container">
              <div className="inputs-field-content">
                <p className="edit_container_label">Obligation Start Date</p>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.obligationStartDate}
                  onValueChange={(e) => {
                    setFields((prev) => ({
                      ...prev,
                      obligationStartDate: e,
                    }));
                  }}
                />
              </div>
              <div className="inputs-field-content second-inputs-field-content">
                <p className="edit_container_label">Fee / Violation</p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"feeViolation"}
                  dropDownData={feeViolationDropdown}
                />
              </div>
            </div>
            <div className="inputs-text-container" style={{ justifyContent: "initial", gap: "20px" }}>
              <div className="change-fee-check-icon">
                {radioValue === "keepPreviosFine" && <CheckIcon />}
                <p className="fee-payment-content-info-para">Pervious Fee/Fine</p>
              </div>
              <span className="fee-payment-content-info-span">{formattedPrice(data?.fAmount)}</span>
              <p className="fee-payment-content-info-para">Balance</p>
              <span className="fee-payment-content-info-span">{formattedPrice(data?.fBalance)}</span>
            </div>
            {/* <div className="inputs-text-container" style={{ justifyContent: "initial", gap: "20px" }}>
              <p className="fee-payment-content-info-para">Balance</p>
              <span className="fee-payment-content-info-span">{formattedPrice(data?.fBalance)}</span>
            </div> */}
            <div className="radio-container">
              <div className="radio-container-box">
                <input
                  type="radio"
                  name="radioValue"
                  id="useDisplayed"
                  value="useDisplayed"
                  checked={radioValue === "useDisplayed"}
                  onChange={handleServiceTypeChange}
                />
                <label htmlFor="useDisplayed" className="fee-payment-content-info-para">
                  Use displayed Violation fee
                </label>
              </div>
              <div className="radio-container-box">
                <input
                  type="radio"
                  name="radioValue"
                  id="keepPreviosFine"
                  value="keepPreviosFine"
                  checked={radioValue === "keepPreviosFine"}
                  onChange={handleServiceTypeChange}
                />
                <label htmlFor="keepPreviosFine" className="fee-payment-content-info-para">
                  Keep previous Fine amount
                </label>
              </div>
              <div className="radio-container-box">
                <input
                  type="radio"
                  name="radioValue"
                  id="useFineAmount"
                  value="useFineAmount"
                  checked={radioValue === "useFineAmount"}
                  onChange={handleServiceTypeChange}
                />
                <label htmlFor="useFineAmount" className="fee-payment-content-info-para">
                  Use fine amount below
                </label>
              </div>
              {radioValdation && <p className="error_validation">{radioValdation}</p>}
            </div>
            <div className="inputs-fields-container-center">
              <div className="inputs-field-content">
                <div className="change-fee-check-icon">
                  {radioValue === "useFineAmount" && <CheckIcon />}
                  <label className="edit_container_label">Fine amount</label>
                </div>
                <input
                  type="number"
                  name="fineAmount"
                  id="fineAmount"
                  placeholder="Fine Amount"
                  className="fee-payment-content-info-input"
                  value={fields.fineAmount}
                  onChange={handleInputChange}
                  disabled={radioValue === "useDisplayed" || radioValue === "keepPreviosFine"}
                />
              </div>
            </div>
            <div className="edit_container_second edit_content_flex">
              <p className="edit_container_label">
                Please add a detailed comment
                <span className="marks_mandatary">*</span>
              </p>
              <textarea
                name="comments"
                id="comments"
                placeholder="Add your comment here"
                className="dark-mode-textarea"
                value={fields.comments}
                onChange={handleInputChange}
              ></textarea>
              {commentValidation && <p className="error_validation">{commentValidation}</p>}
            </div>
            <div className="popup_buttons">
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setChangeFeePopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        {/* </ScrollView> */}
      </div>
    </>
  );
};
