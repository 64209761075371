import { useEffect, useState } from "react";
import { DateBox } from "devextreme-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { handleGetLocal } from "../../../services/auth-service";
import { Toster } from "../../../components/Toster/Toster";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled, value }) => {
    return (
        <>
            <ColumnDropdown
                data={dropDownData}
                setfields={setfields}
                fields={fields}
                fieldNmae={fieldNmae}
                value={fields[fieldNmae]?.name || value}
                reset={reset}
                disabled={disabled}
            />
        </>
    );
};

function InsertDepositInfoPopup({ setInsertDepositInfoPopup, selectedObjects }) {
    const [field, setFields] = useState({
        "bankAccount": { name: selectedObjects?.sBankAccountName || '', id: selectedObjects?.gBankAccountID || ''},
    });
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: "",
        message: "",
    });
    const pathname = handleGetLocal("pathURL");
    const navigate = useNavigate();
    const [dateSelected, setDateSelected] = useState(new Date().toISOString().slice(0, 10));
    const [depositReference, setDepositReference] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);

    const sendDepositInfo = async () => {
        let paymentData = selectedObjects.map(item => item?.obj);
        let data = {
            depositAmount: selectedObjects.reduce((sum, obj) => sum + (obj.Amount || 0), 0),
            depositReference: depositReference,
            depositDate: dateSelected,
            paymentList: paymentData,
            bankAccountId: field?.bankAccount?.id
        }
        await axios
            .post(`${process.env.REACT_APP_API_URL}/api/Financial/insert-deposit-info`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${pathname}`,
                },
            })
            .then((val) => {
                setInsertDepositInfoPopup(false);
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Successfully Added!",
                    type: "success",
                });
                setTimeout(() => {
                    setToastConfig({
                        ...toastConfig,
                        isVisible: false,
                        message: "Successfully Added!",
                        type: "success",
                    });
                }, 1400);
            })
            .catch((val) => {
                setInsertDepositInfoPopup(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status === 401) {
                    logOut();
                }
            });
    }

    const getFeeScheduleDropdownData = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/api/feeschedule/feeschedule-dropdown`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${pathname}`,
                },
            })
            .then((val) => {
                setBankAccounts(
                    val?.data?.data?.bankAccount?.map((val) => {
                        return {
                            name: val?.value,
                            id: val?.key,
                        };
                    })
                );
            })
            .catch((val) => {
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status === 401) {
                    logOut();
                }
            });
    };

    useEffect(() => {
        getFeeScheduleDropdownData();
    }, []);

    return (
        <div>
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <div className="change-fee-popup" style={{ height: '450px' }}>
                <div className="popup_header">
                    <div className="popup_header_title">
                        <div class="popup_header_title_data">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_1306_381769)">
                                    <path
                                        d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                                        fill="#fff"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1306_381769">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p>Insert Deposit Info</p>
                        </div>
                        <p class="popup_header_title_para">PG.3</p>
                    </div>
                    <svg
                        onClick={() => {
                            setInsertDepositInfoPopup(false);
                        }}
                        style={{
                            cursor: "pointer",
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div className="inputs-field-wrapper">
                    <div className="display-flex-div edit_content_space">
                        <label className="edit_container_label fixed-width-lable">Item Count</label>
                        <b>{selectedObjects.length}</b>
                    </div>
                    <div className="display-flex-div edit_content_space">
                        <label className="edit_container_label fixed-width-lable">Amount</label>
                        <b>${selectedObjects.reduce((sum, obj) => sum + (obj.Amount || 0), 0)}</b>
                    </div>
                    <div className="display-flex-div edit_content_space">
                        <label className="edit_container_label fixed-width-lable">Deposit Date</label>
                        <DateBox
                            useMaskBehavior={true}
                            value={dateSelected}
                            onValueChange={(e) => {
                                setDateSelected(e)
                            }}
                        />
                    </div>
                    <div className="display-flex-div edit_content_space">
                        <label className="edit_container_label fixed-width-lable">Deposit Reference</label>
                        <input
                            onChange={(e) => setDepositReference(e.target.value)}
                            style={{
                                width: "33%",
                                border: "1px solid #bab8b8",
                                borderRadius: "3px",
                                height: "35px",
                            }}
                        />
                    </div>
                    <div className="display-flex-div edit_content_space">
                        <label className="edit_container_label fixed-width-lable">Deposit Amount</label>
                        <input
                            disabled
                            value={selectedObjects.reduce((sum, obj) => sum + (obj.Amount || 0), 0)}
                            style={{
                                width: "33%",
                                border: "1px solid #bab8b8",
                                borderRadius: "3px",
                                height: "35px",
                            }} type={'number'} />
                    </div>
                    <div className="display-flex-div edit_content_space">

                        <label className="edit_container_label fixed-width-lable">Bank Account</label>
                        <DropDown
                            setfields={setFields}
                            fields={field}
                            fieldNmae={"bankAccount"}
                            dropDownData={bankAccounts}
                            disabled={true}
                            value={selectedObjects[0]?.sBankAccountName}
                        />
                    </div>
                    <div className="popup_buttons">
                        <button className="btn primary_btn" onClick={sendDepositInfo}>
                            Ok
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                setInsertDepositInfoPopup(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InsertDepositInfoPopup;