import { LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../components/Toster/Toster";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MonitoringFunction } from "./MonitoringFunction";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { referralTableColumn } from "./Data";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import { useEffect } from "react";
import ReferralPreview from "./Popups/ReferralPreview";
import RemoveFromList from "./Popups/RemoveFromList";
import PersonModal from "../PersonFind/PersonModal";
import LinkPersonReferralConfirm from "./Popups/LinkPersonReferralConfirm";
import CreateNewPersonReferral from "./Popups/CreateNewPersonReferral";
import NoteConfirmationReferral from "./Popups/NoteConfirmationReferral";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const MonitoringReferral = () => {
  const [referralData, setReferralData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const [referralPreviewPopup, setReferralPreviewPopup] = useState(false);
  const [monitoringReferralRowData, setMonitoringReferralRowData] = useState([]);
  const [removeFromListPopup, setRemoveFromListPopup] = useState(false);
  const [linkPersonReferral, setLinkPersonReferral] = useState(false);
  const [linkPersonReferralConfirmPopup, setLinkPersonReferralConfirmPopup] = useState(false);
  const [linkPersonRowData, setLinkPersonRowData] = useState([]);
  const [formsPageMenu, setFormsPageMenu] = useState(true);
  const [createNewPersonPopup, setCreateNewPersonPopup] = useState(false);
  const [noteConfirmationPopup, setNoteConfirmationPopup] = useState(false);

  const refreshApi = () => {
    ApiClass.getMonitoringReferralData(setLoading, navigate, setReferralData, setErrorhandling);
  };
  useEffect(() => {
    refreshApi();
  }, []);

  const ProcessScheduleChangeapi = (
    GReferralId,
    IFormId,
    IEntryNo,
    SFormName,
    SLastName,
    SFirstName,
    SMiddleName,
    DDob,
    SAddress1,
    SAddress2,
    SCity,
    SState,
    SZip,
    SHomePhone,
    SCellPhone,
    DDoe,
    SStatus,
    GPersonId,
    SEntryData,
    SAttachmentName,
    SAttachmentUrl,
    SRecordType,
    SAgent,
    SAgentEmail,
    SAgentPhone,
    SOffenderNumber,
    SLastFirst,
    SGpmEntityName,
    SPrimarySupervisorName,
    SCompany,
    BAttachment
  ) => {
    ApiClass?.ProcessScheduleChange(
      setLoading,
      navigate,
      setErrorhandling,
      GReferralId,
      IFormId,
      IEntryNo,
      SFormName,
      SLastName,
      SFirstName,
      SMiddleName,
      DDob,
      SAddress1,
      SAddress2,
      SCity,
      SState,
      SZip,
      SHomePhone,
      SCellPhone,
      DDoe,
      SStatus,
      GPersonId,
      SEntryData,
      SAttachmentName,
      SAttachmentUrl,
      SRecordType,
      SAgent,
      SAgentEmail,
      SAgentPhone,
      SOffenderNumber,
      SLastFirst,
      SGpmEntityName,
      SPrimarySupervisorName,
      SCompany,
      BAttachment,
      setToastConfig,
      toastConfig,
      refreshApi
    );
  };

  const HandleClose = () => {
    if (setFormsPageMenu) {
      setFormsPageMenu(true);
    }
    setLinkPersonReferral(false);
  };

  const processEndMonitoringData = {
    GReferralId: monitoringReferralRowData?.gReferralId,
    IFormId: monitoringReferralRowData?.[`Form Id`],
    IEntryNo: monitoringReferralRowData?.[`Entry No`],
    SFormName: monitoringReferralRowData?.Form,
    SLastName: monitoringReferralRowData?.[`Last Name`],
    SFirstName: monitoringReferralRowData?.[`First Name`],
    SMiddleName: monitoringReferralRowData?.[`Middle Name`],
    DDob: monitoringReferralRowData?.DOB,
    SAddress1: monitoringReferralRowData?.SAddress1,
    SAddress2: monitoringReferralRowData?.sAddress2,
    SCity: monitoringReferralRowData?.sCity,
    SState: monitoringReferralRowData?.sState,
    SZip: monitoringReferralRowData?.sZip,
    SHomePhone: monitoringReferralRowData?.sHomePhone,
    SCellPhone: monitoringReferralRowData?.sCellPhone,
    DDoe: monitoringReferralRowData?.[`Entry Date`],
    SStatus: monitoringReferralRowData?.Status,
    GPersonId: monitoringReferralRowData?.gPersonId,
    SEntryData: monitoringReferralRowData?.sEntryData,
    SAttachmentName: monitoringReferralRowData?.sAttachmentName,
    SAttachmentUrl: monitoringReferralRowData?.sAttachmentUrl,
    SRecordType: monitoringReferralRowData?.sRecordType,
    SAgent: monitoringReferralRowData?.sAgent,
    SAgentEmail: monitoringReferralRowData?.sAgentEmail,
    SAgentPhone: monitoringReferralRowData?.sAgentPhone,
    SOffenderNumber: monitoringReferralRowData?.sOffenderNumber,
    SLastFirst: monitoringReferralRowData?.[`Last First`],
    SGpmEntityName: monitoringReferralRowData?.sGpmEntityName,
    SPrimarySupervisorName: monitoringReferralRowData?.Supervisor,
    SCompany: monitoringReferralRowData?.Company,
    BAttachment: monitoringReferralRowData?.bAttachment,
  };

  const processEndMonitoringApi = () => {
    if (processEndMonitoringData?.GReferralId) {
      ApiClass.saveProcessEndMonitoring(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        refreshApi,
        processEndMonitoringData
      );
    }
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={referralPreviewPopup}
        onHiding={() => {
          setReferralPreviewPopup(false);
        }}
        showTitle={false}
        width={768}
        height={620}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ReferralPreview
          referralPreviewPopup={referralPreviewPopup}
          setReferralPreviewPopup={setReferralPreviewPopup}
          monitoringReferralRowData={monitoringReferralRowData}
        />
      </Popup>
      <Popup
        visible={removeFromListPopup}
        onHiding={() => {
          setRemoveFromListPopup(false);
        }}
        showTitle={false}
        width={450}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RemoveFromList
          removeFromListPopup={removeFromListPopup}
          setRemoveFromListPopup={setRemoveFromListPopup}
          monitoringReferralRowData={monitoringReferralRowData}
          navigate={navigate}
          setLoading={setLoading}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
        />
      </Popup>
      <Popup
        visible={linkPersonReferral}
        onHiding={HandleClose}
        showTitle={false}
        width="90%"
        height={634}
        showCloseButton={true}
        hideOnOutsideClick={true}
        onShown={() => {
          document.querySelector("#TESTE").focus();
        }}
      >
        <PersonModal
          setShowPersonModal={setLinkPersonReferral}
          nojump={false}
          formjump={true}
          setConfirmationPopup={setLinkPersonReferralConfirmPopup}
          setConfirmationData={setLinkPersonRowData}
          setFormsPageMenu={setFormsPageMenu}
        />
      </Popup>
      <Popup
        visible={linkPersonReferralConfirmPopup}
        onHiding={() => {
          setLinkPersonReferralConfirmPopup(false);
        }}
        showTitle={false}
        width={650}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <LinkPersonReferralConfirm
          linkPersonReferralConfirmPopup={linkPersonReferralConfirmPopup}
          setLinkPersonReferralConfirmPopup={setLinkPersonReferralConfirmPopup}
          monitoringReferralRowData={monitoringReferralRowData}
          navigate={navigate}
          setLoading={setLoading}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
          linkPersonRowData={linkPersonRowData}
          setLinkPersonReferral={setLinkPersonReferral}
        />
      </Popup>
      <Popup
        visible={createNewPersonPopup}
        onHiding={() => {
          setCreateNewPersonPopup(false);
        }}
        showTitle={false}
        width={700}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CreateNewPersonReferral
          createNewPersonPopup={createNewPersonPopup}
          setCreateNewPersonPopup={setCreateNewPersonPopup}
          monitoringReferralRowData={monitoringReferralRowData}
          setNoteConfirmationPopup={setNoteConfirmationPopup}
          navigate={navigate}
          setLoading={setLoading}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
        />
      </Popup>
      <Popup
        visible={noteConfirmationPopup}
        onHiding={() => {
          setNoteConfirmationPopup(false);
        }}
        showTitle={false}
        width={480}
        height={150}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <NoteConfirmationReferral
          noteConfirmationPopup={noteConfirmationPopup}
          setNoteConfirmationPopup={setNoteConfirmationPopup}
          monitoringReferralRowData={monitoringReferralRowData}
        />
      </Popup>
      <MonitoringCrumbs title={"Referral"} />
      <div className="financial-page">
        {/* <MonitoringTabs /> */}
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">EM.25</p>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  refreshApi();
                  setErrorhandling(false);
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <Table1
                editTable={false}
                height={"auto"}
                Tablecoloum={referralTableColumn}
                tableRow={referralData}
                load={loading}
                coloumWidthText={"Monitoring Referral"}
                mandatoryColoum={"Company"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={27}
                exporttitle={"Monitoring Referral"}
                monitoringReferralMenu={formsPageMenu}
                setReferralPreviewPopup={setReferralPreviewPopup}
                setMonitoringReferralRowData={setMonitoringReferralRowData}
                monitoringReferralRowData={monitoringReferralRowData}
                setRemoveFromListPopup={setRemoveFromListPopup}
                setLinkPersonReferral={setLinkPersonReferral}
                setFormsPageMenu={setFormsPageMenu}
                setCreateNewPersonPopup={setCreateNewPersonPopup}
                ProcessScheduleChangeapi={ProcessScheduleChangeapi}
                processEndMonitoringApi={processEndMonitoringApi}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MonitoringReferral;
