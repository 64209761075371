import { DropDownBox, Popup, ScrollView } from "devextreme-react";
import "./recipientsDisbursements.css";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { logOut } from "../../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import DataGrid, { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import SaveAddRecipients from "./SaveAddRecipients";
import { GoTOObligationFunction } from "../../GoTOObligationFunction";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const AddRecipients = ({
  addRecipientsPopup,
  setAddRecipientsPopup,
  recipientsType,
  recipientsRowData,
  recipientsDropdownData,
  toastConfig,
  setToastConfig,
  refreshGetRecipientsTableData,
  sRecipientCategoryName,
  recipientsPaymentDropdownData,
  recipientsEntityDropdownData,
  gObligationID,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    recipientsCategory: "",
    disburseFrequency: { name: "", id: "" },
    disburseMethod: { name: "", id: "" },
    disbursePerctange: 0,
    disbursePerctangeLabel: 0,
    disburseFundsAutomatically: false,
    recipientInformationDropdown: { name: "", id: "" },
    firstName: "",
    lastCompany: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    zipCode: "",
    country: "",
    commentsPhone: "",
  });
  const [serviceType, setServiceType] = useState("entity");
  const [disbursePerctangeValidation, setDisbursePerctangeValidation] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    "Account Name": "",
    "Account Number": "",
    gBankAccountId: "",
    gBankId: "",
    gEntryUserId: "",
  });
  const [selectedEntityValue, setSelectedEntityValue] = useState({
    Entity: "",
    Type: "",
    "Address?": "",
    gEntityId: "",
    gReportingEntityId: "",
  });
  const [bankAccountValidation, setBankAccountValidation] = useState("");
  const [entityValadation, setEntityValadation] = useState("");
  const [recipientValadation, setRecipientValadation] = useState("");
  const [lastCompanyValadation, setLastCompanyValadation] = useState("");
  const [addressLine1Valadation, setAddressLine1Valadation] = useState("");
  const [saveAddRecipientsPopup, setSaveAddRecipientsPopup] = useState(false);
  const apiclass = new GoTOObligationFunction();
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isGridBoxOpenedEntity, setIsGridBoxOpenedEntity] = useState(false);

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
    setFields((prev) => ({
      ...prev,
      recipientInformationDropdown: { name: "", id: "" },
      firstName: "",
      lastCompany: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      zipCode: "",
      country: "",
      commentsPhone: "",
    }));
    setSelectedEntityValue({
      Entity: "",
      Type: "",
      "Address?": "",
      gEntityId: "",
      gReportingEntityId: "",
    });
    setEntityValadation("");
    setRecipientValadation("");
    setLastCompanyValadation("");
    setAddressLine1Valadation("");
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "disbursePerctange") {
      if (parseFloat(value) > parseFloat(fields?.disbursePerctangeLabel)) {
        setDisbursePerctangeValidation(`The total disburse percentage may not exceed to
        ${fields?.disbursePerctangeLabel}`);
      } else {
        setDisbursePerctangeValidation("");
      }
    } else if (name === "lastCompany") {
      setLastCompanyValadation("");
    } else if (name === "addressLine1") {
      setAddressLine1Valadation("");
    }
  };

  useEffect(() => {
    if (fields?.recipientInformationDropdown?.id) {
      apiclass.getRecipientsInputsAddressData(
        setLoading,
        navigate,
        fields?.recipientInformationDropdown?.id,
        setFields
      );
    }
  }, [fields?.recipientInformationDropdown?.id]);

  useEffect(() => {
    setDisbursePerctangeValidation("");
    setBankAccountValidation("");
    setEntityValadation("");
    setRecipientValadation("");
    setLastCompanyValadation("");
    setAddressLine1Valadation("");
    if (recipientsType === "Add") {
      setFields({
        recipientsCategory: sRecipientCategoryName,
        disburseFrequency: {
          name: recipientsDropdownData?.disburseFrequency?.[0]?.value,
          id: recipientsDropdownData?.disburseFrequency?.[0]?.key,
        },
        disburseMethod: {
          name: recipientsDropdownData?.disburseMethod?.[1]?.value,
          id: recipientsDropdownData?.disburseMethod?.[1]?.key,
        },
        disbursePerctange: 0,
        disbursePerctangeLabel: 0,
        disburseFundsAutomatically: false,
        recipientInformationDropdown: { name: "", id: "" },
        firstName: "",
        lastCompany: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        zipCode: "",
        country: "",
        commentsPhone: "",
      });
      setServiceType("entity");
      setSelectedValue({
        "Account Name": "",
        "Account Number": "",
        gBankAccountId: "",
        gBankId: "",
        gEntryUserId: "",
      });
      setSelectedEntityValue({
        Entity: "",
        Type: "",
        "Address?": "",
        gEntityId: "",
        gReportingEntityId: "",
      });
    } else {
      const findKeyById = (id, data) => {
        const item = data?.find((item) => item?.key?.toLowerCase() === id);
        return item ? item.value : "";
      };
      const recipientInformationDropdownName = findKeyById(
        recipientsRowData?.gRestitutionRecipientId,
        recipientsDropdownData?.restitutionRecipient
      );
      setFields((prev) => ({
        ...prev,
        recipientsCategory: recipientsRowData?.[`Recipient Category`],
        disburseFrequency: {
          name: recipientsRowData?.[`Disburse Frequency`],
          id: recipientsRowData?.gDisburseFrequencyId,
        },
        disburseMethod: {
          name: recipientsRowData?.[`Disburse Method`],
          id: recipientsRowData?.gDisburseMethodId,
        },
        disbursePerctange: recipientsRowData?.[`D %`],
        disbursePerctangeLabel: recipientsRowData?.[`D %`],
        disburseFundsAutomatically: recipientsRowData?.bAuto,
        recipientInformationDropdown: {
          name: recipientInformationDropdownName,
          id: recipientsRowData?.gRestitutionRecipientId,
        },
        firstName: recipientsRowData?.sRecipientDisbursementName,
        lastCompany: "",
        addressLine1: recipientsRowData?.sRecipientDisbursement_Address1,
        addressLine2: recipientsRowData?.sRecipientDisbursement_Address2,
        city: recipientsRowData?.sRecipientDisbursement_CityName,
        state: recipientsRowData?.sRecipientDisbursement_State,
        zip: recipientsRowData?.sRecipientDisbursement_Zip,
        zipCode: recipientsRowData?.sRecipientDisbursement_Zip4,
        country: "",
        commentsPhone: "",
      }));
      const selectedDocket = recipientsPaymentDropdownData?.find(
        (item) => item.gBankAccountId === recipientsRowData?.gBankAccountId
      );
      const selectedEntity = recipientsEntityDropdownData?.find(
        (item) => item.gEntityId === recipientsRowData?.gEntityId
      );
      setSelectedValue(selectedDocket);
      setSelectedEntityValue(selectedEntity);
      if (recipientsRowData?.gEntityId && !recipientsRowData?.gRestitutionRecipientId) {
        setServiceType("entity");
      } else if (recipientsRowData?.gRestitutionRecipientId && !recipientsRowData?.gEntityId) {
        apiclass.getRecipientsInputsAddressData(
          setLoading,
          navigate,
          recipientsRowData?.gRestitutionRecipientId,
          setFields
        );
        setServiceType("recipient");
      } else {
        setServiceType("addNewRecipient");
      }
    }
  }, [addRecipientsPopup]);
  // console.log(fields);

  const data = {
    GDisburseId: recipientsType === "Add" ? "00000000-0000-0000-0000-000000000000" : recipientsRowData?.gDisburseId,
    GEntryUserId: localStorage.getItem("gUserId"),
    GObligationId: gObligationID,
    GDisburseFrequencyId: fields?.disburseFrequency?.id,
    GDisburseMethodId: fields?.disburseMethod?.id,
    GEntityId: serviceType === "recipient" || serviceType === "addNewRecipient" ? null : selectedEntityValue?.gEntityId,
    iDisbursePercent: fields?.disbursePerctange,
    BAuto: fields?.disburseFundsAutomatically ? 1 : 0,
    GBankAccountId: selectedValue?.gBankAccountId,
    GRestitutionRecipientId: null,
    GDisburseRecipientId:
      serviceType === "entity" || serviceType === "addNewRecipient" ? null : fields?.recipientInformationDropdown?.id,
    NewRecipient:
      serviceType === "recipient" || serviceType === "addNewRecipient"
        ? {
            GRestitutionRecipientId:
              recipientsType === "Add"
                ? "00000000-0000-0000-0000-000000000000"
                : recipientsRowData?.gRestitutionRecipientId
                ? recipientsRowData?.gRestitutionRecipientId
                : "00000000-0000-0000-0000-000000000000",
            GEntryUserId: localStorage.getItem("gUserId"),
            SFirstName: fields?.firstName,
            SLastName: fields?.lastCompany,
            SAddress1: fields?.addressLine1,
            SAddress2: fields?.addressLine2,
            SState: fields?.state,
            SZip: fields?.zip,
            SZip4: fields?.zipCode,
            SAddressComment: fields?.commentsPhone,
            SCity: fields?.city,
            SCounty: fields?.country,
          }
        : null,
  };

  const handleSave = () => {
    let isValid = true;
    if (parseFloat(fields?.disbursePerctange) > parseFloat(fields?.disbursePerctangeLabel)) {
      setDisbursePerctangeValidation(`The total disburse percentage may not exceed to
      ${fields?.disbursePerctangeLabel}`);
      isValid = false;
    }
    if (selectedValue?.gBankAccountId === "") {
      setBankAccountValidation("Please Select Bank Account");
      isValid = false;
    }
    if (serviceType === "entity" && selectedEntityValue?.gEntityId === "") {
      setEntityValadation("Please Select Entity");
      isValid = false;
    }
    if (serviceType === "recipient" && fields?.recipientInformationDropdown?.id === "") {
      setRecipientValadation("Please Select Recipient");
      isValid = false;
    }
    if ((serviceType === "recipient" || serviceType === "addNewRecipient") && !fields?.addressLine1) {
      setAddressLine1Valadation("Please Enter Address Line 1");
      isValid = false;
    }
    if ((serviceType === "recipient" || serviceType === "addNewRecipient") && !fields?.lastCompany) {
      setLastCompanyValadation("Please Enter Last/Company");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      if (fields?.disburseFrequency?.name === "Hold in Trust") {
        setSaveAddRecipientsPopup(true);
      } else {
        saveApi();
      }
    }
  };

  const saveApi = async () => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/add-edit-recipient`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setAddRecipientsPopup(false);
          setSaveAddRecipientsPopup(false);
          refreshGetRecipientsTableData();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val.data.message,
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val.data.message,
              type: "success",
            });
          }, 1900);
          setLoading(false);
        } else {
          console.error(val?.data?.Message);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);
  const onGridBoxOpenedEntity = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpenedEntity(e.value);
    }
  }, []);

  return (
    <>
      <Popup
        visible={saveAddRecipientsPopup}
        onHiding={() => {
          setSaveAddRecipientsPopup(false);
        }}
        showTitle={false}
        width={680}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SaveAddRecipients
          saveAddRecipientsPopup={saveAddRecipientsPopup}
          setSaveAddRecipientsPopup={setSaveAddRecipientsPopup}
          saveApi={saveApi}
        />
      </Popup>
      <div className="add-Recipients-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1306_381769)">
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1306_381769">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Disburse Information</p>
            </div>
            <p class="popup_header_title_para">OB.6</p>
          </div>
          <svg
            onClick={() => {
              setAddRecipientsPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ width: "100%", height: "540px" }}>
          <div className="add-Recipients-container">
            <div className="card-container">
              <p className="card-container-header">Disburse Information</p>
              <div className="card-container-header-content">
                <div className="card-content-info">
                  <span className="fee-payment-content-info-para">Recipients Category</span>
                  <span className="fee-payment-content-info-span">{fields?.recipientsCategory}</span>
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">Disburse Frequency</p>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"disburseFrequency"}
                      dropDownData={recipientsDropdownData?.disburseFrequency?.map((vall) => {
                        return {
                          name: vall?.value,
                          id: vall?.key,
                        };
                      })}
                    />
                    <p className="fee-payment-content-info-para" style={{ width: "270px" }}>
                      Be sure to check/change the Payment Alert when changing the Disburse Frequency.
                    </p>
                  </div>
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">Disburse Method</p>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"disburseMethod"}
                      dropDownData={recipientsDropdownData?.disburseMethod?.map((vall) => {
                        return {
                          name: vall?.value,
                          id: vall?.key,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content">
                    <p className="edit_container_label">Disburse %</p>
                    <div className="input-flex">
                      <input
                        type="number"
                        name="disbursePerctange"
                        id="disbursePerctange"
                        value={fields?.disbursePerctange}
                        className="fee-payment-content-info-input"
                        style={{ width: "276px" }}
                        onChange={handleInputChange}
                      />
                      <p className="fee-payment-content-info-span">{fields?.disbursePerctangeLabel}% available</p>
                    </div>
                    {disbursePerctangeValidation && <p className="error_validation">{disbursePerctangeValidation}</p>}
                  </div>
                </div>
                <div className="input-flex">
                  <input
                    type="checkbox"
                    name="disburseFundsAutomatically"
                    id="disburseFundsAutomatically"
                    checked={fields.disburseFundsAutomatically}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="disburseFundsAutomatically" className="fee-payment-content-info-span">
                    Disburse Funds Automatically
                  </label>
                </div>
              </div>
            </div>
            <div className="card-container" style={{ width: "600px" }}>
              <p className="card-container-header">Recipient Information</p>
              <div className="card-container-header-content">
                <div className="inputs-fields-container">
                  <div className="inputs-field-content">
                    <p className="edit_container_label">
                      Bank Account <span className="marks_mandatary">*</span>
                    </p>
                    <DropDownBox
                      placeholder="Select..."
                      style={{ width: "380px" }}
                      dataSource={recipientsPaymentDropdownData}
                      value={selectedValue?.[`Account Number`]}
                      valueExpr={"Account Number"}
                      displayExpr={"Account Number"}
                      opened={isGridBoxOpened}
                      onOptionChanged={onGridBoxOpened}
                      contentRender={(value, element) => (
                        <DataGrid
                          dataSource={recipientsPaymentDropdownData}
                          hoverStateEnabled={true}
                          height={"auto"}
                          onRowClick={(e) => {
                            setSelectedValue(e?.data);
                            setBankAccountValidation("");
                            setIsGridBoxOpened(false);
                          }}
                        >
                          <Selection mode="single" />
                          <FilterRow visible={true} />
                          <Column dataField="Account Name" caption="Account Name" width={"auto"} />
                          <Column dataField="Account Number" caption="Account Number" width={"auto"} />
                        </DataGrid>
                      )}
                    />
                    {bankAccountValidation && <p className="error_validation">{bankAccountValidation}</p>}
                  </div>
                </div>
                <div className="input-flex-dropdown dropdown-inputs-field-content">
                  <main className="input-flex">
                    <input
                      type="radio"
                      name="entity"
                      id="entity"
                      value="entity"
                      checked={serviceType === "entity"}
                      onChange={handleServiceTypeChange}
                    />
                    <label htmlFor="entity" className="fee-payment-content-info-span">
                      Entity <span className="marks_mandatary">*</span>
                    </label>
                  </main>
                  <DropDownBox
                    disabled={serviceType === "recipient" || serviceType === "addNewRecipient"}
                    placeholder="Select..."
                    dataSource={recipientsEntityDropdownData}
                    value={selectedEntityValue?.Entity}
                    valueExpr={"Entity"}
                    displayExpr={"Entity"}
                    opened={isGridBoxOpenedEntity}
                    onOptionChanged={onGridBoxOpenedEntity}
                    contentRender={(value, element) => (
                      <DataGrid
                        dataSource={recipientsEntityDropdownData}
                        hoverStateEnabled={true}
                        height={"auto"}
                        onRowClick={(e) => {
                          setSelectedEntityValue(e?.data);
                          setEntityValadation("");
                          setIsGridBoxOpenedEntity(false);
                        }}
                      >
                        <Selection mode="single" />
                        <FilterRow visible={true} />
                        <Column dataField="Entity" caption="Entity" width={"auto"} />
                        <Column dataField="Type" caption="Type" width={"auto"} />
                        <Column dataField="Address?" caption="Address?" width={"auto"} />
                      </DataGrid>
                    )}
                  />
                  {entityValadation && <p className="error_validation">{entityValadation}</p>}
                </div>
                <div className="input-flex-dropdown dropdown-inputs-field-content">
                  <main className="input-flex">
                    <input
                      type="radio"
                      name="recipient"
                      id="recipient"
                      value="recipient"
                      checked={serviceType === "recipient"}
                      onChange={handleServiceTypeChange}
                    />
                    <label htmlFor="recipient" className="fee-payment-content-info-span">
                      Recipient
                      {serviceType === "recipient" && <span className="marks_mandatary">*</span>}
                    </label>
                  </main>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"recipientInformationDropdown"}
                    dropDownData={recipientsDropdownData?.restitutionRecipient?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                    disabled={serviceType === "entity" || serviceType === "addNewRecipient"}
                    reset={setRecipientValadation}
                  />
                  {recipientValadation && <p className="error_validation">{recipientValadation}</p>}
                </div>
                <div className="input-flex-dropdown dropdown-inputs-field-content">
                  <main className="input-flex">
                    <input
                      type="radio"
                      name="addNewRecipient"
                      id="addNewRecipient"
                      value="addNewRecipient"
                      checked={serviceType === "addNewRecipient"}
                      onChange={handleServiceTypeChange}
                    />
                    <label htmlFor="addNewRecipient" className="fee-payment-content-info-span">
                      Add New Recipient
                    </label>
                  </main>
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">First Name</p>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">
                      Last/Company
                      {(serviceType === "recipient" || serviceType === "addNewRecipient") && (
                        <span className="marks_mandatary">*</span>
                      )}
                    </p>
                    <input
                      type="text"
                      name="lastCompany"
                      id="lastCompany"
                      placeholder="Last/Company"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.lastCompany}
                      onChange={handleInputChange}
                    />
                    {lastCompanyValadation && <p className="error_validation">{lastCompanyValadation}</p>}
                  </div>
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">
                      Address Line 1
                      {(serviceType === "recipient" || serviceType === "addNewRecipient") && (
                        <span className="marks_mandatary">*</span>
                      )}
                    </p>
                    <input
                      type="text"
                      name="addressLine1"
                      id="addressLine1"
                      placeholder="Address Line 1"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.addressLine1}
                      onChange={handleInputChange}
                    />
                    {addressLine1Valadation && <p className="error_validation">{addressLine1Valadation}</p>}
                  </div>
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">Address Line 2</p>
                    <input
                      type="text"
                      name="addressLine2"
                      id="addressLine2"
                      placeholder="Address Line 2"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.addressLine2}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">City</p>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">State</p>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      placeholder="State"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.state}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="input-flex dropdown-inputs-field-content">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">Zip</p>
                    <input
                      type="number"
                      name="zip"
                      id="zip"
                      placeholder="Zip"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.zip}
                      onChange={handleInputChange}
                    />
                  </div>
                  <input
                    type="number"
                    name="zipCode"
                    id="zipCode"
                    className="zip-info-input"
                    disabled={serviceType === "entity"}
                    value={fields?.zipCode}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">Country</p>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      placeholder="Country"
                      className="fee-payment-content-info-input"
                      disabled={serviceType === "entity"}
                      value={fields?.country}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="inputs-fields-container">
                  <div className="inputs-field-content dropdown-inputs-field-content">
                    <p className="edit_container_label">Comment/Phone</p>
                    <textarea
                      name="commentsPhone"
                      id="commentsPhone"
                      placeholder="Comment/Phone"
                      className="dark-mode-textarea"
                      disabled={serviceType === "entity"}
                      style={{ width: "578px" }}
                      value={fields?.commentsPhone}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="popup_buttons">
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setAddRecipientsPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
