import React, { useEffect } from "react";
import FinancialCrumbs from "./components/FinancialCrumbs";
import "./financialPage.css";
import { DateBox, LoadPanel, Popup, SelectBox } from "devextreme-react";
import { useState } from "react";
import axios from "axios";
import { logOut } from "../../components/Logout";
import { Toster } from "../../components/Toster/Toster";
import { schedulingDateRanges, setTransactionlistTableData, receiptListTableTitle } from "./Data";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { FinancialFunction } from "./FinancialFunction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { getAccessData } from "../../utils/getAccessPermission";
import InsertDepositInfoPopup from "./Popups/InsertDepositInfoPopup";
import { handleGetLocal } from "../../services/auth-service";

const TransactionList = () => {
  const pathname = handleGetLocal("pathURL");
  const { transactionListDate } = useSelector((store) => store.AdminTable);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const { userAccessData } = useSelector((store) => store.Home);
  const [loading, setLoading] = useState(false);
  const [disbursementErrorHandling, setDisbursementErrorHandling] = useState(false);
  const [receiptListData, setReceiptListData] = useState([]);
  const [selectedObjects, setselectedObjects] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [insertDepositInfoPopup, setInsertDepositInfoPopup] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [date, setdate] = useState({
    startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const today = new Date();
  const [quickDatevalue, setquickDatevalue] = useState("Month to Date");
  const ApiClass = new FinancialFunction();
  const navigate = useNavigate();

  const refreshApi = () => {
    if (date.endDate !== "Invalid Date" && date.startDate !== "Invalid Date") {
      ApiClass.getReceiptlistData(
        setLoading,
        navigate,
        setReceiptListData,
        setDisbursementErrorHandling,
        date
      );
    }
  };

  const validateInsertDepositInfo = async () => {
    let paymentData = selectedObjects.map(item => item?.obj);
    let data = {
      paymentList: paymentData,
      depositAmount: selectedObjects.reduce((sum, obj) => sum + (obj.Amount || 0), 0),
      depositDate: new Date().toISOString().slice(0, 10),
      gBankAccountID: selectedObjects[0].gBankAccountID
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Financial/validate-insert-deposit-info`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.success) {
          setInsertDepositInfoPopup(true);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Invalid parameters!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "",
              type: "",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setInsertDepositInfoPopup(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  useEffect(() => {
    if (!transactionListDate?.startDate && !transactionListDate?.startDate) {
      refreshApi();
    } else {
      setdate(transactionListDate);
      ApiClass.getReceiptlistData(
        setLoading,
        navigate,
        setTransactionlistTableData,
        setDisbursementErrorHandling,
        transactionListDate
      );
    }
  }, [date]);

  useEffect(() => {
    const filteredData = receiptListData.filter(item =>
      selectedRow.includes(item['Offender #'])
    );
    setselectedObjects(filteredData)
  }, [selectedRow]);

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "DC4");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
  }, []);

  return (
    <>
      <Popup
        visible={insertDepositInfoPopup}
        onHiding={() => {
          setInsertDepositInfoPopup(false);
        }}
        showTitle={false}
        width={650}
        height={455}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div>
          <InsertDepositInfoPopup
            setInsertDepositInfoPopup={setInsertDepositInfoPopup}
            selectedObjects={selectedObjects}
          />
        </div>
      </Popup>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <FinancialCrumbs title={"Receipt List"} />
      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">DP.4</p>
          {viewAccess ? (
            <div
              style={{
                pointerEvents: editAccess || addAccess ? "auto" : "none",
                opacity: editAccess || addAccess ? 1 : 0.6,
                cursor: editAccess || addAccess ? "default" : "not-allowed",
              }}
            >
              <div className="page-info-buttons" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <div className="buttons">
                  <button
                    className="btn"
                    onClick={() => {
                      refreshApi();
                    }}
                  >
                    Refresh
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_6247_15093)">
                        <path
                          d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                          fill="#424167"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6247_15093">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      
                      if (selectedObjects.length == 0) {
                        setToastConfig({
                          ...toastConfig,
                          isVisible: true,
                          message: "Please select some rows!",
                          type: "error",
                        });
                        setTimeout(() => {
                          setToastConfig({
                            ...toastConfig,
                            isVisible: false,
                            message: "",
                            type: "",
                          });
                        }, 1900);
                      } else {
                        validateInsertDepositInfo();
                      };
                      
                    }}
                  >
                    Insert Deposit Info
                  </button>
                </div>
              </div>
              <div className="page-details-search-content" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <div className="display-flex-div">
                  <label className="edit_container_label">Date</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={date.startDate}
                    onValueChange={(e) => {
                      if (e) {
                        setdate({
                          ...date,
                          startDate: e ? e : null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="display-flex-div">
                  <label className="edit_container_label">To</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={date.endDate}
                    onValueChange={(e) => {
                      if (e) {
                        setdate({
                          ...date,
                          endDate: e ? e : null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="display-flex-div">
                  <label className="edit_container_label">Quick Date</label>
                  <SelectBox
                    dataSource={schedulingDateRanges}
                    valueExpr="name"
                    displayExpr="name"
                    searchEnabled={true}
                    searchExpr={"name"}
                    value={quickDatevalue}
                    dropDownOptions={{ height: "180px" }}
                    onItemClick={(e) => {
                      setquickDatevalue(e?.itemData?.name);
                      let startDate = null;
                      let endDate = null;
                      switch (e?.itemData?.name) {
                        case "Last 3 Days":
                          startDate = new Date();
                          startDate.setDate(startDate.getDate() - 2);
                          endDate = new Date();
                          break;
                        case "Last 7 Days":
                          startDate = new Date();
                          startDate.setDate(startDate.getDate() - 6);
                          endDate = new Date();
                          break;
                        case "Month to Date":
                          startDate = new Date();
                          startDate.setDate(1);
                          endDate = new Date();
                          break;
                        case "Previous Month":
                          startDate = new Date();
                          startDate.setMonth(startDate.getMonth() - 1);
                          startDate.setDate(1);
                          endDate = new Date();
                          endDate.setDate(0);
                          break;
                        case "Next Month from Date":
                          startDate = new Date();
                          endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
                          break;
                        case "Next 3 Month from Date":
                          startDate = new Date();
                          endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0);
                          break;
                        case "last 3 Month to Date":
                          startDate = new Date();
                          startDate.setMonth(startDate.getMonth() - 2);
                          startDate.setDate(1);
                          endDate = new Date();
                          break;
                        case "last 6 Month to Date":
                          startDate = new Date();
                          startDate.setMonth(startDate.getMonth() - 5);
                          startDate.setDate(1);
                          endDate = new Date();
                          break;
                        case "Year to Date":
                          startDate = new Date();
                          startDate.setMonth(0);
                          startDate.setDate(1);
                          endDate = new Date();
                          break;
                        case "Previous Year":
                          startDate = new Date();
                          startDate.setFullYear(startDate.getFullYear() - 1);
                          startDate.setMonth(0);
                          startDate.setDate(1);
                          endDate = new Date();
                          endDate.setFullYear(endDate.getFullYear() - 1);
                          endDate.setMonth(11);
                          endDate.setDate(31);
                          break;
                        case "Today":
                        default:
                          startDate = new Date();
                          endDate = new Date();
                          break;
                      }
                      setdate({
                        startDate: startDate.toISOString().slice(0, 10),
                        endDate: endDate.toISOString().slice(0, 10),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="disbursement-error-handling">
                {disbursementErrorHandling ? (
                  <ApiError />
                ) : (
                  <Table1
                    editTable={false}
                    height={"auto"}
                    Tablecoloum={receiptListTableTitle}
                    tableRow={receiptListData?.map((val, i) => {
                      return { ...val, ID: i };
                    })}
                    load={loading}
                    coloumWidthText={"Receipt-List"}
                    mandatoryColoum={"Offender #"}
                    receiptListBool={true}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                  />
                )}
              </div>
            </div>) : "No view access"}
        </div>
      </div>
    </>
  );
};

export default TransactionList;
