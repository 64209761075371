import React, { useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { ReactComponent as MedicalIcon } from "../../../../../assets/images/icons/medical_services.svg";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { drugTestTablecoloumn } from "../Data";
import { Popup } from "devextreme-react";
import { AddDrugTest } from "./AddDrugTest";
import { RandomTesting } from "./RandomTesting";
import { DoNotCreateObligation } from "./DoNotCreateObligation";
import { ApiError } from "../../../../Error/ApiError";
import { VoidPopup } from "../VoidPopup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";

export const DrugTest = ({
  toastConfig,
  setToastConfig,
  userinfo,
  dropdownData,
  docketDropdownData,
  drugTestData,
  drugTestErrorHandling,
  getrugTestDataApi,
  dockertDetail,
  gotoDrugTest,
}) => {
  const [loading, setLoading] = useState(false);
  const [addDrugTest, setAddDrugTest] = useState(false);
  const [randomTesting, setRandomTesting] = useState(false);
  const [doNotCreateObligation, setDoNotCreateObligation] = useState(false);
  const [voidPopup, setVoidPopup] = useState(false);
  const [drugTestRowData, setDrugTestRowData] = useState([]);
  const [drugTestType, setDrugTestType] = useState("");
  const [drugDropdownData, setDrugDropdownData] = useState([]);
  const [existingDocketDropdownData, setExistingDocketDropdownData] = useState(
    []
  );
  const [amount, setAmount] = useState(null);
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");

  const getDrugTestDropdownData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/DrugTest/DrugTestDropdownAsync`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setDrugDropdownData(val?.data?.data);
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };
  const url = gotoDrugTest
    ? `api/DrugTest/getExistingObligation?personId=${userinfo?.gPersonId}&docketId=${dockertDetail.id}`
    : `api/DrugTest/getExistingObligation?personId=${userinfo?.gPersonId}`;

  const getExistingDocketDropdownData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setExistingDocketDropdownData(val?.data?.data);
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getDrugTestDropdownData();
    getExistingDocketDropdownData();
  }, []);

  return (
    <>
      <Popup
        visible={addDrugTest}
        onHiding={() => {
          setAddDrugTest(false);
        }}
        showTitle={false}
        width={768}
        height={560}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddDrugTest
          addDrugTest={addDrugTest}
          setAddDrugTest={setAddDrugTest}
          userinfo={userinfo}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          setLoading={setLoading}
          SetDoNotCreateObligation={setDoNotCreateObligation}
          setAmount={setAmount}
          amount={amount}
          existingDocketDropdownData={existingDocketDropdownData}
          gotoDrugTest={gotoDrugTest}
          dockertDetail={dockertDetail}
        />
      </Popup>
      <Popup
        visible={randomTesting}
        onHiding={() => {
          setRandomTesting(false);
        }}
        showTitle={false}
        width={768}
        height={500}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RandomTesting
          randomTesting={randomTesting}
          setRandomTesting={setRandomTesting}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          userinfo={userinfo}
          docketDropdownData={docketDropdownData}
          drugDropdownData={drugDropdownData}
          gotoDrugTest={gotoDrugTest}
          dockertDetail={dockertDetail}
        />
      </Popup>
      <Popup
        visible={doNotCreateObligation}
        onHiding={() => {
          setDoNotCreateObligation(false);
        }}
        showTitle={false}
        width={768}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <DoNotCreateObligation
          doNotCreateObligation={doNotCreateObligation}
          setDoNotCreateObligation={setDoNotCreateObligation}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          userinfo={userinfo}
          docketDropdownData={docketDropdownData}
          setLoading={setLoading}
          loading={loading}
          drugTestRowData={drugTestRowData}
          drugTestType={drugTestType}
          drugDropdownData={drugDropdownData}
          getrugTestDataApi={getrugTestDataApi}
          amount={amount}
          setAddDrugTest={setAddDrugTest}
          gotoDrugTest={gotoDrugTest}
          dockertDetail={dockertDetail}
        />
      </Popup>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPopup
          setVoidPopup={setVoidPopup}
          voidHeading="Void Drug Test"
          voidSubHeading="Void Drug Test"
          setLoad={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          filedId={drugTestRowData?.gDrugTestId}
          fieldName="gDrugTestId"
          apiURl="DrugTest/voidDrugTest"
          refreshApi={getrugTestDataApi}
          userinfo={userinfo}
          comment="Comments"
        />
      </Popup>
      <div className="drug_test">
        <div className="buttons">
          <button
            className="btn"
            onClick={() => {
              setAddDrugTest(true);
              setDrugTestType("ADD");
            }}
            style={{ width: "auto" }}
          >
            Add Drug Test <AddIcon className="svg-blue-white" />
          </button>
          <button
            className="btn"
            onClick={() => {
              setRandomTesting(true);
            }}
            style={{ width: "auto" }}
          >
            Random Testing <MedicalIcon className="svg-blue-white" />
          </button>
        </div>
        {drugTestErrorHandling ? (
          <ApiError />
        ) : (
          <div className="drugtest_table">
            <Table1
              editTable={false}
              height={"auto"}
              Tablecoloum={drugTestTablecoloumn}
              tableRow={drugTestData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"DrugTest"}
              mandatoryColoum={"Docket #"}
              setVoidPopup={setVoidPopup}
              setDoNotCreateObligation={setDoNotCreateObligation}
              drugTestMenu={true}
              setDrugTestRowData={setDrugTestRowData}
              setDrugTestType={setDrugTestType}
              setVoideFalse={true}
            />
          </div>
        )}
      </div>
    </>
  );
};
