import { Table2 } from "../../../../../components/Table2/Table2";

const Tablecoloum = [
  {
    title: "Report Date",
  },
  { title: "Location" },
  {
    title: "Resourse",
  },
  { title: "Type" },
];

export const ReportDateFindpopup = ({ setReportDatefind }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "598px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        <div className="RecivePaymentModel_header">
          <div style={{ width: "220px" }} className="RecivePaymentModel_header_name">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_1331_396199)">
                <path d="M0 0H24V24H0V0Z" fill="rgb(67, 66, 102)" />
                <path
                  d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1331_396199">
                  <rect width="24" height="24" fill="blue" />
                </clipPath>
              </defs>
            </svg>
            <p style={{ marginLeft: "-10px" }}>Report Date Find</p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                RD.2
              </p>
            </span>
          </div>
          <svg
            onClick={() => {
              setReportDatefind(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ width: "96%", marginLeft: "2%" }}>
          <div style={{ marginTop: "2%" }}>
            <input
              type="text"
              id="TESTE"
              style={{
                width: "47%",
                padding: "9px",
                border: "0.8px solid rgb(187 187 193)",
                borderRadius: "3px",
              }}
            />
            <button onClick={() => {}} style={{ width: "12%" }} className="btn-dark btn-new">
              Find
              <svg width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5006 10.9999H11.7106L11.4306 10.7299C12.6306 9.32989 13.2506 7.41989 12.9106 5.38989C12.4406 2.60989 10.1206 0.389893 7.32063 0.0498932C3.09063 -0.470107 -0.469374 3.08989 0.0506256 7.31989C0.390626 10.1199 2.61063 12.4399 5.39063 12.9099C7.42063 13.2499 9.33063 12.6299 10.7306 11.4299L11.0006 11.7099V12.4999L15.2506 16.7499C15.6606 17.1599 16.3306 17.1599 16.7406 16.7499C17.1506 16.3399 17.1506 15.6699 16.7406 15.2599L12.5006 10.9999ZM6.50063 10.9999C4.01063 10.9999 2.00063 8.98989 2.00063 6.49989C2.00063 4.00989 4.01063 1.99989 6.50063 1.99989C8.99063 1.99989 11.0006 4.00989 11.0006 6.49989C11.0006 8.98989 8.99063 10.9999 6.50063 10.9999Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div style={{ marginTop: "2%" }}>
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={[]}
              editTable={false}
              load={false}
              coloumWidthText={"Doctetss"}
              height={"auto"}
              mandatoryColoum={"DOE"}
              showTotalBlock={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
