import { useEffect, useState } from "react";
import "./Docket.css";
import { Overview } from "./overview/Overview";
import { Program } from "./Program/Program";
import { CommunityService } from "./CommunityService/CommunityService";
import { Toster } from "../../../../components/Toster/Toster";
import { handleGetLocal } from "../../../../services/auth-service";
import axios from "axios";
import { JailTime } from "./JailTime/JailTime";
import { Tolling } from "./Tolling/Tolling";
import { Warrents } from "./Warrents/Warrents";
import {
  setDrugTestTableData,
  setJailCompletedDatas,
  setJailPlannedDatas,
  setPlannedservicesTableData,
  setProgramTableRowData,
  setTollingTableData,
  setWarrantTableData,
  setcompletedServiceTableData,
  setrevocationsTableData,
} from "./Data";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { Revocations } from "./Revocations/Revocations";
import { DrugTest } from "./DrugTest/DrugTest";

export const Docket = ({ userinfo, data, loading, setLoading, errorhandling }) => {
  const [tab, setTab] = useState(1);
  const [dropdownData, setDropdownData] = useState([]);
  const [docketDropdownData, setDocketDropdownData] = useState([]);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const pathname = handleGetLocal("pathURL");
  const [countWarrant, setCountWarrant] = useState();
  const [countProgram, setCountProgram] = useState();
  const [countjailPlanned, setCountjailPlanned] = useState();
  const [countCommunityPlanned, setCountCommunityPlanned] = useState();
  const [countTolling, setCountTolling] = useState();
  const [countrevocations, setCountrevocations] = useState();
  const [programTableData, setProgramTableData] = useState([]);
  const [communityPlannedServicesData, setCommunityPlannedServicesData] = useState([]);
  const [communityCompletedServiceData, setCommunityCompletedServiceData] = useState([]);
  const navigate = useNavigate();
  const [jailPlannedData, setJailPlannedData] = useState([]);
  const [jailCompletedData, setJailCompletedData] = useState([]);
  const [warrantData, setWarrantData] = useState([]);
  const [revocationsgData, setrevocationsgData] = useState([]);
  const [communityServiceJailtimelogDropdown, setCommunityServiceJailtimelogDropdown] = useState([]);
  const [JailtimelogDropdown, setJailtimelogDropdown] = useState([]);
  const [tollingData, setTollingData] = useState([]);
  const [drugTestData, setDrugTestData] = useState([]);
  const [drugTestCount, setDrugTestCount] = useState([]);
  const [Tollingreset, setTollingreset] = useState(false);
  const [Revocationserrorhandlig, setRevocationserrorhandlig] = useState(false);
  const [drugTestErrorHandling, setDrugTestErrorHandling] = useState(false);
  const [programErorrHandling, setProgramErrorHandling] = useState(false);
  const [communityPlannedErorrHandling, setCommunityPlannedErrorHandling] = useState(false);
  const [communityCompletedErorrHandling, setCommunityCompletedErrorHandling] = useState(false);
  const [jailPlannedErrorHandling, setJailPlannedErrorHandling] = useState(false);
  const [jailCompletedErorrHandling, setjailCompletedErrorHandling] = useState(false);
  const [warrentErrorHandling, setWarrentErrorHandling] = useState(false);
  const [TollingErorrHandling, setTollingErorrHandlingErrorHandling] = useState(false);

  const [casePlanVisible, setCasePlanVisible] = useState(false);
  const [childDocketVisible, setChildDocketVisible] = useState(false);
  const [communityServiceVisible, setCommunityServiceVisible] = useState(false);
  const [drugTestVisible, setDrugTestVisible] = useState(false);
  const [jailTimeVisible, setJailTimeVisible] = useState(false);
  const [obligationVisible, setObligationVisible] = useState(false);
  const [paymentPlanVisible, setPaymentPlanVisible] = useState(false);
  const [programVisible, setProgramVisible] = useState(false);
  const [revocationVisible, setRevocationVisible] = useState(false);
  const [tollingVisible, setTollingVisible] = useState(false);
  const [warrantVisible, setWarrantVisible] = useState(false);

  const getDocketTabVisibility = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-docket-tab-visibility`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        const {
          casePlan: casePlan,
          childDocket: childDocket,
          communityService: communityService,
          drugTest: drugTest,
          jailTime: jailTime,
          obligation: obligation,
          paymentPlan: paymentPlan,
          program: program,
          revocation: revocation,
          tolling: tolling,
          warrant: warrant,
        } = res.data.data;

        setCasePlanVisible(!casePlan);
        setChildDocketVisible(!childDocket);
        setCommunityServiceVisible(!communityService);
        setDrugTestVisible(!drugTest);
        setJailTimeVisible(!jailTime);
        setObligationVisible(!obligation);
        setPaymentPlanVisible(!paymentPlan);
        setProgramVisible(!program);
        setRevocationVisible(!revocation);
        setTollingVisible(!tolling);
        setWarrantVisible(!warrant);
      })
      .catch((err) => console.log(err));
  };

  const getDropdownDatas = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getDocketDropdownData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-dockets?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setDocketDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getProgramListApi = async () => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Programs/getPrograms?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountProgram(val?.data?.data?.length);
          setProgramTableData(setProgramTableRowData(val?.data?.data));
        } else {
          setProgramErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setProgramErrorHandling(true);
        }
      });
  };

  const getJailPlannedServiceListApi = async () => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/jail-time/get-jailtimes?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountjailPlanned(val?.data?.data?.length);
          setJailPlannedData(setJailPlannedDatas(val?.data?.data));
        } else {
          setJailPlannedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setJailPlannedErrorHandling(true);
        }
      });
  };

  const getJailCopletedServiceListApi = async () => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/jail-time/get-jailtime-logs?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setJailCompletedData(setJailCompletedDatas(val?.data?.data));
        } else {
          setjailCompletedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setjailCompletedErrorHandling(true);
        }
      });
  };

  const getCommunityPlannedServiceListApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/CommunityService/getPlannedData?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountCommunityPlanned(val?.data?.data?.length);
          setCommunityPlannedServicesData(setPlannedservicesTableData(val?.data?.data));
        } else {
          setCommunityPlannedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setCommunityPlannedErrorHandling(true);
        }
      });
  };

  const getCommunityCompletedServiceListApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/CommunityService/getCompletedData?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCommunityCompletedServiceData(setcompletedServiceTableData(val?.data?.data));
        } else {
          setCommunityCompletedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setCommunityCompletedErrorHandling(true);
        }
      });
  };

  const getWarrantsDataApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Warrants/GetWarrantData?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountWarrant(val?.data?.data?.length);
          setWarrantData(setWarrantTableData(val?.data?.data));
        } else {
          setWarrentErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setWarrentErrorHandling(true);
        }
      });
  };

  const getTollingDataApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/tolling/get-tollings?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setTollingErorrHandlingErrorHandling(false);
          setCountTolling(val?.data?.data?.length);
          setTollingData(setTollingTableData(val?.data?.data));
        } else {
          setTollingErorrHandlingErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setTollingErorrHandlingErrorHandling(true);
        }
      });
  };

  const getCommunityServiceJailtimelogDropdown = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/CommunityService/get-jailsites?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setCommunityServiceJailtimelogDropdown(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getJailtimelogDropdown = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/jail-time/get-jailsites?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setJailtimelogDropdown(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getRevocationsDataApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/revocation/get-revocations?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setRevocationserrorhandlig(false);
          setCountrevocations(val?.data?.data?.length);
          setrevocationsgData(setrevocationsTableData(val?.data?.data));
        } else {
          setRevocationserrorhandlig(true);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setRevocationserrorhandlig(true);
        }
      });
  };

  const getrugTestDataApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/DrugTest/GetDrugTest?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setDrugTestCount(val?.data?.data?.length);
          setDrugTestData(setDrugTestTableData(val?.data?.data));
        } else {
          setDrugTestErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setDrugTestErrorHandling(true);
        }
      });
  };

  useEffect(() => {
    if (userinfo?.gPersonId) {
      getDropdownDatas();
      getDocketDropdownData();
      getWarrantsDataApi();
      getJailPlannedServiceListApi();
      getJailCopletedServiceListApi();
      getProgramListApi();
      getCommunityPlannedServiceListApi();
      getCommunityCompletedServiceListApi();
      getCommunityServiceJailtimelogDropdown();
      getJailtimelogDropdown();
      getRevocationsDataApi();
      getrugTestDataApi();
    }
  }, [userinfo?.gPersonId]);

  useEffect(() => {
    userinfo?.gPersonId && getTollingDataApi();
  }, [userinfo?.gPersonId, Tollingreset]);

  useEffect(() => {
    getDocketTabVisibility();
  }, []);

  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="Docketmaidiv">
        <div className="Docketmaidiv_left">
          <button
            className="custom_btn_width"
            style={{
              backgroundColor: tab == 1 && "var(--btnColor)",
            }}
            onClick={() => {
              setTab(1);
            }}
          >
            <p style={{ color: tab == 1 && "white" }}>Overview</p>
          </button>
          <button
            className="custom_btn_width"
            style={{
              backgroundColor: tab == 2 && "var(--btnColor)",
            }}
            onClick={() => {
              setTab(2);
            }}
          >
            <p style={{ color: tab == 2 && "white" }}>Programs ({countProgram})</p>
          </button>
          <button
            className="custom_btn_width"
            style={{
              backgroundColor: tab == 3 && "var(--btnColor)",
            }}
            onClick={() => {
              setTab(3);
            }}
          >
            <p style={{ color: tab == 3 && "white" }}>Community Service ({countCommunityPlanned})</p>
          </button>
          {tollingVisible && (
            <button
              className="custom_btn_width"
              style={{
                backgroundColor: tab == 4 && "var(--btnColor)",
              }}
              onClick={() => {
                setTab(4);
              }}
            >
              <p style={{ color: tab == 4 && "white" }}>Tolling ({countTolling})</p>
            </button>
          )}
          {jailTimeVisible && (
            <button
              className="custom_btn_width"
              style={{
                backgroundColor: tab == 5 && "var(--btnColor)",
              }}
              onClick={() => {
                setTab(5);
              }}
            >
              <p style={{ color: tab == 5 && "white" }}>Jail Time ({countjailPlanned})</p>
            </button>
          )}
          <button
            className="custom_btn_width"
            style={{
              backgroundColor: tab == 6 && "var(--btnColor)",
            }}
            onClick={() => {
              setTab(6);
            }}
          >
            <p
              style={{ color: tab == 6 && "white" }}
              onClick={() => {
                setTab(6);
              }}
            >
              Warrants ({countWarrant})
            </p>
          </button>
          <button
            className="custom_btn_width"
            style={{
              backgroundColor: tab == 7 && "var(--btnColor)",
            }}
            onClick={() => {
              setTab(7);
            }}
          >
            <p style={{ color: tab == 7 && "white" }}>Revocations({countrevocations})</p>
          </button>
          <button
            className="custom_btn_width"
            style={{
              backgroundColor: tab == 8 && "var(--btnColor)",
            }}
            onClick={() => {
              setTab(8);
            }}
          >
            <p style={{ color: tab == 8 && "white" }}>Drug Tests ({drugTestCount})</p>
          </button>
        </div>
        <div className="Docketmaidiv_right">
          {tab == 1 && (
            <Overview
              data={data}
              dropdownData={dropdownData}
              loading={loading}
              userinfo={userinfo}
              errorhandling={errorhandling}
            />
          )}
          {tab === 2 && (
            <Program
              userinfo={userinfo}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              programTableData={programTableData}
              loading={loading}
              setLoading={setLoading}
              getProgramListApi={getProgramListApi}
              programErorrHandling={programErorrHandling}
            />
          )}
          {tab === 3 && (
            <CommunityService
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              communityPlannedServicesData={communityPlannedServicesData}
              communityCompletedServiceData={communityCompletedServiceData}
              getCommunityCompletedServiceListApi={getCommunityCompletedServiceListApi}
              getCommunityPlannedServiceListApi={getCommunityPlannedServiceListApi}
              communityServiceJailtimelogDropdown={communityServiceJailtimelogDropdown}
              getCommunityServiceJailtimelogDropdown={getCommunityServiceJailtimelogDropdown}
              communityCompletedErorrHandling={communityCompletedErorrHandling}
              communityPlannedErorrHandling={communityPlannedErorrHandling}
            />
          )}
          {tab === 4 && (
            <Tolling
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              getTollingDataApi={getTollingDataApi}
              tollingData={tollingData}
              Tollingreset={Tollingreset}
              setTollingreset={setTollingreset}
              TollingErorrHandling={TollingErorrHandling}
            />
          )}
          {tab === 5 && (
            <JailTime
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              jailPlannedData={jailPlannedData}
              jailCompletedData={jailCompletedData}
              getJailCopletedServiceListApi={getJailCopletedServiceListApi}
              getJailPlannedServiceListApi={getJailPlannedServiceListApi}
              JailtimelogDropdown={JailtimelogDropdown}
              getJailtimelogDropdown={getJailtimelogDropdown}
              jailPlannedErrorHandling={jailPlannedErrorHandling}
              jailCompletedErorrHandling={jailCompletedErorrHandling}
            />
          )}
          {tab === 6 && (
            <Warrents
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              warrantData={warrantData}
              loading={loading}
              setLoading={setLoading}
              getWarrantsDataApi={getWarrantsDataApi}
              warrentErrorHandling={warrentErrorHandling}
            />
          )}
          {tab === 7 && (
            <Revocations
              Revocationserrorhandlig={Revocationserrorhandlig}
              docketDropdownData={docketDropdownData}
              revocationsgData={revocationsgData}
              dropdownData={dropdownData}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              getRevocationsDataApi={getRevocationsDataApi}
              userinfo={userinfo}
            />
          )}
          {tab === 8 && (
            <DrugTest
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              drugTestData={drugTestData}
              drugTestErrorHandling={drugTestErrorHandling}
              getrugTestDataApi={getrugTestDataApi}
            />
          )}
        </div>
      </div>
    </>
  );
};
