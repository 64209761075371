import { useEffect, useState } from "react";
import { Table2 } from "../../../../components/Table2/Table2";
import { Popup } from "devextreme-react";
import { AddDocket } from "../../AddDocket/AddDocket";
import { setAddParticipateDockets } from "../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Tablecoloum = [
  {
    moreoption: true,
  },
  {
    addObligation: true,
  },
  {
    title: "Select",
    DocketManagementSelect: true,
    bool: true,
  },
  { title: "Alerts" },
  {
    title: "Reference",
  },
  { title: "Docket/Count" },
  {
    title: "Count",
  },
  {
    title: "Offense",
  },
  {
    title: "Supervisor",
  },
  {
    title: "Supervisor Status",
  },
  {
    title: "Court Sentence",
    date: true,
  },
  {
    title: "Sentence Date Range",
    date: true,
  },
  {
    title: "Days Tolled",
  },
  {
    title: "Balance",
    currency: true,
  },
  {
    title: "Docket Link",
  },
  {
    title: "Total Days",
  },
];

export const AddDocketInfo = ({ userinfo, updateDocketManagement, obligationStatus }) => {
  const { AddParticipateDockets } = useSelector((store) => store.participate);

  const [addDocketPopupshow, setaddDocketPopupshow] = useState(false);
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);

  return (
    <>
      <Popup
        visible={addDocketPopupshow}
        onHiding={() => {
          setaddDocketPopupshow(false);
        }}
        showTitle={false}
        width={650}
        height={900}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddDocket
          addDocketPopupshow={addDocketPopupshow}
          setaddDocketPopupshow={setaddDocketPopupshow}
          userinfo={userinfo}
          updateDocketManagement={updateDocketManagement}
        />
      </Popup>
      <div style={{ padding: "5px 15px 40px 15px" }}>
        <button
          onClick={() => {
            setaddDocketPopupshow(true);
          }}
        >
          +
        </button>
        <span style={{ marginLeft: "2rem", color: "black" }}>{obligationStatus && `${obligationStatus}`}</span>
        <Table2
          Tablecoloum={Tablecoloum}
          tableRow={AddParticipateDockets}
          editTable={false}
          load={false}
          coloumWidthText={"DocketManagementTable"}
          height={"auto"}
          mandatoryColoum={"DOE"}
          showTotalBlock={true}
          DocketManagementTotalshow={true}
          DocketManagementRightClick={true}
          updateDocketManagement={updateDocketManagement}
          selectedRowCheckbox={selectedRowCheckbox}
          setSelectedRowCheckbox={setSelectedRowCheckbox}
        />
      </div>
    </>
  );
};
