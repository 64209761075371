import { useState } from "react";
import { Table2 } from "../../../../components/Table2/Table2";
import { Popup } from "devextreme-react";
import { EditAttachment } from "./EditAttechment";
import { useDispatch, useSelector } from "react-redux";
import { setresetValue } from "../../../../reducer/participantNoteReducer";
import { DeleteViewImage } from "./DeleteViewImage";
import { Toster } from "../../../../components/Toster/Toster";

const EditAttachmentpopup = ({
  editAttachmentPopupOpen,
  seteditAttachmentPopupOpen,
  AttachmenteditData,
  attachmentCategories,
  userinfo,
  toastConfig,
  setToastConfig,
  attachmentfunction,
  getphotoTableData,
}) => {
  const { resetValue } = useSelector((store) => store.participateNotes);
  const dispatch = useDispatch();
  return (
    <Popup
      visible={userinfo?.gPersonId && editAttachmentPopupOpen}
      onHiding={() => {
        dispatch(setresetValue(!resetValue));
        seteditAttachmentPopupOpen(false);
      }}
      showTitle={false}
      width={700}
      height={420}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <EditAttachment
        seteditAttachmentPopupOpen={seteditAttachmentPopupOpen}
        AttachmenteditData={AttachmenteditData}
        attachmentCategories={attachmentCategories}
        userinfo={userinfo}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        attachmentfunction={attachmentfunction}
        getphotoTableData={getphotoTableData}
      />
    </Popup>
  );
};
const DeleteImagePopUp = ({
  deletePopup,
  setdeletePopup,
  getbinaryDataIdInAttachment,
  toastConfig,
  setToastConfig,
  attachmentfunction,
  getphotoTableData,
}) => {
  return (
    <>
      <Popup
        visible={getbinaryDataIdInAttachment && deletePopup}
        onHiding={() => {
          setdeletePopup(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <DeleteViewImage
          setdeletePopup={setdeletePopup}
          getbinaryDataIdInAttachment={getbinaryDataIdInAttachment}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          attachmentfunction={attachmentfunction}
          getphotoTableData={getphotoTableData}
        />
      </Popup>
    </>
  );
};

const Tablecoloum = [
  {title:"", moreoption:true, customwidth:"50px"},
  {
    title: "Edit",
    Action: true,
  },
  { title: "View", viewImage: true },
  {
    title: "Filename",
  },
  { title: "Category" },
  {
    title: "Description",
  },
  {
    title: "DOE",
    date: true,
  },
  {
    title: "Entered By",
  },
];

export const AllAttachmentFiles = ({
  userinfo,
  loading,
  data,
  attachmentCategories,
  attachmentfunction,
  getphotoTableData,
}) => {
  const [AttachmenteditData, getAttachmenteditData] = useState({});
  const [editAttachmentPopupOpen, seteditAttachmentPopupOpen] = useState(false);
  const [deletePopup, setdeletePopup] = useState(false);
  const [getbinaryDataIdInAttachment, setgetbinaryDataIdInAttachment] =
    useState("");
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  return (
    <>
      <EditAttachmentpopup
        seteditAttachmentPopupOpen={seteditAttachmentPopupOpen}
        editAttachmentPopupOpen={editAttachmentPopupOpen}
        AttachmenteditData={AttachmenteditData}
        attachmentCategories={attachmentCategories}
        userinfo={userinfo}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        attachmentfunction={attachmentfunction}
        getphotoTableData={getphotoTableData}
      />
      <DeleteImagePopUp
        deletePopup={deletePopup}
        setdeletePopup={setdeletePopup}
        getbinaryDataIdInAttachment={getbinaryDataIdInAttachment}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        attachmentfunction={attachmentfunction}
        getphotoTableData={getphotoTableData}
      />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <div className="AllAttachmentFiles_component">
        <div className="AllAttachmentFiles_component_table">
          <Table2
            Tablecoloum={Tablecoloum}
            tableRow={data}
            editTable={false}
            load={loading}
            coloumWidthText={"AttachmentTable"}
            height={"auto"}
            mandatoryColoum={"DOE"}
            showTotalBlock={false}
            getAttachmenteditData={getAttachmenteditData}
            seteditAttachmentPopupOpen={seteditAttachmentPopupOpen}
            attachmentRightCickPopup={true}
            setattachmentdeletePopup={setdeletePopup}
            setgetbinaryDataIdInAttachment={setgetbinaryDataIdInAttachment}
          />
        </div>
      </div>
    </>
  );
};
