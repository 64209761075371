import { useCallback, useEffect, useState } from "react";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import { DataGrid, DateBox, DropDownBox, LoadPanel } from "devextreme-react";
import { Toster } from "../../components/Toster/Toster";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import Calculator from "../../assets/images/calculator.svg";
import { receiveAgencyPaymentTableColumn } from "./Data";
import { ColumnDropdown } from "../../components/Dropdownn";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import "./monitoring.css";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeMonitoringTabs } from "../../reducer/AdminReadReducer";
import { useSelector } from "react-redux";
import { getAccessData } from "../../utils/getAccessPermission";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
  value
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name || value}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const ReceiveAgencyPayment = () => {
  const { userAccessData, user_data } = useSelector((store) => store.Home);
  const { monitoringTabs } = useSelector((store) => store.AdminTable);
  const [receiveAgencyPaymentData, setReceiveAgencyPaymentData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [selectedValue, setSelectedValue] = useState({ gBillingID: "" });
  const [dataSource, setDataSource] = useState([]);
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    paymentDate: new Date().toISOString().slice(0, 10),
    cashDrawer: { name: "", id: "" },
    checkAuthorizationNumber: "",
    paymentAmount: 0,
  });
  const [cashDrawerDropdownData, setCashDrawerDropdownData] = useState([]);
  const [checkAuthorizationValidation, setCheckAuthorizationValidation] =
    useState("");
  const [paymentAmountValidation, setPaymentAmountValidation] =
    useState("");
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);
  const [selectMaxAmountValidation, setSelectMaxAmountValidation] =
    useState("");
  useState("");
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(false);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const getAgencyPaymentGridDataApi = () => {
    ApiClass.getAgencyPaymentGridData(
      setLoading,
      navigate,
      setReceiveAgencyPaymentData,
      setErrorhandling,
      selectedValue?.gBillingID
    );
  };

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "EM24");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "paymentAmount") {
      if (value?.toString().split(".")[1]?.length > 2) {
        setPaymentAmountValidation(
          "Please Enter upto 2 decimal values."
        );
      } else {
        setPaymentAmountValidation("");
        setSelectMaxAmountValidation("");
      }
    }
    if (name === "checkAuthorizationNumber") {
      if (fields?.checkAuthorizationNumber?.charAt(0) === " ") {
        setCheckAuthorizationValidation(
          "Please Enter Check Authorization Number."
        );
      } else {
        setCheckAuthorizationValidation("");
      }
    }
  };

  const resetFields = () => {
    setSelectedValue({});
    setFields({
      paymentDate: new Date().toISOString().slice(0, 10),
      cashDrawer: { name: "", id: "" },
      checkAuthorizationNumber: "",
      paymentAmount: 0
    });
    setReceiveAgencyPaymentData([]);
    setSelectedRowCheckbox([]);
  };

  const handleSave = () => {
    const data = {
      gBillingID: selectedValue?.gBillingID,
      authorizationNumber: fields?.checkAuthorizationNumber,
      cashDrawerID: fields?.cashDrawer?.id || user_data?.gCashDrawerId,
      paymentDate: fields?.paymentDate,
      FPaymentAmount: Number(fields?.paymentAmount || 0).toFixed(2),
      gridData: selectedRowCheckbox?.map((val) => {
        let body = {
          DPeriodStart: new Date(val?.Start).toISOString().split("Z")[0],
          DPeriodEnd: new Date(val?.End).toISOString().split("Z")[0],
          IDays: val?.Quantity,
          fRate: val?.Rate,
          fAmount: val?.Amount,
          sEmType: val?.Type,
          SOffenderNumber: val["Offender #"],
          SLastFirst: val["Offender Name"],
          fBalance: Number(val["AMT To Receive"].toFixed(2)),
          sFeeRemarks: val?.Remarks,
          gBillingDetailId: val?.gBillingDetailId,
          gBillingId: val?.gBillingId,
          gEmPeriodId: val?.gEmPeriodId || '00000000-0000-0000-0000-000000000000',
          gPaymentAllocationId: val?.gPaymentAllocationId || '00000000-0000-0000-0000-000000000000',
          gPersonId: val?.gPersonId,
          bVoid: false,
          dVoid: null,
          GObligationId: val?.gObligationId,
          gVoidedById: '00000000-0000-0000-0000-000000000000',
          sDDSLegalCode: null,
          SPaymentTypeName: null,
          SInvoiceNumber: null,
          DInvoice: val?.dInvoice,
          DPaid: null
        }
        return (body)
      }),
    };
    let isValid = true;
    if (
      !fields?.checkAuthorizationNumber ||
      fields?.checkAuthorizationNumber?.[0] === " "
    ) {
      isValid = false;
      setCheckAuthorizationValidation(
        "Please Enter Check Authorization Number."
      );
    }
    if (selectedValue?.gBillingID === "") {
      isValid = false;
    }
    if (paymentAmountValidation) isValid = false;
    let totalAmount = selectedRowCheckbox?.reduce(
      (sum, item) => sum + item["AMT To Receive"],
      0
    );
    if (Number(fields?.paymentAmount)?.toFixed(2) !== Number(totalAmount)?.toFixed(2)) {
      isValid = false;
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "The payment must be fully applied!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "",
          type: "",
        });
      }, 1900);
    }
    if (isValid) {
      ApiClass.saveReceiveAgencyPayment(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        data,
        resetFields
      );
    }
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const onClose = () => {
    dispatch(removeMonitoringTabs("Receive Agency Payment"));
    setTemp(true);
  };

  const handleSelectAll = () => {
    setSelectedRowCheckbox(receiveAgencyPaymentData);
  };

  const handleDeselectAll = () => {
    setSelectedRowCheckbox([]);
  };

  const handleMaxAmount = () => {
    let isValid = true;
    if (!fields?.paymentAmount || fields?.paymentAmount === "0") {
      isValid = false;
      setSelectMaxAmountValidation("Maximum Amount Must Be Greater Than Zero");
    }
    if (isValid) {
      const totalAmount = parseInt(fields?.paymentAmount, 10) || 0;
      let currentAmount = 0;
      const selectedRowsForAmount = [];
      for (let i = 0; i < receiveAgencyPaymentData?.length; i++) {
        const row = receiveAgencyPaymentData[i];
        if (currentAmount + row.Amount < totalAmount) {
          currentAmount += row.Amount;
          selectedRowsForAmount.push(row);
        } else if(currentAmount < totalAmount){
          let remainingAmount = Number(totalAmount - currentAmount);
          currentAmount += remainingAmount;
          row["AMT To Receive"] = remainingAmount;
          selectedRowsForAmount.push(row);
        } else {
          row["AMT To Receive"] = 0;
        }
      }
      setSelectedRowCheckbox(selectedRowsForAmount);
    }
  };

  const updateTotalAmount = (amount, i) => {
    if(amount > receiveAgencyPaymentData[i].Amount) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "New value cannot be greater than Amount field!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "",
          type: "",
        });
      }, 1900);
    }
  }

  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  useEffect(() => {
    if (temp) {
      navigate(monitoringTabs[monitoringTabs.length - 1]?.link);
    }
  }, [monitoringTabs, temp]);

  useEffect(() => {
    if (selectedValue?.gBillingID) {
      getAgencyPaymentGridDataApi();
    }
  }, [selectedValue?.gBillingID]);

  useEffect(() => {
    ApiClass.getReceiveAgencyPaymentInvoiceDropdown(
      setLoading,
      navigate,
      setDataSource
    );
    ApiClass.getAgencyPaymentCashDrawerDropdownApi(
      setLoading,
      navigate,
      setCashDrawerDropdownData
    );
    getUserAccessPermissions();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <MonitoringCrumbs title={"Receive Agency Payment"} />
      <div className="financial-page">
        <DynamicTabb />
        {viewAccess ? <div className="financial-page-section receive-agency-payment">
          <p className="page-info-number">EM.24</p>
          <div className="financial-page-section receive-agency-payment"
            style={{
              pointerEvents: editAccess ? "auto" : "none",
              opacity: editAccess ? 1 : 0.6,
              cursor: editAccess ? "default" : "not-allowed"
            }}
          >
            <div className="page-details-search-content">
              <div className="display-flex-div">
                <label style={{ marginLeft: '32px' }} className="edit_container_label">Invoice</label>
                <DropDownBox
                  placeholder="Select..."
                  width={300}
                  dataSource={dataSource}
                  value={selectedValue?.sDescription}
                  valueExpr={"sDescription"}
                  displayExpr={"sDescription"}
                  opened={isGridBoxOpened}
                  onOptionChanged={onGridBoxOpened}
                  dropDownOptions={{
                    width: "auto", // Adjust popup width to accommodate all columns
                  }}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={dataSource}
                      hoverStateEnabled={true}
                      height={"auto"}
                      width="100%"
                      onRowClick={(e) => {
                        setSelectedValue(e?.data);
                        setSelectedRowCheckbox([]);
                        setIsGridBoxOpened(false);
                      }}
                      paging={{ pageSize: 20 }} // Limit to 20 rows per page
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <Column
                        dataField="Invoice #"
                        caption="Invoice #"
                        width={"auto"}
                      />
                      <Column
                        dataField="Entity"
                        caption="Entity"
                        width={"auto"}
                      />
                      <Column
                        dataField="Amount"
                        caption="Amount"
                        width={"auto"}
                        format={{ type: "currency", precision: 2 }}
                      />
                      <Column
                        dataField="Due"
                        caption="Due"
                        width={"auto"}
                        format={{ type: "currency", precision: 2 }}
                      />
                      <Column dataField="Items" caption="Items" width={"auto"} />
                      <Column dataField="Date" caption="Date" width={"auto"} />
                    </DataGrid>
                  )}
                />
              </div>
              <div className="display-flex-div">
                <label className="edit_container_label">Payment Date</label>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.paymentDate}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setFields((prev) => ({
                        ...prev,
                        paymentDate: e ? utcFormatted : null,
                      }));
                    }
                  }}
                />
              </div>
              <div className="display-flex-div">
                <label
                  htmlFor="checkAuthorizationNumber"
                  className="edit_container_label"
                >
                  Check/Authorization Number
                  <span className="marks_mandatary">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    name="checkAuthorizationNumber"
                    id="checkAuthorizationNumber"
                    placeholder="Enter Check/Authorization Number"
                    className="fee-payment-content-info-input"
                    onChange={handleInputChange}
                    value={fields?.checkAuthorizationNumber}
                  />
                  {checkAuthorizationValidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "285px" }}
                    >
                      {checkAuthorizationValidation}
                    </p>
                  )}
                </div>
              </div>

            </div>
            <div
              className="page-details-search-content"
              style={{ alignItems: "center", marginTop: "10px" }}
            >
              <div className="display-flex-div edit_content_space">
                <label className="edit_container_label">Cash Drawer</label>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"cashDrawer"}
                  dropDownData={cashDrawerDropdownData}
                  value={user_data?.sCashDrawerName}
                />
              </div>
              <div className="display-flex-div">
                <label
                  htmlFor="checkAuthorizationNumber"
                  className="edit_container_label"
                  style={{ marginLeft: '83px' }}
                >
                  Payment Amount
                </label>
                <div>
                  <input
                    type="number"
                    name="paymentAmount"
                    id="paymentAmount"
                    placeholder="Enter Payment Amount"
                    className="fee-payment-content-info-input"
                    onChange={handleInputChange}
                    value={fields?.paymentAmount}
                  />
                  {selectMaxAmountValidation && (
                  <p className="error_validation"
                  style={{ position: "absolute", top: "355px" }}
                  >
                    {selectMaxAmountValidation}
                  </p>
                  )}
                  {paymentAmountValidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "355px" }}
                    >
                      {paymentAmountValidation}
                    </p>
                  )}
                </div>
                
              </div>
              <div className="agency-column-fields">
                <button
                  className="btn"
                  onClick={handleMaxAmount}
                >
                  <img title='Select Max Amount' src={Calculator} height='30px' />
                </button>                
              </div>
              <div className="buttons">
                <button className="btn" style={{marginLeft: '127px'}}>Process Credit Card</button>
              </div>
            </div>            
            <div className="page-info-buttons">
              <div className="buttons" style={{ alignItems: "baseline" }}>
                <button className="btn primary_btn" onClick={handleSave}>
                  Ok
                </button>
                <button className="btn" onClick={onClose}>
                  Cancel
                </button>
                <div className="agency-column-fields">
                  <button className="btn" onClick={handleSelectAll}>
                    Select All
                  </button>
                  <button className="btn" onClick={handleDeselectAll}>
                    Deselect All
                  </button>
                </div>
                <p className="edit_container_label">
                  Amount Received:{" "}
                  {formattedPrice(
                    selectedRowCheckbox?.reduce(
                      (sum, item) => sum + item["AMT To Receive"],
                      0
                    )
                  )}
                </p>
              </div>
            </div>
            <div className="table-section">
              {errorhandling ? (
                <ApiError />
              ) : (
                <>
                  {receiveAgencyPaymentData?.length ? (
                    <Table1
                      editTable={false}
                      height={"auto"}
                      Tablecoloum={receiveAgencyPaymentTableColumn}
                      tableRow={receiveAgencyPaymentData}
                      load={loading}
                      coloumWidthText={"Receive Agency Payment"}
                      mandatoryColoum={"Start"}
                      setSelectedRowCheckbox={setSelectedRowCheckbox}
                      selectedRowCheckbox={selectedRowCheckbox}
                      editableParticularValue={true}
                      recivePayment={true}
                      updateTotalAmount={updateTotalAmount}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
          :
          "No EM.28 View Access"}
      </div>
    </>
  );
};

export default ReceiveAgencyPayment;
