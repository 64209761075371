export const Tablecoloum = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Status" },
  { title: "Text", bool: true },
  { title: "Email", bool: true },
  { title: "Split Fee", bool: true },
  { title: "Client Id" },
  { title: "Client Name" },
  { title: "Amount", currency: true },
  { title: "Balance", currency: true },
  { title: "Arrears", currency: true },
  { title: "Pending CC" },
  { title: "Court/Agency" },
  { title: "Days Ahead/Behind" },
  { title: "Company" },
  { title: "Region" },
  { title: "Last Payment", date: true },
  { title: "Compliance Level" },
  { title: "Supervisor" },
  { title: "Last Arrearage Note" },
];
