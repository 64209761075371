import { DataGrid, DateBox, DropDownBox, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { useCallback, useEffect, useState } from "react";
import { EmFunction } from "../EmFunction";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

const AddEqupmentLog = ({
  addEqupmentLogPopup,
  setAddEqupmentLogPopup,
  setToastConfig,
  toastConfig,
  setLoading,
  navigate,
  typeTab,
  equipmentStatusDropdown,
  returnStatusDropdown,
  rowLevelData,
  refreshApi,
  emRowLevelData,
  equipmentDropdown,
}) => {
  const [fields, setFields] = useState({
    hookUpDate: "",
    removalDate: "",
    returnStatus: { name: "", id: "" },
    equipmentStatus: { name: "", id: "" },
    comments: "",
  });
  const [hookUpDateValidation, setHookUpDateValidation] = useState("");
  const [returnStatusValidation, setReturnStatusValidation] = useState("");
  const [equipmentValidation, setEquipmentValidation] = useState("");
  const [equipmentStatusValidation, setEquipmentStatusValidation] =
    useState("");
  const [selectedValue, setSelectedValue] = useState({
    Type: "",
    Vendor: "",
    "Serial #": "",
    Location: "",
    Available: "",
    gEmEquipmentId: "",
  });
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const ApiClass = new EmFunction();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setHookUpDateValidation("");
    setReturnStatusValidation("");
    setEquipmentStatusValidation("");
    setEquipmentValidation("");
    if (typeTab === "Add") {
      setFields({
        hookUpDate: "",
        removalDate: "",
        returnStatus: { name: "", id: "" },
        equipmentStatus: { name: "", id: "" },
        comments: "",
      });
      setSelectedValue({
        Type: "",
        Vendor: "",
        "Serial #": "",
        Location: "",
        Available: "",
        gEmEquipmentId: "",
      });
    } else {
      const findIdEqupmentStatus = equipmentStatusDropdown?.find(
        (status) => status.name === rowLevelData?.sEquipmentStatusName
      );
      setSelectedValue({
        Type: rowLevelData?.Type,
        Vendor: "",
        "Serial #": "",
        Location: "",
        Available: "",
        gEmEquipmentId: rowLevelData?.gEmEquipmentId
          ? rowLevelData?.gEmEquipmentId
          : "",
      });
      setFields({
        hookUpDate: rowLevelData?.[`Hookup Date`],
        removalDate: rowLevelData?.[`Removal Date`],
        returnStatus: {
          name: rowLevelData?.[`Return Status`],
          id: rowLevelData?.gEmEquipmentReturnedConditionId
            ? rowLevelData?.gEmEquipmentReturnedConditionId
            : "",
        },
        equipmentStatus: {
          name: rowLevelData?.sEquipmentStatusName,
          id: findIdEqupmentStatus?.id ? findIdEqupmentStatus?.id : "",
        },
        comments: rowLevelData?.sNote ? rowLevelData?.sNote : "",
      });
    }
  }, [addEqupmentLogPopup]);

  const data = {
    GEmEquipmentLogId:
      typeTab === "Add"
        ? "00000000-0000-0000-0000-000000000000"
        : rowLevelData?.gEmEquipmentLogId,
    GEntryUserId: localStorage.getItem("gUserId"),
    GEmPeriodId:
      typeTab === "Add"
        ? emRowLevelData?.gEmPeriodId
        : rowLevelData?.gEmPeriodId,
    GEmEquipmentId: selectedValue?.gEmEquipmentId,
    DHookup: fields?.hookUpDate,
    DRemoval: fields?.removalDate,
    GEmEquipmentReturnedConditionId: fields?.returnStatus?.id,
    SNote: fields?.comments,
  };

  const handleSave = () => {
    let isValid = true;
    if (!fields?.hookUpDate) {
      isValid = false;
      setHookUpDateValidation("Please Select Hookup Date.");
    }
    // if (fields?.returnStatus?.id === "") {
    //   isValid = false;
    //   setReturnStatusValidation("Please Select Return Status.");
    // }
    if (fields?.equipmentStatus?.id === "") {
      isValid = false;
      setEquipmentStatusValidation("Please Select Equipment Status.");
    }
    if (selectedValue?.gEmEquipmentId === "") {
      isValid = false;
      setEquipmentValidation("Please Select Equipment.");
    }
    if (isValid) {
      ApiClass.addEditEmPeriodEquipment(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        data,
        typeTab,
        setAddEqupmentLogPopup,
        refreshApi
      );
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="add-new-rate">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Eqipment Log</p>
            </div>
            <p class="popup_header_title_para">EM.3</p>
          </div>
          <svg
            onClick={() => {
              setAddEqupmentLogPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "560px" }}>
          <div className="popup-container-data">
            <div className="edit_container_first">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Eqipment <span className="marks_mandatary">*</span>
                </p>
                <DropDownBox
                  placeholder="Select..."
                  dataSource={equipmentDropdown}
                  value={selectedValue?.Type}
                  valueExpr={"Type"}
                  displayExpr={"Type"}
                  opened={isGridBoxOpened}
                  onOptionChanged={onGridBoxOpened}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={equipmentDropdown}
                      hoverStateEnabled={true}
                      height={"auto"}
                      onRowClick={(e) => {
                        setSelectedValue(e?.data);
                        setEquipmentValidation("");
                        setIsGridBoxOpened(false);
                      }}
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <FilterRow visible={true} />
                      <Column dataField="Type" caption="Type" width={"auto"} />
                      <Column
                        dataField="Vendor"
                        caption="Vendor"
                        width={"auto"}
                      />
                      <Column
                        dataField="Serial #"
                        caption="Serial #"
                        width={"auto"}
                      />
                      <Column
                        dataField="Location"
                        caption="Location"
                        width={"auto"}
                      />
                      <Column
                        dataField="Available"
                        caption="Available"
                        width={"auto"}
                      />
                    </DataGrid>
                  )}
                />
                {equipmentValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "95px" }}
                  >
                    {equipmentValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Hookup Date <span className="marks_mandatary">*</span>
                </p>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.hookUpDate}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setFields((prev) => ({
                        ...prev,
                        hookUpDate: e ? utcFormatted : null,
                      }));
                      setHookUpDateValidation("");
                    }
                  }}
                />
                {hookUpDateValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "95px" }}
                  >
                    {hookUpDateValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first import-margin-top">
              <div className="edit_content_flex">
                <p className="edit_container_label">Removal Date</p>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.removalDate}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setFields((prev) => ({
                        ...prev,
                        removalDate: e ? utcFormatted : null,
                      }));
                    }
                  }}
                />
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">Return Status</p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"returnStatus"}
                  dropDownData={returnStatusDropdown}
                  reset={setReturnStatusValidation}
                />
                {/* {returnStatusValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "205px" }}
                  >
                    {returnStatusValidation}
                  </p>
                )} */}
              </div>
            </div>
            <div className="edit_container_first import-margin-top">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Equipment Status <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"equipmentStatus"}
                  dropDownData={equipmentStatusDropdown}
                  reset={setEquipmentStatusValidation}
                />
                {equipmentStatusValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "316px" }}
                  >
                    {equipmentStatusValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first import-margin-top">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  If the Equipment Status is chnaged, the assoicated device will
                  be updated on the Equipment (EM.7) screen.
                </p>
              </div>
            </div>
            <div className="edit_container_second edit_content_flex">
              <p className="edit_container_label">Comments</p>
              <textarea
                name="comments"
                id="comments"
                placeholder="Enter Comments"
                className="dark-mode-textarea"
                value={fields?.comments}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="popup_buttons">
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setAddEqupmentLogPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};

export default AddEqupmentLog;
