import { useNavigate, useParams } from "react-router-dom";
import AdminCrumbs from "../components/AdminCrumbs";
import "./feeSchedule.css";
import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { DateBox, DropDownBox, LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../../components/Toster/Toster";
import { FeeScheduleFind } from "./FeeSchedulePopup/FeeScheduleFind";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { ApiError } from "../../Error/ApiError";
import { feeshudulefunctions } from "./feeshudulefunctions";
import { useDispatch } from "react-redux";
import { getAccessData } from "../../../utils/getAccessPermission";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";
import { setDynamicTab } from "../../../reducer/HomePageReducer";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const ObligationFeeList = () => {
  const { userAccessData } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [fields, setFields] = useState({
    feeCode: "",
    feeCodeName: "",
    ddsLegalCode: "", 
    acdCode: "",
    activeDate: "",
    inactiveBeginning: "",
    feeSchType: { name: "", id: "" },
    depositBankAccount: { name: "", id: "" },
    feeCodeDesc: "",
    ddsviolationDesc: "",
    glAccountNo: { name: "", id: "" },
    paymentPalnRecurring: false,
    paymentPalnMonthStart: { name: "", id: "" },
    paymentPalnDayOfMonth: { name: "", id: "" },
    paymentPalnPeriod: { name: "", id: "" },
    paymentPlanNoAdvancePayment: false,
    paymentPlanRoundUpNearestDollar: false,
    paymentPlanPayPriority: 0,
    paymentPlanInUseCount: "",
    quickAddName: "",
    quickAddObligationType: { name: "", id: "" },
    quickAddRecipientCategory: { name: "", id: "" },
    quickAddDisburseFrequency: { name: "", id: "" },
    quickAddDisburseMethod: { name: "", id: "" },
    quickAddDisburse: 0,
  });
  const [selectedValue, setSelectedValue] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [isEditableButton, setIsEditableButton] = useState(false);
  const [findFeeSchedulePopup, setFindFeeSchedulePopup] = useState(false);
  const [feeScheduleDropdownData, setFeeScheduleDropdownData] = useState([]);
  const [feeScheduleResponseData, setFeeScheduleResponseData] = useState([]);
  const [feeScheduleHandling, setFeeScheduleHandling] = useState(false);
  const [typeFeeSchedule, setTypeFeeSchedule] = useState("Add");
  const pathname = handleGetLocal("pathURL");
  const params = useParams();

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "BN1");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
    if (localStorage.getItem("user_designation") !== "Administrator") {
      navigate("/");
    }
    getFeeScheduleDropdownData();
  }, []);

  const emptyField = () => {
    setFields({
      feeCode: "",
      feeCodeName: "",
      ddsLegalCode: "",
      acdCode: "",
      activeDate: "",
      inactiveBeginning: "",
      feeSchType: { name: "", id: "" },
      depositBankAccount: { name: "", id: "" },
      feeCodeDesc: "",
      ddsviolationDesc: "",
      glAccountNo: { name: "", id: "" },
      paymentPalnRecurring: false,
      paymentPalnMonthStart: { name: "", id: "" },
      paymentPalnDayOfMonth: { name: "", id: "" },
      paymentPalnPeriod: { name: "", id: "" },
      paymentPlanNoAdvancePayment: false,
      paymentPlanRoundUpNearestDollar: false,
      paymentPlanPayPriority: 0,
      paymentPlanInUseCount: "",
      quickAddName: "",
      quickAddObligationType: { name: "", id: "" },
      quickAddRecipientCategory: { name: "", id: "" },
      quickAddDisburseFrequency: { name: "", id: "" },
      quickAddDisburseMethod: { name: "", id: "" },
      quickAddDisburse: 0,
    });
    setSelectedValue();
  };

  const getFeeScheduleDropdownData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/feeschedule/feeschedule-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setFeeScheduleDropdownData(val?.data?.data);
        const multiDropdownData = val?.data?.data?.entity?.map((item) => {
          return {
            Entity: item?.sName,
            Type: item?.x,
            Address: item?.sAddress1,
            gEntityId: item.gEntityId,
            gEntityTypeId: item.gEntityTypeId,
          };
        });
        setDataSource(multiDropdownData);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };


  const getFeeScheduleData = async (gFeeScheduleId) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/feeschedule/get-feeschedule?gFeeScheduleId=${gFeeScheduleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setFeeScheduleHandling(false);
          setFeeScheduleResponseData(val?.data?.data);
          setFindFeeScheduleData(val?.data?.data);
          setLoading(false);
        } else {
          setFeeScheduleHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setFeeScheduleHandling(true);
        }
      });
  };

  function findDropdown(array, keyToFind) {
    const foundItem = array?.find((item) => item?.key?.toLowerCase() === keyToFind);
    return foundItem ? { name: foundItem.value, id: foundItem.key } : { name: "", id: "" };
  }

  function findDropdownByNumber(array, keyToFind) {
    keyToFind = String(keyToFind);
    const foundItem = array?.find((item) => item?.key === keyToFind);
    return foundItem ? { name: foundItem.value, id: foundItem.key } : { name: "", id: "" };
  }

  const setFindFeeScheduleData = (data) => {
    setFields({
      feeCode: data?.sLocalLegalCode,
      feeCodeName: data?.sLocalLegalCodeExtra,
      ddsLegalCode: data?.sDdslegalCode,
      acdCode: data?.sAcdcode,
      activeDate: data?.dActive,
      inactiveBeginning: data?.dInactive,
      feeSchType: findDropdown(feeScheduleDropdownData?.feeScheduleType, data?.gFeeScheduleTypeId),
      depositBankAccount: findDropdown(feeScheduleDropdownData?.bankAccount, data?.gBankAccountId),
      feeCodeDesc: data?.sLocalViolationDescription,
      ddsviolationDesc: data?.sDdsviolationDescription,
      glAccountNo: { name: "", id: "" },
      paymentPalnRecurring: data?.bPaymentPlanRecurring,
      paymentPalnMonthStart: findDropdownByNumber(
        feeScheduleDropdownData?.monthStart,
        data?.iPaymentPlanStartMonthOffset
      ),
      paymentPalnDayOfMonth: {
        name: data?.iPaymentPlanStartDay,
        id: data?.iPaymentPlanStartDay,
      },
      paymentPalnPeriod: findDropdownByNumber(feeScheduleDropdownData?.period, data?.iPaymentPlanRecurringMonths),
      paymentPlanNoAdvancePayment: data?.bNoAdvancedPayment,
      paymentPlanRoundUpNearestDollar: data?.bRoundUpDollar,
      paymentPlanPayPriority: data?.iPayPriority,
      paymentPlanInUseCount: "",
      quickAddName: data?.sQuickAddName,
      quickAddObligationType: findDropdown(feeScheduleDropdownData?.obligationType, data?.gObligationTypeId),
      quickAddRecipientCategory: findDropdown(
        feeScheduleDropdownData?.feeScheduleCategory,
        data?.gFeeScheduleCategoryId
      ),
      quickAddDisburseFrequency: findDropdown(feeScheduleDropdownData?.disburseFrequency, data?.gDisburseFrequencyId),
      quickAddDisburseMethod: findDropdown(feeScheduleDropdownData?.disburseMethod, data?.gDisburseMethodId),
      quickAddDisburse: data?.iDisbursePercent,
      gEntryUserId: data?.gEntryUserId,
      gFeeScheduleId: data?.gFeeScheduleId,
    });
    const selectedDocket = dataSource?.find((item) => item.gEntityId.toLowerCase() === data.gDisburseEntityId);
    setSelectedValue(selectedDocket);
  };

  useEffect(() => {
    if (params?.id) {
      getFeeScheduleDropdownData();
      getFeeScheduleData(params?.id);
      setTypeFeeSchedule("Edit");
      setIsEditableButton(false);
    } else {
      setTypeFeeSchedule("Add");
      emptyField();
    }
  }, [params?.id]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={findFeeSchedulePopup}
        onHiding={() => {
          setFindFeeSchedulePopup(false);
        }}
        showTitle={false}
        width={700}
        height={520}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <FeeScheduleFind
          findFeeSchedulePopup={findFeeSchedulePopup}
          setFindFeeSchedulePopup={setFindFeeSchedulePopup}
        />
      </Popup>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <AdminCrumbs title={"Fee Schedule"} user={"Data"} />
      <div className="fee-schedule-container">
        <DynamicTabb />
       {viewAccess ? <div className="fee-schedule-content">
          <p className="page-info-number">CO.2</p>
          <div className="fee-schedule-buttons"
            style={{
              pointerEvents: editAccess ? "auto" : "none",
              opacity: editAccess ? 1 : 0.6,
              cursor: editAccess ? "default" : "not-allowed"
            }}
          >
            <div className="buttons">
            <button
              className="btn"
              onClick={() => {
                // setexportPopupOpen(true);
              }}
            >
              Export
              <svg
                className="svg-blue-dark"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
              >
                <path
                  d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                  fill="#424167"
                />
              </svg>
            </button>
              <button
                className="btn"
                onClick={() => {
                  if (params?.id) {
                    getFeeScheduleData(params?.id);
                    getFeeScheduleDropdownData();
                    setTypeFeeSchedule("Edit");
                  }
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
         
        </div>
        :
        "No CO.2 view access"}
      </div>
    </>
  );
};

export default ObligationFeeList;
