import { DateBox, ScrollView, SelectBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { useEffect, useState, useRef } from "react";
import { cancelIcon } from "@progress/kendo-svg-icons";
import { Table2 } from "../../../components/Table2/Table2";
import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../components/Logout";
import { setTableData } from "./Function";
import { Tablecoloum } from "./Function";

const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};


export const ReportCatalogFindPopup = ({
    setIsFindPopupVisible,
    setRowData,
    setFields,
    rowData,
    documentAsBase64,
}) => {
    const pathname = handleGetLocal("pathURL");
    const [operationtogel, setoperationtogel] = useState(false);
    const [data, setdata] = useState([]);
    const [load, setLoad] = useState(false);
    const [fields, setfields] = useState({});
    const [showDataArray, setshowDataArray] = useState([]);
    const [TogleFilterButton, setTogleFilterButton] = useState(false);
    const [search, setsearch] = useState("");
    const navigate = useNavigate();
    const ClickCencelButton = () => {
        setIsFindPopupVisible(false);
        setdata(false);
        setsearch("");
    };

    const ref = useRef();
    useOutsideClick(ref, () => {
        setTogleFilterButton(false);
        setoperationtogel(false);
    });

    const ref2 = useRef();
    useOutsideClick(ref, () => {
        setoperationtogel(false);
    });

    const handleRowClick = (row) => {
        setRowData(row);
        setIsFindPopupVisible(false);
        setdata(false);
        setsearch("");
        // setFields((prev) => ({
        //     ...prev,
        //     reportname: {
        //         name: rowData?.sName,
        //         id: rowData?.gReportCatalogId,
        //     },
        //     reporttype: {
        //         name: rowData?.sReportTypeName,
        //         id: rowData?.gReportTypeId,
        //     },
        //     // parentform: {
        //     //     name: rowData?.sReportTypeName,
        //     //     id: rowData?.gParentId,
        //     // },
        // }));
    };

    const getReports = async (search) => {
        setLoad(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/report-catalog/get-reports?search=${search}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                    },
                }
            )
            .then((val) => {
                // setdata(setTableData(val?.data.data));
                setdata(val?.data.data);
                setLoad(false);
            })
            .catch((val) => {
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status == 401) {
                    logOut();
                }
            });
    };

    useEffect(() => {
        getReports("");
    }, [rowData],[documentAsBase64]);

    useEffect(() => {
        getReports("");
    }, []);

    console.log('data', data);
    return (


        <div
            style={{ border: "3px solid  var(--button2)", height: "460px" }}
            className="PersonModalbody"
        >
            <div className="popup_header">
                <div className="popup_header_title">
                    <div class="popup_header_title_data">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                marginTop: "4px",
                                marginLeft: "8%",
                            }}
                        >
                            <path
                                fill="white"
                                d="M12.5006 10.9999H11.7106L11.4306 10.7299C12.6306 9.32989 13.2506 7.41989 12.9106 5.38989C12.4406 2.60989 10.1206 0.389893 7.32063 0.0498932C3.09063 -0.470107 -0.469374 3.08989 0.0506256 7.31989C0.390626 10.1199 2.61063 12.4399 5.39063 12.9099C7.42063 13.2499 9.33063 12.6299 10.7306 11.4299L11.0006 11.7099V12.4999L15.2506 16.7499C15.6606 17.1599 16.3306 17.1599 16.7406 16.7499C17.1506 16.3399 17.1506 15.6699 16.7406 15.2599L12.5006 10.9999ZM6.50063 10.9999C4.01063 10.9999 2.00063 8.98989 2.00063 6.49989C2.00063 4.00989 4.01063 1.99989 6.50063 1.99989C8.99063 1.99989 11.0006 4.00989 11.0006 6.49989C11.0006 8.98989 8.99063 10.9999 6.50063 10.9999Z"
                            />
                        </svg>
                        <p>Report Catalog Find</p>
                    </div>
                    <p class="popup_header_title_para">RC.2</p>
                </div>
                <svg
                    onClick={() => {
                        setIsFindPopupVisible(false);
                        setdata(false);
                        setsearch("");
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                        fill="white"
                    />
                </svg>
            </div>
            <ScrollView height={380}>
                <div
                    style={{
                        padding: "24px",
                        background: "var(--popup-background-white-black)",
                    }}
                >
                    <div className="buttons">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                // if (search) {
                                //     getPerson(false);
                                // }
                            }}
                            className="personfindinput"
                        >
                            <input
                                type="text"
                                id="TESTE"
                                style={{
                                    padding: "9px",
                                    border: "0.8px solid rgb(187 187 193)",
                                    borderRadius: "3px",
                                }}
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />
                        </form>
                        <button
                            onClick={() => {
                                if (search) {
                                    getReports(search);
                                }
                                else {
                                    getReports("");
                                }
                            }}
                            className="primary_btn"
                        >
                            Find
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.5006 10.9999H11.7106L11.4306 10.7299C12.6306 9.32989 13.2506 7.41989 12.9106 5.38989C12.4406 2.60989 10.1206 0.389893 7.32063 0.0498932C3.09063 -0.470107 -0.469374 3.08989 0.0506256 7.31989C0.390626 10.1199 2.61063 12.4399 5.39063 12.9099C7.42063 13.2499 9.33063 12.6299 10.7306 11.4299L11.0006 11.7099V12.4999L15.2506 16.7499C15.6606 17.1599 16.3306 17.1599 16.7406 16.7499C17.1506 16.3399 17.1506 15.6699 16.7406 15.2599L12.5006 10.9999ZM6.50063 10.9999C4.01063 10.9999 2.00063 8.98989 2.00063 6.49989C2.00063 4.00989 4.01063 1.99989 6.50063 1.99989C8.99063 1.99989 11.0006 4.00989 11.0006 6.49989C11.0006 8.98989 8.99063 10.9999 6.50063 10.9999Z"
                                    fill="white"
                                />
                            </svg>
                        </button>

                    </div>

                    <div
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        {data ? <table>
                            <thead className="primary_btn">
                                <tr
                                    style={{
                                        padding: "12px 15px",
                                    }}
                                >
                                    <th >Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr style={{ cursor: "pointer", padding: "12px 15px", border: "1px solid grey" }} key={item.gReportCatalogId} onClick={() => handleRowClick(item)}>
                                            {item.sName}
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                            : false}
                    </div>
                </div>
            </ScrollView>
        </div>
    );
};
