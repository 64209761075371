import { DataGrid, DropDownBox, Popup, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { Column, Selection } from "devextreme-react/data-grid";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { MonitoringFunction } from "../MonitoringFunction";
import DeleteConfirm from "./DeleteConfirm";
import { FilterRow } from "devextreme-react/gantt";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

const AddNewRate = ({
  addNewRatePopup,
  setAddNewRatePopup,
  addNewRatePopupType,
  equipmentTypeDropdownData,
  dataSource,
  navigate,
  setLoading,
  setToastConfig,
  toastConfig,
  refreshApi,
  rateEditorRowData,
}) => {
  const [selectedValue, setSelectedValue] = useState({
    "Entity Name": "",
    Id: "",
    gEntityId: "",
  });
  const [fields, setFields] = useState({
    equipmentType: { name: "", id: "" },
    dailyMonitoringRate: 0,
    installRate: "0",
    dailyHousingRate: 0,
    gSASIN: "",
    mRFPartNumber: "",
    gSAProductName: "",
  });
  const [entityErrorValidation, setEntityErrorValidation] = useState("");
  const [equipmentTypeErrorValidation, setEquipmentTypeErrorValidation] = useState("");
  const [monitoringRateErrorValidation, setMonitoringRateErrorValidation] = useState("");
  const [installRateErrorValidation, setInstallRateErrorValidation] = useState("");
  const ApiClass = new MonitoringFunction();
  const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  useEffect(() => {
    setEntityErrorValidation("");
    setEquipmentTypeErrorValidation("");
    setMonitoringRateErrorValidation("");
    setInstallRateErrorValidation("");
    if (addNewRatePopupType === "Add") {
      setSelectedValue({
        "Entity Name": "",
        Id: "",
        gEntityId: "",
      });
      setFields({
        equipmentType: { name: "", id: "" },
        dailyMonitoringRate: 0,
        installRate: "0",
        dailyHousingRate: 0,
        gSASIN: "",
        mRFPartNumber: "",
        gSAProductName: "",
      });
    } else {
      setSelectedValue({
        "Entity Name": rateEditorRowData?.[`Entity/Agency`],
        Id: "",
        gEntityId: rateEditorRowData?.gEntityId,
      });
      setFields({
        equipmentType: {
          name: rateEditorRowData?.[`Equipment Type`],
          id: rateEditorRowData?.gEmEquipmentTypeId,
        },
        dailyMonitoringRate: rateEditorRowData?.[`Daily Rate`],
        installRate: rateEditorRowData?.[`Install Rate`],
        dailyHousingRate: rateEditorRowData?.[`Daily Housing Rate`],
        gSASIN: rateEditorRowData?.[`GSA SIN`] ? rateEditorRowData?.[`GSA SIN`] : "",
        mRFPartNumber: rateEditorRowData?.[`GSA Mfr Part`] ? rateEditorRowData?.[`GSA Mfr Part`] : "",
        gSAProductName: rateEditorRowData?.[`GSA Product`] ? rateEditorRowData?.[`GSA Product`] : "",
      });
    }
  }, [addNewRatePopup]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "installRate") {
      if (fields?.installRate?.[0] === " ") {
        setInstallRateErrorValidation("Please Enter Valid Install Rate.");
      } else {
        setInstallRateErrorValidation("");
      }
    }
    if (name === "dailyMonitoringRate") {
      if (fields?.dailyMonitoringRate?.[0] === " ") {
        setMonitoringRateErrorValidation("Please Enter Valid Daily Monitoring Rate.");
      } else {
        setMonitoringRateErrorValidation("");
      }
    }
  };

  const data = {
    GRateId: addNewRatePopupType === "Add" ? "00000000-0000-0000-0000-000000000000" : rateEditorRowData?.gRateId,
    GEntryUserId: localStorage.getItem("gUserId"),
    GEntityId: selectedValue?.gEntityId,
    GEmEquipmentTypeId: fields?.equipmentType?.id,
    FRate: fields?.dailyMonitoringRate,
    FInstallRate: fields?.installRate,
    FHousingRate: fields?.dailyHousingRate,
    SGsaSin: fields?.gSASIN,
    SGsaMfrPart: fields?.mRFPartNumber,
    SGsaProduct: fields?.gSAProductName,
  };

  const handleSave = () => {
    let isValid = true;
    if (selectedValue?.gEntityId === "") {
      isValid = false;
      setEntityErrorValidation("Please Select Billing Agency.");
    }
    if (fields?.equipmentType?.id === "") {
      isValid = false;
      setEquipmentTypeErrorValidation("Please Select Equipment Type.");
    }
    if (
      !fields?.dailyMonitoringRate ||
      fields?.dailyMonitoringRate?.[0] === " " ||
      fields?.dailyMonitoringRate === "0"
    ) {
      isValid = false;
      setMonitoringRateErrorValidation("Please Enter Valid Daily Monitoring Rate.");
    }
    if (!fields?.installRate || fields?.installRate?.[0] === " ") {
      isValid = false;
      setInstallRateErrorValidation("Please Enter Valid Install Rate.");
    }
    if (isValid) {
      ApiClass.saveAddEditRateEditor(
        setAddNewRatePopup,
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        refreshApi,
        addNewRatePopupType,
        data
      );
    }
  };

  const deleteHandleApi = () => {
    if (rateEditorRowData?.gRateId) {
      ApiClass.removeMonitoringRateEditior(
        setAddNewRatePopup,
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        refreshApi,
        rateEditorRowData?.gRateId
      );
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <Popup
        visible={deleteConfirmPopup}
        onHiding={() => {
          setDeleteConfirmPopup(false);
        }}
        showTitle={false}
        width={450}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <DeleteConfirm
          deleteConfirmPopup={deleteConfirmPopup}
          setDeleteConfirmPopup={setDeleteConfirmPopup}
          deleteHandleApi={deleteHandleApi}
        />
      </Popup>
      <div className="add-new-rate">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              {addNewRatePopupType === "Add" ? (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_384_334141)">
                      <path
                        d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                        fill="#fff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_384_334141">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>Add Rate Editor</p>
                </>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_384_334648)">
                      <path
                        d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                        fill="#fff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_384_334648">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>Edit Rate</p>
                </>
              )}
            </div>
            <p class="popup_header_title_para">EM.29</p>
          </div>
          <svg
            onClick={() => {
              setAddNewRatePopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {/* <ScrollView style={{ height: "660px" }}> */}
          <div className="popup-container-data">
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Billing Agency <span className="marks_mandatary">*</span>
                </p>
                <DropDownBox
                  placeholder="Select..."
                  dataSource={dataSource}
                  value={selectedValue?.[`Entity Name`]}
                  valueExpr={"Entity Name"}
                  displayExpr={"Entity Name"}
                  opened={isGridBoxOpened}
                  onOptionChanged={onGridBoxOpened}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={dataSource}
                      hoverStateEnabled={true}
                      height={"auto"}
                      onRowClick={(e) => {
                        setSelectedValue(e?.data);
                        setEntityErrorValidation("");
                        setIsGridBoxOpened(false);
                      }}
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <Column dataField="Entity Name" caption="Entity Name" width={"auto"} />
                      <Column dataField="Id" caption="Id" width={"auto"} />
                    </DataGrid>
                  )}
                />
                {entityErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "140px" }}>
                    {entityErrorValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Equipment Type <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"equipmentType"}
                  dropDownData={equipmentTypeDropdownData}
                  reset={setEquipmentTypeErrorValidation}
                />
                {equipmentTypeErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "140px" }}>
                    {equipmentTypeErrorValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first import-margin-top">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Daily Monitoring Rate
                  <span className="marks_mandatary">*</span>
                </p>
                <input
                  id="dailyMonitoringRate"
                  name="dailyMonitoringRate"
                  type="number"
                  placeholder="Enter Daily Monitoring Rate"
                  className="edit_container_input"
                  value={fields?.dailyMonitoringRate}
                  onChange={handleInputChange}
                />
                {monitoringRateErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "248px" }}>
                    {monitoringRateErrorValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Install Rate <span className="marks_mandatary">*</span>
                </p>
                <input
                  id="installRate"
                  name="installRate"
                  type="number"
                  placeholder="Enter Install Rate"
                  className="edit_container_input"
                  value={fields?.installRate}
                  onChange={handleInputChange}
                />
                {/* {installRateErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "248px" }}>
                    {installRateErrorValidation}
                  </p>
                )} */}
              </div>
            </div>
            <div className="edit_container_first import-margin-top">
              <div className="edit_content_flex">
                <p className="edit_container_label">Daily Housing Rate</p>
                <input
                  id="dailyHousingRate"
                  name="dailyHousingRate"
                  type="number"
                  placeholder="Enter Daily Housing Rate"
                  className="edit_container_input"
                  style={{ width: "720px" }}
                  value={fields?.dailyHousingRate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="card-container">
              <p className="card-container-header">GSA Setup</p>
              <div className="card-container-header-content">
                <div className="edit_container_first import-margin-top">
                  <div className="edit_content_flex">
                    <p className="edit_container_label">GSA SIN</p>
                    <input
                      id="gSASIN"
                      name="gSASIN"
                      type="text"
                      placeholder="Enter GSA SIN"
                      className="edit_container_input"
                      value={fields?.gSASIN}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="edit_content_flex edit_content_space">
                    <p className="edit_container_label">MRF Part Number</p>
                    <input
                      id="mRFPartNumber"
                      name="mRFPartNumber"
                      type="text"
                      placeholder="Enter MRF Part Number"
                      className="edit_container_input"
                      value={fields?.mRFPartNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="edit_container_first import-margin-top">
                  <div className="edit_content_flex">
                    <p className="edit_container_label">GSA Product Name</p>
                    <input
                      id="gSAProductName"
                      name="gSAProductName"
                      type="text"
                      placeholder="Enter GSA Product Name"
                      className="edit_container_input"
                      style={{ width: "700px" }}
                      value={fields?.gSAProductName}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="popup_buttons">
              {addNewRatePopupType === "Edit" && (
                <button
                  className="btn"
                  onClick={() => {
                    setDeleteConfirmPopup(true);
                  }}
                >
                  Delete
                  <svg
                    className="svg-blue-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_7310_76062)">
                      <path
                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z"
                        fill="#424167"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7310_76062">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setAddNewRatePopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        {/* </ScrollView> */}
      </div>
    </>
  );
};

export default AddNewRate;
