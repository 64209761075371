import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetLocal } from "../../../../../services/auth-service";
import { Table2 } from "../../../../../components/Table2/Table2";
import axios from "axios";
import { useSelector } from "react-redux";
import { setcontectInfoTabWhileAddParticipent } from "../../../../../reducer/ParticipateReducer";
import { logOut } from "../../../../../components/Logout";
import { ContactsFunction } from "../../ContactsFunction";
import { setAddContactsInfoSaveData } from "../../../../../reducer/AdminReadReducer";

const Tablecoloum = [
  {
    title: "Primary",
    bool: true,
    editable: true,
  },
  {
    title: "Contact Information",
    requiredColoumWhileEdit: true,
    editable: true,
  },
  {
    title: "Contact Type",
    dropdown: true,
    dropdownData: [{ name: "Email" }, { name: "Phone" }],
    editable: true,
  },
];

const DetailsContactInfo = ({
  isEditableButton,
  toastConfig,
  setToastConfig,
  contactData,
  contactInfoType,
  data,
  setdata,
  setContactInfoTypeCount,
  typeFeeSchedule,
  allData,
  setAllData,
}) => {
  const apiClass = new ContactsFunction();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [tabledata, newrowData] = useState({ data: [], name: "" });
  const { contectInfoTabWhileAddParticipent, contectTableAddData } =
    useSelector((store) => store.participate);
  const { addContactsInfoSaveData } = useSelector((store) => store.AdminTable);

  const setTableDataa = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Primary: val?.Primary ? val?.Primary : false,
        "Contact Information": val["Contact Information"]
          ? val["Contact Information"]
              ?.replace(/^\+1/, "")
              ?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
          : "",

        "Contact Type": val?.["Contact Type"] ? val?.["Contact Type"] : "",
        Comment: val?.Comment,
        Effective: val?.Effective,
        ID: val?.ID,
      });
    });
    return arr;
  };

  const saveTable = async (body) => {
    if (isEditableButton) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/Contacts/add-edit-contact-info`,
          body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading(false);
          if (val?.data?.isSuccess) {
            apiClass.getContactInfoDetails(
              contactData?.gContactId,
              setLoading,
              setdata,
              setContactInfoTypeCount
            );
          } else {
            console.error(val?.data?.errors);
          }
        })
        .catch((val) => {
          setLoading(false);
          apiClass.getContactInfoDetails(
            contactData?.gContactId,
            setLoading,
            setdata,
            setContactInfoTypeCount
          );
          if (val?.response?.status === 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };

  useEffect(() => {
    if (params?.id) {
      // contactData?.gContactId &&
      //   apiClass.getContactInfoDetails(
      //     contactData?.gContactId,
      //     setLoading,
      //     setdata
      //   );
    } else {
      setdata(setTableDataa(contectTableAddData));
      setdata(
        setTableDataa(
          addContactsInfoSaveData
            .map((val, i) => {
              return {
                ...val,
                ID: contectTableAddData.length + i + 1,
              };
            })
            .map((vall) => {
              return {
                Primary: vall?.BPrimary ? vall?.BPrimary : false,
                "Contact Information": vall?.SInformation
                  ? vall?.SInformation
                  : "",
                "Contact Type": vall?.GContactInfoTypeId
                  ? vall?.GContactInfoTypeId
                  : "",
                Comment: vall?.sComment ? vall?.sComment : "",
                ID: vall?.ID,
              };
            })
        )
      );
    }
  }, [params?.id, contactData?.gContactId]);

  useEffect(() => {
    if (isEditableButton && typeFeeSchedule === "Edit") {
      if (tabledata.name === "edit") {
        saveTable([
          {
            GContactInfoId: tabledata?.data?.oldData?.ID,
            GContactId: contactData?.gContactId,
            SInformation: tabledata?.data?.newData["Contact Information"]
              ? /\w*[a-zA-Z]\w*/.test(
                  tabledata?.data?.newData["Contact Information"]
                )
                ? tabledata?.data?.newData["Contact Information"]
                : tabledata?.data?.newData["Contact Information"].replace(
                    /\D/g,
                    ""
                  )
              : tabledata?.data?.oldData["Contact Information"] !== null
              ? /\w*[a-zA-Z]\w*/.test(
                  tabledata?.data?.oldData["Contact Information"]
                )
                ? tabledata?.data?.oldData["Contact Information"]
                : tabledata?.data?.oldData["Contact Information"].replace(
                    /\D/g,
                    ""
                  )
              : "",
            BPrimary: tabledata?.data?.newData.Primary
              ? tabledata?.data?.newData.Primary
              : tabledata?.data?.oldData.Primary !== null
              ? tabledata?.data?.oldData.Primary
              : false,
            GContactInfoTypeId: contactInfoType.find((val) => {
              if (
                val.name === tabledata?.data?.newData["Contact Type"] ||
                val.name === tabledata?.data?.oldData["Contact Type"]
              ) {
                return val;
              }
            })?.id,
            SComment: tabledata?.data?.newData.Comment
              ? tabledata?.data?.newData.Comment
              : tabledata?.data?.oldData.Comment !== null
              ? tabledata?.data?.oldData.Comment
              : "",
            isDelete: "no",
          },
        ]);
      } else if (tabledata.name === "insert") {
        saveTable([
          {
            GContactInfoId: "",
            GContactId: contactData?.gContactId,
            SInformation: tabledata?.data?.data["Contact Information"]
              ? /\w*[a-zA-Z]\w*/.test(
                  tabledata?.data?.data["Contact Information"]
                )
                ? tabledata?.data?.data["Contact Information"]
                : tabledata?.data?.data["Contact Information"].replace(
                    /\D/g,
                    ""
                  )
              : "",
            BPrimary: tabledata?.data?.data?.Primary
              ? tabledata?.data?.data?.Primary
              : false,
            GContactInfoTypeId: contactInfoType.find((val) => {
              if (val.name === tabledata?.data?.data["Contact Type"]) {
                return val;
              }
            })?.id,
            sComment: tabledata?.data?.data?.Comment
              ? tabledata?.data?.data?.Comment
              : "",
            isDelete: "no",
          },
        ]);
      } else if (tabledata.name === "delete") {
        saveTable([
          {
            GContactInfoId: tabledata?.data?.data?.ID,
            GContactId: contactData?.gContactId,
            SInformation: tabledata?.data?.data["Contact Information"],
            BPrimary: tabledata?.data?.data?.Primary,
            GContactInfoTypeId:
              tabledata?.data?.data["Contact Type"]?.toLowerCase().trim() ===
              "email"
                ? "DCBDE1D1-8789-E011-89ED-00508D98A9EE"
                : "DEBDE1D1-8789-E011-89ED-00508D98A9EE",
            SComment: tabledata?.data?.data?.Comment,
            isDelete: "Yes",
          },
        ]);
      } else {
        dispatch(
          setcontectInfoTabWhileAddParticipent(
            contectTableAddData.map((val) => {
              return {
                GContactInfoId: "",
                GContactId: "",
                SInformation: val["Contact Information"]
                  ? val["Contact Information"]
                  : "",
                BPrimary: val.Primary ? val.Primary : false,
                GContactInfoTypeId: contactInfoType.find((vall) => {
                  if (vall.name === val["Contact Type"]) {
                    return val;
                  }
                })?.id,
                SComment: val.Comment ? val.Comment : "",
                isDelete: "no",
              };
            })
          )
        );
      }
    } else {
      if (tabledata.name === "edit") {
        const editedData = {
          GContactInfoId: tabledata?.data?.oldData?.ID,
          GContactId: "",
          SInformation: tabledata?.data?.newData["Contact Information"]
            ? /\w*[a-zA-Z]\w*/.test(
                tabledata?.data?.newData["Contact Information"]
              )
              ? tabledata?.data?.newData["Contact Information"]
              : tabledata?.data?.newData["Contact Information"].replace(
                  /\D/g,
                  ""
                )
            : tabledata?.data?.oldData["Contact Information"] !== null
            ? /\w*[a-zA-Z]\w*/.test(
                tabledata?.data?.oldData["Contact Information"]
              )
              ? tabledata?.data?.oldData["Contact Information"]
              : tabledata?.data?.oldData["Contact Information"].replace(
                  /\D/g,
                  ""
                )
            : "",
          BPrimary: tabledata?.data?.newData.Primary
            ? tabledata?.data?.newData.Primary
            : tabledata?.data?.oldData.Primary !== null
            ? tabledata?.data?.oldData.Primary
            : "",
          GContactInfoTypeId: contactInfoType.find((val) => {
            if (
              val.name === tabledata?.data?.newData["Contact Type"] ||
              val.name === tabledata?.data?.oldData["Contact Type"]
            ) {
              return val;
            }
          })?.id,
          SComment: tabledata?.data?.newData.Comment
            ? tabledata?.data?.newData.Comment
            : tabledata?.data?.oldData.Comment !== null
            ? tabledata?.data?.oldData.Comment
            : "",
          isDelete: "no",
        };
        const index = allData.findIndex(
          (item) => item.GContactInfoId === editedData.GContactInfoId
        );
        if (index !== -1) {
          allData[index] = editedData;
          setAllData([...allData]);
        }
      } else if (tabledata.name === "insert") {
        const insertedData = {
          GContactInfoId: "",
          GContactId: "",
          SInformation: tabledata?.data?.data["Contact Information"]
            ? /\w*[a-zA-Z]\w*/.test(
                tabledata?.data?.data["Contact Information"]
              )
              ? tabledata?.data?.data["Contact Information"]
              : tabledata?.data?.data["Contact Information"].replace(/\D/g, "")
            : "",
          BPrimary: tabledata?.data?.data?.Primary
            ? tabledata?.data?.data?.Primary
            : false,
          GContactInfoTypeId: contactInfoType.find((val) => {
            if (val.name === tabledata?.data?.data["Contact Type"]) {
              return val;
            }
          })?.id,
          sComment: tabledata?.data?.data?.Comment
            ? tabledata?.data?.data?.Comment
            : "",
          isDelete: "no",
        };
        setAllData([...allData, insertedData]);
      } else if (tabledata.name === "delete") {
        const deleteData = tabledata?.data?.data;
        if (!deleteData.hasOwnProperty("Primary")) {
          deleteData["Primary"] = false;
        }
        const indexToDelete = allData.findIndex(
          (item) =>
            item.SInformation === deleteData["Contact Information"] &&
            item.BPrimary === deleteData["Primary"]
        );
        if (indexToDelete !== -1) {
          const updatedData = [
            ...allData.slice(0, indexToDelete),
            ...allData.slice(indexToDelete + 1),
          ];
          setAllData(updatedData);
        }
      }
    }
  }, [tabledata, contectTableAddData]);

  useEffect(() => {
    dispatch(setAddContactsInfoSaveData(allData));
  }, [allData]);

  // console.log(allData);
  // console.log(addContactsInfoSaveData);

  return (
    <>
      <div className="details-contact-info">
        <Table2
          Tablecoloum={Tablecoloum}
          tableRow={data}
          load={false}
          coloumWidthText={"Contacts-Contact-Info"}
          height={"auto"}
          editTable={isEditableButton}
          newrowData={newrowData}
          tabledata={tabledata}
          mandatoryColoum={"Contact Type"}
          contactInfoType={contactInfoType}
          editParticipant={isEditableButton}
          showTotalBlock={false}
        />
      </div>
    </>
  );
};

export default DetailsContactInfo;
