import axios from "axios";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { logOut } from "../../../../../../components/Logout";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ContextMenu, DataGrid, LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../../../../../components/Toster/Toster";
import AddEditObligationDetail from "../../../Financial/components/ObligationDetail/AddEditObligationDetail";
import { FinancialFunction } from "../../../../../Financial/FinancialFunction";
import { Column, HeaderFilter, Lookup } from "devextreme-react/cjs/data-grid";
import ThreeDotMenu from "../../../../../../components/Common/Icons/ThreeDotMenu";
import { confirm } from "devextreme/ui/dialog";
import { useSelector } from "react-redux";
import { Summary, TotalItem } from "devextreme-react/data-grid";

export const GoToObligationDetail = ({ gObligationId, data, getObligation }) => {
  const { gotooblogationValue } = useSelector((store) => store.participateNotes);

  const { participantdata } = useSelector((store) => store.participate);

  const pathname = handleGetLocal("pathURL");

  const [loading, setloading] = useState(false);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const ApiClass = new FinancialFunction();

  const navigate = useNavigate();

  const [table, setTable] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [errorHandling, setErrorHandling] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});

  const [obligationDetail, setObligationDetail] = useState({});

  const [showPopup, setShowPopup] = useState(false);

  const [modalType, setModalType] = useState("edit");

  const getData = async () => {
    setloading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/get-obligation-detail-data?obligationId=${gObligationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setTable(val.data.data);
        setloading(false);
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getTransactionTypes = () => {
    ApiClass.populateObligationDetailTypes(navigate, setTransactionTypes);
  };

  useEffect(() => {
    getData();
    getTransactionTypes();
  }, []);

  const voidObligationDetail = (gObligationDetailId) => {
    let result = confirm("Do you really want to void this record?", "Void Obligation Detail?");
    result.then((dialogResult) => {
      if (dialogResult) {
        ApiClass.voidObligationDetail(
          setloading,
          navigate,
          setErrorHandling,
          gObligationDetailId,
          setToastConfig,
          getData
        );
      }
    });
  };

  return (
    <div className="Revocationstable">
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <Popup
        visible={showPopup}
        onHiding={() => {
          setShowPopup(false);
        }}
        showTitle={false}
        height={420}
        width={600}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEditObligationDetail
          obligationDetail={obligationDetail}
          setShowPopup={setShowPopup}
          modalType={modalType}
          key={JSON.stringify({...obligationDetail, key: Math.random()})}
          reloadObligationDetails={getData}
          setToastConfig={setToastConfig}
          data={data}
          getObligation={getObligation}
        />
      </Popup>
      <div className="financial_overview_tab">
        <button
          onClick={() => {
            setModalType("add");
            setShowPopup(true);
            setObligationDetail({
              sOffenderNumber: participantdata?.sOffenderNumber,
              sFeeName: gotooblogationValue?.userinfo?.sObligationTypeName,
              sLastFirst: participantdata?.sLastFirst,
              gObligationId: gObligationId,
            });
          }}
        >
          Add +
        </button>
        <div className="financial_tab_table" style={{ maxWidth: "1200px" }}>
          <DataGrid
            dataSource={table}
            onContextMenuPreparing={(e) => {
              setSelectedRow(e.row?.data);
            }}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            onCellPrepared={(e) => {
              if (e.isAltRow) {
                e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
                e.cellElement.style.color = "var(--white-black)";
              }
            }}
            columnAutoWidth
          >
            <HeaderFilter visible={true} allowSearch />
            <Column
              cellRender={(rowData) => {
                return (
                  <div
                    id="threedots"
                    className="add_navBar_hamburger"
                    onClick={() => {
                      setSelectedRow(rowData?.data);
                    }}
                    style={{ margin: "-7px" }}
                  >
                    <ThreeDotMenu />
                    <ContextMenu
                      showEvent="dxclick"
                      target="#threedots"
                      dataSource={[
                        {
                          text: "Edit",
                          icon: "edit",
                        },
                        {
                          text: "Void",
                          icon: "remove",
                        },
                      ]}
                      onItemClick={(e) => {
                        if (e.itemData.text === "Edit") {
                          setModalType("edit");
                          setObligationDetail(selectedRow);
                          setShowPopup(true);
                        }
                        if (e.itemData.text === "Void") {
                          voidObligationDetail(selectedRow?.gObligationDetailId);
                        }
                      }}
                    />
                  </div>
                );
              }}
            />
            <Column dataField="dDoe" dataType="date" caption="Date" />
            <Column dataField="gTransactionTypeId" caption="Type">
              <Lookup dataSource={transactionTypes} valueExpr={"gTransactionTypeID"} displayExpr="sName" />
            </Column>
            {/* <Column dataField="iQuantity" caption="Quantity" /> */}
            {/* <Column
              dataField="fRate"
              caption="Rate"
              cellRender={(rowData) => {
                return `$${rowData?.data?.fRate}`;
              }}
            /> */}
            <Column
              dataField="fAmount"
              caption="Amount"
              cellRender={(rowData) => {
                return `$${rowData?.data?.fAmount}`;
              }}
            />
            <Column dataField="sFeeName" caption="Fee Name" />
            {/* <Column dataField="sLastFirst" caption="Person Name" /> */}
            <Column dataField="sNote" caption="Note" />
            {/* <Summary>
              <TotalItem column="fAmount" summaryType="sum" displayFormat="{0}" valueFormat="currency" />
            </Summary> */}
            <Summary>
              {table.length > 0 && (
                <TotalItem column="fAmount" summaryType="sum" displayFormat="{0}" valueFormat="currency" />
              )}
            </Summary>
          </DataGrid>
          <ContextMenu
            target=".dx-data-row"
            dataSource={[
              {
                text: "Edit",
                icon: "edit",
              },
              {
                text: "Void",
                icon: "remove",
              },
            ]}
            onItemClick={(e) => {
              if (e.itemData.text === "Edit") {
                setModalType("edit");
                setObligationDetail(selectedRow);
                setShowPopup(true);
              }
              if (e.itemData.text === "Void") {
                voidObligationDetail(selectedRow?.gObligationDetailId);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
