import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TopDynamicTab } from "../TopDynamicTab";
import { Table2 } from "../../../components/Table2/Table2";
import { AllocationAdjustmentfunction } from "./AllocationAdjustmentfunction";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../components/Toster/Toster";
import { LoadPanel } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";

const Tablecoloum = [
  { title: "Reference", editable: false },
  { title: "Docket", editable: false },
  { title: "Court", editable: false },
  { title: "Fee", editable: false },
  { title: "Amount", editable: false, currency: true },
  { title: "Paid", editable: false, currency: true },
  { title: "Write Off", editable: false, currency: true },
  { title: "Balance", editable: false, currency: true },
  { title: "On Hand", editable: false, currency: true },
  {
    title: "Amount to Deallocate",
    number: true,
    editable: true,
    currency: true,
  },
];
const Tablecoloum2 = [
  { title: "Reference", editable: false },
  { title: "Docket", editable: false },
  { title: "Court", editable: false },
  { title: "Fee", editable: false },
  { title: "Amount", editable: false, currency: true },
  { title: "Paid", editable: false, currency: true },
  { title: "Write Off", editable: false, currency: true },
  { title: "Balance", editable: false, currency: true },
  { title: "On Hand", editable: false, currency: true },
  { title: "Amount to Allocate", number: true, editable: true, currency: true },
];

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const AllocationAdjustment = () => {
  const [tabSwitch, setTabSwitch] = useState(2);
  const { dynamicParticipatTab, AllocationAdjustment } = useSelector(
    (store) => store.participate
  );
  const [Loading, setLoading] = useState();
  const clas = new AllocationAdjustmentfunction();
  const navigate = useNavigate();
  const [grid1, setgrid1] = useState([]);
  const [gridalldata1, setgridalldata1] = useState([]);
  const [grid2, setgrid2] = useState([]);
  const [gridalldata2, setgridalldata2] = useState([]);
  const [updategrid1, setupdategrid1] = useState([]);
  const [updategrid2, setupdategrid2] = useState([]);
  const [fields, setfields] = useState({
    note: "",
    "To Be Approved By": { name: "", id: "" },
  });

  const [Approveddropdown, setApproveddropdown] = useState([]);

  const [errormessage, seterrormessage] = useState("");
  const [errormessage2, seterrormessage2] = useState("");
  const [errormessage3, seterrormessage3] = useState("");

  useEffect(() => {
    clas.AllocationAdjustmenttabledata(
      "Grid1",
      AllocationAdjustment?.gPersonId,
      setLoading,
      navigate,
      setgrid1,
      setgrid2,
      setgridalldata1,
      setgridalldata2
    );
    clas.AllocationAdjustmenttabledata(
      "Grid2",
      AllocationAdjustment?.gPersonId,
      setLoading,
      navigate,
      setgrid1,
      setgrid2,
      setgridalldata1,
      setgridalldata2
    );
    clas.allocationadjustmentapprovals(
      setLoading,
      navigate,
      setApproveddropdown
    );
  }, []);

  useEffect(() => {
    seterrormessage("");

    if (
      Number(updategrid1?.oldData?.["Amount to Deallocate"]) >
      updategrid1?.oldData?.["On Hand"]
    ) {
      seterrormessage("Amount enter exceeds amount On Hand");
      return;
    } else {
      if (
        Number(updategrid2?.oldData?.["Amount to Allocate"]) >
        updategrid2?.oldData?.["On Hand"]
      ) {
        seterrormessage("Amount enter exceeds amount On Hand");
        return;
      } else {
        seterrormessage("");
      }
    }

    setgridalldata1(
      gridalldata1?.map((val) => {
        if (val?.gObligationId == updategrid1?.oldData?.["gObligationId"]) {
          return {
            ...val,
            AmountToDallocate: updategrid1?.oldData?.["Amount to Deallocate"],
          };
        } else {
          return val;
        }
      })
    );

    setgridalldata2(
      gridalldata2?.map((val) => {
        if (val?.gObligationId == updategrid2?.oldData?.["gObligationId"]) {
          return {
            ...val,
            AmountToAllocate: updategrid2?.oldData?.["Amount to Allocate"],
          };
        } else {
          return val;
        }
      })
    );
  }, [updategrid1, updategrid2]);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const save = () => {
    let count1 = 0;
    let count2 = 0;

    gridalldata1.map((val) => {
      count1 = count1 + val?.AmountToDallocate;
    });
    gridalldata2.map((val) => {
      count2 = count2 + val?.AmountToAllocate;
    });
    if (count1 !== count2) {
      seterrormessage("Selected amounts do not match");
      return;
    }
    if (Approveddropdown.length && !fields["To Be Approved By"]?.id) {
      seterrormessage3("Please select To Be Approved By");
      return;
    }

    if (fields?.note && !errormessage) {
      seterrormessage2("");
      clas.saveAllocationAdjustment(
        {
          gPersonId: AllocationAdjustment?.gPersonId,
          grid1: gridalldata1,
          grid2: gridalldata2,
          note: fields?.note,
          gApproverId: fields["To Be Approved By"]?.id
            ? fields["To Be Approved By"]?.id
            : "00000000-0000-0000-0000-000000000000",
        },
        setLoading,
        navigate,
        setToastConfig,
        toastConfig
      );
    } else {
      !fields?.note && seterrormessage2("Please enter Notes");
    }
  };

  return (
    <>
      <div className="particcipentPage">
        <LoadPanel shadingColor="true" visible={Loading} delay={10} />
        <Toster
          message={toastConfig.message}
          type={toastConfig.type}
          visible={toastConfig.isVisible}
        />
        <TopDynamicTab
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          text={dynamicParticipatTab}
        />
        <div style={{ width: "96%", marginLeft: "2%" }}>
          <p
            style={{ marginTop: "2%", marginBottom: "2%", fontWeight: "bold" }}
          >
            {AllocationAdjustment?.sOffenderNumber} -{" "}
            {AllocationAdjustment?.sLastFirst}
          </p>
          <div className="edit_container_second edit_content_flex">
            {Approveddropdown.length ? (
              <>
                <p className="edit_container_label">
                  To Be Approved By
                  <span className="marks_mandatary">*</span>
                </p>
                <div style={{ width: "20%" }}>
                  <DropDown
                    setfields={setfields}
                    dropDownData={Approveddropdown}
                    fieldNmae={"To Be Approved By"}
                    fields={fields}
                    reset={seterrormessage3}
                  />
                  <p style={{ color: "red" }}>{errormessage3}</p>
                </div>
              </>
            ) : (
              ""
            )}
            <p className="edit_container_label">
              Notes
              <span className="marks_mandatary">*</span>
            </p>
            <textarea
              name="remark"
              id="remark"
              placeholder="Write Notes*"
              style={{ height: "160px", width: "500px" }}
              onChange={(e) => {
                seterrormessage2("");
                setfields({
                  ...fields,
                  note: e.target.value,
                });
              }}
            ></textarea>
            <p style={{ color: "red" }}>{errormessage2}</p>
          </div>
          <button
            style={{
              marginTop: "2%",
              marginBottom: "2%",
              cursor: "pointer",
              background: "var(--button-lightblack-lightblue)",
              color: "var(--icons2)",
            }}
            onClick={() => {
              save();
            }}
          >
            Process
          </button>
          <p style={{ color: "red", marginBottom: "5px" }}>{errormessage}</p>
          <div>
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={grid1}
              editTable={false}
              load={false}
              coloumWidthText={"NotesTable"}
              height={"auto"}
              mandatoryColoum={"DOE"}
              showTotalBlock={true}
              AllocationAdjustmenttgrid1={true}
              setupdategrid1={setupdategrid1}
            />
          </div>
          <div style={{ marginTop: "4%" }}>
            <Table2
              Tablecoloum={Tablecoloum2}
              tableRow={grid2}
              editTable={false}
              load={false}
              coloumWidthText={"NotesTable"}
              height={"auto"}
              mandatoryColoum={"DOE"}
              showTotalBlock={true}
              AllocationAdjustmenttgrid2={true}
              setupdategrid2={setupdategrid2}
              AllocationAdjustmenttgrid1={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
