import { ContextMenu, LoadPanel, Popup } from "devextreme-react";
import { useState, useEffect } from "react";
import { Table2 } from "../../../components/Table2/Table2";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../Error/ApiError";
import { ReportWizaerdFuncition } from "../ReportWizaerdFuncition";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { CretaeNewFilter } from "../commonpopup/CretaeNewFilter";
import { SharequeryPopup } from "../commonpopup/SharequeryPopup";
import { Toster } from "../../../components/Toster/Toster";

const Tablecoloum = [
  {
    title: "Report Name",
  },
  { title: "Date", date: true },
  {
    title: "Expiration",
  },
  { title: "Comment" },
  {
    title: "Query XML",
  },
  {
    title: "Filter XML ",
  },
  {
    title: "File UNC",
  },
  {
    title: "Logical Unit",
  },
];

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, setxmldata, setisdefault, setfilterdata }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        setxmldata={setxmldata}
        setisdefault={setisdefault}
        setfilterdata={setfilterdata}
        // reset={reset}
      />
    </>
  );
};

const Sharequerypopups = ({ setsharequeryshow, sharequeryshow, ItemType }) => {
  return (
    <>
      <Popup
        visible={sharequeryshow}
        onHiding={() => {
          setsharequeryshow(false);
        }}
        showTitle={false}
        width={700}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SharequeryPopup setsharequeryshow={setsharequeryshow} sharequeryshow={sharequeryshow} ItemType={ItemType} />
      </Popup>
    </>
  );
};

const CretaeNewFilterpopup = ({
  setshowCretaeNewFilterpopup,
  showCretaeNewFilterpopup,
  tablename,
  filterName,
  getQueryFilterReportWizard,
  name,
  id,
  editname,
  setfield,
  field,
  setfilterdata,
}) => {
  return (
    <>
      <Popup
        visible={showCretaeNewFilterpopup}
        onHiding={() => {
          setshowCretaeNewFilterpopup(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CretaeNewFilter
          showCretaeNewFilterpopup={showCretaeNewFilterpopup}
          setshowCretaeNewFilterpopup={setshowCretaeNewFilterpopup}
          tablename={tablename}
          filterName={filterName}
          getQueryFilterReportWizard={getQueryFilterReportWizard}
          filtername={name}
          filterid={id}
          editname={editname}
          setfield={setfield}
          field={field}
          setfilterdata={setfilterdata}
        />
      </Popup>
    </>
  );
};

export const ReportSchedule = () => {
  const [loading, setLoading] = useState(false);
  const [errorhandler, seterrorhandler] = useState(false);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [filterdata, setfilterdata] = useState("");
  const [editname, seteditname] = useState(false);
  const [showCretaeNewFilterpopup, setshowCretaeNewFilterpopup] = useState(false);
  const [ItemTypeswitch, setItemTypeswitch] = useState(true);
  const [field, setfield] = useState({
    query: { name: "", id: "" },
    filter: { name: "", id: "" },
  });
  const [filterdropdown, setfilterdropdown] = useState([]);
  const [tablereload, settablereload] = useState(false);
  const [sharequeryshow, setsharequeryshow] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);

  const clas = new ReportWizaerdFuncition();

  useEffect(() => {
    clas.getQueryFilterReportWizard(
      setLoading,
      navigate,
      "grvReportArchive",
      setfilterdropdown,
      false,
      setfield,
      field,
      setfilterdata
    );
  }, []);

  const getQueryFilterReportWizard = (temp = false) => {
    clas.getQueryFilterReportWizard(
      setLoading,
      navigate,
      "grvReportArchive",
      setfilterdropdown,
      temp,
      setfield,
      field,
      setfilterdata
    );
  };

  useEffect(() => {
    clas.getReportScheduleReportWizard(setLoading, seterrorhandler, navigate, setdata);
  }, [refresh]);

  useEffect(() => {
    if (field?.filter?.id) {
      localStorage.setItem("ReportWizardReportshuduleDateTable", filterdata);
      settablereload(!tablereload);
    }
  }, [field?.filter?.id]);

  useEffect(() => {
    localStorage.removeItem("ReportWizardReportshuduleDateTable");
    settablereload(!tablereload);
  }, []);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  return (
    <>
      {errorhandler ? (
        <ApiError />
      ) : (
        <div>
          <LoadPanel shadingColor="true" visible={loading} delay={10} />
          <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
          <Sharequerypopups
            setsharequeryshow={setsharequeryshow}
            sharequeryshow={sharequeryshow}
            ItemType={ItemTypeswitch ? "Query" : "Filter"}
          />
          <CretaeNewFilterpopup
            setshowCretaeNewFilterpopup={setshowCretaeNewFilterpopup}
            showCretaeNewFilterpopup={showCretaeNewFilterpopup}
            tablename={"ReportWizardReportDateTable"}
            filterName={"grvReportArchive"}
            getQueryFilterReportWizard={getQueryFilterReportWizard}
            name={field?.filter?.name}
            id={field?.filter?.id}
            editname={editname}
            setfield={setfield}
            field={field}
            setfilterdata={setfilterdata}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: ".3%",
            }}
          >
            <p
              style={{
                color: "var(--input-color-black-white)",
                fontWeight: "bold",
                marginTop: "10px",
                marginLeft: "1%",
              }}
            >
              Filter
            </p>
            <div
              style={{
                width: "25%",
                marginLeft: "1%",
              }}
              id="Filter"
            >
              <DropDown
                setfields={setfield}
                fields={field}
                fieldNmae={"filter"}
                dropDownData={filterdropdown}
                setfilterdata={setfilterdata}
              />
              <ContextMenu
                dataSource={[
                  {
                    text: "Save Change",
                    disabled: field?.filter?.id ? false : true,
                  },
                  { text: "Create New Filter" },
                  { text: "Reset Filters" },
                  {
                    text: "Rename Filter",
                    disabled: field?.filter?.id ? false : true,
                  },
                  {
                    text: "Delete Filter",
                    disabled: field?.filter?.id ? false : true,
                  },

                  {
                    text: "Share Filter",
                    disabled: field?.filter?.id ? false : true,
                  },
                ]}
                width={200}
                target={`#Filter`}
                onItemClick={(e) => {
                  if (e.itemData.text === "Create New Filter") {
                    seteditname(false);
                    setshowCretaeNewFilterpopup(true);
                  } else if (e.itemData.text === "Save Change") {
                    clas.saveAndEditFilterReportWizard(
                      setLoading,
                      navigate,
                      {
                        gStoredFilterId: field?.filter?.id,
                        sName: field?.filter?.name,
                        dDoe: new Date().toISOString(),
                        gEntryUserId: localStorage.getItem("gUserId"),
                        gUserId: localStorage.getItem("gUserId"),
                        sData: localStorage.getItem("ReportWizardReportshuduleDateTable"),
                        sGridViewName: "grvReportArchive",
                        bDefault: true,
                      },
                      setshowCretaeNewFilterpopup,
                      getQueryFilterReportWizard,
                      setToastConfig,
                      toastConfig,
                      setfield,
                      field,
                      setfilterdata
                    );
                  } else if (e.itemData.text === "Rename Filter") {
                    seteditname(true);
                    setshowCretaeNewFilterpopup(true);
                  } else if (e.itemData.text === "Reset Filters") {
                    localStorage.removeItem("ReportWizardReportshuduleDateTable");
                    settablereload(!tablereload);
                  } else if (e.itemData.text === "Share Filter") {
                    setItemTypeswitch(false);
                    setsharequeryshow(true);
                  } else if (e.itemData.text === "Delete Filter") {
                    clas.deleteFilterFilterReportWizard(
                      setLoading,
                      navigate,
                      field?.filter?.id,
                      getQueryFilterReportWizard
                    );
                  }
                }}
              />
            </div>
            <div className="ChecklistTabbuttons">
              <button
                className="btn"
                onClick={() => {
                  setrefresh(!refresh);
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_5486)">
                    <path
                      d="M17.6493 6.35097C16.0193 4.72097 13.7093 3.78097 11.1693 4.04097C7.49929 4.41097 4.47929 7.39097 4.06929 11.061C3.51929 15.911 7.26929 20.001 11.9993 20.001C15.1893 20.001 17.9293 18.131 19.2093 15.441C19.5293 14.771 19.0493 14.001 18.3093 14.001C17.9393 14.001 17.5893 14.201 17.4293 14.531C16.2993 16.961 13.5893 18.501 10.6293 17.841C8.40929 17.351 6.61929 15.541 6.14929 13.321C5.30929 9.44097 8.25929 6.00097 11.9993 6.00097C13.6593 6.00097 15.1393 6.69097 16.2193 7.78097L14.7093 9.29097C14.0793 9.92097 14.5193 11.001 15.4093 11.001H18.9993C19.5493 11.001 19.9993 10.551 19.9993 10.001V6.41097C19.9993 5.52097 18.9193 5.07097 18.2893 5.70097L17.6493 6.35097Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_5486">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => {
                  setexportPopupOpen(true);
                }}
                id="myBtn"
                className="btn btnlightblue"
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div style={{ margin: "1%" }}>
            <div style={{ marginTop: "1%" }}>
              <Table2
                Tablecoloum={Tablecoloum}
                tableRow={data}
                editTable={false}
                load={false}
                coloumWidthText={"ReportWizardReportshuduleDateTable"}
                height={"auto"}
                mandatoryColoum={"Offender #"}
                showTotalBlock={false}
                settablereload={settablereload}
                tablereload={tablereload}
                coloumchosser={true}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={25}
                exportFont={8}
                exporttitle={"Report Schedule"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
