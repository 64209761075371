import React, { useEffect } from "react";
import FinancialCrumbs from "./components/FinancialCrumbs";
import FinancialTabs from "./components/FinancialTabs";
import "./financialPage.css";
import { DateBox, LoadPanel, SelectBox } from "devextreme-react";
import { useState } from "react";
import { Toster } from "../../components/Toster/Toster";
import { disbursementObligationListTableTitle, schedulingDateRanges } from "./Data";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { FinancialFunction } from "./FinancialFunction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setdisbursementCheckObligationListDate } from "../../reducer/AdminReadReducer";
import { useDispatch } from "react-redux";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { getAccessData } from "../../utils/getAccessPermission";

const DisbursementObligationList = () => {
  const { disbursementCheckObligationListDate } = useSelector((store) => store.AdminTable);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const { userAccessData } = useSelector((store) => store.Home);
  const [loading, setLoading] = useState(false);
  const [disbursementErrorHandling, setDisbursementErrorHandling] = useState(false);
  const [disbursementObligationListData, setDisbursementObligationListData] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [date, setdate] = useState({
    startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const today = new Date();
  const [quickDatevalue, setquickDatevalue] = useState("Month to Date");
  const ApiClass = new FinancialFunction();
  const navigate = useNavigate();
  const [disbursementObligationRowListData, setDisbursementObligationRowListData] = useState();
  const dispatch = useDispatch();

  const refreshApi = () => {
    if (date.endDate !== "Invalid Date" && date.startDate !== "Invalid Date") {
      ApiClass.getDisbursementCheckObligationlistData(
        setLoading,
        navigate,
        setDisbursementObligationListData,
        setDisbursementErrorHandling,
        date
      );
    }
  };

  useEffect(() => {
    if (!disbursementCheckObligationListDate?.startDate && !disbursementCheckObligationListDate?.startDate) {
      refreshApi();
    } else {
      setdate(disbursementCheckObligationListDate);
      ApiClass.getDisbursementCheckObligationlistData(
        setLoading,
        navigate,
        setDisbursementObligationListData,
        setDisbursementErrorHandling,
        disbursementCheckObligationListDate
      );
    }
  }, [date]);

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "DC4");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
  }, []);


  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <FinancialCrumbs title={"Disbursement Check Obligation List"} />
      <div className="financial-page">
        {/* <FinancialTabs /> */}
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">DC.4</p>
          {viewAccess ? (
            <div
              style={{
                pointerEvents: editAccess || addAccess ? "auto" : "none",
                opacity: editAccess || addAccess ? 1 : 0.6,
                cursor: editAccess || addAccess ? "default" : "not-allowed",
              }}
            >
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  refreshApi();
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="page-details-search-content">
            <div className="display-flex-div">
              <label className="edit_container_label">Create Date</label>
              <DateBox
                useMaskBehavior={true}
                value={date.startDate}
                onValueChange={(e) => {
                  dispatch(setdisbursementCheckObligationListDate([]));
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setdate({
                      ...date,
                      startDate: e ? utcFormatted : null,
                    });
                  }
                }}
              />
            </div>
            <div className="display-flex-div">
              <label className="edit_container_label">To</label>
              <DateBox
                useMaskBehavior={true}
                value={date.endDate}
                onValueChange={(e) => {
                  dispatch(setdisbursementCheckObligationListDate([]));
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setdate({
                      ...date,
                      endDate: e ? utcFormatted : null,
                    });
                  }
                }}
              />
            </div>
            {/* <div className="buttons">
              <button className="btn">
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div> */}
            <div className="display-flex-div">
              <label className="edit_container_label">Quick Date</label>
              <SelectBox
                dataSource={schedulingDateRanges}
                valueExpr="name"
                displayExpr="name"
                searchEnabled={true}
                searchExpr={"name"}
                value={quickDatevalue}
                dropDownOptions={{ height: "180px" }}
                onItemClick={(e) => {
                  dispatch(setdisbursementCheckObligationListDate([]));
                  setquickDatevalue(e?.itemData?.name);
                  let startDate = null;
                  let endDate = null;
                  switch (e?.itemData?.name) {
                    case "Last 3 Days":
                      startDate = new Date();
                      startDate.setDate(startDate.getDate() - 2);
                      endDate = new Date();
                      break;
                    case "Last 7 Days":
                      startDate = new Date();
                      startDate.setDate(startDate.getDate() - 6);
                      endDate = new Date();
                      break;
                    case "Month to Date":
                      startDate = new Date();
                      startDate.setDate(1);
                      endDate = new Date();
                      break;
                    case "Previous Month":
                      startDate = new Date();
                      startDate.setMonth(startDate.getMonth() - 1);
                      startDate.setDate(1);
                      endDate = new Date();
                      endDate.setDate(0);
                      break;
                    case "Next Month from Date":
                      startDate = new Date();
                      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
                      break;
                    case "Next 3 Month from Date":
                      startDate = new Date();
                      endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0);
                      break;
                    case "last 3 Month to Date":
                      startDate = new Date();
                      startDate.setMonth(startDate.getMonth() - 2);
                      startDate.setDate(1);
                      endDate = new Date();
                      break;
                    case "last 6 Month to Date":
                      startDate = new Date();
                      startDate.setMonth(startDate.getMonth() - 5);
                      startDate.setDate(1);
                      endDate = new Date();
                      break;
                    case "Year to Date":
                      startDate = new Date();
                      startDate.setMonth(0);
                      startDate.setDate(1);
                      endDate = new Date();
                      break;
                    case "Previous Year":
                      startDate = new Date();
                      startDate.setFullYear(startDate.getFullYear() - 1);
                      startDate.setMonth(0);
                      startDate.setDate(1);
                      endDate = new Date();
                      endDate.setFullYear(endDate.getFullYear() - 1);
                      endDate.setMonth(11);
                      endDate.setDate(31);
                      break;
                    case "Today":
                    default:
                      startDate = new Date();
                      endDate = new Date();
                      break;
                  }
                  setdate({
                    startDate: startDate.toISOString().slice(0, 10),
                    endDate: endDate.toISOString().slice(0, 10),
                  });
                }}
              />
            </div>
          </div>
          <div className="disbursement-error-handling">
            {disbursementErrorHandling ? (
              <ApiError />
            ) : (
              <Table1
                editTable={false}
                height={"auto"}
                Tablecoloum={disbursementObligationListTableTitle}
                tableRow={disbursementObligationListData?.map((val, i) => {
                  return { ...val, ID: i };
                })}
                load={loading}
                coloumWidthText={"Disbursement-Check-List"}
                mandatoryColoum={"Check Date"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={27}
                exporttitle={"Disbursement Check List"}
                disbursementObligationCheckListMenu={true}
                setDisbursementObligationRowListData={setDisbursementObligationRowListData}
                disbursementObligationRowListData={disbursementObligationRowListData}
              />
            )}
          </div>
          </div>):"No view access"}
        </div>
        
      </div>
      
    </>
  );
};

export default DisbursementObligationList;
