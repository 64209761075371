import { DateBox, DropDownBox, LoadPanel, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import DataGrid, { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { ApiError } from "../../../../Error/ApiError";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
        // margin={"7px"}
      />
    </>
  );
};

export const EditRevocationsPopup = ({
  editRevocationspopup,
  seteditRevocationspopup,
  docketDropdownData,
  reset,
  setReset,
  dropdownData,
  toastConfig,
  setToastConfig,
  getRevocationsDataApi,
  getEDitdataRevocations,
  GoToRevocationDocketDisable,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [courtDropdown, setcourtDropdown] = useState([]);
  const [judgDropdown, setjudgDropdown] = useState([]);
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [courtAppearances, setcourtAppearances] = useState([]);
  const [dispositions, setdispositions] = useState([]);

  const [fields, setfields] = useState({
    Court: { name: "", id: "" },
    Judge: { name: "", id: "" },
    Disposition: { name: "", id: "" },
    Remarks: "",
    "Hearing Date": "",
  });

  const [CourtAppearanceType, setCourtAppearanceType] = useState({
    "Court Appearance Type": { name: "", id: "" },
  });

  const [Courtvalidation, setCourtvalidation] = useState("");
  const [Judgevalidation, setJudgevalidation] = useState("");
  const [CourtAppearanceTypevalidation, setCourtAppearanceTypevalidation] = useState("");
  const [Dispositionvalidation, setDispositionvalidation] = useState("");
  const [Remarksvalidation, setRemarksvalidation] = useState("");
  const [HearingDatevalidation, setHearingDatevalidation] = useState("");
  const [docketvalidation, setdocketvalidation] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const navigate = useNavigate();

  const [errorhandling, seterrorhandling] = useState();

  const getDropdown = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/revocation/get-revocation-dropdowns`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          val?.data?.data?.courtAppearances.length &&
            setcourtAppearances(
              val?.data?.data?.courtAppearances?.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );
          val?.data?.data?.dispositions.length &&
            setdispositions(
              val?.data?.data?.dispositions?.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );
          seterrorhandling(false);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  const OnSave = async (body) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/revocation/get-revocations`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully updated!",
              type: "success",
            });
          }, 1400);
          seteditRevocationspopup(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }

        resetFUnc();
        getRevocationsDataApi();

        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const save = () => {
    if (
      fields?.Remarks &&
      CourtAppearanceType?.["Court Appearance Type"].id &&
      selectedValue?.gDocketId &&
      fields?.Disposition.id &&
      fields?.["Hearing Date"] &&
      fields?.Court.id &&
      fields?.Judge.id
    ) {
      setRemarksvalidation("");
      setCourtAppearanceTypevalidation("");
      setdocketvalidation("");
      setDispositionvalidation("");
      setHearingDatevalidation("");
      setCourtvalidation("");
      setJudgevalidation("");
      OnSave({
        gRevocationId: getEDitdataRevocations?.gRevocationId,
        gDocketId: selectedValue?.gDocketId,
        dHearing: fields?.["Hearing Date"],
        gCourtEntityId: fields?.Court.id,
        gJudgeContactId: fields?.Judge.id,
        gDispositionId: fields?.Disposition.id,
        sViolation: fields?.Remarks,
        gCourtAppearanceTypeId: CourtAppearanceType?.["Court Appearance Type"].id,
      });
    } else {
      !fields?.Remarks && setRemarksvalidation("Please Enter Remarks");
      !CourtAppearanceType?.["Court Appearance Type"].id &&
        setCourtAppearanceTypevalidation("Please Select Court Appearance Type");
      !selectedValue?.gDocketId && setdocketvalidation("Please Select Docket");
      !fields?.Disposition.id && setDispositionvalidation("Please Select Disposition");
      !fields?.["Hearing Date"] && setHearingDatevalidation("Please Select Hearing Date");
      !fields?.Court.id && setCourtvalidation("Please Select Court");
      !fields?.Judge.id && setJudgevalidation("Please Select Judge");
    }
  };

  const getgetjudgescourts = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/revocation/get-judges-courts?docketId=${selectedValue?.gDocketId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        const judgeDropdownData = val?.data?.data;

        judgeDropdownData?.courts?.length &&
          setcourtDropdown(
            judgeDropdownData?.courts?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
        judgeDropdownData?.judges?.length &&
          setjudgDropdown(
            judgeDropdownData?.judges?.map((item) => {
              return {
                name: item?.sJudgeName,
                id: item?.gContactId,
              };
            })
          );
        if (!judgeDropdownData?.courts || !judgeDropdownData?.judges) {
          setcourtDropdown([]);
          setfields({
            ...fields,
            Court: {
              name: "",
              id: "",
            },
          });
          setjudgDropdown([]);
          setfields({
            ...fields,
            Judge: {
              name: "",
              id: "",
            },
          });
        } else {
          if (judgeDropdownData?.judges?.length > 1) {
            setfields({
              ...fields,
              Court: {
                name: judgeDropdownData?.courts[0]?.value,
                id: judgeDropdownData?.courts[0]?.key,
              },
            });
          } else {
            setfields({
              ...fields,
              Court: {
                name: judgeDropdownData?.courts[0]?.value,
                id: judgeDropdownData?.courts[0]?.key,
              },
              Judge: {
                name: judgeDropdownData?.judges[0]?.sJudgeName,
                id: judgeDropdownData?.judges[0]?.gContactId,
              },
            });
          }
          setJudgevalidation("");
          setCourtvalidation("");
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    selectedValue?.gDocketId && getgetjudgescourts();
  }, [selectedValue?.gDocketId]);

  useEffect(() => {
    const fetchedDataSource = docketDropdownData?.map((val) => {
      const convertDateFormat = (dateString) => {
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US");
      };
      return {
        Court: val?.sCourtName,
        Docket: val?.sDocketNumberCourtCounts,
        "Sentence Date": convertDateFormat(val?.dSentence),
        "Supervision Status": val?.sSupervisionStatusName,
        gDocketId: val?.gDocketId,
        Offense: val?.sOffenseDescription,
      };
    });
    setDataSource(fetchedDataSource);
    if (getEDitdataRevocations) {
      const selectedDocket = fetchedDataSource?.find((item) => {
        return item.gDocketId === getEDitdataRevocations?.gDocketId;
      });
      setSelectedValue(selectedDocket);
    }
  }, [docketDropdownData, getEDitdataRevocations]);

  useEffect(() => {
    dropdownData?.courts?.length &&
      setcourtDropdown(
        dropdownData?.courts.map((val) => {
          return {
            name: val?.value,
            id: val?.key,
          };
        })
      );
  }, [dropdownData]);

  const resetFUnc = () => {
    setRemarksvalidation("");
    setCourtAppearanceTypevalidation("");
    setdocketvalidation("");
    setDispositionvalidation("");
    setHearingDatevalidation("");
    setCourtvalidation("");
    setJudgevalidation("");
    setfields({
      Court: {
        name: getEDitdataRevocations?.Court,
        id: getEDitdataRevocations?.gCourtEntityId,
      },
      Judge: {
        name: getEDitdataRevocations?.Judge,
        id: getEDitdataRevocations?.gJudgeContactId,
      },
      Disposition: {
        name: getEDitdataRevocations?.Disposition,
        id: getEDitdataRevocations?.gDispositionId,
      },
      Remarks: getEDitdataRevocations?.Violation,
      "Hearing Date": getEDitdataRevocations?.["Hearing Date"],
    });
  };

  useEffect(() => {
    getDropdown();
  }, []);

  useEffect(() => {
    if (courtAppearances.length) {
      setCourtAppearanceType({
        "Court Appearance Type": {
          name: getEDitdataRevocations?.["Appearance Type"],
          id: getEDitdataRevocations?.gCourtAppearanceTypeId,
        },
      });
    }
  }, [getEDitdataRevocations, courtAppearances]);

  useEffect(() => {
    resetFUnc();
  }, [reset, getEDitdataRevocations]);
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      {errorhandling ? (
        <ApiError />
      ) : (
        <ScrollView>
          <LoadPanel shadingColor="true" visible={loading} delay={10} />
          <div className="RecivePaymentModel_header">
            <div style={{ width: "180px" }} className="RecivePaymentModel_header_name">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_692_372834)">
                  <path
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_692_372834">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p style={{ marginLeft: "-10px" }}>Edit Revocations</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  RV.1
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setReset(!reset);
                seteditRevocationspopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>

          <div style={{ width: "94%", marginLeft: "3%" }}>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Docket <span style={{ fontSize: "14px" }}>*</span>
                </p>
                <div>
                  <DropDownBox
                    placeholder="Select..."
                    disabled={true}
                    style={{ marginTop: "3.5%" }}
                    dataSource={dataSource}
                    value={selectedValue?.Docket}
                    valueExpr={"Docket"}
                    displayExpr={"Docket"}
                    opened={isGridBoxOpened}
                    onOptionChanged={onGridBoxOpened}
                    contentRender={(value, element) => (
                      <DataGrid
                        dataSource={dataSource}
                        hoverStateEnabled={true}
                        height={"auto"}
                        onRowClick={(e) => {
                          setSelectedValue(e?.data);
                          setdocketvalidation("");
                          setIsGridBoxOpened(false);
                        }}
                      >
                        <Selection mode="single" />
                        <FilterRow visible={true} />
                        <FilterRow visible={true} />
                        <Column dataField="Court" caption="Court" width={"auto"} />
                        <Column dataField="Docket" caption="Docket" width={"auto"} />
                        <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                        <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                        <Column dataField="Offense" caption="Offense" width={"auto"} />
                      </DataGrid>
                    )}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {docketvalidation}
                  </p>
                </div>
              </div>
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Court <span style={{ fontSize: "14px" }}>*</span>
                </p>
                <div>
                  <DropDown
                    setfields={setfields}
                    fields={fields}
                    fieldNmae={"Court"}
                    dropDownData={courtDropdown}
                    reset={setCourtvalidation}
                    disabled={true}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {Courtvalidation}
                  </p>
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Judge <span style={{ fontSize: "14px" }}>*</span>
                </p>
                <div>
                  <DropDown
                    setfields={setfields}
                    fields={fields}
                    fieldNmae={"Judge"}
                    dropDownData={judgDropdown}
                    reset={setJudgevalidation}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {Judgevalidation}
                  </p>
                </div>
              </div>
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Hearing Date <span style={{ fontSize: "14px" }}>*</span>
                </p>
                <div>
                  <DateBox
                    style={{ marginTop: "3.5%" }}
                    value={fields["Hearing Date"]}
                    onValueChange={(e) => {
                      setHearingDatevalidation("");
                      setfields({
                        ...fields,
                        "Hearing Date": e
                          ? new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA")
                          : null,
                      });
                    }}
                    useMaskBehavior={true}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {HearingDatevalidation}
                  </p>
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Court Appearance Type <span style={{ fontSize: "14px" }}>*</span>
                </p>
                <div>
                  <DropDown
                    setfields={setCourtAppearanceType}
                    fields={CourtAppearanceType}
                    fieldNmae={"Court Appearance Type"}
                    dropDownData={courtAppearances}
                    reset={setCourtAppearanceTypevalidation}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {CourtAppearanceTypevalidation}
                  </p>
                </div>
              </div>
              <div className="collectionpopupBLOCK2inputt">
                <p>
                  Disposition <span style={{ fontSize: "14px" }}>*</span>
                </p>
                <div>
                  <DropDown
                    setfields={setfields}
                    fields={fields}
                    fieldNmae={"Disposition"}
                    dropDownData={dispositions}
                    reset={setDispositionvalidation}
                  />
                  <p
                    style={{
                      color: "red",
                      fontWeight: "400",
                      position: "absolute",
                    }}
                  >
                    {Dispositionvalidation}
                  </p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "4%" }}>
              <p style={{ fontWeight: "bold" }}>
                Remarks <span style={{ fontSize: "14px" }}>*</span>
              </p>
              <textarea
                value={fields?.Remarks}
                onChange={(e) => {
                  setfields({ ...fields, Remarks: e.target.value });
                  setRemarksvalidation("");
                }}
                style={{ width: "100%", height: "150px", marginTop: "1.4%" }}
              />
              <p
                style={{
                  color: "red",
                  fontWeight: "400",
                  position: "absolute",
                  fontWeight: "bold",
                }}
              >
                {Remarksvalidation}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  width: "15%",
                  cursor: "pointer",
                  marginRight: "1%",
                }}
                onClick={() => {
                  save();
                }}
              >
                Save
              </button>
              <button
                style={{
                  background: "var(--button-lightblack-lightblue)",
                  color: "var(--icons2)",
                  width: "15%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  resetFUnc();
                  seteditRevocationspopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      )}
    </>
  );
};
