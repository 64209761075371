import React, { useCallback, useState } from "react";
import "./drugTest.css";
// import { ColumnDropdown } from "../../../../../components/Dropdownn";
import DataGrid, { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { DropDownBox, SelectBox } from "devextreme-react";
import { useEffect } from "react";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { useDispatch } from "react-redux";
import { setDrugDropdown } from "../../../../../reducer/AdminReadReducer";

// const DropDown = ({
//   dropDownData,
//   setfields,
//   fields,
//   fieldNmae,
//   reset,
//   disabled,
// }) => {
//   return (
//     <>
//       <ColumnDropdown
//         data={dropDownData}
//         setfields={setfields}
//         fields={fields}
//         fieldNmae={fieldNmae}
//         value={fields[fieldNmae]?.name}
//         reset={reset}
//         disabled={disabled}
//       />
//     </>
//   );
// };

export const AddDrugTest = ({
  addDrugTest,
  setAddDrugTest,
  userinfo,
  dropdownData,
  docketDropdownData,
  SetDoNotCreateObligation,
  amount,
  setAmount,
  existingDocketDropdownData,
  dockertDetail,
  gotoDrugTest,
}) => {
  const [fields, setFields] = useState({ feeCode: { name: "", id: "" } });
  const [selectedValue, setSelectedValue] = useState({
    Court: "",
    Docket: "",
    "Sentence Date": "",
    "Supervision Status": "",
    gDocketId: "",
  });
  const [existingSelectedValue, setExistingSelectedValue] = useState({
    Reference: "",
    "Fee Name": "",
    Court: "",
    "Requisition Number": "",
  });
  const [dataSource, setDataSource] = useState([]);
  const [existingdataSource, setExistingdataSource] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [isNewObligationVisible, setIsNewObligationVisible] = useState(false);
  const [isExistingObligationVisible, setIsExistingObligationVisible] = useState(false);
  const [isDoNotCreateVisible, setIsDoNotCreateVisible] = useState(false);
  const [docketvalidation, setDocketvalidation] = useState("");
  const [existingDocketvalidation, setExistingDocketvalidation] = useState("");
  const [amountValidation, setAmountValidation] = useState("");
  const [feeCodevalidation, setFeeCodevalidation] = useState("");
  const [feeCodeDropdown, setFeeCodeDropdown] = useState([]);
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const dispatch = useDispatch();
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isGridBoxOpened2, setIsGridBoxOpened2] = useState(false);

  const getFeecodeDropdown = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/feecode-dropdown?docketId=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setFeeCodeDropdown(
          val?.data?.data?.map((item) => {
            return { name: item?.sQuickAddName, id: "", fFee: item?.fFee };
          })
        );
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    setFeeCodeDropdown([]);
    setSelectedRadio("");
    setDocketvalidation("");
    setExistingDocketvalidation("");
    setAmountValidation("");
    setFeeCodevalidation("");
    setAmount(null);
    setSelectedValue({
      Court: "",
      "Docket #": "",
      "Sentence Date": "",
      "Supervision Status": "",
      gDocketId: "",
    });
    setExistingSelectedValue({
      Reference: "",
      "Fee Name": "",
      Court: "",
      "Requisition Number": "",
    });
    setFields({ feeCode: { name: "", id: "" } });
    const fetchedDataSource = docketDropdownData?.map((val) => {
      const convertDateFormat = (dateString) => {
        const dateObj = new Date(dateString);
        return dateObj.toLocaleDateString("en-US");
      };
      return {
        Court: val?.sCourtName,
        "Docket #": val?.sDocketNumberCourtCounts,
        "Sentence Date": convertDateFormat(val?.dSentence),
        "Supervision Status": val?.sSupervisionStatusName,
        gDocketId: val?.gDocketId,
      };
    });
    setDataSource(fetchedDataSource);
    const existingFetchedDataSource = existingDocketDropdownData?.map((val) => {
      return {
        Reference: val?.sReference,
        "Fee Name": val?.sFeeName,
        Court: val?.sCourtName,
        "Requisition Number": val?.s,
        gDocketId: val?.gDocketId,
        "Docket #": val?.sDocketNumberCourtCounts,
        fFee: val?.fFee,
      };
    });
    setExistingdataSource(existingFetchedDataSource);
  }, [addDrugTest]);

  const handleSave = () => {
    let isValid = true;
    if (!selectedValue?.gDocketId && selectedRadio === "newObligation") {
      setDocketvalidation("Please Select Docket");
      isValid = false;
    }
    if (!fields.feeCode.name && selectedRadio === "newObligation") {
      setFeeCodevalidation("Please Select Fee Code");
      isValid = false;
    }
    if (!existingSelectedValue?.[`Fee Name`] && selectedRadio === "existingObligation") {
      setExistingDocketvalidation("Please Select Existing Obligation");
      isValid = false;
    }
    if (!amount && selectedRadio !== "doNotCreateObligation") {
      setAmountValidation("Please Enter Anount");
      isValid = false;
    }
    if (isValid) {
      if (selectedRadio === "") {
        SetDoNotCreateObligation(false);
      } else {
        SetDoNotCreateObligation(true);
      }
    }
  };

  function firstSelectempty() {
    setDocketvalidation("");
    setSelectedValue({
      Court: "",
      "Docket #": "",
      "Sentence Date": "",
      "Supervision Status": "",
      gDocketId: "",
    });
    setFeeCodevalidation("");
    setFields({ feeCode: { name: "", id: "" } });
  }
  function secondempty() {
    setExistingSelectedValue({
      Reference: "",
      "Fee Name": "",
      Court: "",
      "Requisition Number": "",
    });
  }
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);
  const onGridBoxOpened2 = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened2(e.value);
    }
  }, []);

  return (
    <>
      <div className="add_drug_test">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="add" clipPath="url(#clip0_5349_5505)">
                  <path
                    id="Vector"
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5349_5505">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Add Recurring Test</p>
            </div>
            <p class="popup_header_title_para">DT.13</p>
          </div>
          <svg
            onClick={() => {
              setAddDrugTest(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="add_drug_test_container" style={{ height: "510px" }}>
          <div className="add_drug_flex">
            <p className="add_drug_userinfo_para">{userinfo?.sOffenderNumberWithPin}</p>
            <p className="add_drug_userinfo_para add_drug_userinfo_sub_para">{userinfo?.sLastFirst}</p>
          </div>
          <div className="add_drug_flex">
            <div className="add_drug_flex">
              <input
                type="radio"
                name="obligation"
                id="newObligation"
                checked={selectedRadio === "newObligation"}
                onChange={() => {
                  setSelectedRadio("newObligation");
                  setIsNewObligationVisible(true);
                  setIsExistingObligationVisible(false);
                  setIsDoNotCreateVisible(false);
                  setExistingDocketvalidation("");
                  secondempty();
                  setAmount("");
                  const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
                  setSelectedValue(selectedDocket);
                  getFeecodeDropdown(dockertDetail?.id);
                  gotoDrugTest &&
                    dispatch(
                      setDrugDropdown({
                        drugAddDocket: {
                          "Docket #": selectedDocket?.[`Docket #`],
                          gDocketId: selectedDocket?.gDocketId,
                        },
                      })
                    );
                }}
              />
              <label className="add_drug_userinfo_para" htmlFor="newObligation">
                New Obligation
              </label>
            </div>
            <div className="add_drug_flex">
              <input
                type="radio"
                name="obligation"
                id="existingObligation"
                checked={selectedRadio === "existingObligation"}
                onChange={() => {
                  setSelectedRadio("existingObligation");
                  setIsNewObligationVisible(false);
                  setIsExistingObligationVisible(true);
                  setIsDoNotCreateVisible(false);
                  firstSelectempty();
                  setAmount("");
                  // const selectedDocket = existingdataSource?.find(
                  //   (item) => item.gDocketId === dockertDetail?.id
                  // );
                  // setExistingSelectedValue(selectedDocket);
                  // console.log(selectedDocket)
                  // console.log(existingdataSource)
                  // console.log(dockertDetail?.id)
                }}
              />
              <label className="add_drug_userinfo_para" htmlFor="existingObligation">
                Existing Obligation
              </label>
            </div>
            <div className="add_drug_flex">
              <input
                type="radio"
                name="obligation"
                id="doNotCreateObligation"
                checked={selectedRadio === "doNotCreateObligation"}
                onChange={() => {
                  setSelectedRadio("doNotCreateObligation");
                  setIsNewObligationVisible(false);
                  setIsExistingObligationVisible(false);
                  setIsDoNotCreateVisible(true);
                  firstSelectempty();
                  setAmount("");
                  setAmountValidation();
                  setExistingDocketvalidation("");
                  secondempty();
                  dispatch(
                    setDrugDropdown({
                      drugAddDocket: {},
                    })
                  );
                  const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
                  gotoDrugTest &&
                    dispatch(
                      setDrugDropdown({
                        drugAddDocket: {
                          "Docket #": selectedDocket?.[`Docket #`],
                          gDocketId: selectedDocket?.gDocketId,
                        },
                      })
                    );
                }}
              />
              <label className="add_drug_userinfo_para" htmlFor="doNotCreateObligation">
                Do Not Create Obligation
              </label>
            </div>
          </div>
          <div className="add_community_service_content" style={{ padding: "0" }}>
            <p className={`add_drug_content_para ${selectedRadio !== "newObligation" ? "disabled" : ""}`}>
              New Obligation
            </p>
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className={`edit_container_label ${selectedRadio !== "newObligation" ? "disabled" : ""}`}>
                  Docket <span className="marks_mandatary">*</span>
                </p>
                <DropDownBox
                  placeholder="Select..."
                  disabled={!isNewObligationVisible || gotoDrugTest ? true : false}
                  dataSource={dataSource}
                  opened={isGridBoxOpened}
                  onOptionChanged={onGridBoxOpened}
                  value={selectedValue?.[`Docket #`]}
                  valueExpr={"Docket #"}
                  displayExpr={"Docket #"}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={dataSource}
                      hoverStateEnabled={true}
                      height={"auto"}
                      onRowClick={(e) => {
                        dispatch(
                          setDrugDropdown({
                            drugAddDocket: {
                              "Docket #": e?.data?.[`Docket #`],
                              gDocketId: e?.data?.gDocketId,
                            },
                          })
                        );
                        setSelectedValue(e?.data);
                        getFeecodeDropdown(e?.data?.gDocketId);
                        setDocketvalidation("");
                        setIsGridBoxOpened(false);
                      }}
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <Column dataField="Court" caption="Court" width={"auto"} />
                      <Column dataField="Docket #" caption="Docket #" width={"auto"} />
                      <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                      <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                    </DataGrid>
                  )}
                />
                {docketvalidation && (
                  <p className="error_validation" style={{ position: "fixed", top: "53.2%" }}>
                    {docketvalidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space">
                <p
                  className={`edit_container_label ${selectedRadio !== "newObligation" ? "disabled" : ""}`}
                  disabled={!isNewObligationVisible}
                >
                  Fee Code <span className="marks_mandatary">*</span>
                </p>
                {/* <DropDown
                  disabled={!isNewObligationVisible}
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"feeCode"}
                  dropDownData={feeCodeDropdown}
                  reset={setFeeCodevalidation}
                /> */}
                <SelectBox
                  dataSource={feeCodeDropdown.map((vall) => {
                    return {
                      name: vall?.name,
                      id: vall?.id,
                      fFee: vall?.fFee,
                    };
                  })}
                  valueExpr="name"
                  displayExpr="name"
                  searchEnabled={true}
                  searchExpr={"name"}
                  value={fields.feeCode.name}
                  dropDownOptions={{ height: "180px" }}
                  disabled={!isNewObligationVisible}
                  onItemClick={(e) => {
                    setFields((prev) => ({
                      ...prev,
                      feeCode: {
                        ...prev.feeCode,
                        name: e?.itemData?.name,
                      },
                    }));
                    setFeeCodevalidation("");
                    setAmount(e?.itemData?.fFee !== 0 ? e?.itemData?.fFee : "0");
                    setAmountValidation();
                  }}
                />
                {feeCodevalidation && (
                  <p className="error_validation" style={{ position: "fixed", top: "53.2%" }}>
                    {feeCodevalidation}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="add_community_service_content" style={{ padding: "0", marginBottom: "20px" }}>
            <p className={`add_drug_content_para ${selectedRadio !== "existingObligation" ? "disabled" : ""}`}>
              Existing Obligation
            </p>
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className={`edit_container_label ${selectedRadio !== "existingObligation" ? "disabled" : ""}`}>
                  Existing Obligation <span className="marks_mandatary">*</span>
                </p>
                <DropDownBox
                  placeholder="Select..."
                  disabled={!isExistingObligationVisible}
                  dataSource={existingdataSource}
                  value={existingSelectedValue?.[`Fee Name`]}
                  valueExpr={"Fee Name"}
                  displayExpr={"Fee Name"}
                  opened={isGridBoxOpened2}
                  onOptionChanged={onGridBoxOpened2}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={existingdataSource}
                      hoverStateEnabled={true}
                      height={"auto"}
                      onRowClick={(e) => {
                        setAmount(e?.data?.fFee !== 0 ? e?.data?.fFee : "0");
                        setAmountValidation();
                        dispatch(
                          setDrugDropdown({
                            drugAddDocket: {
                              "Docket #": e?.data?.[`Docket #`],
                              gDocketId: e?.data?.gDocketId,
                            },
                          })
                        );
                        setExistingSelectedValue(e?.data);
                        setExistingDocketvalidation("");
                        setIsGridBoxOpened2(false);
                      }}
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <Column dataField="Reference" caption="Reference" width={"auto"} />
                      <Column dataField="Fee Name" caption="Fee Name" width={"auto"} />
                      <Column dataField="Court" caption="Court" width={"auto"} />
                      <Column dataField="Requisition Number" caption="Requisition Number" width={"auto"} />
                    </DataGrid>
                  )}
                />
                {existingDocketvalidation && (
                  <p className="error_validation" style={{ position: "fixed", top: "80.2%" }}>
                    {existingDocketvalidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex">
                <p className={`edit_container_label ${selectedRadio === "doNotCreateObligation" ? "disabled" : ""}`}>
                  Amount <span className="marks_mandatary">*</span>
                </p>
                <input
                  id="amount"
                  name="amount"
                  type="number"
                  placeholder="Enter Amount"
                  className="edit_container_input"
                  value={amount || ""}
                  disabled={selectedRadio === "doNotCreateObligation"}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setAmountValidation("");
                  }}
                />
                {amountValidation && (
                  <p className="error_validation" style={{ position: "fixed", top: "80.2%" }}>
                    {amountValidation}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setAddDrugTest(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
