import React, { useEffect, useState } from "react";
import { Tablecoloum } from "./TableData";
import "./person.css";
import { Table2 } from "../../components/Table2/Table2";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ScrollView } from "devextreme-react";
import { logOut } from "../../components/Logout";
import { useSelector } from "react-redux";
import { getAccessData } from "../../utils/getAccessPermission";
import { setDynamicTab } from "../../reducer/HomePageReducer";
import { set_dynamicParticipatTab } from "../../reducer/ParticipateReducer";

const setTableData = (data) => {
  const arr = [];
  data.map((val) => {
    const date = new Date(val?.dDOB);
    arr.push({
      Name: val.sLastFirst,
      Supervisor: val.sPrimarySupervisorName,
      DOB: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      SSN: val?.sSSN ? `***-**-${val?.sSSN?.slice(5)}` : "",
      "Offender #": val.sOffenderNumber,
      "Offender # PIN": val.sOffenderNumberPIN,
      "Person Status": val.sPersonSupervisionStatusName,
      "Primary Location": val.sGPM_LocationName,
      Balance: val.fBalance,
      "Co-Obligor": `${val.sGuarantorLastName == null ? "" : val.sGuarantorLastName} ${val.sGuarantorFirstName == null ? "" : val.sGuarantorFirstName
        }`,
      Phone: val?.sInformation,
      Type: val?.sContactInfoTypeName,
      "Home Address": val.sHomeAddressBlock,
      ID: val.gPersonId,
      gPersonId: val.gPersonId,
      sLastName: val?.sLastName,
    });
  });

  return arr;
};

const PersonModal = ({
  setShowPersonModal,
  nojump,
  formjump,
  fromOP3,
  fromPersonOverview,
  setConfirmationPopup,
  setConfirmationData,
  setFormsPageMenu,
}) => {
  const pathname = handleGetLocal("pathURL");
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_data } = useSelector((store) => store.Home);
  const { userAccessData } = useSelector((store) => store.Home);

  const [viewAccess, setViewAccess] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [data, setdata] = useState([]);
  const [search, setsearch] = useState("");
  const [load, setLoad] = useState(false);
  const [activeOnly, setactiveOnly] = useState(true);
  const [location, setlocation] = useState(true);
  const navigate = useNavigate();

  const saveCookieCrum = async (personId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${personId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
    } catch (error) {
      console.error("Error saving cookie crumb:", error);
    }
  };

  const getPerson = async (advance) => {
    setLoad(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Person/PersonFind`,
        {
          sSearch: search,
          bAdvanced: advance,
          bActiveOnly: activeOnly,
          sGPM_LocationName: location ? user_data?.sLocationName : "%",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );

      const persons = setTableData(response?.data?.data);
      setdata(persons);
      setLoad(false);

      if (persons.length === 1) {
        const offenderNo = persons[0]?.["Offender #"];
        const personId = persons[0]?.gPersonId
        const personName = persons[0]?.Name.split(",")[0];

        if (personId) {
          if (fromOP3) {
            setConfirmationData(persons[0]);
            return;
          }
          if (fromPersonOverview) {
            setConfirmationData(persons[0]);
            return;
          }
          await saveCookieCrum(personId);
          dispatch(
            setDynamicTab({
              Offender: offenderNo,
              link: `/participant/${offenderNo}`,
              title: personName,
            })
          );
          // Adding a slight delay to ensure the route transition is smooth
          setTimeout(() => {
            navigate(`/participant/${offenderNo}`);
            setShowPersonModal(false);
          }, 100);
        } else {
          console.error("Invalid Person ID:", personId);
        }
      }
    } catch (error) {
      setLoad(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      }
    }
  };

  const personFindByAddressPhone = async () => {
    setLoad(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/personFindByAddressPhone`,
        {
          sSearch: search,
          bAdvanced: false,
          bActiveOnly: activeOnly,
          sGPM_LocationName: location ? user_data?.sLocationName : "%",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setdata(setTableData(val?.data.data));
        setLoad(false);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    const getUserAccessPermissions = () => {
      const accessData = getAccessData(userAccessData, "PS2");
      setAddAccess(accessData?.Add);
      setEditAccess(accessData?.Edit);
      setViewAccess(accessData?.View);
    };
    getUserAccessPermissions();
  }, []);

  const resetPersonFind = () => {
    setdata([]);
    setsearch('');
  }

  return (
    <div style={{ border: "3px solid  var(--button2)" }} className="PersonModalbody">
      <ScrollView>
        <div className="PersonModal">
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "18%",
            }}
            className="modelHeader"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginTop: "4px",
                marginLeft: "8%",
              }}
            >
              <path
                fill="white"
                d="M12.5006 10.9999H11.7106L11.4306 10.7299C12.6306 9.32989 13.2506 7.41989 12.9106 5.38989C12.4406 2.60989 10.1206 0.389893 7.32063 0.0498932C3.09063 -0.470107 -0.469374 3.08989 0.0506256 7.31989C0.390626 10.1199 2.61063 12.4399 5.39063 12.9099C7.42063 13.2499 9.33063 12.6299 10.7306 11.4299L11.0006 11.7099V12.4999L15.2506 16.7499C15.6606 17.1599 16.3306 17.1599 16.7406 16.7499C17.1506 16.3399 17.1506 15.6699 16.7406 15.2599L12.5006 10.9999ZM6.50063 10.9999C4.01063 10.9999 2.00063 8.98989 2.00063 6.49989C2.00063 4.00989 4.01063 1.99989 6.50063 1.99989C8.99063 1.99989 11.0006 4.00989 11.0006 6.49989C11.0006 8.98989 8.99063 10.9999 6.50063 10.9999Z"
              />
            </svg>
            <p
              style={{
                color: "white",
                fontWeight: "500",
                fontSize: "13px",
              }}
            >
              Person Find{" "}
            </p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
                marginTop: "-1%,",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                PS.2
              </p>
            </span>
          </div>
          <span
            style={{
              fontSize: "30px",
              marginRight: "2%",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setsearch('');
              if (setFormsPageMenu) {
                setFormsPageMenu(true);
              }
              setdata([]);
              setShowPersonModal(false);
            }}
          >
            &times;
          </span>
        </div>
        <div
          style={{
            padding: "24px",
            height: "583px",
            background: "var(--popup-background-white-black)",
          }}
        >
          <div className="buttons">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (search) {
                  getPerson(false);
                }
              }}
              className="personfindinput"
            >
              <input
                type="text"
                id="TESTE"
                value={search}
                style={{
                  padding: "9px",
                  border: "0.8px solid rgb(187 187 193)",
                  borderRadius: "3px",
                }}
                onChange={(e) => {
                  setsearch(e.target.value);
                }}
              />
            </form>
            <button
              onClick={() => {
                if (search) {
                  getPerson(false);
                }
              }}
              className="primary_btn"
            >
              Find
              <svg width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5006 10.9999H11.7106L11.4306 10.7299C12.6306 9.32989 13.2506 7.41989 12.9106 5.38989C12.4406 2.60989 10.1206 0.389893 7.32063 0.0498932C3.09063 -0.470107 -0.469374 3.08989 0.0506256 7.31989C0.390626 10.1199 2.61063 12.4399 5.39063 12.9099C7.42063 13.2499 9.33063 12.6299 10.7306 11.4299L11.0006 11.7099V12.4999L15.2506 16.7499C15.6606 17.1599 16.3306 17.1599 16.7406 16.7499C17.1506 16.3399 17.1506 15.6699 16.7406 15.2599L12.5006 10.9999ZM6.50063 10.9999C4.01063 10.9999 2.00063 8.98989 2.00063 6.49989C2.00063 4.00989 4.01063 1.99989 6.50063 1.99989C8.99063 1.99989 11.0006 4.00989 11.0006 6.49989C11.0006 8.98989 8.99063 10.9999 6.50063 10.9999Z"
                  fill="white"
                />
              </svg>
            </button>

            <button
              onClick={() => {
                dispatch(
                  // setDynamicTab({
                  //   Offender: "/add-participant",
                  //   link: "/add-participant",
                  //   title: "Add Participant",
                  // })
                  set_dynamicParticipatTab({
                    Offender: "/add-participant",
                    link: "/add-participant",
                    title: "Add Participant",
                  })
                );
                Navigate("/add-participant");
                setShowPersonModal(false);
              }}
              className="primary_btn"
              disabled={!addAccess}
            >
              Add Participant
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                if (search) {
                  getPerson(true);
                }
              }}
              className="primary_btn"
            >
              Advanced
              <span
                style={{
                  position: "relative",
                  top: "3px",
                  marginRight: "7px",
                }}
              >
                <svg
                  style={{
                    position: "absolute",
                    left: "4px",
                    top: "-2px",
                  }}
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
                    fill={"white"}
                  />
                </svg>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.35 0.0100234C5.62 -0.0899766 0 1.27002 0 4.00002V5.00002C0 5.55002 0.45 6.00002 1 6.00002H9.54C7.07 3.24002 8.31 0.110023 8.35 0.0100234Z"
                    fill={"white"}
                  />
                </svg>
                <svg
                  style={{
                    position: "absolute",
                    left: "9px",
                    top: "6px",
                  }}
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.42865 6.02003C8.89865 5.22003 9.12865 4.25003 8.90865 3.20003C8.56865 1.56003 7.18865 0.25003 5.52865 0.0400301C2.89865 -0.29997 0.67865 1.91003 1.02865 4.54003C1.24865 6.20003 2.54865 7.58003 4.18865 7.92003C5.23865 8.14003 6.20865 7.91003 7.00865 7.44003L8.86865 9.30003C9.25865 9.69003 9.88865 9.69003 10.2787 9.30003C10.6687 8.91003 10.6687 8.28003 10.2787 7.89003L8.42865 6.02003ZM4.99865 6.00003C3.89865 6.00003 2.99865 5.10003 2.99865 4.00003C2.99865 2.90003 3.89865 2.00003 4.99865 2.00003C6.09865 2.00003 6.99865 2.90003 6.99865 4.00003C6.99865 5.10003 6.09865 6.00003 4.99865 6.00003Z"
                    fill={"white"}
                  />
                </svg>
              </span>
            </button>
            <button
              onClick={() => {
                personFindByAddressPhone();
              }}
              className="primary_btn"
            >
              Phone/Address
              <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H22C23.1 18 23.99 17.1 23.99 16L24 2C24 0.9 23.1 0 22 0ZM8 3C9.66 3 11 4.34 11 6C11 7.66 9.66 9 8 9C6.34 9 5 7.66 5 6C5 4.34 6.34 3 8 3ZM14 15H2V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15ZM17.85 11H19.24C19.4 11 19.54 11.07 19.64 11.2L20.74 12.65C20.89 12.85 20.87 13.13 20.69 13.3L19.33 14.66C19.15 14.84 18.85 14.86 18.66 14.7C17.53 13.74 16.69 12.45 16.28 10.99C16.1 10.36 16 9.69 16 9C16 8.31 16.1 7.64 16.28 7C16.69 5.53 17.53 4.25 18.66 3.29C18.86 3.12 19.15 3.15 19.33 3.33L20.69 4.69C20.87 4.87 20.89 5.15 20.74 5.34L19.64 6.79C19.55 6.92 19.4 6.99 19.24 6.99H17.85C17.63 7.62 17.5 8.29 17.5 8.99C17.5 9.69 17.63 10.37 17.85 11Z"
                  fill="white"
                />
              </svg>
            </button>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div>
                <input
                  checked={activeOnly}
                  onClick={() => {
                    setactiveOnly(!activeOnly);
                  }}
                  type="checkbox"
                />
              </div>
              <p style={{ fontWeight: "bold", fontSize: "13px" }}>Active Only</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div>
                <input
                  checked={location}
                  onClick={() => {
                    setlocation(!location);
                  }}
                  type="checkbox"
                />
              </div>
              <p style={{ fontWeight: "bold", fontSize: "13px" }}>
                {user_data?.sLocationName ? user_data?.sLocationName : ""}
              </p>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Table2
              editTable={false}
              height={"auto"}
              Tablecoloum={Tablecoloum}
              tableRow={data}
              load={load}
              coloumWidthText={"PersonFindTableWidth"}
              setShowPersonModal={setShowPersonModal}
              mandatoryColoum={"Offender #"}
              showTotalBlock={false}
              nojump={nojump}
              fromOP3={fromOP3}
              fromPersonOverview={fromPersonOverview}
              formjump={formjump}
              setConfirmationPopup={setConfirmationPopup}
              setConfirmationData={setConfirmationData}
              resetPersonFind={resetPersonFind}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10%",
              marginTop: "1%",
            }}
          >
            <button
              onClick={() => {
                setsearch('');
                setdata([]);
                setShowPersonModal(false);
              }}
              style={{
                position: "absolute",
                right: "24px",
              }}
              className="primary_btn"
            >
              Done
            </button>
          </div>
        </div>
      </ScrollView>
    </div>
  );
};

export default PersonModal;

