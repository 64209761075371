import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, DateBox, DropDownBox } from "devextreme-react";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const AddCommunityServicePopup = ({
  addCommunityServicePopup,
  setAddCommunityServicePopup,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  getCommunityPlannedServiceListApi,
  setLoad,
  userinfo,
  plannedRowData,
  getCommunityServiceJailtimelogDropdown,
  gotoAddCommunity,
  dockertDetail,
  gotoCommunity,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState({
    hoursCompleted: "",
    completed: "",
    completeBy: "",
    startDate: "",
    reason: { name: "", id: "" },
    workSite: { name: "", id: "" },
  });
  const [reasonvalidation, setreasonvalidation] = useState("");
  const [startDateValidation, setStartDateValidation] = useState("");
  const [workSitevalidation, setWorkSitevalidation] = useState("");
  const [completeByvalidation, setCompleteByvalidation] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const findEndReason = () => {
    const matchingStatus = dropdownData?.communityReason?.find(
      (status) => status.value === plannedRowData?.[`Reason for CS`]
    );
    const endReasonId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      reason: {
        ...prevFields.reason,
        name: plannedRowData?.[`Reason for CS`],
        id: endReasonId,
      },
    }));
  };
  const findworkSiteCode = () => {
    const matchingStatus = dropdownData?.workSite?.find((status) => status.value === plannedRowData?.[`Work Site`]);
    const programCodeId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      workSite: {
        ...prevFields.workSite,
        name: plannedRowData?.[`Work Site`],
        id: programCodeId,
      },
    }));
  };

  useEffect(() => {
    if (gotoAddCommunity === "ADD") {
      setFields({
        hoursCompleted: "",
        completed: "",
        completeBy: "",
        startDate: "",
        reason: { name: "", id: "" },
        workSite: { name: "", id: "" },
      });
      const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
      setSelectedValue(selectedDocket);
      setreasonvalidation("");
      setStartDateValidation("");
      setWorkSitevalidation("");
      setCompleteByvalidation("");
    } else {
      setFields((prev) => ({
        ...prev,
        hoursCompleted: plannedRowData?.[`Hours Ordered`] || "",
        completed: plannedRowData?.[`Completion Date`],
        completeBy: plannedRowData?.[`Completed By Date`],
        startDate: plannedRowData?.[`Start Date`],
        reason: { name: plannedRowData?.[`Reason for CS`], id: "" },
        workSite: { name: plannedRowData?.[`Work Site`], id: "" },
      }));
      findEndReason();
      findworkSiteCode();
      const fetchedDataSource = docketDropdownData?.map((val) => {
        const convertDateFormat = (dateString) => {
          const dateObj = new Date(dateString);
          return dateObj.toLocaleDateString("en-US");
        };
        return {
          Court: val?.sCourtName,
          Docket: val?.sDocketNumberCourtCounts,
          "Sentence Date": convertDateFormat(val?.dSentence),
          "Supervision Status": val?.sSupervisionStatusName,
          gDocketId: val?.gDocketId,
          Offense: val?.sOffenseDescription,
        };
      });
      setDataSource(fetchedDataSource);
      const selectedDocket = dataSource?.find((item) => item.gDocketId === plannedRowData?.gDocketId);
      setSelectedValue(selectedDocket);
    }
  }, [addCommunityServicePopup, plannedRowData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const data = {
    GCommunityServiceId: plannedRowData?.gCommunityServiceId,
    GEntryUserId: userinfo?.gEntryUserId,
    GDocketId: selectedValue?.gDocketId,
    GCommunityServiceReasonId: fields?.reason?.id,
    GEntityId: fields?.workSite?.id,
    FHoursOrdered: fields?.hoursCompleted || 0,
    FHoursCompleted: 0.0,
    FHoursRemaining: 1.0,
    DStart: fields?.startDate,
    DCompleteBy: fields?.completeBy,
    DComplete: fields?.completed,
  };
  const navigate = useNavigate();

  const handleSave = async () => {
    var startDate = new Date(fields.startDate);
    var completeBy = new Date(fields.completeBy);
    let isValid = true;
    if (!fields.startDate) {
      setStartDateValidation("Please Select Start Date.");
      isValid = false;
    }
    if (!fields.completeBy) {
      setCompleteByvalidation("Please Select Complete By.");
      isValid = false;
    }
    if (fields.workSite.id === "") {
      setWorkSitevalidation("Please Select Work Site.");
      isValid = false;
    }
    if (fields.reason.id === "") {
      setreasonvalidation("Please Select Reason.");
      isValid = false;
    }
    if (completeBy <= startDate) {
      setCompleteByvalidation("Complete By Date should be greater than Start Date");
      isValid = false;
    }
    if (isValid) {
      setLoad(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/CommunityService/AddEditCommunityService`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoad(false);
          setAddCommunityServicePopup(false);
          getCommunityPlannedServiceListApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: gotoAddCommunity === "ADD" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: gotoAddCommunity === "ADD" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
          getCommunityServiceJailtimelogDropdown();
        })
        .catch((val) => {
          setLoad(false);
          if (val?.response?.status === 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="add_community_service_popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="add" clipPath="url(#clip0_5349_5505)">
                  <path
                    id="Vector"
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5349_5505">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Community Service</p>
            </div>
            <p class="popup_header_title_para">CM.1</p>
          </div>
          <svg
            onClick={() => {
              setAddCommunityServicePopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="add_community_service_content">
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Docket</p>
              <DropDownBox
                placeholder="Select..."
                disabled={gotoCommunity ? true : false}
                dataSource={dataSource}
                value={selectedValue?.Docket}
                valueExpr={"Docket"}
                displayExpr={"Docket"}
                opened={isGridBoxOpened}
                onOptionChanged={onGridBoxOpened}
                contentRender={(value, element) => (
                  <DataGrid
                    dataSource={dataSource}
                    hoverStateEnabled={true}
                    height={"auto"}
                    onRowClick={(e) => {
                      setSelectedValue(e?.data);
                      setIsGridBoxOpened(false);
                    }}
                  >
                    <Selection mode="single" />
                    <FilterRow visible={true} />
                    <Column dataField="Court" caption="Court" width={"auto"} />
                    <Column dataField="Docket" caption="Docket" width={"auto"} />
                    <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                    <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                    <Column dataField="Offense" caption="Offense" width={"auto"} />
                  </DataGrid>
                )}
              />
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Reason <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"reason"}
                dropDownData={dropdownData?.communityReason?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setreasonvalidation}
              />
              {reasonvalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "150px" }}>
                  {reasonvalidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Hours Ordered</p>
              <input
                id="hoursCompleted"
                name="hoursCompleted"
                type="number"
                value={fields.hoursCompleted}
                placeholder="Enter Hours Ordered"
                className="edit_container_input"
                onChange={handleInputChange}
              />
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Work Site <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"workSite"}
                dropDownData={dropdownData?.workSite?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setWorkSitevalidation}
              />
              {workSitevalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "240px" }}>
                  {workSitevalidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Start Date <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.startDate}
                onValueChange={(e) => {
                  const initialDateString = e;
                  const initialDate = new Date(initialDateString);
                  const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                  const utcFormatted = new Date(utcTime).toISOString();
                  setFields((prev) => ({
                    ...prev,
                    startDate: e ? utcFormatted : null,
                  }));
                  setStartDateValidation("");
                }}
              />
              {startDateValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "331px" }}>
                  {startDateValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Complete By <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.completeBy}
                onValueChange={(e) => {
                  const initialDateString = e;
                  const initialDate = new Date(initialDateString);
                  const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                  const utcFormatted = new Date(utcTime).toISOString();
                  setFields((prev) => ({
                    ...prev,
                    completeBy: e ? utcFormatted : null,
                  }));
                  setCompleteByvalidation("");
                }}
              />
              {completeByvalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "331px" }}>
                  {completeByvalidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Completed</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.completed}
                onValueChange={(e) => {
                  const initialDateString = e;
                  const initialDate = new Date(initialDateString);
                  const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                  const utcFormatted = new Date(utcTime).toISOString();
                  setFields((prev) => ({
                    ...prev,
                    completed: e ? utcFormatted : null,
                  }));
                }}
              />
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setAddCommunityServicePopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
